import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
} from '@material-ui/core';
import {
  t,
  GetUserGroupHistory,
  UserGroupUser,
} from '@psp/common';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import { useLoading } from '../../../contexts/loading.context';
import { useStyles } from './styles';
import UserGroupHistoryTable from '../../UserGroupHistoryTable';
import { useProgram } from '../../../contexts/program.context';

export type UserGroupHistoryProps = {
  getUserGroupHistory: GetUserGroupHistory;
};

type UserGroupHistoryState = {
  userGroupUsers: UserGroupUser[];
};

const defaultState: UserGroupHistoryState = {
  userGroupUsers: [],
};

export default function UserGroupHistory({
  getUserGroupHistory,
}: UserGroupHistoryProps): JSX.Element {
  const classes = useStyles();
  const { isLoading, showLoading, hideLoading } = useLoading();
  const { id } = useParams<{ id: string }>();
  const { program } = useProgram();
  const { goBack } = useHistory();
  const [state, setState] = useState<UserGroupHistoryState>(defaultState);

  const loadUserGroupHistory = useCallback(
    (userId) => {
      if (!program) return;
      showLoading();
      (async () => {
        try {
          const userGroupUsers = await getUserGroupHistory.execute({
            userId,
            programId: program.id,
          });
          setState({
            ...defaultState,
            userGroupUsers,
          });
        } catch (err) {
          console.log(err);
          toast.error(t('err.failedToLoadUserUserGroups'));
          goBack();
        } finally {
          hideLoading();
        }
      })();
    },
    [program, state],
  );

  useEffect(() => {
    if (program) {
      loadUserGroupHistory(id);
    }
  }, [program]);

  return (
    <Box className={classes.container}>
      <UserGroupHistoryTable
        data={state.userGroupUsers}
      />
    </Box>
  );
}
