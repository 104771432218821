import React from 'react';
import {
  getInventoryAmount,
  getProgramProducts,
  getProductPresentations,
  registerProcedure,
  validateTreatment,
  checkPatientRegistred,
  registerMedicine,
  registerPatient,
  getDinamicFieldsByOrigin,
} from '@psp/common';
import MedicationRelease, { MedicationReleaseProps } from '../../components/Medication/Release';

const dispatchers: Pick<
  MedicationReleaseProps,
  'getInventoryAmount'
  | 'getProgramProducts'
  | 'getProductPresentations'
  | 'registerProcedure'
  | 'validateTreatment'
  | 'checkPatientRegistred'
  | 'registerMedicine'
  | 'registerPatient'
  | 'getDinamicFieldsByOrigin'
> = {
  getInventoryAmount,
  getProgramProducts,
  getProductPresentations,
  registerProcedure,
  validateTreatment,
  checkPatientRegistred,
  registerMedicine,
  registerPatient,
  getDinamicFieldsByOrigin,
};

export default function MedicationReleaseComposer(): JSX.Element {
  return <MedicationRelease {...dispatchers} />;
}
