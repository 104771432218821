import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto 20px',
    padding: '10px',
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: '7px',
    backgroundColor: '#fff',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16.41px',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      padding: '8px',
    },
  },
  filterIcon: {
    marginRight: '10px',
    color: '#ccc',
  },
  filterInput: {
    border: 'none',
    flex: 1,
    fontSize: '16px',
    '&:focus': {
      outline: 'none',
    },
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '21.09px',
    letterSpacing: '0.38px',
    textAlign: 'left',
    marginBottom: '20px',
    color: '#282929',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '10px',
      fontSize: '18px',
    },
  },
}));
