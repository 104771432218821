import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import {
  Program,
  ProgramModules,
  buildModules,
  defaultModules,
  getPrograms,
  ProgramModulesEnum,
} from '@psp/common';
import { useAuth } from './auth.context';
import { useLoading } from './loading.context';

export type ProgramContextState = {
  programs?: Program[];
  program?: Program;
  selectProgram: (program: Program) => void;
  modules: ProgramModulesEnum[];
  modulesMap: ProgramModules;
};

export type ProgramContextProps = {
  children: ReactElement;
};

type Action =
  | { type: 'SET_PROGRAMS'; payload: Program[] }
  | { type: 'SELECT_PROGRAM'; payload: Program };

const stub = (): never => {
  throw new Error('Wrap your component in a ProgramProvider');
};

const defaultState: ProgramContextState = {
  selectProgram: stub,
  modules: [],
  modulesMap: defaultModules,
};

const reducer = (state: ProgramContextState, action: Action): ProgramContextState => {
  switch (action.type) {
    case 'SELECT_PROGRAM':
      return {
        ...state,
        program: action.payload,
        modules: (action.payload.modules || '').split(',') as ProgramModulesEnum[],
        modulesMap: {
          ...state.modulesMap,
          ...buildModules(action.payload.modules || ''),
        },
      };
    case 'SET_PROGRAMS':
    default:
      return {
        ...state,
        programs: action.payload,
      };
  }
};

export const ProgramContext = createContext<ProgramContextState>(defaultState);

export const ProgramProvider = ({ children }: ProgramContextProps): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, defaultState);
  const { isAuthenticated } = useAuth();
  const { showLoading, hideLoading } = useLoading();

  const selectProgram = useCallback((program: Program) => {
    dispatch({ type: 'SELECT_PROGRAM', payload: program });
  }, []);

  useEffect(() => {
    if (!isAuthenticated) return;
    (async () => {
      try {
        showLoading();
        const programs = await getPrograms.execute();
        dispatch({ type: 'SET_PROGRAMS', payload: programs });
      } catch (err) {
        console.log(err);
      } finally {
        hideLoading();
      }
    })();
  }, [isAuthenticated]);

  useEffect(() => {
    if (state.program) {
      localStorage.setItem('program', JSON.stringify(state.program));
    }
  }, [state.program]);

  useEffect(() => {
    try {
      const programStr = localStorage.getItem('program');
      if (programStr) {
        let prg = JSON.parse(programStr);
        if (state.programs) {
          prg = state.programs.find((p) => p.id === prg.id);
        }
        if (prg && prg.modules) {
          selectProgram(prg);
          return;
        }
      }
      if (state.programs && state.programs.length) {
        selectProgram(state.programs[0]);
      }
    } catch (err) {
      console.log(err);
    }
  }, [state.programs]);

  return (
    <ProgramContext.Provider
      value={{
        ...state,
        selectProgram,
      }}
    >
      {children}
    </ProgramContext.Provider>
  );
};

/* eslint-disable-next-line */
export const useProgram = (): ProgramContextState => useContext<ProgramContextState>(ProgramContext);
