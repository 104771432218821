import React, { useCallback, useEffect, useRef } from 'react';
import { Box, Grid, Paper } from '@material-ui/core';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import {
  extractValidationErrors,
  t,
  ChangeUserEmail,
  ChangeUserEmailParams,
  ValidationError,
  changeUserEmailForm,
} from '@psp/common';

import { LOGOUT_ROUTE, USER_EMAIL_CHANGE_ROUTE } from '../../../constants';
import { useLoading } from '../../../contexts/loading.context';
import Button from '../../Button';
import Input from '../../Input';
import { useStyles } from './styles';
import PaperTitle from '../../PaperTitle';
import PaperContent from '../../PaperContent';
import { useAuth } from '../../../contexts/auth.context';
import { useProgram } from '../../../contexts/program.context';

export type UserChangeEmailProps = {
  changeUserEmail: ChangeUserEmail;
};

export default function UserChangeEmail({
  changeUserEmail,
}: UserChangeEmailProps): JSX.Element {
  const classes = useStyles();
  const formRef = useRef({} as any);
  const { isLoading, showLoading, hideLoading, showBackdrop, hideBackdrop } = useLoading();
  const { push } = useHistory();
  const { user } = useAuth();
  const { program } = useProgram();

  const handleSuccess = useCallback(() => {
    if (program && program.slug) {
      window.location.href = `${LOGOUT_ROUTE}?program=${program.slug}`;
    } else {
      window.location.href = LOGOUT_ROUTE;
    }
  }, [program]);

  const handleSubmit = useCallback(
    (data) => {
      showLoading();
      async function runAsync() {
        try {
          const req = (await changeUserEmailForm.validate(data, {
            abortEarly: false,
          })) as ChangeUserEmailParams;
          await changeUserEmail.execute({
            ...req,
          });
          toast.success(t('msg.successToUpdateEmail'), {
            onOpen: showBackdrop,
            onClose: () => { handleSuccess(); },
          });
        } catch (err) {
          let errors = {};
          if (err instanceof ValidationError) {
            errors = extractValidationErrors(err);
            formRef.current.setErrors(errors);
          } else if (err.message === 'emailAlreadyInUse') {
            toast.warning(t('err.emailAlreadyInUse'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } else {
            toast.error(t('err.failedToUpdateEmail'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          }
        } finally {
          hideLoading();
        }
      }
      runAsync();
    },
    [],
  );

  useEffect(() => {
    showLoading();
    async function runAsync() {
      try {
        formRef.current.setData(user);
      } catch (err) {
        toast.error(t('err.failedToLoadUser'));
        push('/');
      } finally {
        hideLoading();
      }
    }
    runAsync();
  }, []);

  return (
    <Box className={classes.container}>
      <Paper className={classes.container}>
        <PaperTitle title={t(`navigation.${USER_EMAIL_CHANGE_ROUTE}`)} />
        <PaperContent>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Grid container spacing={2} direction="column">
              <Grid item xs={12} sm="auto">
                <Input
                  name="email"
                  variant="outlined"
                  label={t('email')}
                  className={classes.input}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" color="primary">
                  {t('save')}
                </Button>
              </Grid>
            </Grid>
          </Form>
        </PaperContent>
      </Paper>
    </Box>
  );
}
