"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DistributionType = void 0;
var DistributionType;
(function (DistributionType) {
    DistributionType["INCREASE"] = "INCREASE";
    DistributionType["REDUCE"] = "REDUCE";
    DistributionType["ADD_UNITS"] = "ADD_UNITS";
    DistributionType["REMOVE_UNITS"] = "REMOVE_UNITS";
})(DistributionType = exports.DistributionType || (exports.DistributionType = {}));
