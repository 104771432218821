import React from 'react';
import {
  getProgramSupplyGroups,
  getSupplyGroupSupplies,
  getUserAddress,
  getAddressByCEP,
  registerProcedure,
} from '@psp/common';
import SupplyRequest, {
  SupplyRequestProps,
} from '../../components/Supply/Request';

const dispatchers: Pick<
SupplyRequestProps,
  'getProgramSupplyGroups' | 'getSupplyGroupSupplies' | 'getUserAddress' |
  'getAddressByCEP' | 'registerProcedure'
> = {
  getProgramSupplyGroups,
  getSupplyGroupSupplies,
  getUserAddress,
  getAddressByCEP,
  registerProcedure,
};

export default function SupplyRequestComposer(): JSX.Element {
  return <SupplyRequest {...dispatchers} />;
}
