import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { formatPhone, formatCPF, t, PatientDetailsFromPatientCare, d } from '@psp/common';

import TextItem from '../TextItem';

import { useStyles } from './styles';
import PaperContent from '../PaperContent';

export type PatientInfoProps = {
  patient?: PatientDetailsFromPatientCare;
};

export default function PatientInfo({ patient }: PatientInfoProps): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <Paper className={classes.container}>
          <PaperContent>
            <Grid container spacing={2} direction="row">
              <Grid item spacing={2} direction="column" xs={6}>
                <Grid item xs={12}>
                  <strong>{t('patientData')}</strong>
                </Grid>
                <Grid item>
                  <TextItem label={t('fullName')} content={patient ? patient.patientName : '-'} />
                </Grid>
                <Grid item>
                  <TextItem label={t('birthDate')} content={patient ? patient.patientBirthDate : '-'} />
                </Grid>
                <Grid item>
                  <TextItem label={t('cpf')} content={patient ? formatCPF(patient.patientCPF) : '-'} />
                </Grid>
                <Grid item>
                  <TextItem label={t('phone')} content={patient && patient?.patientPhone ? formatPhone(patient.patientPhone) : '-'} />
                </Grid>
              </Grid>
              <Grid item spacing={2} direction="column" xs={6}>
                <Grid item>
                  <TextItem label={t('email')} content={patient ? patient.patientEmail : '-'} />
                </Grid>
                <Grid item>
                  <TextItem label={t('program')} content={patient ? patient.patientProgram : '-'} />
                </Grid>
                <Grid item>
                  <TextItem label={t('followup')} content={patient ? patient.followupDescription : '-'} />
                </Grid>
              </Grid>
            </Grid>
          </PaperContent>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper className={classes.container}>
          <PaperContent>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextItem label={t('patientPhase')} content={patient ? patient.planPhaseDescription : '-'} />
              </Grid>
              <Grid item>
                <TextItem label={t('patientPreRegister')} content={patient ? patient.preRegisterDate ?? '-' : '-'} />
              </Grid>
              <Grid item>
                <TextItem label={t('patientRegister')} content={patient ? patient.registerDate ?? '-' : '-'} />
              </Grid>
              <Grid item>
                <TextItem label={t('patientDiagnosis')} content={patient ? patient.diagnosisDate ?? '-' : '-'} />
              </Grid>
              <Grid item>
                <TextItem label={t('numberCallCentersNurses')} content={patient ? patient.numberCallCentersNurses : '-'} />
              </Grid>
            </Grid>
          </PaperContent>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper className={classes.container}>
          <PaperContent>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextItem label={t('treatmentStartDate')} content={patient ? patient.treatmentStartDate ?? '-' : '-'} />
              </Grid>
              <Grid item>
                <TextItem label={t('accessWay')} content={patient?.accessWay || '-'} />
              </Grid>
              <Grid item>
                <TextItem label={t('reasonInactivation')} content={patient?.reasonInactivation || '-'} />
              </Grid>
              <Grid item>
                <TextItem label={t('aplicationsQuantities')} content={patient?.applicationsQuantities || '-'} />
              </Grid>
            </Grid>
          </PaperContent>
        </Paper>
      </Grid>
    </>
  );
}
