import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { RequestOptout, t } from '@psp/common';

import { useLoading } from '../../contexts/loading.context';

export type RemoveAccountDialogProps = {
  onClose: () => void;
  open: boolean;
  requestOptout: RequestOptout;
};

export default function RemoveAccountDialog({
  onClose,
  open,
  requestOptout,
}: RemoveAccountDialogProps): JSX.Element {
  const { showBackdrop, hideBackdrop } = useLoading();

  const handleNoClick = () => {
    onClose();
  };

  const handleYesClick = () => {
    (async () => {
      try {
        await requestOptout.execute();
        toast.success(t('msg.successToRemoveAccount'), {
          onOpen: showBackdrop,
          onClose: hideBackdrop,
        });
      } catch (err) {
        toast.info(t('err.failedToRequestOptout'), {
          onOpen: showBackdrop,
          onClose: hideBackdrop,
        });
      } finally {
        onClose();
      }
    })();
  };

  return (
    <Dialog open={open} disableBackdropClick disableEscapeKeyDown maxWidth="xs">
      <DialogTitle>{t('deleteAccount')}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{t('msg.removeAccountConfirmation')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleNoClick}>{t('no')}</Button>
        <Button onClick={handleYesClick}>{t('yes')}</Button>
      </DialogActions>
    </Dialog>
  );
}
