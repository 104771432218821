import React, { useState } from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';

import Footer2 from '../../components/Footer2';
import PendingApproval from '../../components/PendingApproval';
import NavbarComposer from '../../composers/NavbarComposer';
import SidebarComposer from '../../composers/SidebarComposer';
import { useAuth } from '../../contexts/auth.context';
import { useStyles } from './styles';
import { useProgram } from '../../contexts/program.context';
import ProgramStyles from '../../components/ProgramStyles';

type MainLayoutProps = {
  children: JSX.Element;
};

export default function MainLayout({ children }: MainLayoutProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));
  const [isDrawerOpen, setIsDrawerOpen] = useState(!isXS);
  const { isDoctor, isApproved } = useAuth();
  const { program } = useProgram();

  function handleDrawerOpen(): void {
    setIsDrawerOpen(true);
  }

  function handleDrawerClose(): void {
    setIsDrawerOpen(false);
  }

  if (isDoctor && !isApproved) {
    return <PendingApproval />;
  }

  return (
    <div className={classes.container}>
      <div className={classes.mainLayout}>
        <ProgramStyles program={program} />
        <NavbarComposer
          isDrawerOpen={isDrawerOpen}
          onDrawerOpen={handleDrawerOpen}
          onDrawerClose={handleDrawerClose}
        />
        <SidebarComposer isDrawerOpen={isDrawerOpen} onDrawerClose={handleDrawerClose} />
        <main className={classes.main}>
          <div className={classes.content}>{children}</div>
        </main>
      </div>
      <Footer2 className={classes.footer} isDrawerOpen={isDrawerOpen} />
    </div>
  );
}
