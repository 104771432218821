"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.examRequestForm = void 0;
var Yup = __importStar(require("yup"));
var ducks_1 = require("../../ducks");
var cpf_1 = require("../types/cpf");
var phone_1 = require("../types/phone");
var cnpj_1 = require("../types/cnpj");
exports.examRequestForm = Yup.object().shape({
    programId: Yup.string().required(),
    groupId: Yup.string().required(),
    itemIds: Yup.array(Yup.string().required()).required().min(1),
    institutionId: Yup.string().when('$requiresSampleWithdrawal', function (requiresSampleWithdrawal, schema) { return requiresSampleWithdrawal ? schema.required() : schema; }),
    supplies: Yup.array(Yup.object().shape({
        itemId: Yup.string().required(),
        supplyId: Yup.string().required(),
        institutionId: Yup.string().required(),
    })),
    userAddress: Yup.object()
        .shape({
        recipientName: Yup.string().when('$userAddress', function (userAddress, schema) { return userAddress && schema.required(); }),
        recipientType: Yup.string().when('$userAddress', function (userAddress, schema) { return userAddress && schema.required(); }),
        pickUpTime: Yup.string().when(['$logisticType', '$requiresSampleWithdrawal'], function (logisticType, requiresSampleWithdrawal, schema) {
            if (requiresSampleWithdrawal && logisticType !== undefined && logisticType !== null && logisticType === ducks_1.ProcedureLogisticType.LOGISTIC_COMPANY) {
                return schema.required();
            }
            return schema;
        }),
        cep: Yup.string().when('$userAddress', function (userAddress, schema) { return userAddress && schema.required(); }),
        placeDescription: Yup.string().when('$userAddress', function (userAddress, schema) { return userAddress && schema.required(); }),
        number: Yup.string().when('$userAddress', function (userAddress, schema) { return userAddress && schema.required(); }),
        complement: Yup.string(),
        neighborhood: Yup.string().when('$userAddress', function (userAddress, schema) { return userAddress && schema.required(); }),
        city: Yup.string().when('$userAddress', function (userAddress, schema) { return userAddress && schema.required(); }),
        state: Yup.string().when('$userAddress', function (userAddress, schema) { return userAddress && schema.required(); }),
        save: Yup.boolean(),
        firstDateForSampleWithdrawal: Yup.date().min(new Date(new Date().setDate(new Date().getDate() + 1)), 'validation.dateNeedBeTwoDaysAfterToday'),
        secondDateForSampleWithdrawal: Yup.date().nullable().min(new Date(new Date().setDate(new Date().getDate() + 1)), 'validation.dateNeedBeTwoDaysAfterToday')
            .test('not-equal', 'Datas não podem ser iguais', function (value) {
            var firstDateForSampleWithdrawal = this.parent.firstDateForSampleWithdrawal;
            return !firstDateForSampleWithdrawal || !value || firstDateForSampleWithdrawal.getTime() !== value.getTime();
        }),
        thirdDateForSampleWithdrawal: Yup.date().nullable().min(new Date(new Date().setDate(new Date().getDate() + 1)), 'validation.dateNeedBeTwoDaysAfterToday')
            .test('not-equal', 'Datas não podem ser iguais', function (value) {
            var _a = this.parent, firstDateForSampleWithdrawal = _a.firstDateForSampleWithdrawal, secondDateForSampleWithdrawal = _a.secondDateForSampleWithdrawal;
            return (!firstDateForSampleWithdrawal || !secondDateForSampleWithdrawal || !value)
                || (firstDateForSampleWithdrawal.getTime() !== value.getTime()
                    && secondDateForSampleWithdrawal.getTime() !== value.getTime());
        }),
        fourthDateForSampleWithdrawal: Yup.date().nullable().min(new Date(new Date().setDate(new Date().getDate() + 1)), 'validation.dateNeedBeTwoDaysAfterToday')
            .test('not-equal', 'Datas não podem ser iguais', function (value) {
            var _a = this.parent, firstDateForSampleWithdrawal = _a.firstDateForSampleWithdrawal, secondDateForSampleWithdrawal = _a.secondDateForSampleWithdrawal, thirdDateForSampleWithdrawal = _a.thirdDateForSampleWithdrawal;
            return (!firstDateForSampleWithdrawal || !secondDateForSampleWithdrawal || !thirdDateForSampleWithdrawal || !value)
                || (firstDateForSampleWithdrawal.getTime() !== value.getTime()
                    && secondDateForSampleWithdrawal.getTime() !== value.getTime()
                    && thirdDateForSampleWithdrawal.getTime() !== value.getTime());
        }),
        documentType: Yup.string().when('$userAddress', function (userAddress, schema) { return userAddress && schema.required(); }),
        requesterName: Yup.string().when('$documentType', function (documentType, schema) { return documentType === 'cnpj' ? schema.required() : schema; }),
        requesterCnpj: Yup.string().when('$documentType', function (documentType, schema) { return documentType === 'cnpj' ? cnpj_1.cnpj.required() : schema; }),
        requesterCpf: Yup.string().when(['$documentType', '$hasSupplyRequest'], function (documentType, hasSupplyRequest, schema) { return documentType === 'cpf' || hasSupplyRequest ? cpf_1.cpf.required() : schema; }),
    }),
    logisticType: Yup.string().when('$userAddress', function (userAddress, schema) { return userAddress && schema.required(); }),
    procedureFieldsAnswers: Yup.object(),
    patient: Yup.object()
        .shape({
        cpf: cpf_1.cpf.required(),
        name: Yup.string().required(),
        phone: phone_1.phone.required(),
    })
        .required(),
    requirePatientAgreement: Yup.boolean(),
    agreementType: Yup.string().when('requirePatientAgreement', function (requirePatientAgreement, schema) { return (requirePatientAgreement
        ? schema
            .oneOf([ducks_1.ProcedureAgreementType.CODE, ducks_1.ProcedureAgreementType.FILE], 'validation.requiredField')
            .required()
        : schema); }),
    code: Yup.string().when(['agreementType', 'requirePatientAgreement'], function (agreementType, requirePatientAgreement, schema) {
        if (requirePatientAgreement && agreementType === ducks_1.ProcedureAgreementType.CODE) {
            return schema.min(5).required();
        }
        return schema;
    }),
    agreementFile: Yup.object().when(['agreementType', 'requirePatientAgreement'], function (agreementType, requirePatientAgreement, schema) {
        if (requirePatientAgreement && agreementType === ducks_1.ProcedureAgreementType.FILE) {
            return schema.required();
        }
        return schema;
    }),
});
