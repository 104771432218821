import React from 'react';
import { t, SortingType, d, formatCPF, PatientFromPatientCareForList } from '@psp/common';

import { Description } from '@material-ui/icons';
import Table, { TableProps } from '../Table';

export interface SortingConfiguration {
    propertyName: keyof {
        id: string;
        [key: string]: unknown;
    },
    sortType: SortingType,
    compareFunction: TableDataComparable
}

export type TableDataComparable = ((a: {
    id: string;
    [key: string]: unknown;
}, b: {
    id: string;
    [key: string]: unknown;
    }) => number);

const CompareByEquality = (column: keyof {
    id: string;
    [key: string]: unknown;
}) => (a: {
    id: string;
    [key: string]: unknown;
}, b: {
    id: string;
    [key: string]: unknown;
    }) => {
  if (a[column] === null && b[column] === null) {
    return 0;
  }
  if (a[column] === null) {
    return -1;
  }
  if (b[column] === null) {
    return 1;
  }

  if (a[column] === b[column]) {
    return 0;
  }

  if ((a[column] as any).toString() > (b[column] as any).toString()) {
    return -1;
  }
  return 1;
};

const columns = [
  {
    key: 'patientName',
    label: t('namePatient'),
    compareFunction: CompareByEquality('patientName'),
  },
  {
    key: 'patientCPF',
    label: t('cpf'),
    compareFunction: CompareByEquality('patientCPF'),
    render: function CpfRender(value: string) {
      return value != null ? formatCPF(value) : '- - -';
    },
  },
  {
    key: 'patientBirthDate',
    label: t('birthDate'),
    compareFunction: CompareByEquality('patientBirthDate'),
    render: function BirthDateRender(value: string) {
      return value != null ? value : '- - -';
    },
  },
  {
    key: 'caregiverName',
    label: t('caregiver'),
    compareFunction: CompareByEquality('caregiverName'),
    render: function CaregiverNameRender(value: string) {
      return value != null ? value : '- - -';
    },
  },
  {
    key: 'planPhaseDescription',
    label: t('phase'),
    compareFunction: CompareByEquality('planPhaseDescription'),
  },
  {
    key: 'followupDescription',
    label: t('followup'),
    compareFunction: CompareByEquality('followupDescription'),
  },
];

export type PatientTableProps = {
  data: PatientFromPatientCareForList[];
  onSortConfig: SortingConfiguration[];
  handleNavigateToPatientDetails: (item: any) => void;
} & Pick<
  TableProps,
    'canSearch' | 'count' | 'onChangePage' | 'onChangeRows' | 'onSearch' | 'page' | 'rowsPerPage' | 'onSortBy'
>;

export default function PatientTable({ data,
  onSortConfig,
  handleNavigateToPatientDetails,
  ...rest }: PatientTableProps): JSX.Element {
  const extraActions = [
    {
      icon: <Description />,
      label: '',
      onExecute: (item: any) => handleNavigateToPatientDetails(item),
    },
  ];

  return (
    <Table
      {...rest}
      columns={columns}
      data={data}
      title={t('patients')}
      size="small"
      extraActions={extraActions}
      onSortConfig={onSortConfig}
    />
  );
}
