import React from 'react';
import {
  distributeInventory,
  distributeInventoryXLSX,
  getDistributionRequiredAmount,
  getExamGroupExams,
  getFollowupExamGroups,
  getInventoryAmount,
  getPrograms,
  getProgramFollowups,
  getProgramProducts,
  getProductPresentations,
  getProductPatientProcedures,
  getUsersForDistribution,
} from '@psp/common';

import InventoryDistribution, {
  InventoryDistributionProps,
} from '../../components/Inventory/Distribution';

const dispatchers: Pick<
  InventoryDistributionProps,
  | 'distributeInventory'
  | 'distributeInventoryXLSX'
  | 'getDistributionRequiredAmount'
  | 'getExamGroupExams'
  | 'getFollowupExamGroups'
  | 'getInventoryAmount'
  | 'getPrograms'
  | 'getProgramFollowups'
  | 'getProgramProducts'
  | 'getProductPresentations'
  | 'getUsersForDistribution'
  | 'getProductPatientProcedures'
> = {
  distributeInventory,
  distributeInventoryXLSX,
  getDistributionRequiredAmount,
  getExamGroupExams,
  getFollowupExamGroups,
  getInventoryAmount,
  getPrograms,
  getProgramFollowups,
  getProgramProducts,
  getProductPresentations,
  getProductPatientProcedures,
  getUsersForDistribution,
};

export default function InventoryDistributionComposer(): JSX.Element {
  return <InventoryDistribution {...dispatchers} />;
}
