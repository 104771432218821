import React, { createContext, useContext, useEffect, useState, ReactElement } from 'react';
import {
  allModules,
  buildModulesFromPermissions,
  ProgramModules,
  ProgramModulesEnum,
  UserGroup,
  UserGroupPermission,
  UserType,
} from '@psp/common';

export type AuthContextState = {
  isActive: boolean;
  isAdmin: boolean;
  isApproved: boolean;
  isAuthenticated: boolean;
  isDoctor: boolean;
  isLoading: boolean;
  isRepresentative: boolean;
  isSysadmin: boolean;
  hasPendingPolicy: boolean;
  pendingUsagePolicies: any;
  modules: {
    [key: string]: ProgramModulesEnum[];
  };
  modulesMap: {
    [key: string]: ProgramModules;
  };
  role: UserType | '';
  user?: any;
};

export type AuthProviderProps = {
  children: ReactElement;
};

const defaultState: AuthContextState = {
  isActive: false,
  isAdmin: false,
  isApproved: false,
  isAuthenticated: false,
  isDoctor: false,
  isLoading: true,
  isRepresentative: false,
  isSysadmin: true,
  hasPendingPolicy: false,
  pendingUsagePolicies: [],
  modules: {},
  modulesMap: {},
  role: '',
  user: undefined,
};

export const AuthContext = createContext<AuthContextState>(defaultState);

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const [state, setState] = useState<AuthContextState>(defaultState);

  useEffect(() => {
    async function runAsync() {
      try {
        const response = await fetch('/user');
        const user = await response.json();
        if (!user) {
          setState({
            ...defaultState,
            isLoading: false,
          });
        } else {
          const userModules = user.userGroups
            .map((ug: any) => ({
              programId: ug.programId,
              modules: ug.userGroupPermissions.map((ugp: any) => ugp.type),
            }))
            .reduce(
              (m: any, c: any) => ({
                ...(m || {}),
                [c.programId]:
                  m[c.programId] === null || c.modules.includes(null)
                    ? allModules
                    : [m[c.programId] || '', ...c.modules].join(','),
              }),
              {},
            );

          const modulesMap = (Object.keys(userModules).reduce(
            (m: any, c: any) => ({
              ...m,
              [c]: buildModulesFromPermissions(userModules[c]),
            }),
            {},
          ) as unknown) as any;

          setState({
            isActive: user.active,
            isAdmin: user.roles.includes('ADMIN'),
            isApproved: user.approved,
            isAuthenticated: true,
            isDoctor: user.roles.includes('DOCTOR'),
            isLoading: false,
            isRepresentative: user.roles.includes('REPRESENTATIVE'),
            isSysadmin: user.roles.includes('SYSADMIN'),
            hasPendingPolicy: user.pendingUsagePolicies.length > 0,
            pendingUsagePolicies: user.pendingUsagePolicies,
            role: user.roles[0],
            modules: userModules,
            modulesMap,
            user,
          });
        }
      } catch (err) {
        console.log(err);
        setState({
          ...defaultState,
          isLoading: false,
        });
      }
    }
    runAsync();
  }, []);

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextState => useContext<AuthContextState>(AuthContext);
