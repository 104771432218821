import React, { useCallback, useEffect, useState } from 'react';
import { FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, TextField, Select, SelectProps } from '@material-ui/core';

interface Resposta {
    codigoQuestao: number;
    codigoResposta: number;
    textoDigitado?: string;
}

interface DropdownInputProps {
    name: string;
    label: string;
    options: { CodigoResposta: number; TituloExibicao: string }[];
    disabled: boolean;
    onChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
}

const DropdownInput: React.FC<DropdownInputProps> = (
  { name,
    label,
    options,
    disabled,
    onChange },
) => (
  <FormControl fullWidth disabled={disabled}>
    <InputLabel>{label}</InputLabel>
    <Select name={name} onChange={onChange}>
      {options.map((option) => (
        <MenuItem key={option.CodigoResposta} value={option.CodigoResposta}>
          {option.TituloExibicao}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

const RadioInput = ({ name, label, options, onChange }: any) => (
  <FormControl component="fieldset">
    <InputLabel>{label}</InputLabel>
    <RadioGroup name={name} onChange={onChange}>
      {options.map((option: any) => (
        <FormControlLabel
          key={option.CodigoResposta}
          value={option.CodigoResposta}
          control={<Radio />}
          label={option.TituloExibicao}
        />
      ))}
    </RadioGroup>
  </FormControl>
);

interface RespostaLista {
  CodigoResposta: number;
  TituloExibicao: string;
}

interface Questao {
  CodigoQuestao: number;
  CodigoQuestaoPai: number | null;
  TituloExibicao: string;
  TipoControle: string;
  Respostas: RespostaLista[];
  QuestaoAninhada?: {
    CodigoRespostaPai: number;
  } | null;
}
interface DynamicFieldsProps {
  questoes: Questao[];
  respostaSelecionadaRef: React.RefObject<Resposta[]>;
}

export default function DynamicFields({
  questoes,
  respostaSelecionadaRef,
}: DynamicFieldsProps) {
  const [respostas, setRespostas] = useState<any[]>(respostaSelecionadaRef.current || []);
  const [questoesFiltradas, setQuestoesFiltradas] = useState(questoes);

  useEffect(() => {
    respostaSelecionadaRef.current?.pop();
    respostaSelecionadaRef.current?.push(...respostas);
  }, [respostas]);

  const deveExibirQuestaoAninhada = useCallback((questao: any) => {
    if (questao.CodigoQuestaoPai) {
      const pai = questoes.find((q: any) => q.CodigoQuestao === questao.CodigoQuestaoPai);

      if (pai && pai.QuestaoAninhada) {
        const respostaPai = respostaSelecionadaRef.current?.find(
          (res: any) => res.codigoQuestao === pai.CodigoQuestao,
        );
        return respostaPai ? respostaPai.codigoResposta === pai
          .QuestaoAninhada.CodigoRespostaPai : false;
      }
    }
    return true;
  }, [questoes, respostas]);

  useEffect(() => {
    const resultQuestoesFiltradas = questoes.filter(
      (questao: any) => deveExibirQuestaoAninhada(questao),
    );
    setQuestoesFiltradas(resultQuestoesFiltradas);
  }, [respostas, questoes, deveExibirQuestaoAninhada]);

  const onRespostaChange = (
    codigoQuestao: number,
    codigoResposta: number,
    textoDigitado?: string,
  ) => {
    setRespostas((prevRespostas) => {
      const respostaIndex = prevRespostas.findIndex(
        (res) => res.codigoQuestao === codigoQuestao,
      );
      let newRespostas;
      if (respostaIndex !== -1) {
        newRespostas = [...prevRespostas];
        newRespostas.splice(respostaIndex, 1, { codigoQuestao, codigoResposta, textoDigitado });
      } else {
        newRespostas = [...prevRespostas, { codigoQuestao, codigoResposta, textoDigitado }];
      }
      return newRespostas;
    });
  };

  return (
    <>
      <Grid>
        {questoesFiltradas.map((questao: any) => {
          if (!deveExibirQuestaoAninhada(questao)) {
            return null;
          }

          if (questao.TipoControle === 'textbox') {
            return (
              <Grid item xs={12} sm={4} lg={3}>
                <TextField
                  key={questao.CodigoQuestao}
                  label={questao.TituloExibicao}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  onBlur={(e) => onRespostaChange(
                    questao.CodigoQuestao, questao.Respostas[0].CodigoResposta, e.target.value,
                  )}
                />
              </Grid>
            );
          }

          if (questao.TipoControle === 'dropdownlist') {
            return (
              <Grid item xs={12} sm={4} lg={3}>
                <DropdownInput
                  key={questao.CodigoQuestao}
                  name={`questao-${questao.CodigoQuestao}`}
                  label={questao.TituloExibicao}
                  options={questao.Respostas}
                  disabled={false}
                  onChange={(e) => onRespostaChange(
                    questao.CodigoQuestao, Number(e.target.value),
                  )}
                />
              </Grid>
            );
          }

          if (questao.TipoControle === 'radiobuttonlist') {
            return (
              <Grid item xs={12} sm={4} lg={3}>
                <RadioInput
                  key={questao.CodigoQuestao}
                  name={`questao-${questao.CodigoQuestao}`}
                  label={questao.TituloExibicao}
                  options={questao.Respostas}
                  onChange={(e: any) => onRespostaChange(
                    questao.CodigoQuestao, e.target.value,
                  )}
                />
              </Grid>
            );
          }
          return null;
        })}
      </Grid>
    </>
  );
}
