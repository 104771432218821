import React from 'react';
import MaskedInput from 'react-text-mask';

type DocumentCnpjInputProps = {
  inputRef: (ref: HTMLInputElement | null) => void;
  [key: string]: any;
};

const cnpjMask = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];

export default function DocumentCnpjInput({ inputRef, ...rest }: DocumentCnpjInputProps):
  JSX.Element {
  return (
    <MaskedInput
      {...rest}
      ref={(ref: any): void => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={cnpjMask}
      placeholderChar="_"
    />
  );
}
