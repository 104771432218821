import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  Paper,
  Tooltip,
} from '@material-ui/core';
import { Form } from '@unform/web';
import {
  GetInventoryAmount,
  GetProductPresentations,
  GetProgramProducts,
  RegisterProcedure,
  t,
  InventoryType,
  RegisterPatient,
  ValidateTreatment,
  CheckPatientRegistred,
  RegisterMedicine,
  GetDinamicFieldsByOrigin,
} from '@psp/common';
import { toast } from 'react-toastify';

import { useLoading } from '../../../contexts/loading.context';
import Input from '../../Input';
import CheckboxInput from '../../CheckboxInput';
import GenderSelect from '../../GenderSelect';
import DateInput from '../../DateInput';
import DocumentInput from '../../DocumentInput';
import Button from '../../Button';
import MaskedPhoneInput from '../../MaskedPhoneInput';
import ProductSelect, { ProductSelectValues } from '../../ProductSelect';

import { useStyles, useTooltipStyles } from './styles';
import { useProgram } from '../../../contexts/program.context';
import PaperTitle from '../../PaperTitle';
import PaperContent from '../../PaperContent';
import Chip from '../../Chip';
import CrmInput from '../../CrmInput';
import StateSelect from '../../StateSelect';
import { useAuth } from '../../../contexts/auth.context';
import { FUNCIONAL_WS_ACESSO_REST_ORIGEM, PROGRAM_ID } from '../../../constants';
import DynamicFields from '../../WSAcessoDynamicFields';

export type MedicationReleaseProps = {
  getInventoryAmount: GetInventoryAmount;
  getProgramProducts: GetProgramProducts;
  getProductPresentations: GetProductPresentations;
  registerProcedure: RegisterProcedure;
  validateTreatment: ValidateTreatment;
  checkPatientRegistred: CheckPatientRegistred;
  registerMedicine: RegisterMedicine;
  registerPatient: RegisterPatient;
  getDinamicFieldsByOrigin: GetDinamicFieldsByOrigin;
};

type MedicationReleaseState = {
  inventory?: number;
  presentationEan: string;
  presentationName: string;
};

const defaultState: MedicationReleaseState = {
  inventory: undefined,
  presentationEan: '',
  presentationName: '',
};

interface Resposta {
  codigoQuestao: number;
  codigoResposta: number;
  textoDigitado?: string;
}

export default function MedicationRelease({
  validateTreatment,
  checkPatientRegistred,
  getInventoryAmount,
  registerProcedure,
  registerPatient,
  registerMedicine,
  getDinamicFieldsByOrigin,
  ...dispatchers
}: MedicationReleaseProps): JSX.Element {
  const classes = useStyles();
  const tooltipClasses = useTooltipStyles();
  const formRef = useRef({} as any);
  const patientQuestionsRef = useRef<any[]>([]);
  const medicineQuestionsRef = useRef<any[]>([]);
  const allQuestionsRef = useRef<any[]>([]);
  const firstQuestion = useRef({} as any);
  const indicationRef = useRef({} as any);
  const { isLoading, showLoading, hideLoading, showBackdrop, hideBackdrop } = useLoading();
  const { program, modulesMap } = useProgram();
  const { isDoctor } = useAuth();
  const [state, setState] = useState<MedicationReleaseState>(defaultState);
  const [showPhoneTooltip, setShowPhoneTooltip] = useState(false);
  const respostasSelecionadasRef = useRef<Resposta[]>([]);
  const [isRegistered, setIsRegistered] = useState<boolean | null>(null);
  const cpfInputRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef(state);

  const doctorBenefits: boolean = useMemo(
    () => modulesMap.doctorBenefits && !isDoctor, [modulesMap.doctorBenefits, isDoctor],
  );

  useEffect(() => {
    (async () => {
      const questions = await getDinamicFieldsByOrigin.execute({
        payload: {
          codigoOrigem: 23,
        },
      });

      const allQuestions = questions.ListarCamposDinamicosOrigemJsonResult.Questionario || [];

      allQuestionsRef.current = allQuestions;

      formRef.current.setFieldValue('prescriberDoctor.firstMedicine', true);
    })();
  }, []);

  const handlePhoneFocus = () => {
    setShowPhoneTooltip(true);
  };

  const handlePhoneBlur = () => {
    setShowPhoneTooltip(false);
  };

  const updateState = (newState: any) => {
    stateRef.current = newState;
    setState(newState);
  };

  const handleProductChange = useCallback(
    (value: ProductSelectValues) => {
      if (!value || !value.productId || !value.presentationId) return;

      showLoading();

      updateState({
        ...stateRef.current,
        inventory: undefined,
        presentationEan: value.presentationEan,
        presentationName: value.presentationName,
      });

      const fetchInventory = async () => {
        try {
          const inventory = await getInventoryAmount.execute({
            inventory: InventoryType.FIRST_MEDICINE,
            groupId: value.productId,
            itemId: value.presentationId,
          });

          updateState({
            ...stateRef.current,
            inventory,
            presentationEan: value.presentationEan,
            presentationName: value.presentationName,
          });
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      };

      medicineQuestionsRef.current = allQuestionsRef.current.filter(
        (questao) => questao.FlagTipoQuestao === 2 && questao.EAN.includes(value.presentationEan),
      );

      patientQuestionsRef.current = allQuestionsRef.current.filter(
        (questao) => questao.FlagTipoQuestao === 1,
      );

      firstQuestion.current = patientQuestionsRef.current.find(
        (questao) => questao.CodigoQuestaoPai === null,
      );
      fetchInventory();
    },
    [],
  );

  const validatePatientFormData = (formData: any): string | null => {
    if (!formData.patient.name) {
      return 'O nome do paciente é obrigatório.';
    }
    if (!formData.patient.gender) {
      return 'O sexo biológico do paciente é obrigatório.';
    }
    if (!formData.patient.birthDate) {
      return 'A data de nascimento do paciente é obrigatória.';
    }
    if (!formData.patient.email) {
      return 'O email do paciente é obrigatório.';
    }
    if (!formData.patient.mobile) {
      return 'O celular do paciente é obrigatório.';
    }
    if (!formData.patient.state) {
      return 'O estado do paciente é obrigatório.';
    }
    if (!formData.patient.city) {
      return 'A cidade do paciente é obrigatória.';
    }
    if (!formData.patient.neighborhood) {
      return 'O bairro do paciente é obrigatório.';
    }
    if (!formData.patient.address) {
      return 'O endereço do paciente é obrigatório.';
    }
    if (!formData.patient.number) {
      return 'O número da residência do paciente é obrigatório.';
    }
    if (!formData.patient.cep) {
      return 'O CEP do paciente é obrigatório.';
    }
    if (!formData.prescriberDoctor.name) {
      return 'O nome do médico é obrigatório.';
    }
    if (!formData.prescriberDoctor.crm) {
      return 'O CRM do médico é obrigatório.';
    }
    if (!formData.prescriberDoctor.uf) {
      return 'O UF do CRM do médico é obrigatório.';
    }
    return null;
  };

  const validateMedicineFormData = (formData: any): string | null => {
    if (!formData.prescriberDoctor.crm) {
      return 'O CRM do médico é obrigatório.';
    }
    if (!formData.prescriberDoctor.uf) {
      return 'O UF do CRM é obrigatório.';
    }
    return null;
  };

  const handleCheckCpf = useCallback(
    (data: any): void => {
      showLoading();
      (async () => {
        try {
          const formData = await formRef.current.getData();
          const cpfInput = formData.patient.cpf.replace(/\D/g, '');
          const response = await checkPatientRegistred.execute({
            payload: {
              cpf: cpfInput,
            },
          });

          if (response.PacienteCadastradoJsonResult.Status === '00') {
            setIsRegistered(true);
          } else {
            setIsRegistered(false);
          }
        } catch (err) {
          console.log(err);
          if (err.message === 'invalidCpf') {
            toast.info(t('CPF inválido'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } else {
            toast.info(t('Falha ao verificar o CPF'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          }
        } finally {
          hideLoading();
        }
      })();
    },
    [],
  );

  const handleSubmit = useCallback(
    (data: any): void => {
      if (!program) return;
      showLoading();
      (async () => {
        try {
          const formData = await formRef.current.getData();
          if (!formData) {
            toast.error('Erro ao obter os dados do formulário.');
            return;
          }

          const validationError = validatePatientFormData(formData);
          if (validationError) {
            hideLoading();
            toast.info(validationError);
            return;
          }
          const validationResponse = await validateTreatment.execute({
            payload: {
              cpf: formData.patient.cpf,
              ean: stateRef.current.presentationEan,
              numeroCRMO: formData.prescriberDoctor.crm,
              crmUf: formData.prescriberDoctor.uf,
              tipoCRMO: '1',
            },
          });

          if (validationResponse.ValidarInicioTratamentoJsonResult.Status === '00') {
            const birthDate = new Date(formData.patient.birthDate);
            const formattedBirthDate = birthDate.toLocaleDateString('pt-BR', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            });

            const patientAnswers = Object.values(
              respostasSelecionadasRef.current.reduce<Record<number, Resposta>>((acc, curr) => {
                acc[curr.codigoQuestao] = curr;
                return acc;
              }, {}),
            );

            const response = await registerPatient.execute({
              payload: {
                cadastrarPaciente: {
                  CPF: formData.patient.cpf,
                  CodigoOrigem: FUNCIONAL_WS_ACESSO_REST_ORIGEM,
                  Formularios: [
                    {
                      TipoFormulario: 'Paciente',
                      Campos: [
                        { NomeCampo: 'Nome', Valor: formData.patient.name },
                        { NomeCampo: 'DataNascimento', Valor: formattedBirthDate },
                        { NomeCampo: 'Sexo', Valor: formData.patient.gender },
                        { NomeCampo: 'Telefone', Valor: formData.patient.phone },
                        { NomeCampo: 'TelefoneCelular', Valor: formData.patient.mobile },
                        { NomeCampo: 'Email', Valor: formData.patient.email },
                        { NomeCampo: 'UF', Valor: formData.patient.state },
                        { NomeCampo: 'Cidade', Valor: formData.patient.city },
                        { NomeCampo: 'Bairro', Valor: formData.patient.neighborhood },
                        { NomeCampo: 'Endereco', Valor: formData.patient.address },
                        { NomeCampo: 'Numero', Valor: formData.patient.number },
                        { NomeCampo: 'CEP', Valor: formData.patient.cep },
                        { NomeCampo: 'AutorizaContato', Valor: formData.patient.acceptContact ? 'S' : 'N' },
                        { NomeCampo: 'AutorizaContatoTelefone', Valor: formData.patient.acceptPhone ? 'S' : 'N' },
                        { NomeCampo: 'AutorizaContatoEmail', Valor: formData.patient.acceptEmail ? 'S' : 'N' },
                        { NomeCampo: 'AutorizaContatoSMS', Valor: formData.patient.acceptSms ? 'S' : 'N' },
                        { NomeCampo: 'AutorizaContatoCorreio', Valor: formData.patient.acceptMail ? 'S' : 'N' },
                      ],
                      CamposDinamicos: patientAnswers.map((resposta) => ({
                        CodigoPrograma: PROGRAM_ID,
                        CodigoQuestao: resposta.codigoQuestao,
                        CodigoResposta: resposta.codigoResposta,
                        FlagTipoQuestao: 1,
                        ValorDigitado: resposta.textoDigitado || '',
                      })),
                    },
                    {
                      TipoFormulario: 'Medicamento',
                      Campos: [
                        { NomeCampo: 'NomeMedicamento', Valor: stateRef.current.presentationName },
                        { NomeCampo: 'NomeMedico', Valor: formData.prescriberDoctor.name },
                        { NomeCampo: 'CRM', Valor: formData.prescriberDoctor.crm },
                        { NomeCampo: 'TipoConselho', Valor: '1' },
                        { NomeCampo: 'CRMUF', Valor: formData.prescriberDoctor.uf },
                        { NomeCampo: 'Ean', Valor: stateRef.current.presentationEan },
                        { NomeCampo: 'InicioTratamentoHabilitado', Valor: formData.prescriberDoctor.firstMedicine },
                      ],
                      CamposDinamicos: indicationRef.current?.codigoQuestao
                      && indicationRef.current?.codigoResposta
                        ? [
                          {
                            CodigoPrograma: PROGRAM_ID,
                            CodigoQuestao: indicationRef.current.codigoQuestao,
                            CodigoResposta: indicationRef.current.codigoResposta,
                            FlagTipoQuestao: 2,
                            ValorDigitado: '',
                          },
                        ]
                        : [],
                    },
                  ],
                },
              },
            });

            if (response.CadastrarPacienteJsonResult.Status === '00') {
              toast.success(t('Paciente cadastrado com sucesso'), {
                onOpen: showBackdrop,
                onClose: () => {
                  hideBackdrop();
                  window.location.reload();
                },
              });
              formRef.current.reset();
              setState(defaultState);
            } else {
              toast.info(response.CadastrarPacienteJsonResult?.Mensagem || 'Erro desconhecido', {
                onOpen: showBackdrop,
                onClose: hideBackdrop,
              });
            }
          } else {
            toast.info('Paciente não elegível para início de tratamento', {
              onOpen: showBackdrop,
              onClose: () => {
                hideBackdrop();
                window.location.reload();
              },
            });
          }
        } catch (err) {
          console.log(err);
          toast.error(t('Falha ao registrar o paciente'), {
            onOpen: showBackdrop,
            onClose: () => {
              hideBackdrop();
              window.location.reload();
            },
          });
        } finally {
          hideLoading();
        }
      })();
    },
    [program],
  );

  const handleSubmitRegisterMedicine = useCallback(
    (data: any): void => {
      if (!program) return;
      showLoading();
      (async () => {
        try {
          const formData = await formRef.current.getData();
          if (!formData) {
            toast.error('Erro ao obter os dados do formulário.');
            return;
          }
          const validationError = validateMedicineFormData(formData);
          if (validationError) {
            hideLoading();
            toast.info(validationError);
            return;
          }

          const validationResponse = await validateTreatment.execute({
            payload: {
              cpf: formData.patient.cpf,
              ean: stateRef.current.presentationEan,
              numeroCRMO: formData.prescriberDoctor.crm,
              crmUf: formData.prescriberDoctor.uf,
              tipoCRMO: '1',
            },
          });

          if (validationResponse.ValidarInicioTratamentoJsonResult.Status === '00') {
            const response = await registerMedicine.execute({
              payload: {
                formularioMedicamento: {
                  CPF: formData.patient.cpf,
                  CodigoOrigem: '23',
                  Formularios: [
                    {
                      TipoFormulario: 'Medicamento',
                      Campos: [
                        { NomeCampo: 'NomeMedicamento', Valor: stateRef.current.presentationName },
                        { NomeCampo: 'EAN', Valor: stateRef.current.presentationEan },
                        { NomeCampo: 'CRM', Valor: formData.prescriberDoctor.crm },
                        { NomeCampo: 'TipoConselho', Valor: 1 },
                        { NomeCampo: 'CRMUF', Valor: formData.prescriberDoctor.uf },
                        { NomeCampo: 'InicioTratamentoHabilitado', Valor: formData.prescriberDoctor.firstMedicine },
                      ],
                      CamposDinamicos: [
                        {
                          CodigoPrograma: PROGRAM_ID,
                          CodigoQuestao: indicationRef.current.codigoQuestao,
                          CodigoResposta: indicationRef.current.codigoResposta,
                          FlagTipoQuestao: 2,
                          ValorDigitado: '',
                        },
                      ],
                    },
                  ],
                },
              },
            });

            if (response.CadastrarMedicamentoJsonResult.Status === '00') {
              toast.success(t('Medicamento liberado com sucesso'), {
                onOpen: showBackdrop,
                onClose: () => {
                  hideBackdrop();
                  window.location.reload();
                },
              });
              formRef.current.reset();
              setState(defaultState);
            } else {
              toast.info(response.CadastrarMedicamentoJsonResult?.Mensagem || 'Erro desconhecido', {
                onOpen: showBackdrop,
                onClose: hideBackdrop,
              });
            }
          } else {
            toast.info('Paciente não elegível para início de tratamento', {
              onOpen: showBackdrop,
              onClose: () => {
                hideBackdrop();
                window.location.reload();
              },
            });
          }
        } catch (err) {
          console.log(err);
          toast.error(t('Falha ao liberar medicamento'), {
            onOpen: showBackdrop,
            onClose: () => {
              hideBackdrop();
              window.location.reload();
            },
          });
        } finally {
          hideLoading();
        }
      })();
    },
    [program],
  );

  return (
    <Box className={classes.container}>
      <Paper className={classes.paper}>
        <PaperTitle title={t('selectMedication')} />
        <PaperContent>
          <Form
            onSubmit={handleSubmit}
            ref={formRef}
            initialData={{ patient: { acceptSms: true, acceptEmail: true } }}
          >
            <Grid container spacing={2} direction="column">
              <Grid item xs={12} sm={10} lg={6}>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  alignItems="center"
                  className={classes.nowrap}
                >
                  <Grid item xs={12} sm={10}>
                    <ProductSelect
                      {...dispatchers}
                      formRef={formRef}
                      onSelectedValueChange={handleProductChange}
                    />
                  </Grid>
                  <Grid item xs="auto">
                    {state.inventory !== undefined && (
                      <Chip label={`Estoque disponível: ${state.inventory}`} color="info" />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {state.inventory !== undefined && state.inventory > 0 && (
                <Grid item>
                  <Grid container spacing={2} direction="column" className={classes.nowrap}>
                    <Grid item>
                      <Grid container spacing={2} direction="row">
                        <Grid item xs={12} sm={4} lg={3}>
                          <Input
                            name="patient.cpf"
                            variant="outlined"
                            label={t('cpf')}
                            className={classes.input}
                            disabled={isLoading}
                            autoComplete="off"
                            InputProps={{
                              inputComponent: DocumentInput as any,
                            }}
                            inputRef={cpfInputRef}
                          />
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCheckCpf}
                            disabled={isLoading}
                          >
                            Buscar
                          </Button>
                        </Grid>
                      </Grid>
                      {isRegistered === false && (
                        <>
                          <Grid container spacing={2} direction="row" style={{ marginTop: 8 }}>
                            <Grid item xs={12} sm={4} lg={3}>
                              <Input
                                name="patient.name"
                                variant="outlined"
                                label={t('fullName')}
                                className={classes.input}
                                disabled={isLoading}
                                autoComplete="nope"
                              />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3}>
                              <GenderSelect
                                name="patient.gender"
                                variant="outlined"
                                label={t('gender')}
                                displayEmpty
                                className={classes.input}
                                disabled={isLoading}
                                optionalValueToWSAcesso
                              />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3}>
                              <DateInput
                                name="patient.birthDate"
                                inputVariant="outlined"
                                label={t('birthDate')}
                                format="dd/MM/yyyy"
                                className={classes.input}
                                disabled={isLoading}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3}>
                              <Input
                                name="patient.email"
                                variant="outlined"
                                label={t('email')}
                                className={classes.input}
                                disabled={isLoading}
                                autoComplete="nope"
                              />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3}>
                              <Tooltip
                                open={showPhoneTooltip}
                                placement="top"
                                title={t('tooltips.patientCellphone')}
                                classes={tooltipClasses}
                              >
                                <Input
                                  name="patient.mobile"
                                  variant="outlined"
                                  label={t('cellphone')}
                                  className={classes.input}
                                  disabled={isLoading}
                                  autoComplete="nope"
                                  onFocus={handlePhoneFocus}
                                  onBlur={handlePhoneBlur}
                                  InputProps={{
                                    inputComponent: MaskedPhoneInput as any,
                                  }}
                                />
                              </Tooltip>
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3}>
                              <Tooltip
                                open={showPhoneTooltip}
                                placement="top"
                                title={t('tooltips.patientPhone')}
                                classes={tooltipClasses}
                              >
                                <Input
                                  name="patient.phone"
                                  variant="outlined"
                                  label={t('phone')}
                                  className={classes.input}
                                  disabled={isLoading}
                                  autoComplete="nope"
                                  onFocus={handlePhoneFocus}
                                  onBlur={handlePhoneBlur}
                                  InputProps={{
                                    inputComponent: MaskedPhoneInput as any,
                                  }}
                                  optional
                                />
                              </Tooltip>
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3}>
                              <StateSelect
                                name="patient.state"
                                variant="outlined"
                                label={t('uf')}
                                fullWidth
                                displayEmpty
                                className={classes.input}
                                disabled={isLoading}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3}>
                              <Input
                                name="patient.city"
                                variant="outlined"
                                label={t('city')}
                                className={classes.input}
                                disabled={isLoading}
                                autoComplete="nope"
                              />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3}>
                              <Input
                                name="patient.neighborhood"
                                variant="outlined"
                                label={t('neighborhood')}
                                className={classes.input}
                                disabled={isLoading}
                                autoComplete="nope"
                              />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3}>
                              <Input
                                name="patient.address"
                                variant="outlined"
                                label={t('address')}
                                className={classes.input}
                                disabled={isLoading}
                                autoComplete="nope"
                              />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3}>
                              <Input
                                name="patient.number"
                                variant="outlined"
                                label={t('number')}
                                className={classes.input}
                                disabled={isLoading}
                                autoComplete="nope"
                              />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3}>
                              <Input
                                name="patient.cep"
                                variant="outlined"
                                label={t('cep')}
                                className={classes.input}
                                disabled={isLoading}
                                autoComplete="nope"
                                InputProps={{
                                  inputProps: {
                                    maxLength: 9,
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3}>
                              <Input
                                name="prescriberDoctor.name"
                                variant="outlined"
                                label={t('doctorName')}
                                className={classes.input}
                                disabled={isLoading}
                                autoComplete="nope"
                              />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3}>
                              <Input
                                name="prescriberDoctor.crm"
                                variant="outlined"
                                label={t('crm')}
                                className={classes.input}
                                disabled={isLoading}
                                autoComplete="nope"
                                InputProps={{
                                  inputProps: {
                                    maxLength: 6,
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3}>
                              <StateSelect
                                name="prescriberDoctor.uf"
                                variant="outlined"
                                label={t('crmUf')}
                                fullWidth
                                displayEmpty
                                className={classes.input}
                                disabled={isLoading}
                              />
                            </Grid>
                            <Grid container spacing={2} direction="column" style={{ marginLeft: 10, marginTop: 20 }}>
                              <CheckboxInput
                                name="prescriberDoctor.firstMedicine"
                                label={t('Início de Tratamento')}
                                disabled={isLoading}
                                defaultChecked
                                onChange={(event) => {
                                  formRef.current.setFieldValue('prescriberDoctor.firstMedicine', event.target.checked);
                                }}
                              />
                            </Grid>
                            <Grid container spacing={2} direction="column" style={{ marginLeft: 10, marginTop: 20 }}>
                              {medicineQuestionsRef.current.map((questao) => (
                                <Grid key={questao.CodigoQuestao}>
                                  <FormControl component="fieldset" fullWidth>
                                    <FormLabel component="legend">{questao.TituloExibicao}</FormLabel>
                                    {questao.TipoControle === 'radiobuttonlist' && (
                                      <Grid>
                                        {questao.Respostas.map((resposta: any) => (
                                          <CheckboxInput
                                            key={resposta.CodigoResposta}
                                            name="medicine.indication"
                                            label={resposta.TituloExibicao}
                                            value={resposta.CodigoResposta}
                                            disabled={isLoading}
                                            onChange={(event) => {
                                              const isChecked = event.target.checked;

                                              if (isChecked) {
                                                formRef.current.setFieldValue('medicine.indication',
                                                  event.target.checked);
                                                indicationRef.current = {
                                                  codigoQuestao: questao.CodigoQuestao,
                                                  codigoResposta: resposta.CodigoResposta,
                                                };
                                              } else {
                                                formRef.current.setFieldValue(
                                                  'medicine.indication',
                                                  false,
                                                );
                                                indicationRef.current = null;
                                              }
                                            }}
                                          />
                                        ))}
                                      </Grid>
                                    )}
                                  </FormControl>
                                </Grid>
                              ))}
                            </Grid>
                            <Grid container spacing={2} direction="column" style={{ marginLeft: 10 }}>
                              <DynamicFields
                                questoes={patientQuestionsRef.current}
                                respostaSelecionadaRef={respostasSelecionadasRef}
                              />
                            </Grid>
                            <Grid container spacing={2} direction="column" style={{ marginLeft: 10, marginTop: 20 }}>
                              <CheckboxInput
                                name="patient.acceptContact"
                                label={t('medication.release.acceptContact')}
                                disabled={isLoading}
                                onChange={(event) => {
                                  formRef.current.setFieldValue('patient.acceptContact', event.target.checked);
                                }}
                              />
                              <CheckboxInput
                                name="patient.acceptSms"
                                label={t('medication.release.acceptSms')}
                                disabled={isLoading}
                                onChange={(event) => {
                                  formRef.current.setFieldValue('patient.acceptSms', event.target.checked);
                                }}
                              />
                              <CheckboxInput
                                name="patient.acceptPhone"
                                label={t('medication.release.acceptPhone')}
                                disabled={isLoading}
                                onChange={(event) => {
                                  formRef.current.setFieldValue('patient.acceptPhone', event.target.checked);
                                }}
                              />
                              <CheckboxInput
                                name="patient.acceptMail"
                                label={t('medication.release.acceptMail')}
                                disabled={isLoading}
                                onChange={(event) => {
                                  formRef.current.setFieldValue('patient.acceptMail', event.target.checked);
                                }}
                              />
                              <CheckboxInput
                                name="patient.acceptEmail"
                                label={t('medication.release.acceptEmail')}
                                disabled={isLoading}
                                onChange={(event) => {
                                  formRef.current.setFieldValue('patient.acceptEmail', event.target.checked);
                                }}
                              />
                            </Grid>
                            <Grid item style={{ marginTop: 8 }}>
                              <Button type="submit" variant="contained" color="primary">
                                Solicitar
                              </Button>
                            </Grid>
                          </Grid>
                        </>
                      )}
                      {isRegistered === true && (
                        <>
                          <Grid container spacing={2} direction="row" style={{ marginTop: 8 }}>
                            <Grid item xs={12} sm={4} lg={3}>
                              <Input
                                name="prescriberDoctor.crm"
                                variant="outlined"
                                label={t('crm')}
                                className={classes.input}
                                disabled={isLoading}
                                autoComplete="nope"
                                InputProps={{
                                  inputProps: {
                                    maxLength: 6,
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3}>
                              <StateSelect
                                name="prescriberDoctor.uf"
                                variant="outlined"
                                label={t('crmUf')}
                                fullWidth
                                displayEmpty
                                className={classes.input}
                                disabled={isLoading}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} direction="column" style={{ marginLeft: 10 }}>
                            {medicineQuestionsRef.current.map((questao) => (
                              <Grid item xs={12} key={questao.CodigoQuestao}>
                                <FormControl component="fieldset" fullWidth>
                                  <FormLabel component="legend">{questao.TituloExibicao}</FormLabel>
                                  {questao.TipoControle === 'radiobuttonlist' && (
                                    <Grid>
                                      {questao.Respostas.map((resposta: any) => (
                                        <CheckboxInput
                                          key={resposta.CodigoResposta}
                                          name="medicine.indication"
                                          label={resposta.TituloExibicao}
                                          value={resposta.CodigoResposta}
                                          disabled={isLoading}
                                          onChange={(event) => {
                                            const isChecked = event.target.checked;

                                            if (isChecked) {
                                              formRef.current.setFieldValue('medicine.indication',
                                                event.target.checked);
                                              indicationRef.current = {
                                                codigoQuestao: questao.CodigoQuestao,
                                                codigoResposta: resposta.CodigoResposta,
                                              };
                                            } else {
                                              formRef.current.setFieldValue(
                                                'medicine.indication',
                                                false,
                                              );
                                              indicationRef.current = null;
                                            }
                                          }}
                                        />
                                      ))}
                                    </Grid>
                                  )}
                                </FormControl>
                              </Grid>
                            ))}
                          </Grid>
                          <Grid item>
                            <Button
                              onClick={handleSubmitRegisterMedicine}
                              disabled={isLoading}
                              variant="contained"
                              color="primary"
                            >
                              Solicitar
                            </Button>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Form>
        </PaperContent>
      </Paper>
    </Box>
  );
}
