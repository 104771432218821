"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientMedicationStatus = void 0;
var PatientMedicationStatus;
(function (PatientMedicationStatus) {
    PatientMedicationStatus["OPEN"] = "OPEN";
    PatientMedicationStatus["SCHEDULED"] = "SCHEDULED";
    PatientMedicationStatus["CANCELED"] = "CANCELED";
    PatientMedicationStatus["REALIZED"] = "REALIZED";
})(PatientMedicationStatus = exports.PatientMedicationStatus || (exports.PatientMedicationStatus = {}));
