import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Pill, Stethoscope, HeartPulse } from 'mdi-material-ui';
import { Inventory, InventoryType, ProgramModulesEnum, t } from '@psp/common';
import InfoCard from '../InfoCard';
import { useProgram } from '../../contexts/program.context';

export type InventoryDisplayProps = {
  inventory: Inventory[];
};

export type InventoryDisplayState = {
  groups: {
    [key in keyof typeof InventoryType]?: Inventory[];
  };
};

const defaultState: InventoryDisplayState = {
  groups: {},
};

const getPrimary = (iv: Inventory) => {
  if (iv.item) return iv.item;
  switch (iv.inventory) {
    case InventoryType.PATIENT_PROCEDURE:
      return t('allPatientProcedures');
    case InventoryType.CLINICAL_EXAMINATION:
      return t('allExaminations');
    case InventoryType.FIRST_MEDICINE:
    default:
      return t('allPresentations');
  }
};

const getIcon = (iv: Inventory) => {
  switch (iv.inventory) {
    case InventoryType.PATIENT_PROCEDURE:
      return HeartPulse;
    case InventoryType.CLINICAL_EXAMINATION:
      return Stethoscope;
    case InventoryType.FIRST_MEDICINE:
    default:
      return Pill;
  }
};

export default function InventoryDisplay({ inventory }: InventoryDisplayProps): JSX.Element {
  const [state, setState] = useState<InventoryDisplayState>(defaultState);
  const { modules } = useProgram();

  useEffect(() => {
    if (inventory.length) {
      setState({
        groups: {
          [InventoryType.CLINICAL_EXAMINATION]: inventory.filter(
            (i: Inventory) => i.inventory === InventoryType.CLINICAL_EXAMINATION,
          ),
          [InventoryType.FIRST_MEDICINE]: inventory.filter(
            (i: Inventory) => i.inventory === InventoryType.FIRST_MEDICINE,
          ),
          [InventoryType.PATIENT_PROCEDURE]: inventory.filter(
            (i: Inventory) => i.inventory === InventoryType.PATIENT_PROCEDURE,
          ),
        },
      });
    }
  }, [inventory]);

  return (
    <Box>
      {/* <InventoryTable data={state.inventory} /> */}
      {Object.keys(state.groups)
        .filter((tp) => modules.includes(tp as ProgramModulesEnum))
        .map((tp) => (
          <Accordion key={`inventory_${tp}`} defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h6">
                <strong>{t(`inventoryType.${tp}`)}</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} direction="row">
                {state?.groups[tp as InventoryType]?.map((iv) => (
                  <Grid key={`${iv.inventory}_${iv.group}_${iv.item}`} item>
                    <InfoCard
                      title={iv.group}
                      primary={getPrimary(iv)}
                      value={iv.amount}
                      color={iv.amount > 0 ? 'primary' : 'error'}
                      icon={getIcon(iv)}
                    />
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
    </Box>
  );
}
