import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Container, Grid, Typography, Button } from '@material-ui/core';
import { t } from '@psp/common';
import { LOGOUT_ROUTE } from '../../constants';
import { useStyles } from './styles';

export default function PendingApproval(): JSX.Element {
  const classes = useStyles();
  const handleLogoutClick = useCallback(() => {
    window.location.href = LOGOUT_ROUTE;
  }, []);

  return (
    <Container className={classes.container}>
      <Grid
        className={classes.container}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid
          className={classes.divButton}
        >
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            size="large"
            onClick={handleLogoutClick}
          >
            {t('logout')}
          </Button>
        </Grid>
        <Grid item>
          <Typography variant="h1" className={classes.h1}>
            {t('pendingApproval.title')}
          </Typography>
          <br />
          <Typography variant="h2" className={classes.h2}>
            {t('pendingApproval.subtitle')}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
