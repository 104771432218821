import React from 'react';
import { ArrowRight } from 'mdi-material-ui';
import { Doctor, Inventory, t, UserInventory, UserType } from '@psp/common';

import { Typography } from '@material-ui/core';
import Table, { TableColumn, TableProps } from '../Table';

const CompareByEquality = (column: keyof {
    id: string;
    [key: string]: unknown;
}) => (a: {
    id: string;
    [key: string]: unknown;
}, b: {
    id: string;
    [key: string]: unknown;
}) => {
  if (a[column] === b[column]) {
    return 0;
  }
  return 1;
};

const baseColumns = [
  {
    key: 'email',
    label: t('email'),
    compareFunction: CompareByEquality('email'),
  },
  // {
  //   key: 'inventory',
  //   label: t('product'),
  //   render: (value?: Inventory[]) => (value && value.length ? value[0].product : ''),
  // },
  // {
  //   key: 'inventory',
  //   label: t('presentation'),
  //   render: (value?: Inventory[]) => (value && value.length ? value[0].presentation : ''),
  // },
];

const userColumns: TableColumn[] = [
  {
    key: 'name',
    label: t('name'),
    compareFunction: CompareByEquality('name'),
  },
  ...baseColumns,
];

const doctorColumns: TableColumn[] = [
  {
    key: 'doctor',
    label: t('name'),
    compareFunction: CompareByEquality('doctor'),
    render: (value?: Doctor) => (value ? `${value.name} - ${value.crm}/${value.uf}` : ''),
  },
  ...baseColumns,
];

const getAmount = (type: string, amount: number, current: number): number => {
  switch (type) {
    case 'INCREASE':
      return Math.max(current, amount);
    case 'ADD_UNITS':
      return current + amount;
    case 'REDUCE':
      return Math.min(current, amount);
    case 'REMOVE_UNITS':
    default:
      return Math.max(current - amount, 0);
  }
};

export type UserInventoryTableProps = {
  data: UserInventory[];
  userType?: UserType;
  distribution: {
    type: string;
    amount?: number;
  };
} & Pick<
  TableProps,
  'canSearch' | 'count' | 'onChangePage' | 'onChangeRows' | 'onSearch' | 'page' | 'rowsPerPage'
>;

export default function UserInventoryTable({
  data,
  distribution,
  userType,
  ...props
}: UserInventoryTableProps): JSX.Element {
  const columns = userType === UserType.DOCTOR ? [...doctorColumns] : [...userColumns];
  columns.push({
    key: 'inventory',
    label: t('amount'),
    compareFunction: CompareByEquality('inventory'),
    render: (value?: Inventory[]) => (value && value.length ? (
      distribution && distribution.type ? (
        <Typography variant="body1">
          {value[0].amount.toString()}
          <ArrowRight
            fontSize="inherit"
            style={{ position: 'relative', top: '0.2em', margin: '0 4px' }}
          />
          {getAmount(distribution.type, distribution.amount || 0, value[0].amount)}
        </Typography>
      ) : (
        value[0].amount.toString()
      )
    ) : distribution && distribution.type ? (
      <Typography variant="body1">
        0
        <ArrowRight
          fontSize="inherit"
          style={{ position: 'relative', top: '0.2em', margin: '0 4px' }}
        />
        {` ${getAmount(distribution.type, distribution.amount || 0, 0)}`}
      </Typography>
    ) : (
      ''
    )),
  });
  return <Table columns={columns} data={data} title={t('users')} size="small" {...props} />;
}
