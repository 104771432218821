import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './styles';

export type TextItemProps = {
  label: string;
  content: string;
  className?: string;
  [key: string]: any;
};

export default function TextItem({
  label,
  content,
  className,
  ...rest
}: TextItemProps): JSX.Element {
  const classes = useStyles();

  return (
    <div className={clsx(className, classes.container)} {...rest}>
      <Typography variant="body2" display="block" color="textSecondary">
        {label}
      </Typography>
      <Typography variant="body1" component="span" display="block" style={{ whiteSpace: 'pre-line' }}>
        {content}
      </Typography>
    </div>
  );
}
