import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {},
  paper: {},
  input: {
    width: '100%',
  },
  nowrap: {
    flexWrap: 'nowrap',
  },
  codeButton: {
    width: 120,
  },
  codeField: {
    width: 40,
    marginLeft: 5,
    marginRight: 5,
  },
}));
