import * as React from 'react';
import { dt, t, Procedure, ProcedureStatus, User, ProcedureType, ExamGroup, ProcedureUtils } from '@psp/common';
import {
  Grid,
  Typography,
  Box,
} from '@material-ui/core';
import { ArrowForwardIos, DoneAll, Cancel, HourglassEmptySharp, WatchLaterSharp } from '@material-ui/icons';
import { useStyles } from './styles';

export type MultiDataBoxProps = {
  procedure: Procedure,
};

export default function MultiDataBox({ procedure }: MultiDataBoxProps): JSX.Element {
  const classes = useStyles();
  let positionNumber = 0;

  const isSupply = procedure.type === ProcedureType.SUPPLY;
  const isTravel = procedure.type === ProcedureType.TRAVEL;
  const isRejected = procedure.status === ProcedureStatus.REJECTED;
  const isPartial = ProcedureUtils.CheckIfIsPartial(procedure.children ?? []);
  const hasConcludedStatus = procedure.children == null ? false
    : procedure.children.some((c: Procedure) => c.status === ProcedureStatus.CONCLUDED);

  function showArrow(examGroup: ExamGroup, validation: number): boolean {
    switch (validation) {
      case 1:
        if (examGroup.showApprovedAt || examGroup.showScheduledAt
          || examGroup.showCollectedAt || examGroup.showReceivedAt
          || examGroup.showConcludedAt || examGroup.showCanceledAt) {
          return true;
        }
        break;
      case 2:
        if (examGroup.showScheduledAt || examGroup.showCollectedAt
          || examGroup.showReceivedAt || examGroup.showConcludedAt
          || examGroup.showCanceledAt) {
          return true;
        }
        break;
      case 3:
        if (examGroup.showCollectedAt || examGroup.showReceivedAt
          || examGroup.showConcludedAt || examGroup.showCanceledAt) {
          return true;
        }
        break;
      case 4:
        if (examGroup.showReceivedAt || examGroup.showConcludedAt
          || examGroup.showCanceledAt) {
          return true;
        }
        break;
      case 5:
        if (examGroup.showConcludedAt || examGroup.showCanceledAt) {
          return true;
        }
        break;
      default:
        return false;
    }
    return false;
  }
  return (
    <div className={classes.row}>
      {(procedure.examGroup?.showCreatedAt || isSupply || isTravel) && (
        positionNumber++,
          <Box className={classes.box}>
            {procedure.createdAt && (
              <Typography className={classes.positionNumber}>
                {positionNumber}
              </Typography>
            )}
            {!procedure.createdAt && (
              <Typography className={classes.positionNumberWaiting}>
                {positionNumber}
              </Typography>
            )}
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} sm={4} className={classes.grid}>
                <div className={classes.card}>
                  <Typography className={classes.title}>
                    {t('solicitation')}
                  </Typography>
                  {procedure.createdAt && (
                    <div>
                      <Typography className={classes.date}>
                        {dt(procedure.createdAt)}
                      </Typography>
                      <DoneAll className={classes.green} />
                    </div>
                  )}
                  {!procedure.createdAt && (
                    <div>
                      <Typography className={classes.waiting}>
                        {t('waiting')}
                      </Typography>
                      <HourglassEmptySharp className={classes.waiting} />
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
            {((procedure.examGroup && showArrow(procedure.examGroup, 1)) || isSupply || isTravel)
              && (<ArrowForwardIos className={classes.arrow} />
              )}
          </Box>
      )}
      {(procedure.examGroup?.showApprovedAt || isSupply || isTravel) && !isRejected && (
        positionNumber++,
          <Box className={classes.box}>
            {procedure.approvedAt && (
              <Typography className={classes.positionNumber}>
                {positionNumber}
              </Typography>
            )}
            {!procedure.approvedAt && (
              <Typography className={classes.positionNumberWaitingAuthorization}>
                {positionNumber}
              </Typography>
            )}
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} sm={4} className={classes.grid}>
                <div className={classes.card}>
                  {isTravel && (
                  <Typography className={classes.title}>
                    {t('approved')}
                  </Typography>
                  )}
                  {!isTravel && (
                  <Typography className={classes.title}>
                    {t('voucherAuthorization')}
                  </Typography>
                  )}
                  {procedure.approvedAt && (
                    <div>
                      <Typography className={classes.date}>
                        {dt(procedure.approvedAt)}
                      </Typography>
                      <DoneAll className={classes.green} />
                    </div>
                  )}
                  {!procedure.approvedAt && (
                    <div>
                      <Typography className={classes.date}>
                        {t('pending')}
                      </Typography>
                      <WatchLaterSharp className={classes.waitingAuthorization} />
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
            {((procedure.examGroup && showArrow(procedure.examGroup, 2)) || isSupply || isTravel)
              && (<ArrowForwardIos className={classes.arrow} />
              )}
          </Box>
      )}
      {(procedure.examGroup?.showApprovedAt || isSupply || isTravel) && isRejected && (
        positionNumber++,
          <Box className={classes.box}>
            {procedure.approvedAt && (
              <Typography className={classes.positionNumberCanceled}>
                {positionNumber}
              </Typography>
            )}
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} sm={4} className={classes.grid}>
                <div className={classes.card}>
                  <Typography className={classes.title}>
                    {t('rejected')}
                  </Typography>
                  {procedure.approvedAt && (
                    <div>
                      <Typography className={classes.date}>
                        {dt(procedure.approvedAt)}
                      </Typography>
                      <Cancel className={classes.red} />
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </Box>
      )}
      {!isRejected && (
        <>
          {(procedure.examGroup?.showScheduledAt || isTravel) && (
            positionNumber++,
              <Box className={classes.box}>
                {procedure.scheduledAt
                  && (!isPartial || (isPartial && hasConcludedStatus)) && (
                  <Typography className={classes.positionNumber}>
                    {positionNumber}
                  </Typography>
                )}
                {!procedure.scheduledAt
                  && (!isPartial || (isPartial && hasConcludedStatus)) && (
                  <Typography className={classes.positionNumberWaiting}>
                    {positionNumber}
                  </Typography>
                )}
                {isPartial && !hasConcludedStatus && (
                  <Typography className={classes.positionNumberWaitingAuthorization}>
                    {positionNumber}
                  </Typography>
                )}
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} sm={4} className={classes.grid}>
                    <div className={classes.card}>
                      {isTravel && (
                      <Typography className={classes.title}>
                        {t('scheduled')}
                      </Typography>
                      )}
                      {!isTravel && (
                      <Typography className={classes.title}>
                        {t('scheduling')}
                      </Typography>
                      )}
                      {procedure.scheduledAt
                        && (!isPartial || (isPartial && hasConcludedStatus)) && (
                        <div>
                          <Typography className={classes.date}>
                            {dt(procedure.scheduledAt)}
                          </Typography>
                          <DoneAll className={classes.green} />
                        </div>
                      )}
                      {!procedure.scheduledAt
                        && (!isPartial || (isPartial && hasConcludedStatus)) && (
                        <div>
                          <Typography className={classes.waiting}>
                            {t('waiting')}
                          </Typography>
                          <HourglassEmptySharp className={classes.waiting} />
                        </div>
                      )}
                      {isPartial && !hasConcludedStatus && (
                        <div>
                          <Typography className={classes.waiting}>
                            {t('partial')}
                          </Typography>
                          <WatchLaterSharp className={classes.waitingAuthorization} />
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
                {((procedure.examGroup && showArrow(procedure.examGroup, 3)) || isTravel) && (
                  <ArrowForwardIos className={classes.arrow} />
                )}
              </Box>
          )}
          {procedure.examGroup?.showCollectedAt && (
            positionNumber++,
              <Box className={classes.box}>
                {procedure.collectedAt && (
                  <Typography className={classes.positionNumber}>
                    {positionNumber}
                  </Typography>
                )}
                {!procedure.collectedAt && (
                  <Typography className={classes.positionNumberWaiting}>
                    {positionNumber}
                  </Typography>
                )}
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} sm={4} className={classes.grid}>
                    <div className={classes.card}>
                      <Typography className={classes.title}>
                        {t('collect')}
                      </Typography>
                      {procedure.collectedAt && (
                        <div>
                          <Typography className={classes.date}>
                            {dt(procedure.collectedAt)}
                          </Typography>
                          <DoneAll className={classes.green} />
                        </div>
                      )}
                      {!procedure.collectedAt && (
                        <div>
                          <Typography className={classes.waiting}>
                            {t('waiting')}
                          </Typography>
                          <HourglassEmptySharp className={classes.waiting} />
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
                {showArrow(procedure.examGroup, 4) && (
                  <ArrowForwardIos className={classes.arrow} />
                )}
              </Box>
          )}
          {procedure.examGroup?.showReceivedAt && (
            positionNumber++,
              <Box className={classes.box}>
                {procedure.receivedAt && (
                  <Typography className={classes.positionNumber}>
                    {positionNumber}
                  </Typography>
                )}
                {!procedure.receivedAt && (
                  <Typography className={classes.positionNumberWaiting}>
                    {positionNumber}
                  </Typography>
                )}
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} sm={4} className={classes.grid}>
                    <div className={classes.card}>
                      <Typography className={classes.title}>
                        {t('receivement')}
                      </Typography>
                      {procedure.receivedAt && (
                        <div>
                          <Typography className={classes.date}>
                            {dt(procedure.receivedAt)}
                          </Typography>
                          <DoneAll className={classes.green} />
                        </div>
                      )}
                      {!procedure.receivedAt && (
                        <div>
                          <Typography className={classes.waiting}>
                            {t('waiting')}
                          </Typography>
                          <HourglassEmptySharp className={classes.waiting} />
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
                {showArrow(procedure.examGroup, 5) && (
                  <ArrowForwardIos className={classes.arrow} />
                )}
              </Box>
          )}
          {((procedure.examGroup?.showConcludedAt || isSupply || isTravel) && !procedure.canceledAt)
            && (positionNumber++,
              <Box className={classes.box}>
                {procedure.concludedAt
                  && (!isPartial || (isPartial && !hasConcludedStatus)) && (
                  <Typography className={classes.positionNumber}>
                    {positionNumber}
                  </Typography>
                )}
                {!procedure.concludedAt
                  && (!isPartial || (isPartial && !hasConcludedStatus)) && (
                  <Typography className={classes.positionNumberWaiting}>
                    {positionNumber}
                  </Typography>
                )}
                {isPartial && hasConcludedStatus && (
                  <Typography className={classes.positionNumberWaitingAuthorization}>
                    {positionNumber}
                  </Typography>
                )}
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} sm={4} className={classes.grid}>
                    <div className={classes.card}>
                      {(!isSupply && !isTravel) && (
                        <Typography className={classes.title}>
                          {t('exam')}
                        </Typography>
                      )}
                      {(isSupply || isTravel) && (
                        <Typography className={classes.title}>
                          {t('concluded')}
                        </Typography>
                      )}
                      {procedure.concludedAt
                        && (!isPartial || (isPartial && !hasConcludedStatus)) && (
                        <div>
                          <Typography className={classes.date}>
                            {dt(procedure.concludedAt)}
                          </Typography>
                          <DoneAll className={classes.green} />
                        </div>
                      )}
                      {!procedure.concludedAt
                        && (!isPartial || (isPartial && !hasConcludedStatus)) && (
                        <div>
                          <Typography className={classes.waiting}>
                            {t('waiting')}
                          </Typography>
                          <HourglassEmptySharp className={classes.waiting} />
                        </div>
                      )}
                      {isPartial && hasConcludedStatus && (
                        <div>
                          <Typography className={classes.waiting}>
                            {t('partial')}
                          </Typography>
                          <WatchLaterSharp className={classes.waitingAuthorization} />
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Box>
            )}
          {(procedure.canceledAt && (procedure.examGroup?.showCanceledAt || isSupply || isTravel))
            && (positionNumber++,
              <Box className={classes.box}>
                <Typography className={classes.positionNumberCanceled}>
                  {positionNumber}
                </Typography>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} sm={4} className={classes.grid}>
                    <div className={classes.card}>
                      {(!isSupply && !isTravel) && (
                        <Typography className={classes.title}>
                          {t('exam')}
                        </Typography>
                      )}
                      <div>
                        <Typography className={classes.date}>
                          {t('canceled')}
                        </Typography>
                        {(isSupply || isTravel) && (
                          <Typography className={classes.date}>
                            {dt(procedure.canceledAt)}
                          </Typography>
                        )}
                        <Cancel className={classes.red} />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            )}
        </>
      )}
    </div>
  );
}
