import React, { useCallback, useEffect, useState } from 'react';

import { Box, Grid } from '@material-ui/core';

import { useHistory, useParams } from 'react-router-dom';
import { GetPatientDetailsFromPatientCare, PatientDetailsFromPatientCare } from '@psp/common';
import { useStyles } from './styles';
import PatientInfo from '../../PatientInfo';
import { useProgram } from '../../../contexts/program.context';
import { useLoading } from '../../../contexts/loading.context';
import PatientHealthInfo from '../../PatientHealthInfo';

export type PatientDetailsProps = {
  getPatientDetailsFromPatientCare: GetPatientDetailsFromPatientCare;
};

type PatientDetailsState = {
  patient?: PatientDetailsFromPatientCare;
};

const defaultState: PatientDetailsState = {
  patient: undefined,
};

export default function PatientDetails({
  getPatientDetailsFromPatientCare,
}: PatientDetailsProps): JSX.Element {
  const classes = useStyles();
  const [state, setState] = useState<PatientDetailsState>(defaultState);
  const { id } = useParams<{ id: string }>();
  const { program } = useProgram();
  const { goBack } = useHistory();
  const { showLoading, hideLoading } = useLoading();

  const loadPatient = useCallback(() => {
    if (!program) return;
    showLoading();
    (async () => {
      try {
        const patient = await getPatientDetailsFromPatientCare.execute({ patientId: id });

        setState((prevState) => ({
          ...prevState,
          patient,
        }));
      } catch (err) {
        console.log(err);
        goBack();
      } finally {
        hideLoading();
      }
    })();
  }, [id, program, state]);

  useEffect(
    useCallback(() => {
      loadPatient();
    }, [loadPatient]),
    [id, program],
  );

  return (
    <Box className={classes.container}>
      <Grid container xs={12} spacing={2}>
        {state?.patient?.accessPhases
          && <PatientHealthInfo data={state.patient.accessPhases} patientId={id} />}
        <PatientInfo patient={state.patient} />
      </Grid>
    </Box>
  );
}
