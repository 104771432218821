import React from 'react';
import { approveUser, getDoctors, doctorsXLSX } from '@psp/common';

import DoctorList, { DoctorListProps } from '../../components/Doctor/List';

const dispatchers: Pick<DoctorListProps, 'approveUser' | 'getDoctors' | 'doctorsXLSX' > = {
  approveUser,
  getDoctors,
  doctorsXLSX,
};

export default function DoctorListComposer(): JSX.Element {
  return <DoctorList {...dispatchers} />;
}
