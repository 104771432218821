import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Grid } from '@material-ui/core';
import {
  t,
  getProgramFollowups,
  Followup,
  emptyFunction,
  ExamGroup,
  getFollowupExamGroups,
} from '@psp/common';
import { Form } from '@unform/web';
import { useProgram } from '../../contexts/program.context';
import { useLoading } from '../../contexts/loading.context';
import CheckboxSelect from '../CheckboxSelect';
import { useStyles } from './styles';

export type FollowupExamGroupSelectProps = {
  onFollowupChange: (e: any) => void;
  onExamGroupChange: (e: any) => void;
  multiple: boolean;
};

type FollowupExamGroupSelectState = {
  followups: Followup[];
  examGroups: ExamGroup[];
  followupId: string;
};

const defaultState: FollowupExamGroupSelectState = {
  followups: [],
  examGroups: [],
  followupId: '',
};

export default function FollowupSelect({
  onFollowupChange,
  onExamGroupChange,
  multiple,
}: FollowupExamGroupSelectProps): JSX.Element {
  const classes = useStyles();
  const { showLoading, hideLoading, isLoading } = useLoading();
  const { program } = useProgram();
  const [state, setState] = useState<FollowupExamGroupSelectState>(defaultState);
  const formRef = useRef({} as any);

  useEffect(
    useCallback(() => {
      if (!program) return;
      showLoading();
      setState({
        ...defaultState,
      });
      (async () => {
        try {
          const followups = await getProgramFollowups.execute({ programId: program?.id });
          setState({
            ...defaultState,
            followups,
          });
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    }, [state]),
    [program],
  );

  const handleFollowupChange = useCallback(
    (value) => {
      if (!value) return;
      showLoading();
      setState({
        ...state,
        examGroups: [],
      });
      (async () => {
        try {
          const examGroups = await getFollowupExamGroups.execute({ followupId: value });
          setState({
            ...state,
            followupId: value,
            examGroups,
          });
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    },
    [state, onFollowupChange],
  );

  return (
    <Form ref={formRef} onSubmit={emptyFunction}>
      <Grid container className={classes.container} spacing={2} direction="row">
        <Grid item>
          <CheckboxSelect
            name="followup"
            label={t('followup')}
            items={state.followups.map((f: any) => ({
              id: f.id, name: f.name,
            }))}
            auxiliarFunction={handleFollowupChange}
            onChange={onFollowupChange}
            style={{ width: 400 }}
            multiple={multiple}
          />
        </Grid>

        <Grid item>
          <CheckboxSelect
            name="examGroup"
            label={t('examGroup')}
            items={state.examGroups.map((f: any) => ({
              id: f.id, name: f.name,
            }))}
            disabled={state.examGroups.length === 0 || isLoading}
            onChange={onExamGroupChange}
            style={{ width: 160 }}
            multiple={multiple}
          />
        </Grid>
      </Grid>
    </Form>
  );
}
