import React from 'react';
import { getProgramProducts } from '@psp/common';

import MedicineLeaflets, { MedicineLeafletsProps } from '../../components/MedicineLeaflet';

const dispatchers: Pick<MedicineLeafletsProps, 'getProducts'> = {
  getProducts: getProgramProducts,
};

export default function MedicineLeafletsComposer(): JSX.Element {
  return <MedicineLeaflets {...dispatchers} />;
}
