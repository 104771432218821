import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  appContainer: {
    width: '80%',
    margin: '0 auto',
    padding: '20px',
    [theme.breakpoints.down('sm')]: {
      width: '95%',
      padding: '10px',
    },
  },
  cardsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '28px',
    fontWeight: 'bold',
    lineHeight: '32.17px',
    letterSpacing: '0.364px',
    textAlign: 'left',
    marginBottom: '20px',
    color: '#282929',
    gap: '24px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '10px',
      fontSize: '25px',
    },
  },
  horizontalLine: {
    border: 'none',
    height: '1px',
    backgroundColor: '#E7E8E8',
    marginBottom: '20px',
  },
}));
