import React, { useCallback, useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Drawer,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  Divider,
  List,
  Tooltip,
  useMediaQuery,
  ListItemTextProps,
  ListSubheader,
} from '@material-ui/core';
import { ChevronLeft, ChevronRight, SvgIconComponent } from '@material-ui/icons';
import ChatIcon from '@material-ui/icons/Chat';
import clsx from 'clsx';
import { emptyFunction, t } from '@psp/common';

import { CodeNotEqual } from 'mdi-material-ui';
import ProgramSwitcher from '../ProgramSwitcher';
import { useRoutes } from '../../contexts/routes.context';
import { useProgram } from '../../contexts/program.context';
import { useStyles } from './styles';

type SidebarProps = {
  isDrawerOpen?: boolean;
  onDrawerClose?: () => void;
};

function SidebarListItem({
  route,
  label,
  Icon,
  isDrawerOpen,
  className,
  onDrawerClose,
  isXS,
}: {
  route: string;
  label: string;
  Icon?: SvgIconComponent;
  isDrawerOpen?: boolean;
  className: string;
  onDrawerClose?: () => void;
  isXS: boolean;
}): JSX.Element {
  const { pathname } = useLocation();
  const classes = useStyles();

  return (
    <ListItem
      button
      className={clsx(className, {
        'sidebar-nav-active': pathname.startsWith(route),
      })}
      component={Link}
      to={route}
      onClick={isXS ? onDrawerClose : emptyFunction}
    >
      <ListItemIcon>{!!Icon && <Icon />}</ListItemIcon>
      <ListItemText
        primary={label}
        hidden={!isDrawerOpen}
        className={classes.listItemText}
      />
    </ListItem>
  );
}

export default function Sidebar({ isDrawerOpen, onDrawerClose }: SidebarProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));
  const { routes } = useRoutes();
  const { program } = useProgram();

  let logedProgram;

  if (program) {
    logedProgram = program.slug;
  }

  console.log(logedProgram);

  const dashboardItems = useMemo(
    () => routes
      .filter((r) => r.sidebar === true && r.dashboard === true)
      .map(({ icon: Icon, label = '', route }) => (
        <Tooltip
          title={label}
          key={label}
          placement="right"
          arrow
          disableHoverListener={isDrawerOpen}
        >
          <SidebarListItem
            route={route}
            label={label}
            Icon={Icon}
            className={clsx(classes.drawerListItem, {
              [classes.drawerListItemShift]: !isDrawerOpen,
            })}
            isDrawerOpen={isDrawerOpen}
            onDrawerClose={onDrawerClose}
            isXS={isXS}
          />
        </Tooltip>
      )),
    [routes, isDrawerOpen],
  );
  const quickAccessItems = useMemo(
    () => routes
      .filter((r) => r.sidebar === true && r.quickAccess === true)
      .map(({ icon: Icon, label = '', route }) => (
        <Tooltip
          title={label}
          key={label}
          placement="right"
          arrow
          disableHoverListener={isDrawerOpen}
        >
          <SidebarListItem
            route={route}
            label={label}
            Icon={Icon}
            className={clsx(classes.drawerListItem, {
              [classes.drawerListItemShift]: !isDrawerOpen,
            })}
            isDrawerOpen={isDrawerOpen}
            onDrawerClose={onDrawerClose}
            isXS={isXS}
          />
        </Tooltip>
      )),
    [routes, isDrawerOpen],
  );
  const listItems = useMemo(
    () => routes
      .filter((r) => r.sidebar === true && !r.dashboard && !r.quickAccess)
      .map(({ icon: Icon, label = '', route }) => (
        <Tooltip
          title={label}
          key={label}
          placement="right"
          arrow
          disableHoverListener={isDrawerOpen}
        >
          <SidebarListItem
            route={route}
            label={label}
            Icon={Icon}
            className={clsx(classes.drawerListItem, {
              [classes.drawerListItemShift]: !isDrawerOpen,
            })}
            isDrawerOpen={isDrawerOpen}
            onDrawerClose={onDrawerClose}
            isXS={isXS}
          />
        </Tooltip>
      )),
    [routes, isDrawerOpen],
  );

  return (
    <Drawer
      variant={isXS ? 'temporary' : 'permanent'}
      open={isDrawerOpen}
      ModalProps={{
        keepMounted: true,
      }}
      onClose={onDrawerClose}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isDrawerOpen,
        [classes.drawerClose]: !isDrawerOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: isDrawerOpen,
          [classes.drawerClose]: !isDrawerOpen,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <div
          className={clsx('sidebar-program-image', classes.brandImage, {
            'sidebar-program-image-shift': !isDrawerOpen,
          })}
        />
        {/* isXS && <ProgramSwitcher /> */}
      </div>
      <List
        className={clsx('sidebar-nav', classes.drawerList, {
          'sidebar-nav-shift': !isDrawerOpen,
          [classes.drawerListShift]: !isDrawerOpen,
        })}
      >
        {dashboardItems}
      </List>
      <List
        className={clsx('sidebar-nav', classes.drawerList, {
          'sidebar-nav-shift': !isDrawerOpen,
          [classes.drawerListShift]: !isDrawerOpen,
        })}
        subheader={(
          <ListSubheader component="div" className={classes.listSubheader}>
            {isDrawerOpen ? t('quickAccess') : <>&nbsp;</>}
          </ListSubheader>
        )}
      >
        {quickAccessItems}
      </List>
      <List
        className={clsx('sidebar-nav', classes.drawerList, {
          'sidebar-nav-shift': !isDrawerOpen,
          [classes.drawerListShift]: !isDrawerOpen,
        })}
        subheader={(
          <ListSubheader component="div" className={classes.listSubheader}>
            {isDrawerOpen ? t('menu') : <>&nbsp;</>}
          </ListSubheader>
        )}
      >
        {listItems}
      </List>
      {logedProgram === 'abracaravida' && (
      <List
        className={clsx('sidebar-nav', classes.drawerList, {
          'sidebar-nav-shift': !isDrawerOpen,
          [classes.drawerListShift]: !isDrawerOpen,
        })}
        subheader={(
          <div className={classes.faq}>
            {isDrawerOpen ? (
              <a href="https://patient.boehringer-ingelheim.com/br/abracar-a-vida/perguntas-frequentes-abracar-vida" target="_blank" rel="noopener noreferrer" className={classes.faqLink}>
                <ListItemIcon>
                  <ChatIcon />
                </ListItemIcon>
                {t('Perguntas frequentes')}
              </a>
            ) : <>&nbsp;</>}
          </div>
        )}
      />
      )}
    </Drawer>
  );
}
