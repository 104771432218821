import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  animatedContainer: {
    position: 'absolute',
    height: '100%',
  },
  container: {
    height: '100vh',
    overflowX: 'hidden',
  },
  gridContainer: {
    height: 'calc(100vh - 25px)',
    overflowX: 'hidden',
  },
  gridContainerFull: {
    height: '100vh',
  },
  footerAbsolute: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    [theme.breakpoints.down('xs')]: {
      bottom: 14,
    },
  },
  fullHeight: {
    height: '100%',
  },
  h1: {
    fontWeight: 700,
    fontSize: 64,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 32,
    },
  },
  h2: {
    fontWeight: 700,
    fontSize: 24,
    textAlign: 'center',
    color: theme.palette.grey[500],
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
      marginLeft: 10,
      marginRight: 10,
    },
  },
  hidden: {
    display: 'none',
  },
  left: {
    textAlign: 'left',
  },
  padding: {
    padding: theme.spacing(2),
  },
  registrationForm: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: 400,
    },
  },
  searchButton: {
    marginTop: 7,
    width: 200,
  },
  caption: {
    textAlign: 'center',
    color: theme.palette.grey[500],
    fontWeight: 700,
    marginTop: 10,
    height: 60,
  },
  imgContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
    textAlign: 'center',
  },
  img1: {
    height: 60,
    marginRight: theme.spacing(2),
  },
  img2: {
    height: 60,
  },
}));
