import React, { useCallback, useEffect, useState } from 'react';
import { Box, Paper, Tabs, Tab, Grid } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';
import {
  t,
  Doctor,
  GetDoctor,
  GetProgramProducts,
  GetProgramFollowups,
  GetFollowupExamGroups,
  GetProductPresentations,
  GetExamGroupExams,
  GetDoctorInventoryAmounts,
  DistributeInventory,
  GetProductPatientProcedures,
} from '@psp/common';

import { useLoading } from '../../../contexts/loading.context';
import DoctorInfo from '../../DoctorInfo';
import MedicationReleaseTable from '../../MedicationReleaseTable';
import PatientProcedureRequestTable from '../../PatientProcedureRequestTable';
import TabPanel from '../../TabPanel';
import InventoryTable from '../../InventoryTable';
import InventoryDisplay from '../../InventoryDisplay';
import ExamRequestTable from '../../ExamRequestTable';

import { useStyles } from './styles';
import DoctorInventoryDistribution from '../../DoctorInventoryDistribution';
import { useProgram } from '../../../contexts/program.context';

export type DoctorDetailsProps = {
  getDoctor: GetDoctor;
  getProgramProducts: GetProgramProducts;
  getProgramFollowups: GetProgramFollowups;
  getFollowupExamGroups: GetFollowupExamGroups;
  getProductPresentations: GetProductPresentations;
  getExamGroupExams: GetExamGroupExams;
  getProductPatientProcedures: GetProductPatientProcedures;
  getDoctorInventoryAmounts: GetDoctorInventoryAmounts;
  distributeInventory: DistributeInventory;
};

type DoctorDetailsState = {
  doctor?: Doctor;
};

const defaultState: DoctorDetailsState = {
  doctor: undefined,
};

export default function DoctorDetails({
  getDoctor,
  ...dispatchers
}: DoctorDetailsProps): JSX.Element {
  const classes = useStyles();
  const [state, setState] = useState<DoctorDetailsState>(defaultState);
  const { id } = useParams<{ id: string }>();
  const { goBack } = useHistory();
  const { showLoading, hideLoading, isLoading } = useLoading();
  const { program, modulesMap } = useProgram();
  const [tabIndex, setTabIndex] = useState<number>(
    modulesMap.firstMedicine ? 0 : modulesMap.clinicalExamination ? 1 : 2,
  );

  const handleTabChange = useCallback(
    (_event, newValue: number): void => {
      setTabIndex(newValue);
    },
    [setTabIndex],
  );

  const loadDoctor = useCallback(() => {
    if (!program) return;
    showLoading();
    (async () => {
      try {
        const doctor = await getDoctor.execute({ programId: program.id, doctorId: id });
        setState({
          ...state,
          doctor,
        });
      } catch (err) {
        console.log(err);
        goBack();
      } finally {
        hideLoading();
      }
    })();
  }, [id, program, state]);

  const handleReload = useCallback(() => {
    loadDoctor();
  }, [loadDoctor]);

  useEffect(
    useCallback(() => {
      loadDoctor();
    }, [loadDoctor]),
    [id, program],
  );

  return (
    <Box className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DoctorInfo doctor={state.doctor} />
        </Grid>
        {state.doctor && state.doctor.userId && (
          <Grid item xs={12}>
            <Paper>
              <Tabs value={tabIndex} onChange={handleTabChange} aria-label="doctor info tabs">
                <Tab
                  label={t('firstMedicineRequests')}
                  id="doctor-tab-0"
                  aria-controls="doctor-tabpanel-0"
                  className={clsx({ [classes.hidden]: !modulesMap.firstMedicine })}
                />
                <Tab
                  label={t('examRequests')}
                  id="doctor-tab-1"
                  aria-controls="doctor-tabpanel-1"
                  className={clsx({ [classes.hidden]: !modulesMap.clinicalExamination })}
                />
                <Tab
                  label={t('patientProcedureRequests')}
                  id="doctor-tab-2"
                  aria-controls="doctor-tabpanel-2"
                  className={clsx({ [classes.hidden]: !modulesMap.patientProcedure })}
                />
                <Tab label={t('vouchers')} id="doctor-tab-3" aria-controls="doctor-tabpanel-3" />
                <Tab
                  label={t('inventoryDistribution')}
                  id="doctor-tab-4"
                  aria-controls="doctor-tabpanel-4"
                />
              </Tabs>
              {modulesMap.firstMedicine && (
                <TabPanel name="doctor-tabpanel-0" value={tabIndex} index={0}>
                  <MedicationReleaseTable
                    data={
                      state.doctor && state.doctor.medicationReleases
                        ? state.doctor.medicationReleases
                        : []
                    }
                  />
                </TabPanel>
              )}
              {modulesMap.clinicalExamination && (
                <TabPanel name="doctor-tabpanel-1" value={tabIndex} index={1}>
                  <ExamRequestTable
                    data={
                      state.doctor && state.doctor.examRequests ? state.doctor.examRequests : []
                    }
                  />
                </TabPanel>
              )}
              {modulesMap.patientProcedure && (
                <TabPanel name="doctor-tabpanel-2" value={tabIndex} index={2}>
                  <PatientProcedureRequestTable
                    data={
                      state.doctor && state.doctor.patientProcedureRequests
                        ? state.doctor.patientProcedureRequests
                        : []
                    }
                  />
                </TabPanel>
              )}
              <TabPanel name="doctor-tabpanel-3" value={tabIndex} index={3}>
                <InventoryDisplay inventory={state.doctor?.inventory || []} />
              </TabPanel>
              <TabPanel name="doctor-tabpanel-4" value={tabIndex} index={4}>
                <DoctorInventoryDistribution
                  doctor={state.doctor}
                  reloadDoctor={handleReload}
                  {...dispatchers}
                />
              </TabPanel>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
