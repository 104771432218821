import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  selectContainer: {

    [theme.breakpoints.down(1463)]: {
      height: '35px',
    },
  },
  formControl: {
    minWidth: 75,
  },
}));
