import React from 'react';
import { MenuItem } from '@material-ui/core';
import clsx from 'clsx';
import { t, userTypesMap } from '@psp/common';

import { useAuth } from '../../contexts/auth.context';
import Select, { SelectProps } from '../Select';
import { useStyles } from './styles';

type UserTypeSelectProps = Omit<SelectProps, 'children'> & {
  off?: string[];
};

export default function UserTypeSelect({
  className,
  off = [],
  ...props
}: UserTypeSelectProps): JSX.Element {
  const classes = useStyles();
  const { role } = useAuth();
  const userTypes = userTypesMap[role];
  return (
    <Select label={t('profile')} className={clsx(className, classes.formControl)} {...props}>
      {[
        <MenuItem value="" disabled key="disabled_item" />,
        ...userTypes
          .filter((ut) => !off.includes(ut))
          .map((ut: string) => (
            <MenuItem value={ut} key={ut}>
              {t(`userType.${ut}`)}
            </MenuItem>
          )),
      ]}
    </Select>
  );
}
