import React, { ReactElement } from 'react';
import { AuthProvider } from './auth.context';
import { LoadingProvider } from './loading.context';
import { ProgramProvider } from './program.context';
import { RoutesProvider } from './routes.context';

export type AppContextProps = {
  children: ReactElement;
};

export default function AppContext({ children }: AppContextProps): JSX.Element {
  return (
    <AuthProvider>
      <LoadingProvider>
        <ProgramProvider>
          <RoutesProvider>{children}</RoutesProvider>
        </ProgramProvider>
      </LoadingProvider>
    </AuthProvider>
  );
}
