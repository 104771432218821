import React from 'react';
import { t } from '@psp/common';

import { PATIENTS_ROUTE } from '../../../constants';
import Breadcrumbs from '../../../components/Breadcrumbs';
import PatientListComposer from '../../../composers/PatientListComposer';

export default function PatientsListPage(): JSX.Element {
  return (
    <>
      <Breadcrumbs title={t(`navigation.${PATIENTS_ROUTE}`)} />
      <PatientListComposer />
    </>
  );
}
