import React from 'react';
import { getProgram, registerProgram } from '@psp/common';

import ProgramRegister, { ProgramRegisterProps } from '../../components/Program/Register';

const dispatchers: ProgramRegisterProps = {
  getProgram,
  registerProgram,
};

export default function ProgramRegisterComposer(): JSX.Element {
  return <ProgramRegister {...dispatchers} />;
}
