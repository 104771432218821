import React from 'react';
import {
  User,
  dt,
  t,
  UserGroupUser,
  UserGroup,
} from '@psp/common';
import { Pencil } from 'mdi-material-ui';
import Table, { TableProps } from '../Table';
import Chip from '../Chip';

const CompareByEquality = (column: keyof {
    id: string;
    [key: string]: unknown;
}) => (a: {
    id: string;
    [key: string]: unknown;
}, b: {
    id: string;
    [key: string]: unknown;
}) => {
  if (a[column] === b[column]) {
    return 0;
  }
  return 1;
};

const columns = [
  {
    key: 'createdAt',
    label: t('date'),
    compareFunction: CompareByEquality('createdAt'),
    render: (value: any) => dt(value),
  },
  {
    key: 'userGroup',
    label: t('userGroup'),
    compareFunction: CompareByEquality('userGroup'),
    render: (userGroup?: UserGroup) => (userGroup ? userGroup.name : '-'),
  },
  {
    key: 'manager',
    label: t('manager'),
    compareFunction: CompareByEquality('manager'),
    render: (manager?: User) => (manager ? manager.name : '-'),
  },
  {
    key: 'createdBy',
    label: t('executor'),
    compareFunction: CompareByEquality('createdBy'),
    render: (createdBy?: User) => (createdBy ? createdBy.name : '-'),
  },
  {
    key: 'active',
    label: t('status'),
    compareFunction: CompareByEquality('active'),
    render: function UserGroupHistoryStatusRender(active: boolean) {
      return (
        <Chip
          color={active ? 'primary' : 'default'}
          label={
            active
              ? t('active')
              : t('inactive')
          }
          size="small"
        />
      );
    },
  },
];

export type UserGroupHistoryTableProps = {
  data: UserGroupUser[];
};

export default function UserGroupHistoryTable({
  data,
}: UserGroupHistoryTableProps): JSX.Element {
  return (
    <>
      <Table
        columns={columns}
        data={data}
        title={t('userUserGroups')}
        size="small"
      />
    </>
  );
}
