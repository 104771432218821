import React from 'react';
import { MenuItem } from '@material-ui/core';
import clsx from 'clsx';
import { t } from '@psp/common';

import Select, { SelectProps } from '../Select';
import { useStyles } from './styles';

type StateSelectProps = Omit<SelectProps, 'children'>;

export default function StateSelect({ className, optionalValueToWSAcesso = false, ...props }
  : StateSelectProps): JSX.Element {
  const classes = useStyles();
  const valueMale = optionalValueToWSAcesso ? 'M' : 'MALE';
  const valueFemale = optionalValueToWSAcesso ? 'F' : 'FEMALE';
  const valueNotInformed = optionalValueToWSAcesso ? 'N' : 'NOT_INFORMED';
  return (
    <Select className={clsx(className, classes.formControl)} {...props}>
      <MenuItem value="" disabled />
      <MenuItem value={valueMale}>{t('male')}</MenuItem>
      <MenuItem value={valueFemale}>{t('female')}</MenuItem>
      <MenuItem value={valueNotInformed}>{t('notInformed')}</MenuItem>
    </Select>
  );
}
