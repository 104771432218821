import React, { useCallback, useRef, useState, useEffect } from 'react';

import { AidFieldType, t } from '@psp/common';

import { useLoading } from '../../contexts/loading.context';

import CheckboxInput from '../CheckboxInput';

export type DynamicAidField = {
  name: string;
  label: string;
  fieldType: string;
  [key: string]: any;
};

export default function DynamicAidField({
  name,
  label,
  fieldType,
}: DynamicAidField): JSX.Element {
  const { isLoading } = useLoading();

  return (
    <>
      {fieldType === AidFieldType.CHECKBOX && (
        <CheckboxInput
          name={name}
          label={label}
          disabled={isLoading}
        />
      )}
    </>
  );
}
