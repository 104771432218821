import React from 'react';
import {
  getUser,
  registerUser,
  getDoctorByCrm,
  getDoctorAddress,
  registerDoctorAddress,
} from '@psp/common';

import UserDetails, {
  UserDetailsProps,
} from '../../components/User/Details';

const dispatchers: UserDetailsProps = {
  getUser,
  registerUser,
  getDoctorByCrm,
  getDoctorAddress,
  registerDoctorAddress,
};

export default function UserDetailsComposer(): JSX.Element {
  return <UserDetails {...dispatchers} />;
}
