import React from 'react';
import {
  saveUserGroupPermission,
  deleteUserGroupPermission,
  getUserGroupPermissions,
  getProgramProducts,
  getProductPresentations,
  getProgramFollowups,
  getFollowupExamGroups,
  getExamGroupExams,
  getProductPatientProcedures,
  getProgramSupplyGroups,
  getSupplyGroupSupplies,
} from '@psp/common';

import UserGroupPermissions, { UserGroupPermissionsProps } from '../../components/UserGroups/Permissions';

const dispatchers: Pick<
  UserGroupPermissionsProps,
  | 'saveUserGroupPermission'
  | 'deleteUserGroupPermission'
  | 'getUserGroupPermissions'
  | 'getProgramProducts'
  | 'getProductPresentations'
  | 'getProgramFollowups'
  | 'getFollowupExamGroups'
  | 'getExamGroupExams'
  | 'getProductPatientProcedures'
  | 'getProgramSupplyGroups'
  | 'getSupplyGroupSupplies'
> = {
  saveUserGroupPermission,
  deleteUserGroupPermission,
  getUserGroupPermissions,
  getProgramProducts,
  getProductPresentations,
  getProgramFollowups,
  getFollowupExamGroups,
  getExamGroupExams,
  getProductPatientProcedures,
  getProgramSupplyGroups,
  getSupplyGroupSupplies,
};

export default function UserGroupPermissionsComposer(): JSX.Element {
  return <UserGroupPermissions {...dispatchers} />;
}
