import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  MenuItem,
  Typography,
} from '@material-ui/core';
import {
  ProgramFile,
  GetProgramProducts,
  GetProductPresentations,
  GetProductPatientProcedures,
  GetProgramFollowups,
  GetFollowupExamGroups,
  GetExamGroupExams,
  GetProgramSupplyGroups,
  GetSupplyGroupSupplies,
  createProgramFileForm,
  t,
  extractValidationErrors,
  ValidationError,
  CreateProgramFile,
  CreateProgramFileParams,
  emptyFunction,
  LibraryType,
  FileType,
} from '@psp/common';
import { ExpandMore } from '@material-ui/icons';
import { Pill, Stethoscope, HeartPulse, FileDownload } from 'mdi-material-ui';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import Input from '../Input';
import Select from '../Select';
import FileCard from '../FileCard';
import { useProgram } from '../../contexts/program.context';
import { useLoading } from '../../contexts/loading.context';
import { useStyles } from './styles';
import ProductSelect from '../ProductSelect';
import ExamSelect from '../ExamSelect';
import PatientProcedureSelect from '../PatientProcedureSelect';
import SupplySelect from '../SupplySelect';
import Base64FileUploadInput from '../Base64FileUploadInput';
import Button from '../Button';
import StateSelect from '../StateSelect';

export type LibraryUploadProps = {
  getProgramProducts: GetProgramProducts;
  getProductPresentations: GetProductPresentations;
  getProductPatientProcedures: GetProductPatientProcedures;
  getProgramFollowups: GetProgramFollowups;
  getFollowupExamGroups: GetFollowupExamGroups;
  getExamGroupExams: GetExamGroupExams;
  getProgramSupplyGroups: GetProgramSupplyGroups;
  getSupplyGroupSupplies: GetSupplyGroupSupplies;
  createProgramFile: CreateProgramFile;
  onUpload: (programFile: ProgramFile) => void;
  filesTypes: LibraryType[];
};

export type LibraryUploadState = {
  programId?: string
  procedureType?: LibraryType;
  groupId?: string;
  itemId?: string;
};

const defaultState: LibraryUploadState = {
  programId: undefined,
  procedureType: undefined,
  groupId: undefined,
  itemId: undefined,
};

export default function LibraryUpload({
  getProgramProducts,
  getProductPresentations,
  getProductPatientProcedures,
  getProgramFollowups,
  getFollowupExamGroups,
  getExamGroupExams,
  getProgramSupplyGroups,
  getSupplyGroupSupplies,
  createProgramFile,
  onUpload = emptyFunction,
  filesTypes,
}: LibraryUploadProps): JSX.Element {
  const formRef = useRef({} as any);
  const [state, setState] = useState<LibraryUploadState>(defaultState);
  const { program } = useProgram();
  const { isLoading, showLoading, hideLoading, showBackdrop, hideBackdrop } = useLoading();
  const classes = useStyles();

  const handleProcedureTypeChange = useCallback(
    (value) => {
      if (!value) return;
      showLoading();
      setState({
        ...defaultState,
        programId: value === 'GENERAL' ? undefined : program?.id,
        procedureType: value,
      });
      hideLoading();
    },
    [state],
  );

  const handleGroupItemChange = useCallback(
    (value) => {
      if (!value) return;

      const groupId = value.productId || value.examGroupId || value.supplyGroupId || undefined;
      const itemId = value.presentationId || value.examId || value.patientProcedureId
        || value.supplyId || undefined;

      setState({
        ...state,
        groupId: groupId === '' ? undefined : groupId,
        itemId: itemId === '' ? undefined : itemId,
      });
    },
    [state],
  );

  const handleSubmit = useCallback(
    (event: any): void => {
      showLoading();
      formRef.current.setErrors({});
      (async () => {
        try {
          const data = formRef.current.getData();
          let fileName = data.programFile.name;
          let fileType = FileType.LIBRARY;
          if (state.procedureType === LibraryType.PCDT) {
            fileType = FileType.CONTENTS;
            const uf = data.programFile.uf ? data.programFile.uf : 'Geral';
            fileName = `${uf} - ${data.programFile.name}`;
          }

          const req = (await createProgramFileForm.validate(
            {
              programFile: {
                ...data.programFile,
                name: fileName,
                programId: state.programId,
                groupId: state.groupId,
                itemId: state.itemId,
              },
              libraryFile: data.libraryFile,
              fileType,
            },
            {
              abortEarly: false,
            },
          )) as any;
          req.programFile.procedureType = state.procedureType;
          const programFile = await createProgramFile.execute((req) as CreateProgramFileParams);
          toast.success(t('msg.successToCreateProgramFile'), {
            onOpen: showBackdrop,
            onClose: hideBackdrop,
          });
          formRef.current.reset({
            values: {},
          });
          formRef.current.setData({ });
          setState({
            ...defaultState,
          });
          onUpload(programFile);
        } catch (err) {
          let errors = {};
          if (err instanceof ValidationError) {
            errors = extractValidationErrors(err);
            formRef.current.setErrors(errors);
          } else if (err.name === 'BadRequestError') {
            toast.info(err.message, {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } else {
            toast.info(t('err.failedToCreateProgramFile'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          }
        } finally {
          hideLoading();
        }
      })();
    },
    [program, state],
  );

  return (
    <Box>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h6">
            <strong>{t('uploadFile')}</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Form onSubmit={handleSubmit} ref={formRef}>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Input
                  name="programFile.name"
                  variant="outlined"
                  label={t('uploadTitle')}
                  className={classes.input}
                  disabled={isLoading}
                  autoComplete="nope"
                />
              </Grid>
              <Grid item>
                <Input
                  name="programFile.description"
                  variant="outlined"
                  label={t('description')}
                  className={classes.input}
                  disabled={isLoading}
                  optional
                  autoComplete="nope"
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item>
                <Select
                  label={t('category')}
                  variant="outlined"
                  name="programFile.procedureType"
                  fullWidth
                  onSelectedValueChange={handleProcedureTypeChange}
                  disabled={isLoading}
                >
                  {Object.values(LibraryType).filter((pt) => filesTypes.includes(pt)).map((pt) => (
                    <MenuItem key={pt} value={pt}>
                      {t(`library.${pt}`)}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              {state.procedureType === LibraryType.FIRST_MEDICINE && (
                <Grid item>
                  <ProductSelect
                    formRef={formRef}
                    getProgramProducts={getProgramProducts}
                    getProductPresentations={getProductPresentations}
                    onSelectedValueChange={handleGroupItemChange}
                    direction="column"
                    optionalProduct
                    optionalPresentation
                  />
                </Grid>
              )}
              {state.procedureType === LibraryType.CLINICAL_EXAMINATION && (
                <Grid item>
                  <ExamSelect
                    formRef={formRef}
                    getProgramFollowups={getProgramFollowups}
                    getFollowupExamGroups={getFollowupExamGroups}
                    getExamGroupExams={getExamGroupExams}
                    onSelectedValueChange={handleGroupItemChange}
                    direction="column"
                    optionalFollowup
                    optionalExamGroup
                    optionalExam
                  />
                </Grid>
              )}
              {state.procedureType === LibraryType.PATIENT_PROCEDURE && (
                <Grid item>
                  <PatientProcedureSelect
                    formRef={formRef}
                    getProgramProducts={getProgramProducts}
                    getProductPatientProcedures={getProductPatientProcedures}
                    onSelectedValueChange={handleGroupItemChange}
                    direction="column"
                    optionalProduct
                    optionalPatientProcedure
                  />
                </Grid>
              )}
              {state.procedureType === LibraryType.SUPPLY && (
                <Grid item>
                  <SupplySelect
                    formRef={formRef}
                    getProgramSupplyGroups={getProgramSupplyGroups}
                    getSupplyGroupSupplies={getSupplyGroupSupplies}
                    onSelectedValueChange={handleGroupItemChange}
                    direction="column"
                    optionalSupplyGroup
                    optionalSupply
                    settingPermissions
                  />
                </Grid>
              )}
              {state.procedureType === LibraryType.PCDT && (
                <Grid item>
                  <StateSelect
                    name="programFile.uf"
                    variant="outlined"
                    label={t('uf')}
                    fullWidth
                    optional
                    displayEmpty
                    inputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>
              )}
              <Grid item>
                <Base64FileUploadInput name="libraryFile" />
              </Grid>
              <Grid item>
                <Button onClick={handleSubmit} variant="contained" color="primary">
                  {t('save')}
                </Button>
              </Grid>
            </Grid>
          </Form>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
