import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    width: 250,
    maxWidth: '100%',
    position: 'relative',
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  icon: {
    opacity: 0.5,
    position: 'absolute',
    right: theme.spacing(2),
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  primary: {
    background: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  error: {
    background: theme.palette.error.dark,
    color: theme.palette.common.white,
  },
  default: {},
}));
