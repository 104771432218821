import React from 'react';

import {
  getPatientsByCRMFromPatientCare,
} from '@psp/common';

import PatientList, { PatientListProps } from '../../components/Patient/List';

const dispatchers: Pick<PatientListProps, 'getPatientsByCRMFromPatientCare'> = {
  getPatientsByCRMFromPatientCare,
};

export default function PatientListComposer(): JSX.Element {
  return <PatientList {...dispatchers} />;
}
