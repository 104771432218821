import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  box: {
    width: 200,
    position: 'relative',
    alignContent: 'left',
    marginLeft: 30,
  },
  grid: {
    width: 200,
  },
  red: {
    color: '#e62719',
  },
  green: {
    color: theme.palette.success.main,
  },
  card: {
    height: '100%',
    width: 160,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px solid black',
    borderRadius: '5px',
    textAlign: 'center',
    position: 'relative',
  },
  title: {
    fontSize: 13,
    marginTop: 5,
    fontWeight: 'bold',
  },
  date: {
    fontSize: 14,
    marginTop: 5,
    fontWeight: 'bold',
  },
  positionNumber: {
    borderRadius: '50%',
    width: 30,
    height: 30,
    padding: 4,
    textAlign: 'center',
    color: '#ffffff',
    fontSize: '8',
    border: '2px solid theme.palette.success.main',
    background: theme.palette.success.main,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: -15,
    zIndex: 100,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  positionNumberWaiting: {
    borderRadius: '50%',
    width: 30,
    height: 30,
    padding: 2,
    textAlign: 'center',
    color: '#000000',
    fontSize: '8',
    background: '#ffffff',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: -15,
    zIndex: 100,
    marginTop: 'auto',
    marginBottom: 'auto',
    border: '2px solid #949392',
  },
  positionNumberCanceled: {
    borderRadius: '50%',
    width: 30,
    height: 30,
    padding: 2,
    textAlign: 'center',
    color: '#ffffff',
    fontSize: '8',
    border: '2px solid #e62719',
    background: '#e62719',
    top: 0,
    bottom: 0,
    left: -15,
    zIndex: 100,
    marginTop: 'auto',
    marginBottom: 'auto',
    position: 'absolute',
  },
  positionNumberWaitingAuthorization: {
    borderRadius: '50%',
    width: 30,
    height: 30,
    padding: 2,
    textAlign: 'center',
    color: '#ffffff',
    fontSize: '8',
    border: '2px solid #ffca28',
    background: '#ffca28',
    top: 0,
    bottom: 0,
    left: -15,
    zIndex: 100,
    marginTop: 'auto',
    marginBottom: 'auto',
    position: 'absolute',
  },
  arrow: {
    color: theme.palette.success.main,
    position: 'absolute',
    top: 0,
    bottom: 0,
    zIndex: 100,
    marginTop: 'auto',
    marginBottom: 'auto',
    right: -5,
  },
  waiting: {
    padding: 2,
    color: '#949392',
    fontWeight: 'bold',
  },
  waitingAuthorization: {
    padding: 2,
    color: '#ffca28',
    fontWeight: 'bold',
  },
  canceled: {
    padding: 2,
    color: '#e62719',
    fontWeight: 'bold',
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
  },
}));
