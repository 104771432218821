import React from 'react';
import MaskedInput from 'react-text-mask';

type DocumentInputProps = {
  inputRef: (ref: HTMLInputElement | null) => void;
  [key: string]: any;
};

const mask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];

export default function DocumentInput({ inputRef, ...rest }: DocumentInputProps): JSX.Element {
  return (
    <MaskedInput
      {...rest}
      ref={(ref: any): void => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      placeholderChar="_"
    />
  );
}
