import React, { useState } from 'react';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import { getAddressByCEP, t } from '@psp/common';
import { useStyles } from './styles';
import Input from '../Input';
import { useLoading } from '../../contexts/loading.context';

export type CepRegisterProps ={
  formRef: any;
};

const CepRegister: React.FC<CepRegisterProps> = (props) => {
  const { formRef } = props;

  const [cep, setCep] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [complemento, setComplemento] = useState('');
  const [error, setError] = useState('');
  const classes = useStyles();
  const { showLoading, hideLoading } = useLoading();

  const handleCEPChange = async (value: string) => {
    let formattedCep = value.replace(/\D/g, '');
    setCep(formattedCep);

    if (formattedCep.length === 8) {
      showLoading();
      try {
        const addressByCEP = await getAddressByCEP.execute({ cep: formattedCep });

        if (addressByCEP.erro) {
          setError('CEP não encontrado.');
          setLogradouro('');
          setBairro('');
          setCidade('');
          setEstado('');
          setComplemento('');
          return;
        }

        setLogradouro(addressByCEP.logradouro ?? '');
        setBairro(addressByCEP.bairro ?? '');
        setCidade(addressByCEP.localidade ?? '');
        setEstado(addressByCEP.uf ?? '');
        setComplemento(addressByCEP.complemento ?? '');

        formRef.current.setFieldValue('userAddress.placeDescription', addressByCEP.logradouro ?? '');
        formRef.current.setFieldValue('userAddress.neighborhood', addressByCEP.bairro ?? '');
        formRef.current.setFieldValue('userAddress.city', addressByCEP.localidade ?? '');
        formRef.current.setFieldValue('userAddress.state', addressByCEP.uf ?? '');
        formRef.current.setFieldValue('userAddress.complement', addressByCEP.complemento ?? '');

        setError('');
      } catch (err) {
        console.error('Erro ao buscar CEP:', err);
        setError('Erro ao buscar CEP.');
        setLogradouro('');
        setBairro('');
        setCidade('');
        setEstado('');
        setComplemento('');
      } finally {
        hideLoading();
      }
    } else {
      if (formattedCep.length > 5) {
        formattedCep = `${formattedCep.slice(0, 5)}-${formattedCep.slice(5)}`;
      }
      setCep(formattedCep);
    }
  };

  return (
    <Grid container spacing={2} direction="column">
      <Grid item xs={12}>
        <Input
          name="userAddress.cep"
          label={t('cep')}
          variant="outlined"
          fullWidth
          optional
          value={cep}
          onValueChange={handleCEPChange}
          error={!!error}
          className={classes.input}
        />
      </Grid>
      {error && (
        <Grid item xs={12}>
          <Typography variant="body2" className={classes.errorText}>
            {error}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Input
          name="userAddress.placeDescription"
          label={t('placeDescription')}
          variant="outlined"
          fullWidth
          optional
          value={logradouro}
          onChange={(e) => setLogradouro(e.target.value)}
          className={classes.input}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          name="userAddress.number"
          label={t('number')}
          variant="outlined"
          fullWidth
          optional
          value={numero}
          onChange={(e) => setNumero(e.target.value)}
          className={classes.input}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          name="userAddress.complement"
          label={t('complement')}
          variant="outlined"
          fullWidth
          optional
          value={complemento}
          onChange={(e) => setComplemento(e.target.value)}
          className={classes.input}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          name="userAddress.neighborhood"
          label={t('neighborhood')}
          variant="outlined"
          fullWidth
          optional
          value={bairro}
          onChange={(e) => setBairro(e.target.value)}
          className={classes.input}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          name="userAddress.city"
          label={t('city')}
          variant="outlined"
          fullWidth
          optional
          value={cidade}
          onChange={(e) => setCidade(e.target.value)}
          className={classes.input}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          name="userAddress.state"
          label={t('state')}
          variant="outlined"
          fullWidth
          optional
          value={estado}
          onChange={(e) => setEstado(e.target.value)}
          className={classes.input}
        />
      </Grid>
    </Grid>
  );
};

export default CepRegister;
