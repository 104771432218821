import React from 'react';
import { changeUserEmail } from '@psp/common';

import UserChangeEmail, { UserChangeEmailProps } from '../../components/User/ChangeEmail';

const dispatchers: UserChangeEmailProps = {
  changeUserEmail,
};

export default function UserChangeEmailComposer(): JSX.Element {
  return <UserChangeEmail {...dispatchers} />;
}
