import React, { useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import { TextField, TextFieldProps } from '@material-ui/core';

export type InputProps = TextFieldProps & {
  name: string;
  maxLength?: number;
  onValueChange?: (value: any) => void;
  optional?: boolean;
  [key: string]: any;
};

export default function Input({
  name,
  maxLength,
  helperText,
  onValueChange,
  optional = false,
  label,
  ...rest
}: InputProps): JSX.Element {
  const inputRef = useRef(null);
  const textRef = useRef(null as any);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [value, setValue] = useState();
  const inputLabel = label && !optional ? `${label} *` : label;
  const handleChange = (e: any): void => {
    setValue(e.target.value);
    if (onValueChange) {
      onValueChange(e.target.value);
    }
  };
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue: (ref, newValue: any) => {
        setValue(newValue);
        if (ref && newValue) {
          // eslint-disable-next-line no-param-reassign
          ref.value = newValue;
        }
      },
    });
  }, [fieldName, registerField]);
  return (
    <TextField
      error={!!error}
      helperText={error || helperText}
      defaultValue={defaultValue}
      ref={textRef}
      size="small"
      label={inputLabel}
      {...rest}
      inputRef={inputRef}
      name={name}
      InputLabelProps={{
        shrink: !!value,
      }}
      inputProps={{ maxLength }}
      onChange={handleChange}
      value={value}
    />
  );
}
