import React, { useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';

type MaskedPhoneInputProps = {
  inputRef: (ref: HTMLInputElement | null) => void;
  [key: string]: any;
};

const mask10Digit = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/];
const mask11Digit = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
const maskCharsRegex = /\D/g;

const chooseMask = (value: string | undefined) => {
  const valueWithoutMask = value?.replaceAll(maskCharsRegex, '');
  return valueWithoutMask?.length === 11 ? mask11Digit : mask10Digit;
};

export default function MaskedPhoneInput({
  inputRef,
  value,
  ...rest
}: MaskedPhoneInputProps): JSX.Element {
  const [mask, setMask] = useState(chooseMask(value));

  useEffect(() => {
    setMask(chooseMask(value));
  });

  return (
    <MaskedInput
      {...rest}
      ref={(ref: any): void => {
        inputRef(ref ? ref.inputElement : null);
      }}
      value={value}
      mask={mask}
      placeholderChar="_"
    />
  );
}
