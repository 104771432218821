import React from 'react';
import { ProcedureType, t } from '@psp/common';

import Breadcrumbs from '../../../../components/Breadcrumbs';
import ProcedureListComposer from '../../../../composers/ProcedureListComposer';
import {
  PROCEDURES_PATIENT_PROCEDURES_DETAIL_ROUTE,
  PROCEDURES_PATIENT_PROCEDURES_ROUTE,
} from '../../../../constants';

export default function PatientProcedureProcedureListPage(): JSX.Element {
  return (
    <>
      <Breadcrumbs title={t(`navigation.${PROCEDURES_PATIENT_PROCEDURES_ROUTE}`)} />
      <ProcedureListComposer
        type={ProcedureType.PATIENT_PROCEDURE}
        viewRoute={PROCEDURES_PATIENT_PROCEDURES_DETAIL_ROUTE}
      />
    </>
  );
}
