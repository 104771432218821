import React from 'react';
import { getFollowup, registerFollowup } from '@psp/common';

import FollowupRegister, { FollowupRegisterProps } from '../../components/Followup/Register';

const dispatchers: FollowupRegisterProps = {
  getFollowup,
  registerFollowup,
};

export default function FollowupRegisterComposer(): JSX.Element {
  return <FollowupRegister {...dispatchers} />;
}
