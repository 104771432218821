import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2.5),
    marginTop: theme.spacing(2.5),
  },
  title: {
    color: '#282929',
  },
  update: {
    font: 'small',
    color: '#757575',
  },
}));

export default useStyles;
