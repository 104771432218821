import React from 'react';

import { sendPatientInvitation } from '@psp/common';
import PatientInvitation, { PatientInvitationProps } from '../../components/Patient/Invitation';

const dispatchers: Pick<PatientInvitationProps, 'sendPatientInvitation'> = {
  sendPatientInvitation,
};

export default function PatientInvitationComposer(): JSX.Element {
  return <PatientInvitation {...dispatchers} />;
}
