import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  IconButton,
  makeStyles,
  Modal,
  Box,
  Typography,
} from '@material-ui/core';
import { t } from '@psp/common';
import { Close } from '@material-ui/icons';
import createDomPurify from 'dompurify';
import Button from '../Button';

export type ProgramTermsProps = {
  onClose: () => void;
  open: boolean;
  terms: string;
  variant: 'minimal' | 'full';
};
const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    outline: 'none',
    width: '90%',
    height: '90%',
    top: '50%',
    left: '50%',
    backgroundColor: '#fff',
    boxShadow: theme.shadows[24],
    padding: theme.spacing(2),
    maxHeight: '90%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '20px',
  },
  title: {
    flex: '0 0 auto',
  },
  contentBox: {
    flex: '1 1 auto',
    marginTop: theme.spacing(1),
    display: 'contents',
  },
  buttonBox: {
    display: 'flex',
    flex: '0 0 auto',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  content: {
    display: 'block',
    width: '100%',
    height: '100%',
    border: 'none',
    borderRadius: '4px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    overflowY: 'scroll',
  },
}));

const domPurify = createDomPurify(window);

export default function ProgramTerms({
  terms,
  onClose,
  open,
  variant,
}: ProgramTermsProps): JSX.Element {
  const classes = useStyles();

  if (variant === 'minimal') {
    return (
      <Dialog onClose={onClose} open={open}>
        <DialogTitle id="simple-dialog-title">
          TERMO DE CONSENTIMENTO
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="justify">
            <span dangerouslySetInnerHTML={{ __html: domPurify.sanitize(terms) }} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Modal
      open={open}
      aria-labelledby="terms-of-usage-modal"
      aria-describedby="terms-of-usage-modal-description"
      disableBackdropClick
      disableEscapeKeyDown
    >
      <Box className={classes.container}>
        <Typography variant="h5" color="primary" className={classes.title} id="terms-of-usage-modal">
          {t('pages.terms.title')}
        </Typography>
        <Box className={classes.contentBox}>
          <span
            className={classes.content}
            dangerouslySetInnerHTML={{ __html: domPurify.sanitize(terms) }}
          />
        </Box>
        <Box className={classes.buttonBox}>
          <Button onClick={onClose} variant="contained" color="primary">
            {t('pages.terms.readAndAgreeTerms')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
