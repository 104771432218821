import React from 'react';
import { t } from '@psp/common';

import Breadcrumbs from '../../../components/Breadcrumbs';
import UserRegisterComposer from '../../../composers/UserRegisterComposer';
import { USER_REGISTER_ROUTE } from '../../../constants';

export default function UserRegisterPage(): JSX.Element {
  return (
    <>
      <Breadcrumbs title={t(`navigation.${USER_REGISTER_ROUTE}`)} />
      <UserRegisterComposer />
    </>
  );
}
