import * as React from 'react';
import {
  Grid,
  Paper,
  Typography,
  Box,
} from '@material-ui/core';
import { formatCPF, Procedure, ProcedureType } from '@psp/common';
import { useAuth } from '../../contexts/auth.context';
import { useStyles } from './styles';

export default function PatientOrDoctorColumn(value: any): JSX.Element {
  const classes = useStyles();
  const { isDoctor } = useAuth();
  const isSupply = value.procedure.type === ProcedureType.SUPPLY;

  return (
    <Paper elevation={0} className={classes.Container}>
      <Grid spacing={2}>
        <Grid item xs={12} sm>
          <Grid item xs direction="column" spacing={2}>
            <Grid item xs>
              {(isDoctor && !isSupply) && (
                <div>
                  <Typography className={classes.Solicitation}>
                    Solicitação nº
                    <Box display="inline">
                      <strong>
                        <>&nbsp;</>
                        {value.procedure.code}
                      </strong>
                    </Box>
                  </Typography>
                  <Typography className={classes.Name}>
                    {value.procedure.patient.name}
                  </Typography>
                  <Typography className={classes.Document}>
                    CPF:
                    <Box display="inline">
                      <strong>
                        <>&nbsp;</>
                        {formatCPF(value.procedure.patient.cpf)}
                      </strong>
                    </Box>
                  </Typography>
                </div>
              )}
              {(!isDoctor || isSupply) && (
                <div>
                  <Typography className={classes.Solicitation}>
                    Solicitação nº
                    <strong>
                      <>&nbsp;</>
                      {value.procedure.code}
                    </strong>
                  </Typography>
                  <Typography className={classes.Name}>
                    {value.procedure.createdBy.name}
                  </Typography>
                  <Typography className={classes.Document}>
                    CRM:
                    <strong>
                      <>&nbsp;</>
                      {`${value.procedure.createdBy.doctor.crm}/${value.procedure.createdBy.doctor.uf}`}
                    </strong>
                  </Typography>
                  {value.procedure.supplyRequests?.length && (
                    <Typography className={classes.Document}>
                      Rastreio:
                      <strong>
                        <>&nbsp;</>
                        {`${value.procedure.supplyRequests[0].trackingCode}`}
                      </strong>
                    </Typography>
                  )}
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
