import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  Container,
  Grid,
  Typography,
  Slide,
  Fade,
  ButtonGroup,
  Button as MaterialButton,
  TextField,
  Tooltip,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { Message, Email, Visibility, VisibilityOff } from '@material-ui/icons';
import { Form } from '@unform/web';
import {
  t,
  extractValidationErrors,
  signupDoctorRegistrationForm,
  signupDoctorSearchForm,
  ValidationError,
  GetDoctorByCrm,
  GetDoctorByCrmParams,
  SendVerificationCode,
  Signup as SignupUser,
  SignupParams,
  Program,
  GetProgramBySlug,
  ProgramModulesEnum,
  GetProgramPoliciesByUserType,
  GetSpecialtyUserGroups,
  UserType,
} from '@psp/common';
import clsx from 'clsx';
import useDigitInput from 'react-digit-input';
import { toast } from 'react-toastify';
import { differenceInSeconds } from 'date-fns';
import { CheckCircle } from 'mdi-material-ui';
import { Link } from 'react-router-dom';
import createDomPurify from 'dompurify';
import { SIGNIN_ROUTE, SITE_KEY_CAPTCHA } from '../../constants';
import { useLoading } from '../../contexts/loading.context';
import SigninNavbar from '../SigninNavbar';
import Button from '../Button';
import CheckboxInput from '../CheckboxInput';
import CrmInput from '../CrmInput';
import Footer from '../Footer';
import Input from '../Input';
import MaskedPhoneInput from '../MaskedPhoneInput';
import StateSelect from '../StateSelect';
import { useStyles, useTooltipStyles } from './styles';
import SigninFooter from '../SigninFooter';
import { useQuery } from '../../hooks/use-query';
import ProgramStyles from '../ProgramStyles';
import { useProgram } from '../../contexts/program.context';
import DocumentInput from '../DocumentInput';
import DateInput from '../DateInput';
import CheckboxSelect from '../CheckboxSelect';
import CepRegister from '../CepRegister';

const slideTimeout = {
  enter: 500,
  exit: 400,
};
const fadeTimeout = {
  enter: 1000,
  exit: 200,
};

const preventEvent = (e: any) => {
  e.preventDefault();
};

export type SignupProps = {
  getDoctorByCrm: GetDoctorByCrm;
  getProgramBySlug: GetProgramBySlug;
  sendVerificationCode: SendVerificationCode;
  signup: SignupUser;
  getProgramPoliciesByUserType: GetProgramPoliciesByUserType;
  getSpecialtyUserGroups: GetSpecialtyUserGroups;
};

const domPurify = createDomPurify(window);

export default function Signup({
  getDoctorByCrm,
  getProgramBySlug,
  sendVerificationCode,
  signup,
  getProgramPoliciesByUserType,
  getSpecialtyUserGroups,
}: SignupProps): JSX.Element {
  const classes = useStyles();
  const tooltipClasses = useTooltipStyles();
  const formRef = useRef({} as any);
  const registrationFormRef = useRef({} as any);
  const { program = '' } = useQuery();
  const [step, setStep] = useState(0);
  const [code, setCode] = useState('');
  const [sendDate, setSendDate] = useState<Date | undefined>(undefined);
  const [sendType, setSendType] = useState('');
  const [codeText, setCodeText] = useState('');
  const recaptchaValueRef = useRef('' as any);
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const recaptchaValueRefStep2 = useRef('' as any);
  const recaptchaRefStep2 = useRef<ReCAPTCHA | null>(null);
  const [isRecaptchaDisabled, setIsRecaptchaDisabled] = useState(false);
  const [policies, setPolicies] = useState([] as any);
  const [specialties, setSpecialties] = useState([] as any);
  const [doctorExists, setDoctorExists] = useState(false);
  const [userApproved, setUserApproved] = useState(false);
  const [userExists, setUserExists] = useState(false);
  const [emailAlreadyRegistered, setEmailAlreadyRegistered] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);
  const [showPhoneTooltip, setShowPhoneTooltip] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loadedProgram, setLoadedProgram] = useState<Program | Record<string, never> | undefined>(
    undefined,
  );
  const { isLoading, showLoading, hideLoading, showBackdrop, hideBackdrop } = useLoading();
  const { selectProgram, modulesMap } = useProgram();
  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 5,
    value: code,
    onChange: setCode,
  });

  const salesforceVisit = useCallback(
    () => loadedProgram?.modules
      && loadedProgram?.modules.split(',').includes(ProgramModulesEnum.SALESFORCE_VISIT),
    [loadedProgram],
  );

  const doctorBenefits = useCallback(
    () => loadedProgram?.modules
      && loadedProgram?.modules.split(',').includes(ProgramModulesEnum.DOCTOR_BENEFITS),
    [loadedProgram],
  );

  const requireSpecialty = useCallback(
    () => loadedProgram?.allowSpecialtyDuringSignup
      && specialties?.length > 0,
    [loadedProgram, specialties],
  );

  const requireCpf = useCallback(
    () => loadedProgram?.requireCpfDuringSignup,
    [loadedProgram],
  );

  const showAddressFields = useCallback(
    () => loadedProgram?.name === 'Abraçar a vida',
    [loadedProgram],
  );

  const handlePhoneFocus = () => {
    setShowPhoneTooltip(true);
  };

  const handlePhoneBlur = () => {
    setShowPhoneTooltip(false);
  };

  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  const handleShowPasswordMouseDown = (e: any) => {
    e.preventDefault();
  };

  const recaptchaValueSet = (value: any) => {
    if (typeof value === 'string') {
      recaptchaValueRef.current = value;
    } else {
      recaptchaValueRef.current = '';
    }
  };

  const recaptchaValueSetStep2 = (value: any) => {
    if (typeof value === 'string') {
      recaptchaValueRefStep2.current = value;
    } else {
      recaptchaValueRefStep2.current = '';
    }
  };

  const handleSubmit = useCallback(
    (data) => {
      showLoading();
      async function runAsync() {
        try {
          formRef.current.setErrors({});
          setDoctorExists(false);
          const fixData = {
            ...data,
            crm: data.crm.replace(/^0*(.*)$/, '$1'),
          };
          formRef.current.setData(fixData);
          const req = (await signupDoctorSearchForm.validate(fixData, {
            abortEarly: false,
          })) as GetDoctorByCrmParams;
          const res = await getDoctorByCrm.execute({
            ...req,
            programId: loadedProgram?.id,
          });
          setStep(1);
          setUserExists(res.userExists);
          setUserApproved(res.userApproved);
          registrationFormRef.current.setData({
            ...res,
          });
        } catch (err) {
          if (err.message === 'doctorExists') {
            setDoctorExists(true);
          } else if (err.message === 'invalidCRM') {
            toast.info(loadedProgram?.customMessageInvalidCRM ?? t('validation.invalidCRM'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } else {
            let errors = {};
            if (err instanceof ValidationError) {
              errors = extractValidationErrors(err);
              formRef.current.setErrors(errors);
            } else {
              toast.info(t('err.failedToSearchDoctor'), {
                onOpen: showBackdrop,
                onClose: hideBackdrop,
              });
            }
          }
        } finally {
          hideLoading();
        }
      }
      runAsync();
    },
    [setStep, loadedProgram],
  );

  const setErrorMensages = (data: any, extractedErrors: any = {}) => {
    const validationErrors = {} as any;

    Object.keys(data)
      .filter((key) => key.startsWith('dynamic-policy-') && !data[key])
      .forEach((key) => {
        validationErrors[key] = t('validation.requiredTerms');
      });

    registrationFormRef.current.setErrors({ ...validationErrors, ...extractedErrors });
  };

  const handleRegistrationSubmit = useCallback(
    (data) => {
      if (recaptchaValueRef.current === '' || typeof recaptchaValueRef.current !== 'string') {
        toast.info(t('validation.recaptchaRequired'), { onOpen: showBackdrop, onClose: hideBackdrop });
        return;
      }
      showLoading();
      (async () => {
        try {
          registrationFormRef.current.setErrors({});
          await signupDoctorRegistrationForm.validate(data, {
            abortEarly: false,
            context: {
              doctorBenefits: doctorBenefits(),
              userExists,
              requireSpecialty: requireSpecialty(),
            },
          });

          const dynamicPoliciesValues = Object.keys(data)
            .filter((k) => k.startsWith('dynamic-policy'))
            .map((k) => data[k]);

          if (dynamicPoliciesValues.includes(false)) {
            setErrorMensages(data);
          } else {
            const { phone } = data;
            const type = 'SMS';
            const res = await sendVerificationCode.execute({
              type,
              destination: phone,
              recaptchaValue: recaptchaValueRef.current,
            });
            setSendDate(res);
            setSendType(type);
            setStep(2);
          }
        } catch (err) {
          if (err instanceof ValidationError) {
            setErrorMensages(data, extractValidationErrors(err));
          } else {
            toast.info(t('err.failedToSendCode'), { onOpen: showBackdrop, onClose: hideBackdrop });
          }
        } finally {
          hideLoading();
        }
      })();
    },
    [setStep, userExists, specialties],
  );

  const handleSendVerificationClick = useCallback(
    (type) => {
      showLoading();
      async function runAsync() {
        if (recaptchaValueRefStep2.current === '' || typeof recaptchaValueRefStep2.current !== 'string') {
          toast.info(t('validation.recaptchaRequired'), { onOpen: showBackdrop, onClose: hideBackdrop });
          hideLoading();
          return;
        }
        try {
          const { phone } = await registrationFormRef.current.getData();
          const res = await sendVerificationCode.execute({
            type,
            destination: phone,
            recaptchaValue: recaptchaValueRefStep2.current,
          });
          setSendDate(res);
          setSendType(type);
        } catch (err) {
          toast.info(t('err.failedToSendCode'), { onOpen: showBackdrop, onClose: hideBackdrop });
        } finally {
          hideLoading();
        }
      }
      runAsync();
    },
    [sendVerificationCode, registrationFormRef],
  );

  const handleSendAgainClick = useCallback((): void => {
    handleSendVerificationClick(sendType);
  }, [handleSendVerificationClick, sendType]);

  const handleBackClick = useCallback((): void => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
      recaptchaValueRef.current = '';
    }
    setStep(step - 1);
    setSendDate(undefined);
    setInvalidCode(false);
    setEmailAlreadyRegistered(false);
  }, [step]);

  const register = useCallback(() => {
    showLoading();
    (async () => {
      try {
        setEmailAlreadyRegistered(false);
        setInvalidCode(false);
        const user = await signupDoctorRegistrationForm.validate(
          registrationFormRef.current.getData(),
          {
            abortEarly: false,
            context: {
              doctorBenefits: doctorBenefits(),
              userExists,
              requireSpecialty: requireSpecialty(),
            },
          },
        );
        const req = {
          code,
          type: sendType,
          user: { ...user, approved: userApproved },
          programId: loadedProgram?.id || '',
        } as SignupParams;
        await signup.execute(req);
        setStep(3);
      } catch (err) {
        if (err.message === 'invalidCode') {
          setInvalidCode(true);
        } else if (err.message === 'emailAlreadyRegistered') {
          setEmailAlreadyRegistered(true);
        } else if (err.name === 'BadRequestError') {
          toast.info(err.message, { onOpen: showBackdrop, onClose: hideBackdrop });
        } else {
          toast.info(t('err.failedToSignup'), { onOpen: showBackdrop, onClose: hideBackdrop });
        }
        setCode('');
      } finally {
        hideLoading();
      }
    })();
  }, [registrationFormRef, code, sendType, loadedProgram, userExists, userApproved]);

  useEffect(() => {
    let handler: any;
    if (sendDate) {
      handler = setInterval(() => {
        const count = Math.max(60 - differenceInSeconds(new Date(), sendDate), 0);
        setCodeText(t('sendAgainIn', { count }));
      }, 1000);
    }
    return function cleanup() {
      clearInterval(handler);
    };
  }, [sendDate, setCodeText]);

  useEffect(() => {
    if (/^\d{5}$/.test(code)) {
      register();
    }
  }, [code]);

  useEffect(() => {
    if (program) {
      (async () => {
        try {
          const res = await getProgramBySlug.execute({
            slug: program,
          });
          setLoadedProgram(res);
        } catch (err) {
          console.log(err);
        }
      })();
    } else {
      setLoadedProgram({});
    }
  }, [program]);

  useEffect(() => {
    setIsRecaptchaDisabled(isLoading || codeText !== t('sendAgainIn', { count: 0 }));
  }, [isLoading, codeText, t]);

  useEffect(() => {
    (async () => {
      setPolicies(
        await getProgramPoliciesByUserType.execute({
          programId: loadedProgram?.id,
          userType: UserType.DOCTOR,
        }),
      );

      if (loadedProgram?.id) {
        setSpecialties(
          await getSpecialtyUserGroups.execute({
            programId: loadedProgram?.id,
          }),
        );

        selectProgram(loadedProgram as Program);
      }
    })();
  }, [loadedProgram]);

  if (!loadedProgram) {
    return <></>;
  }

  return (
    <Grid container direction="column" className={clsx(classes.container, 'signup-container')}>
      <ProgramStyles program={loadedProgram} />
      <Grid item className={classes.navbarContainer}>
        <SigninNavbar />
      </Grid>
      <Grid item xs className={classes.main}>
        <Container className={classes.mainContainer}>
          <Grid container spacing={4} direction="column">
            <Grid item xs>
              <div className={classes.titleContainer}>
                <Typography variant="h1" color="primary" className={classes.title}>
                  {t('pages.signup.title')}
                </Typography>
                <Typography variant="h2" className={classes.subtitle}>
                  {t('pages.signup.subtitle')}
                </Typography>
              </div>
            </Grid>
            <Grid item xs>
              <Grid container spacing={2} direction="row">
                <Grid item xs={12} sm>
                  <Slide in={step >= 0} timeout={slideTimeout} direction="up">
                    <Grid container>
                      <Fade in={step >= 0} timeout={fadeTimeout}>
                        <Grid item xs className={classes.box}>
                          <Form ref={formRef} onSubmit={handleSubmit}>
                            <Typography variant="body1" color="primary">
                              {t('pages.signup.firstStep.title')}
                            </Typography>
                            <br />
                            <Grid container spacing={2}>
                              <Grid item xs={8} sm>
                                <Input
                                  name="crm"
                                  variant="outlined"
                                  label={t('crm')}
                                  fullWidth
                                  helperText={t('helperText.numbersOnly')}
                                  className={classes.crmInput}
                                  InputProps={{
                                    inputComponent: CrmInput as any,
                                    className: classes.input,
                                  }}
                                  disabled={step > 0}
                                />
                              </Grid>
                              <Grid item xs>
                                <StateSelect
                                  name="uf"
                                  variant="outlined"
                                  label={t('uf')}
                                  fullWidth
                                  displayEmpty
                                  disabled={step > 0}
                                  inputProps={{
                                    className: classes.input,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm="auto">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="large"
                                  fullWidth
                                  type="submit"
                                  className={classes.searchButton}
                                  disabled={step > 0}
                                >
                                  {t('search')}
                                </Button>
                              </Grid>
                            </Grid>
                          </Form>
                          {doctorExists && (
                            <Typography variant="body1" align="center" color="error" className="signup-doctor-exists-error">
                              Você já possui cadastro,
                              {' '}
                              <Link to={`/login?program=${program}`}>clique aqui</Link>
                              {' '}
                              para se
                              logar
                            </Typography>
                          )}
                        </Grid>
                      </Fade>
                    </Grid>
                  </Slide>
                </Grid>
                <Grid item xs={12} sm>
                  <Slide in={step >= 1} timeout={slideTimeout} unmountOnExit direction="up">
                    <Grid container>
                      <Fade in={step >= 1} timeout={fadeTimeout}>
                        <Grid item xs className={classes.box}>
                          <Typography variant="body1" color="primary">
                            {t('pages.signup.secondStep.title')}
                          </Typography>
                          <br />
                          <Form
                            ref={registrationFormRef}
                            onSubmit={handleRegistrationSubmit}
                            className={classes.registrationForm}
                          >
                            <Grid container spacing={2} direction="column">
                              <Grid item xs={12} sm="auto">
                                <Grid container spacing={2}>
                                  <Grid item xs={8}>
                                    <Input
                                      name="crm"
                                      variant="outlined"
                                      label={t('crm')}
                                      fullWidth
                                      disabled
                                      InputProps={{
                                        className: classes.input,
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <StateSelect
                                      name="uf"
                                      variant="outlined"
                                      label={t('uf')}
                                      fullWidth
                                      displayEmpty
                                      disabled
                                      inputProps={{
                                        className: classes.input,
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm="auto">
                                <Input
                                  name="name"
                                  variant="outlined"
                                  label={t('name')}
                                  fullWidth
                                  disabled={step > 1 || userExists}
                                  InputProps={{
                                    className: classes.input,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm="auto">
                                <Input
                                  name="email"
                                  variant="outlined"
                                  label={t('email')}
                                  fullWidth
                                  autoComplete="nope"
                                  onCopy={preventEvent}
                                  onPaste={preventEvent}
                                  onCut={preventEvent}
                                  disabled={step > 1 || userExists}
                                  InputProps={{
                                    className: classes.input,
                                  }}
                                />
                              </Grid>
                              {!userExists && (
                                <Grid item xs={12} sm="auto">
                                  <Input
                                    name="confirmEmail"
                                    variant="outlined"
                                    label={t('confirmEmail')}
                                    fullWidth
                                    autoComplete="nope"
                                    onCopy={preventEvent}
                                    onPaste={preventEvent}
                                    onCut={preventEvent}
                                    disabled={step > 1}
                                    InputProps={{
                                      className: classes.input,
                                    }}
                                  />
                                </Grid>
                              )}
                              <Grid item xs={12} sm="auto">
                                <Tooltip
                                  open={showPhoneTooltip}
                                  placement="right"
                                  title={t('tooltips.signupPhone')}
                                  classes={tooltipClasses}
                                >
                                  <Input
                                    name="phone"
                                    variant="outlined"
                                    label={t('cellphone')}
                                    fullWidth
                                    autoComplete="nope"
                                    onCopy={preventEvent}
                                    onPaste={preventEvent}
                                    onCut={preventEvent}
                                    onFocus={handlePhoneFocus}
                                    onBlur={handlePhoneBlur}
                                    disabled={step > 1 || userExists}
                                    InputProps={{
                                      inputComponent: MaskedPhoneInput as any,
                                      className: classes.input,
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                              {requireSpecialty() && (
                                <Grid item xs={12} sm="auto">
                                  <CheckboxSelect
                                    name="specialties"
                                    label={t('specialty')}
                                    items={specialties.map((s: any) => ({
                                      id: s.id, name: s.name,
                                    }))}
                                    style={{ size: 'small' }}
                                    multiple
                                  />
                                </Grid>
                              )}
                              {(doctorBenefits() || requireCpf()) && (
                              <Grid item xs={12} sm="auto">
                                <Input
                                  name="cpf"
                                  variant="outlined"
                                  label={t('cpf')}
                                  fullWidth
                                  optional={requireCpf()}
                                  disabled={step > 1}
                                  InputProps={{
                                    inputComponent: DocumentInput as any,
                                    className: classes.input,
                                  }}
                                />
                              </Grid>
                              )}
                              {doctorBenefits() && (
                                <Grid item xs={12} sm="auto">
                                  <DateInput
                                    name="birthDate"
                                    inputVariant="outlined"
                                    label={t('birthDate')}
                                    fullWidth
                                    format="dd/MM/yyyy"
                                    disabled={step > 1}
                                    InputProps={{
                                      className: classes.input,
                                    }}
                                  />
                                </Grid>
                              )}
                              {(showAddressFields()) && (
                                <Grid style={{ marginTop: '6px', marginBottom: '6px' }}>
                                  <CepRegister formRef={registrationFormRef} />
                                </Grid>
                              )}
                              {!userExists && (
                                <>
                                  <Grid item xs={12} sm="auto">
                                    <Input
                                      name="password"
                                      variant="outlined"
                                      label={t('password')}
                                      type={showPassword ? 'text' : 'password'}
                                      fullWidth
                                      autoComplete="new-password"
                                      helperText={t('helperText.password')}
                                      onCopy={preventEvent}
                                      onPaste={preventEvent}
                                      onCut={preventEvent}
                                      disabled={step > 1}
                                      InputProps={{
                                        className: classes.input,
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton
                                              onClick={handleShowPasswordClick}
                                              onMouseDown={handleShowPasswordMouseDown}
                                              disabled={step > 1}
                                            >
                                              {!showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm="auto">
                                    <Input
                                      name="confirmPassword"
                                      variant="outlined"
                                      label={t('confirmPassword')}
                                      type={showPassword ? 'text' : 'password'}
                                      fullWidth
                                      autoComplete="new-password"
                                      onCopy={preventEvent}
                                      onPaste={preventEvent}
                                      onCut={preventEvent}
                                      disabled={step > 1}
                                      InputProps={{
                                        className: classes.input,
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton
                                              onClick={handleShowPasswordClick}
                                              onMouseDown={handleShowPasswordMouseDown}
                                              disabled={step > 1}
                                            >
                                              {!showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </Grid>
                                </>
                              )}
                              <Grid item xs={12}>
                                <ReCAPTCHA
                                  ref={recaptchaRef}
                                  sitekey={SITE_KEY_CAPTCHA}
                                  onChange={recaptchaValueSet}
                                />
                              </Grid>
                              {policies.map((policy: any) => (
                                <Grid item key={policy.id}>
                                  <Grid item xs={12} sm="auto">
                                    <Typography variant="body1">
                                      {policy.url && (
                                        <a href={policy.url} target="_blank" rel="noreferrer">
                                          {t('pages.pendingUsagePolicies.readTerms', {
                                            termsDescription: policy.description,
                                          })}
                                        </a>
                                      )}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm="auto">
                                    <CheckboxInput
                                      name={`dynamic-policy-${policy.id}`}
                                      label={
                                        policy.url
                                          ? t('pages.pendingUsagePolicies.acceptTerms', {
                                            termsDescription: policy.description,
                                          })
                                          : (
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: domPurify.sanitize(
                                                  policy.description,
                                                  { ADD_ATTR: ['target'] },
                                                ),
                                              }}
                                            />
                                          )
                                      }
                                      disabled={step > 1}
                                    />
                                  </Grid>
                                </Grid>
                              ))}
                              {salesforceVisit() && (
                                <Grid item xs={12} sm="auto">
                                  <CheckboxInput
                                    name="requestVisit"
                                    label={t('pages.signup.secondStep.requestVisit')}
                                    disabled={step > 1}
                                  />
                                </Grid>
                              )}
                              <Grid container item xs={12} sm="auto" spacing={2} justify="flex-end">
                                <Grid item>
                                  <Button
                                    variant="text"
                                    color="default"
                                    size="large"
                                    onClick={handleBackClick}
                                    disabled={step > 1}
                                  >
                                    {t('goBack')}
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    type="submit"
                                    disabled={step > 1}
                                  >
                                    {t('register')}
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Form>
                        </Grid>
                      </Fade>
                    </Grid>
                  </Slide>
                </Grid>
                <Grid item xs={12} sm>
                  <Grid container spacing={2} direction="column">
                    <Grid item xs={12}>
                      <Slide in={step >= 2} timeout={slideTimeout} unmountOnExit direction="up">
                        <Grid container>
                          <Fade in={step >= 2} timeout={fadeTimeout}>
                            <Grid item xs className={classes.box}>
                              <Grid container spacing={2} direction="column">
                                <Grid item xs={12}>
                                  <Typography variant="body1" color="primary">
                                    {t('pages.signup.thirdStep.title')}
                                  </Typography>
                                  <br />
                                  <Typography variant="body2" color="primary">
                                    {t('pages.signup.thirdStep.subtitle')}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid container direction="column" alignItems="center">
                                    <Grid item xs>
                                      <Grid container spacing={0} justify="center">
                                        <Grid item>
                                          <TextField
                                            inputMode="decimal"
                                            variant="outlined"
                                            inputProps={{
                                              ...digits[0],
                                              className: classes.input,
                                            }}
                                            autoFocus
                                            className={classes.codeField}
                                            disabled={isLoading || step > 2}
                                          />
                                        </Grid>
                                        <Grid item>
                                          <TextField
                                            inputMode="decimal"
                                            variant="outlined"
                                            inputProps={{
                                              ...digits[1],
                                              className: classes.input,
                                            }}
                                            className={classes.codeField}
                                            disabled={isLoading || step > 2}
                                          />
                                        </Grid>
                                        <Grid item>
                                          <TextField
                                            inputMode="decimal"
                                            variant="outlined"
                                            inputProps={{
                                              ...digits[2],
                                              className: classes.input,
                                            }}
                                            className={classes.codeField}
                                            disabled={isLoading || step > 2}
                                          />
                                        </Grid>
                                        <Grid item>
                                          <TextField
                                            inputMode="decimal"
                                            variant="outlined"
                                            inputProps={{
                                              ...digits[3],
                                              className: classes.input,
                                            }}
                                            className={classes.codeField}
                                            disabled={isLoading || step > 2}
                                          />
                                        </Grid>
                                        <Grid item>
                                          <TextField
                                            inputMode="decimal"
                                            variant="outlined"
                                            inputProps={{
                                              ...digits[4],
                                              className: classes.input,
                                            }}
                                            className={classes.codeField}
                                            disabled={isLoading || step > 2}
                                          />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <br />
                                    {invalidCode && (
                                      <Typography variant="body1" align="center" color="error">
                                        Código inválido
                                      </Typography>
                                    )}
                                    {emailAlreadyRegistered && (
                                      <Typography variant="body1" align="center" color="error" className="signup-email-already-registered-error">
                                        E-mail já cadastrado,
                                        {' '}
                                        <Link to={SIGNIN_ROUTE}>clique aqui</Link>
                                        {' '}
                                        para se logar
                                      </Typography>
                                    )}
                                    <Grid item xs={12}>
                                      {!isRecaptchaDisabled && (
                                        <ReCAPTCHA
                                          ref={recaptchaRefStep2}
                                          sitekey={SITE_KEY_CAPTCHA}
                                          onChange={recaptchaValueSetStep2}
                                        />
                                      )}
                                      <Button
                                        variant="text"
                                        onClick={handleSendAgainClick}
                                        disabled={
                                          isLoading || codeText !== t('sendAgainIn', { count: 0 })
                                        }
                                      >
                                        {codeText}
                                      </Button>
                                    </Grid>
                                    <Button
                                      variant="text"
                                      onClick={handleBackClick}
                                      disabled={isLoading || step > 2}
                                    >
                                      {t('goBack')}
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Fade>
                        </Grid>
                      </Slide>
                    </Grid>
                    <Grid item xs={12}>
                      <Slide in={step >= 3} timeout={slideTimeout} unmountOnExit direction="up">
                        <Grid
                          container
                          className={clsx(classes.box, classes.blueBox, 'signup-success-card')}
                        >
                          <Fade in={step >= 3} timeout={fadeTimeout}>
                            <Grid item>
                              <Grid container spacing={2} direction="column">
                                <Grid item xs>
                                  <Grid container spacing={2} direction="row">
                                    <Grid item xs>
                                      <Typography
                                        variant="h5"
                                        color="inherit"
                                        className={classes.blueBoxTitle}
                                      >
                                        {t('pages.signup.fourthStep.title')}
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <CheckCircle
                                        color="inherit"
                                        className={classes.blueBoxIcon}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs>
                                  <Typography
                                    variant="body2"
                                    color="inherit"
                                    className={classes.blueBoxSubtitle}
                                  >
                                    {t('pages.signup.fourthStep.subtitle')}
                                  </Typography>
                                </Grid>
                                <Grid item xs>
                                  <MaterialButton
                                    variant="outlined"
                                    color="inherit"
                                    component={Link}
                                    to={`/login?program=${program}`}
                                    size="large"
                                    onClick={() => { window.location.href = `/login?program=${program}`; }}
                                  >
                                    {t('accessPlatform')}
                                  </MaterialButton>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Fade>
                        </Grid>
                      </Slide>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid item className={classes.footerContainer}>
        <SigninFooter program={loadedProgram} />
      </Grid>
    </Grid>
  );
}
