import React from 'react';
import { t } from '@psp/common';

import Breadcrumbs from '../../../components/Breadcrumbs';
import MedicationReleaseComposer from '../../../composers/MedicationReleaseComposer';
import { MEDICATION_RELEASE_ROUTE } from '../../../constants';

export default function MedicationReleasePage(): JSX.Element {
  return (
    <>
      <Breadcrumbs title={t(`navigation.${MEDICATION_RELEASE_ROUTE}`)} />
      <MedicationReleaseComposer />
    </>
  );
}
