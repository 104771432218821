import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { GetInventory, Inventory as InventoryModel } from '@psp/common';

import { useLoading } from '../../../contexts/loading.context';
import InventoryDisplay from '../../InventoryDisplay';
import { useStyles } from './styles';
import { useProgram } from '../../../contexts/program.context';

export type InventoryProps = {
  getInventory: GetInventory;
};

export type InventoryState = {
  inventory: InventoryModel[];
};

const defaultState: InventoryState = {
  inventory: [],
};

export default function Inventory({ getInventory }: InventoryProps): JSX.Element {
  const classes = useStyles();
  const [state, setState] = useState<InventoryState>(defaultState);
  const { showLoading, hideLoading } = useLoading();
  const { program } = useProgram();

  useEffect(() => {
    if (!program) return;
    showLoading();
    (async () => {
      try {
        const res = await getInventory.execute({ programId: program.id });
        setState({
          ...state,
          inventory: res,
        });
      } catch (err) {
        console.log(err);
      } finally {
        hideLoading();
      }
    })();
  }, [program]);

  return (
    <Box className={classes.container}>
      {/* <InventoryTable data={state.inventory} /> */}
      <InventoryDisplay inventory={state.inventory} />
    </Box>
  );
}
