import React from 'react';
import clsx from 'clsx';

import { Link } from 'react-router-dom';
import { useScript } from '../../hooks/use-script';
import { SIGNIN_ROUTE, SIGNUP_ROUTE } from '../../constants';

export default function Home(): JSX.Element {
  return (
    <>
      <link rel="shortcut icon" type="image/x-icon" href="assets/img/favicon.png" />
      <link rel="stylesheet" href="assets/css/animate.min.css" />
      <link rel="stylesheet" href="assets/css/animated-headline.css" />
      <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
      <link rel="stylesheet" href="assets/css/flaticon.css" />
      <link rel="stylesheet" href="assets/css/fontawesome-all.min.css" />
      <link rel="stylesheet" href="assets/css/magnific-popup.css" />
      <link rel="stylesheet" href="assets/css/slick.css" />
      <link rel="stylesheet" href="assets/css/slicknav.css" />
      <link rel="stylesheet" href="assets/css/style.css" />
      <link rel="stylesheet" href="assets/css/themify-icons.css" />
      <main>
        <div className="container-fluid">
          <div className="slider-area position-relative">
            <div className="slider-active dot-style">
              <div className="single-slider hero-overly slider-height slider-bg1 d-flex align-items-center">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-9 col-lg-11 col-md-11">
                      <div className="logo">
                        <a href="https://www.bcare.com.br" target="_blank" rel="noreferrer">
                          <img src="assets/img/logo/logo.svg" alt="" width="160px" />
                        </a>
                      </div>
                      <br />
                      <div className="hero__caption">
                        <h2 style={{ color: '#fff' }}>Bem vindo ao</h2>
                        <h1 data-animation="fadeInUp" data-delay=".2s">
                          Portal Médico
                        </h1>
                        <div className="hero-details">
                          <p data-animation="fadeInUp" data-delay=".4s">
                            Olá Doutor(a), se já possuir cadastro,
                            <br />
                            é só&nbsp;
                            <strong>clicar em Acessar o Programa.</strong>
                          </p>

                          <div className="hero__btn">
                            <Link
                              to={SIGNIN_ROUTE}
                              className="btn mr-2"
                              data-animation="fadeInUp"
                              data-delay=".8s"
                            >
                              ACESSAR O PROGRAMA
                            </Link>

                            <Link
                              to={SIGNUP_ROUTE}
                              className="btn-cta mt-10 strong"
                              data-animation="fadeInUp"
                              data-delay="1s"
                            >
                              OU, CRIE AGORA SUA CONTA
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="office-environments">
          <div className="container">
            <div
              className="environments-wrapper section-bg02"
              style={{ backgroundImage: "url('assets/img/sign1.png')" }}
            >
              <div className="row">
                <div className="col-xl-7 col-lg-8 offset-xl-5 offset-lg-4">
                  <div className="office-pera">
                    <div className="section-tittle">
                      <h2 className="mb-30">Como funciona?</h2>
                      <p>
                        O Portal Médico tem como propósito apoiar o médico e seus pacientes
                        elegíveis, acesso aos exames para o diagnóstico. O diagnóstico precoce,
                        poderá ajudar muitos pacientes a terem um acompanhamento adequado de
                        saúde, juntamente com o fundamental apoio e direcionamento exclusivo
                        de seus médicos.
                      </p>
                      <Link
                        to={SIGNUP_ROUTE}
                        className="btn"
                      >
                        CRIE AGORA SUA CONTA
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pricing-card-area fix section-padding30">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-7 col-md-10">
                <div className="section-tittle text-center mb-90">
                  <h2>São apenas quatro passos simples!</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10">
                <div className="single-card text-center mb-30">
                  <div className="card-top">
                    <img src="assets/img/icon/passo1.svg" alt="" width="128px" />
                    <h4>Passo 1</h4>
                    <p>
                      É simples, é só se cadastrar na nossa plataforma com os seus dados.
                      Nosso sistema atende as normas da Lei geral de proteção de dados.
                      <br />
                      <br />
                      <br />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10">
                <div className="single-card text-center mb-30">
                  <div className="card-top">
                    <img src="assets/img/icon/passo2.svg" alt="" width="128px" />
                    <h4>Passo 2</h4>
                    <p>
                      Cadastro realizado, Você decide qual paciente tem o perfil
                      adequado para indicar o procedimento.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10">
                <div className="single-card text-center mb-30">
                  <div className="card-top">
                    <img src="assets/img/icon/passo3.svg" alt="" width="128px" />
                    <h4>Passo 3</h4>
                    <p>
                      Basta escolher o programa e o procedimento para liberar ao seu
                      paciente (*conforme elegibilidade dos pacientes).
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10">
                <div className="single-card text-center mb-30">
                  <div className="card-top">
                    <img src="assets/img/icon/passo4.svg" alt="" width="128px" />
                    <h4>Passo 4</h4>
                    <p>
                      Seu paciente receberá um SMS da liberação do exame e contato do
                      programa para conclusão do cadastro e agendamento do exame.
                      O Dr.(a) poderá acompanhar o status pela plataforma.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="testimonial-area testimonial-padding fix">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-8 col-lg-8">
                <div className="about-caption pb-bottom">
                  <div className="h1-testimonial">
                    <div className="single-testimonial position-relative">
                      <div className="testimonial-caption">
                        <h1>
                          Inicie seu cadastro na Plataforma Portal Médico.
                        </h1>
                        <br />
                      </div>
                      <div className="testimonial-founder d-flex align-items-center">
                        <Link
                          to={SIGNUP_ROUTE}
                          className="genric-btn primary circle arrow"
                        >
                          CRIE AGORA SUA CONTA
                        </Link>
                      </div>
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="testimonial-right-img">
            <img src="assets/img/sign2.png" alt="" />
          </div>
        </section>
      </main>
      <footer>
        <div className="footer-wrapper section-bg2">
          <div className="footer-area footer-padding">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-xl-5 col-lg-4 col-md-5 col-sm-7">
                  <div className="single-footer-caption mb-50">
                    <div className="single-footer-caption mb-30">
                      <div className="footer-logo mb-35">
                        <a href="https://www.bcare.com.br" target="_blank" rel="noreferrer">
                          <img src="assets/img/logo/logo_footer.svg" alt="" width="300px" />
                        </a>
                      </div>
                      <div className="footer-tittle">
                        <div className="footer-pera">
                          <p>
                            Se Você ainda possuí alguma dúvida ou precisa falar com a gente, fique a
                            vontade em nos enviar um e-mail, ou acessar nossas Redes Sociais.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6">
                  <div className="single-footer-caption mb-50">
                    <div className="footer-tittle">
                      <h4>Conheça a BCare</h4>
                      <ul>
                        <li className="number2">
                          <a
                            href="https://www.bcare.com.br"
                            target="_blank"
                            rel="noreferrer"
                          >
                            www.bcare.com.br
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6">
                  <div className="single-footer-caption mb-50">
                    <div className="footer-tittle">
                      <h4>BCare nas Redes</h4>
                      <div className="footer-social">
                        <a
                          href="https://www.facebook.com/bcaregestaodenegociosemsaude/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-facebook-f" />
                        </a>
                        <a
                          href="https://www.linkedin.com/company/bcare-patientsupportprogram/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-linkedin" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div id="back-top">
        <a title="Ir ao Topo" href="#">
          {' '}
          <i className="fas fa-level-up-alt" />
        </a>
      </div>
    </>
  );
}
