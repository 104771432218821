import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {},
  card: {
    background: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  alertCard: {
    background: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    width: 300,
  },
  paper: {},
  nowrap: {
    flexWrap: 'nowrap',
  },
  input: {
    width: '100%',
  },
}));
