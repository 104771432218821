import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { t } from '@psp/common';

import { useStyles } from './styles';

export type PaperTitleProps = {
  title: string;
  children?: React.ReactNode;
};

export default function PaperTitle({ title, children }: PaperTitleProps): JSX.Element {
  const classes = useStyles();
  return (
    <Grid container direction="row" className={classes.container} alignItems="center">
      <Grid item>
        <Typography variant="h3" className={classes.title}>
          <strong>{title}</strong>
        </Typography>
      </Grid>
      <Grid item xs>
        <Grid container spacing={2} direction="row" alignItems="center" justify="flex-end">
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
}
