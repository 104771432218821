import React from 'react';
import {
  t,
  UserGroupPermission,
} from '@psp/common';
import Table, { TableProps } from '../Table';

const CompareByEquality = (column: keyof {
    id: string;
    [key: string]: unknown;
}) => (a: {
    id: string;
    [key: string]: unknown;
}, b: {
    id: string;
    [key: string]: unknown;
}) => {
  if (a[column] === b[column]) {
    return 0;
  }
  return 1;
};

const columns = [
  {
    key: 'type',
    label: t('type'),
    compareFunction: CompareByEquality('type'),
    render: (type?: string) => (type ? t(`enum.permissionType.${type}`) : '-'),
  },
  {
    key: 'groupId',
    label: t('group'),
    compareFunction: CompareByEquality('groupId'),
    render: function GroupCellRender(_productId: string, ugp: UserGroupPermission): string {
      return ugp.product
        ? ugp.product.name
        : ugp.examGroup
          ? ugp.examGroup.name
          : ugp.supplyGroup
            ? ugp.supplyGroup.name
            : ugp.travel
              ? ugp.travel.id
              : ugp.patients
                ? ugp.patients.id
                : '-';
    },
  },
  {
    key: 'itemId',
    label: t('item'),
    compareFunction: CompareByEquality('itemId'),
    render: function ItemCellRender(_presentationId: string, ugp: UserGroupPermission): string {
      return ugp.presentation
        ? ugp.presentation.name
        : ugp.exam?.examType
          ? ugp.exam?.examType?.name
          : ugp.patientProcedure
            ? ugp.patientProcedure?.name
            : ugp.supply
              ? ugp.supply?.name
              : ugp.travel
                ? ugp.travel.id
                : ugp.patients
                  ? ugp.patients.id
                  : '-';
    },
  },
];

export type UserGroupPermissionsTableProps = Pick<TableProps, 'onRemove'> & {
  data: UserGroupPermission[];
  userGroupName: string;
  handleAdd: () => void;
};

export default function UserGroupPermissionsTable({
  data,
  userGroupName,
  handleAdd,
  onRemove,
}: UserGroupPermissionsTableProps): JSX.Element {
  return (
    <Table
      columns={columns}
      data={data}
      title={userGroupName}
      size="small"
      canAdd
      addAction={handleAdd}
      canRemove
      onRemove={onRemove}
    />
  );
}
