import React from 'react';
import { getPresentations, removePresentation } from '@psp/common';

import PresentationList, { PresentationListProps } from '../../components/Presentation/List';

const dispatchers: Pick<PresentationListProps, 'getPresentations' | 'removePresentation'> = {
  getPresentations,
  removePresentation,
};

export default function PresentationListComposer(): JSX.Element {
  return <PresentationList {...dispatchers} />;
}
