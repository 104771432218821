import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.palette.common.white,
    zIndex: theme.zIndex.drawer + 1,
    height: 70,
    padding: 0,
  },
  toolbar: {
    height: 69,
    padding: 0,
    minHeight: 69,
  },
  brandImage: {
    height: 40,
  },
  link: {
    textDecoration: 'none',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
  displayFlex: {
    display: 'flex',
  },
  heightUnset: {
    height: 'unset',
  },
}));
