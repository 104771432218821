"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var yup_1 = require("yup");
yup_1.setLocale({
    mixed: {
        notType: 'validation.invalidField',
        oneOf: 'validation.requiredField',
        required: 'validation.requiredField',
    },
    string: {
        email: 'validation.invalidEmail',
        min: function (_a) {
            var min = _a.min;
            return ({ key: 'validation.minLength', params: { length: min } });
        },
    },
    array: {
        min: function (_a) {
            var min = _a.min;
            return ({ key: 'validation.minSelect', params: { length: min } });
        },
    },
});
