import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { useStyles } from './styles';

interface FilterProps {
  filter: string;
  setFilter: (filter: string) => void;
}

export default function Filter({ filter, setFilter }: FilterProps) {
  const classes = useStyles();

  return (
    <>
      <h4 className={classes.title}>
        Encontre aqui a bula do seu medicamento e todas as informações necessárias para o uso
        consciente e informado, contribuindo para o seu bem-estar e saúde.
      </h4>
      <div className={classes.filterContainer}>
        <SearchIcon className={classes.filterIcon} />
        <input
          className={classes.filterInput}
          type="text"
          placeholder="Busque e explore os medicamentos"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>
    </>
  );
}
