import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import {
  t,
  GetFollowupExamGroups,
  GetExamGroupExams,
  ExamGroup,
  Exam,
  GetProgramFollowups,
  Followup,
  Inventory,
} from '@psp/common';

import { useLoading } from '../../contexts/loading.context';
import CheckboxGroup, { Value } from '../CheckboxGroup';

type FormFielProps = {
  name?: string;
  subvalueName?: string;
};

export type ExamMultiSelectProps = {
  examGroupId?: string;
  getExamGroupExams: GetExamGroupExams;
  examsFieldProps?: FormFielProps;
  inventory?: {
    [key: string]: Inventory;
  };
  canRequestWithoutInventory?: boolean;
  requireApprovalWithoutInventory?: boolean;
  onChange?: (selectedValues: Value[]) => void;
  onExamsLoad?: (exams: Exam[]) => void;
};

type ExamMultiSelectState = {
  exams: Exam[];
};

const defaultState: ExamMultiSelectState = {
  exams: [],
};

export default function ExamMultiSelect({
  examGroupId,
  getExamGroupExams,
  examsFieldProps,
  inventory = {},
  canRequestWithoutInventory = false,
  requireApprovalWithoutInventory = false,
  onChange = undefined,
  onExamsLoad = undefined,
}: ExamMultiSelectProps): JSX.Element {
  const [state, setState] = useState<ExamMultiSelectState>(defaultState);
  const { showLoading, hideLoading, isLoading } = useLoading();

  useEffect(
    useCallback(() => {
      if (!examGroupId) return;
      showLoading();
      setState({
        ...defaultState,
      });
      (async () => {
        try {
          const exams = await getExamGroupExams.execute({ examGroupId });
          setState({
            ...state,
            exams,
          });
          if (onExamsLoad) {
            onExamsLoad(exams);
          }
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    }, [state, examGroupId]),
    [examGroupId],
  );

  if (state.exams.length < 1) { return <></>; }

  return (
    <Grid container spacing={2} direction="row">
      <Grid item xs={12} md={6}>
        <CheckboxGroup
          variant="outlined"
          name="examIds"
          subvalueName="supplies"
          withCaption
          onChange={onChange}
          values={state.exams.map((e) => ({
            key: e.id,
            value: e.examType.name,
            required: e.required,
            caption:
              (inventory[e.id] && inventory[e.id].amount > 0)
              || (inventory[examGroupId || ''] && inventory[examGroupId || ''].amount > 0)
                ? 'Solicitar exame (voucher disponível)'
                : canRequestWithoutInventory
                  ? requireApprovalWithoutInventory
                    ? 'Solicitar aprovação do programa'
                    : 'Solicitar exame'
                  : 'Sem estoque',
            subvalues: e.supplies?.map((s) => ({
              key: s.id,
              value: s.name,
              optionsLabel: 'Laboratório',
              options: s.institutions?.map((i) => ({
                key: i.id,
                value: i.name,
              })),
              supplementaryData: s,
            })),
          }))}
          {...examsFieldProps}
        />
      </Grid>
    </Grid>
  );
}
