"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    number: {
        format: {
            precision: 2,
            delimiter: '.',
            separator: ',',
        },
    },
    date: {
        formats: {
            short: '%d/%m/%Y',
            weekday: '%A',
            full: '%-d de %B de %Y',
        },
    },
    time: {
        formats: {
            short: '%H:%M',
            hour: '%-I%p',
        },
    },
    datetime: {
        formats: {
            short: '%d/%m/%Y %H:%M',
        },
    },
    strings: {
        appName: 'Portal do Médico',
        msg: {
            removeAccountConfirmation: 'Tem certeza que deseja remover sua conta? Sua solicitação será processada em até 24 horas úteis.',
            selectPlaceWithPostalCode: 'Necessário incluir o número do endereço ou realize a busca pelo CEP',
            successToApproveProcedure: 'Solicitação aprovada com sucesso',
            successToApproveUser: 'Usuário aprovado com sucesso',
            successToDistributeUploadXlsx: 'Distribuição realizada com sucesso',
            successToCreateProgramFile: 'Arquivo enviado com sucesso',
            successToDeleteProgramFile: 'Arquivo deletado com sucesso',
            successToDeleteProduct: 'Produto deletado com sucesso',
            successToDeleteProgram: 'Programa deletado com sucesso',
            successToDistributeInventory: 'Vouchers distribuídos com sucesso',
            successToRegisterUser: 'Usuário cadastrado com sucesso',
            successToRegisterProduct: 'Produto cadastrado com sucesso',
            successToRegisterProgram: 'Programa cadastrado com sucesso',
            successToRegisterClient: 'Cliente cadastrado com sucesso',
            successToRejectProcedure: 'Solicitação rejeitada com sucesso',
            successToReleaseMedicine: 'Medicamento liberado com sucesso',
            successToRemoveAccount: 'Sua solicitação foi enviada com sucesso. Aguarde o prazo para remoção dos seus dados.',
            successToRequestExams: 'Exame(s) solicitado(s) com sucesso, favor entregar o pedido físico ao paciente',
            successToRequestExamsSendCodeIn24Hours: 'Exame(s) solicitado(s) com sucesso. O código de postagem reversa será recebido em até 24h úteis.',
            successToRequestPatientProcedure: 'Procedimento solicitado com sucesso',
            successToRequestSupply: 'Kit solicitado com sucesso',
            successToRequestTravel: 'Viagem solicitada com sucesso',
            successToUpdateEmail: 'Sucesso! Você será redirecionado para a tela de login. Entre utilizando o novo email cadastrado.',
            successToUpdateUser: 'Usuário alterado com sucesso',
            successToUpdateProduct: 'Produto alterado com sucesso',
            successToUpdateProgram: 'Programa alterado com sucesso',
            successToRegisterFollowup: 'Acompanhamento cadastrato com sucesso',
            successToUpdateFollowup: 'Acompanhamento alterado com sucesso',
            successToRemoveFollowup: 'Acompanhamento removido com sucesso',
            successToRegisterPresentation: 'Apresentação cadastrada com sucesso',
            successToUpdatePresentation: 'Apresentação alterada com sucesso',
            successToRemovePresentation: 'Apresentação removida com sucesso',
            successToUpdateClient: 'Cliente alterado com sucesso',
            successToRemoveClient: 'Cliente deletado com sucesso',
            successToDoctorsUploadXlsx: 'Importação de médicos realizada com sucesso',
            successToInvitePatient: 'Paciente convidado com sucesso, o sms com o link de cadastro deve chegar em breve.',
        },
        err: {
            accreditedNotFound: 'Nenhuma farmácia encontrada para o CEP especificado',
            cannotDeleteProduct: 'Não é possível deletar. O produto possui registros relacionados.',
            cannotDeleteProgram: 'Não é possível deletar. O programa possui registros relacionados.',
            clientNotFound: 'Não foi possível deletar o cliente',
            clientIsLinkedToOtherEntitie: 'Não foi possível deletar esse Cliente pois existem programas vinculados',
            eligibilityRejected: 'Paciente inelegível para os exames solicitados',
            eligibilityRejectedUnder18: 'Paciente com idade menor que 18 anos, não será possível seguir com a solicitação',
            eligibilityRejectedTravel: 'Paciente inelegível para a viagem solicitada',
            existingPatientProcedure: 'Procedimento já solicitado para esse paciente',
            existingSupplyRequest: 'Kit já solicitado',
            failedToAddUserPermissions: 'Falha ao adicionar permissão',
            failedToDownloadFile: 'Falha ao baixar o arquivo',
            failedToSaveUserGroups: 'Falha ao salvar perfil de usuário',
            failedToSaveUserUserGroup: 'Falha ao salvar perfil do usuário',
            failedToSaveUserGroupPermission: 'Falha ao salvar permissão do perfil',
            failedToApproveProcedure: 'Falha ao aprovar solicitação',
            failedToApproveUser: 'Falha ao aprovar usuário',
            failedToUploadXlsxFile: 'Falha ao realizar upload do arquivo',
            failedToCreateProgramFile: 'Falha ao salvar arquivo de programa',
            failedToDeleteProgramFile: 'Falha ao deletar arquivo de programa',
            failedToDeleteProduct: 'Falha ao deletar o produto',
            failedToDeleteProgram: 'Falha ao deletar o programa',
            failedToDeleteUserPermissions: 'Falha ao remover permissão',
            failedToDeleteUserGroups: 'Falha ao remover perfil de usuário',
            failedToDeleteUserUserGroup: 'Falha ao remover perfil so usuário',
            failedToDeleteUserGroupPermission: 'Falha ao remover permissão do perfil',
            failedToDistributeInventory: 'Falha ao distribuir estoque',
            failedToFindPatientProcedure: 'Falha ao buscar liberação de medicamento',
            failedToLoadData: 'Falha ao carregar dados',
            failedToLoadClient: 'Falha ao carregar dados',
            failedToLoadInventory: 'Falha ao carregar estoque',
            failedToLoadUserGroups: 'Falha ao carregar perfis de usuário',
            failedToLoadUserUserGroups: 'Falha ao carregar perfis do usuário',
            failedToLoadUserGroupPermissions: 'Falha ao carregar as permissões do perfil',
            failedToLoadPharmacies: 'Falha ao buscar farmácias. Por favor, tente novamente ou entre em contato caso o problema persista.',
            failedToRegisterUser: 'Falha ao cadastrar usuário',
            failedToRegisterProduct: 'Falha ao cadastrar produto',
            failedToRegisterProgram: 'Falha ao cadastrar programa',
            failedToRegisterClient: 'Falha ao cadastrar cliente',
            failedToRejectProcedure: 'Falha ao rejeitar solicitação',
            failedToReleaseMedication: 'Falha ao liberar medicamento',
            failedToRequestExams: 'Falha ao solicitar exame(s)',
            failedToRequestPatientProcedure: 'Falha ao solicitar procedimento',
            failedToRequestSupply: 'Falha ao solicitar kit',
            failedToRequestTravel: 'Falha ao solicitar viagem',
            failedToRequestOptout: 'Não foi possível solicitar a remoção dos seus dados. Por favor entre em contato.',
            failedToSearchDoctor: 'Falha ao buscar CRM',
            failedToSendCode: 'Não foi possível enviar o código por SMS. Verifique se o seu celular está correto',
            failedToSignup: 'Falha ao realizar cadastro',
            failedToUpdateEmail: 'Falha ao atualizar email',
            failedToUpdateUser: 'Falha ao atualizar usuário',
            failedToUpdateProcedurePatient: 'Ocorreu um erro ao confirmar os dados. Por favor, tente novamente ou entre em contato caso o problema persista.',
            fillAllLaboratoryFields: 'Selecione o(s) laboratório(s).',
            insufficientInventory: 'Vouchers insuficientes',
            insufficientSupplies: 'Kits insuficientes',
            invalidCode: 'Código inválido',
            invalidUser: 'Usuário ou senha inválidos',
            invalidXlsxFileCheckErrorReport: 'Arquivo com dados inválidos. Verifique o relatório de erros e envie novamente.',
            patientProcedureNotFound: 'Medicamento não liberado para seu CPF. Entre em contato com seu médico.',
            userNotInProgram: 'Sem acesso ao programa',
            failedToRegisterFollowup: 'Falha ao cadastrar acompanhamento',
            failedToLoadFollowup: 'Falha ao carregar dados do acompanhamento',
            failedToRemoveFollowup: 'Falha ao remover acompanhamento',
            followupNotFound: 'Acompanhamento não encontrado',
            followupIsLinkedToOtherEntitie: 'Falha ao remover acompanhamento, pois ele já se encontra vinculado a um grupo de exames',
            failedToRegisterPresentation: 'Falha ao cadastrar apresentação',
            failedToLoadPresentation: 'Falha ao carregar apresentação',
            failedToRemovePresentation: 'Falha ao remover apresentação',
            presentationNotFound: 'Apresentação não encontrada',
            presentationIsLinkedToOtherEntitie: 'Falha ao remover apresentação, pois ela já se encontra vinculada a uma solicitação',
            emailAlreadyInUse: 'O E-mail já esta em uso',
            tevaDoctorNotice: 'Prezados, a partir de 16/10/2023, as solicitações de exames genéticos serão realizadas exclusivamente pelo laboratório Mendelics. O reporte dos resultados de exames (em tramite) permanecerão como habitual, notificação via e-mail. Caso não possua Kit`s Mendelics, por gentileza entre em contato com seu representante/ consultor ou entre em contato com o nosso atendimento: 0800 777 8382 ou psd@tevabrasil.com.br',
            requestEntityTooLarge: 'Arquivo enviado ultrapassa o limite de 10mb',
            fileSizeIsInvalid: 'O arquivo não pode ter tamanho menor que 1KB ou maior que 10MB.',
            unselectedTravelOption: 'Selecione ao menos uma das opções de viagem',
            crmArealdyInUse: 'CRM já cadastrado',
            failedToUpdateDoctorsList: 'Falha ao atualizar listagem de médicos',
            UserExpiredPassword: 'Senha expirada. Clique em Esqueci minha senha',
        },
        title: {
            confirmApproval: 'Confirmar aprovação do usuário',
            confirmRemoval: 'Confirmar remoção',
        },
        validation: {
            dateNeedBeTwoDaysAfterToday: 'Data deve ser maior que dois dias após hoje',
            duplicatedCpf: 'CPF já cadastrado',
            emailMustMatch: 'Os e-mails devem ser iguais',
            insufficientInventory: 'Vouchers insuficientes',
            insufficientSupplies: 'Kits insuficientes',
            invalidCpf: 'CPF inválido',
            invalidCnpj: 'CNPJ inválido',
            invalidCRM: 'CRM e UF inválido',
            invalidEmail: 'Email inválido',
            invalidField: 'Campo inválido',
            invalidPhone: 'Telefone inválido',
            minLength: 'Mínimo de {{length}} caracteres',
            minSelect: 'Selecione ao menos {{length}}',
            passwordMustMatch: 'As senhas devem ser iguais',
            requiredField: 'Campo obrigatório',
            requiredPatientAcceptance: 'É necessário aceitar pelo menos uma das formas de contato',
            requiredTerms: 'É necessário aceitar os termos do programa',
            answersMustMatch: 'A resposta obrigatória é {{answer}}',
            requiredSelect: 'Seleção obrigatória para elegibilidade do serviço',
        },
        navigation: {
            '/dashboard': 'Home Portal',
            '/doctors': 'Médicos',
            '/doctors/:id': 'Detalhes do médico',
            '/exams/request': 'Solicitar exames',
            '/inventory': 'Vouchers',
            '/inventory/distribution': 'Distribuição de vouchers',
            '/library': 'Biblioteca',
            '/medications/release': 'Liberar medicamento',
            '/patient-procedures/request': 'Solicitar procedimento',
            '/procedures': 'Solicitações',
            '/procedures/exams': 'Exames',
            '/procedures/medications': 'Liberações',
            '/procedures/patient-procedures': 'Procedimentos',
            '/procedures/visits': 'Visitas',
            '/procedures/exams/:id': 'Detalhes do exame',
            '/procedures/medications/:id': 'Detalhes da liberação',
            '/procedures/patient-procedures/:id': 'Detalhes do procedimento',
            '/procedures/visits/:id': 'Detalhes da visita',
            '/procedures/:id': 'Detalhes da solicitação',
            '/users': 'Usuários',
            '/users/register': 'Cadastro de usuário',
            '/users/change-email': 'Alterar email',
            '/user/edit': 'Editar usuário',
            '/clients/register': 'Cadastro de cliente',
            '/users/:id/permissions': 'Permissões de acesso',
            '/user-groups': 'Perfis de usuário',
            '/user-groups/:id/permissions': 'Permissões do perfil',
            '/users/:id/permissions/add': 'Adicionar permissão',
            '/supply/request': 'Solicitar kit',
            '/supply-requests': 'Solicitações de Kit',
            '/travel/request': 'Solicitar viagem',
            '/travel-requests': 'Solicitações de viagem',
            '/patients': 'Pacientes',
            '/patients/:id': 'Detalhes do paciente',
            '/patients/invitation': 'Convidar paciente',
            '/medication-administration/:id': 'Administração de medicamentos',
            '/medicine/leaflets': 'Bula de medicamentos',
            '/products': 'Produtos',
            '/products/register': 'Cadastro de produto',
            '/products/:id/edit': 'Editar produto',
            '/programs': 'Programas',
            '/programs/register': 'Cadastro de programa',
            '/programs/:id/edit': 'Editar programa',
            '/followups': 'Acompanhamentos',
            '/followups/register': 'Cadastro de Acompanhamento',
            '/followups/:id/edit': 'Editar Acompanhamento',
            '/presentations': 'Apresentações',
            '/presentations/register': 'Cadastro de Apresentação',
            '/clients': 'Clientes',
            '/users/:id/user-groups': 'Perfis do usuário',
            '/users/:id/user-groups/history': 'Histórico de perfis do usuário',
            '/contents': 'Conteúdos',
            '/discounts': 'Descontos',
        },
        placeholders: {},
        home: {
            title: 'Programa Portal do Médico',
            subtitle: '',
            loginCaption: 'Clique aqui para acessar a plataforma caso já possua seu cadastro',
            signupCaption: 'Clique aqui para criar sua conta e se registrar no programa',
        },
        pages: {
            movements: {
                distributeInventoryXlsx: 'Distribuição de vouchers em lote',
            },
            signin: {
                title: 'Bem vindo ao',
                subtitle: 'Portal do Médico',
                login: 'Acesse com seu Login',
            },
            signup: {
                title: 'Vamos começar seu Cadastro',
                subtitle: 'Se você não está cadastrado no programa, por favor, insira as informações abaixo e clique em confirmar.',
                firstStep: {
                    title: 'Digite seu CRM e a UF da sua inscrição:',
                },
                secondStep: {
                    title: 'Confira e complete seu cadastro:',
                    acceptTerms: 'Confirmo que li e aceito os termos de uso',
                    readTerms: 'Leia aqui nossos termos de uso',
                    requestVisit: 'Solicitar visita do Consultor Técnico',
                    dialogCodeText: 'Enviamos um código de validação por SMS, por favor digite o código recebido',
                },
                thirdStep: {
                    title: 'Confirme seu cadastro:',
                    subtitle: 'Enviamos um código de validação por SMS ao número cadastrado, por favor digite o código recebido.',
                },
                fourthStep: {
                    title: 'Cadastro concluído com Sucesso!',
                    subtitle: 'Clique no botão abaixo para efetuar o login na plataforma.',
                    signin: 'Acessar o sistema',
                },
            },
            supplies: {
                request: {
                    saveAddress: 'Salvar endereço para futuras solicitações',
                },
            },
            exam: {
                request: {
                    acceptSms: 'O paciente aceita receber contatos por SMS',
                    acceptPhone: 'O paciente aceita receber contatos por ligações',
                    acceptEmail: 'O paciente aceita receber instruções por e-mail',
                },
            },
            pendingUsagePolicies: {
                title: 'TERMOS DE USO',
                explanation: 'Para prosseguir com a utilização do portal é necessário o aceite dos seguintes termos de uso:',
                readTerms: 'Leia aqui os {{termsDescription}}',
                acceptTerms: 'Confirmo que li e aceito os {{termsDescription}}',
            },
            terms: {
                title: 'TERMOS DE USO E PROTEÇÃO DE DADOS PESSOAIS',
                readAndAgreeTerms: 'OK',
            },
            doctors: {
                doctorsXlsx: 'Importação de médicos em lote',
            }
        },
        dialogs: {
            unselectedExams: {
                message: 'Você não selecionou todos os exames, deseja continuar mesmo assim?',
                selectExams: 'Selecionar outros exames',
                proceedWithSelectedExams: 'Solicitar apenas exames selecionados',
            },
        },
        medication: {
            release: {
                acceptContact: 'O paciente aceita receber contato',
                acceptSms: 'O paciente aceita receber por SMS as instruções para retirada do medicamento',
                acceptEmail: 'O paciente aceita receber por Email as instruções para retirada do medicamento',
                acceptPhone: 'O paciente aceita receber contatos por ligações',
                acceptMail: 'O paciente aceita receber por Correio as instruções para retirada do medicamento',
            },
        },
        moduleNames: {
            FIRST_MEDICINE: 'Primeira caixa',
            CLINICAL_EXAMINATION: 'Exames',
            PATIENT_PROCEDURE: 'Procedimentos',
            PATIENT_INVITATION: 'Convidar pacientes',
            SALESFORCE_VISIT: 'Visita do representante',
            SUPPLY: 'Kits',
            LIBRARY: 'Biblioteca',
            DOCTOR_BENEFITS: 'Descontos para Médicos',
            EDIT_USER: 'Editar Usuários',
            PATIENTS: 'Pacientes',
            TRAVEL: 'Viagem',
            CONTENTS: 'Conteúdos',
            DISCOUNTS: 'Descontos'
        },
        moduleDescriptions: {
            FIRST_MEDICINE: 'Liberação de primeira caixa',
            CLINICAL_EXAMINATION: 'Solicitação de exames',
            PATIENT_PROCEDURE: 'Solicitação de monitoramento em clínica',
            PATIENT_INVITATION: 'Convidar pacientes',
            SALESFORCE_VISIT: '',
            SUPPLY: 'Solicitação de Kits',
        },
        patientConfirmation: {
            firstStep: {
                title: 'Liberação de medicamento',
                titleLine2: '1ª caixa',
                subtitle: 'Confirme seu cadastro ou procure a farmácia mais próxima para adquirir seu medicamento',
                subtitle2: 'em até 30 dias da liberação',
                confirmRegistrationCaption: 'Clique aqui e complete seu cadastro para agilizar o tempo na farmácia escolhida',
                findPharmaciesCaption: 'Procure a farmácia mais próxima que o medicamento será liberado',
            },
            secondStep: {
                title: 'Liberação de medicamento',
                titleLine2: '1ª caixa',
                subtitle: 'Informe seu CPF para continuar',
            },
            thirdStep: {
                title: 'Confira Seus Dados',
                subtitle: 'VERIFIQUE SE TODAS AS INFORMAÇÕES ESTÃO CORRETAS.',
                readTerms: 'Leia aqui os termos de consentimento',
                acceptTerms: 'Confirmo que li e aceito os termos do programa',
                acceptContactSms: 'Aceito receber contato posterior por SMS',
                acceptContactEmail: 'Aceito receber contato posterior por E-mail',
                acceptContactPhone: 'Aceito receber contato posterior por telefone',
            },
            fourthStep: {
                title: 'Localize sua farmácia',
                subtitle: 'Informe seu CEP ou nome da rua com nº para buscar as farmácias mais próximas',
            },
        },
        pendingApproval: {
            title: 'Aprovação pendente',
            subtitle: 'Informaremos por email quando seu acesso for aprovado',
        },
        userRegister: {
            doctorData: 'Dados do médico',
        },
        helperText: {
            numbersOnly: 'somente números',
            password: 'A senha deve conter no mínimo 6 caracteres',
        },
        enum: {
            permissionType: {
                FIRST_MEDICINE: 'Primeira caixa',
                CLINICAL_EXAMINATION: 'Exame',
                PATIENT_PROCEDURE: 'Procedimentos',
                PATIENT_INVITATION: 'Convidar paciente',
                SUPPLY: 'Kits',
                TRAVEL: 'Viagens',
                PATIENTS: 'Pacientes',
                EDIT_USER: 'Editar usuário',
                CONTENTS: 'Conteúdos',
                DISCOUNTS: 'Descontos',
            },
        },
        userType: {
            SYSADMIN: 'SysAdmin',
            ADMIN: 'Admin',
            REGIONAL_MANAGER: 'Gerente Regional',
            DISTRICT_MANAGER: 'Gerente Distrital',
            REPRESENTATIVE: 'Representante',
            DOCTOR: 'Médico',
        },
        procedureLogisticType: {
            CORREIOS: 'Via Correios',
            LOGISTIC_COMPANY: 'Via empresa logística',
        },
        procedureAgreementType: {
            CODE: 'Via código SMS',
            FILE: 'Via arquivo anexo',
        },
        procedureType: {
            FIRST_MEDICINE: 'Primeira caixa',
            CLINICAL_EXAMINATION: 'Exame',
            PATIENT_PROCEDURE: 'Procedimento',
            SALESFORCE_VISIT: 'Visita do representante',
            SUPPLY: 'Kit',
            TRAVEL: 'Viagem',
        },
        procedureStatus: {
            APPROVED: 'Aprovado',
            OPEN: 'Pendente',
            REJECTED: 'Rejeitado',
            SCHEDULED: 'Agendado',
            CANCELED: 'Cancelado',
            CONCLUDED: 'Realizado',
            PARTIAL: 'Parcial',
        },
        medicationAdminStatus: {
            OPEN: 'Pendente',
            REALIZED: 'Realizado',
            SCHEDULED: 'Agendado',
            CANCELED: 'Cancelado',
        },
        distributionType: {
            label: 'Tipo de distribuição',
            INCREASE: 'Preencher estoque',
            REDUCE: 'Reduzir estoque',
            ADD_UNITS: 'Adicionar unidades',
            REMOVE_UNITS: 'Remover unidades',
        },
        inventoryType: {
            FIRST_MEDICINE: 'Primeira caixa',
            CLINICAL_EXAMINATION: 'Exame',
            PATIENT_PROCEDURE: 'Procedimento',
        },
        library: {
            GENERAL: 'Arquivos gerais',
            PROGRAM: 'Arquivos do programa',
            FIRST_MEDICINE: 'Primeira caixa',
            CLINICAL_EXAMINATION: 'Exames',
            PATIENT_PROCEDURE: 'Procedimentos',
            SALESFORCE_VISIT: 'Visita do representante',
            SUPPLY: 'Kits',
            TRAVEL: 'Viagens',
            PATIENTS: 'Pacientes',
            PCDT: 'Protocolos Clínicos e Diretrizes Terapêuticas',
        },
        tooltips: {
            signupPhone: 'Importante confirmar se o telefone está correto, pois será enviado um SMS com o código validador',
            patientCellphone: 'Oriente seu paciente à aceitar os termos enviados por e-mail ou SMS',
        },
        access: 'Acessar',
        accessPlatform: 'Acessar o Programa',
        active: 'Ativo',
        afternoon: 'Tarde',
        agree: 'Estou de acordo',
        aid: 'Auxílio',
        aids: 'Auxílios',
        alteredcpk: 'CPK alterado',
        confirmCopy: 'Confirmo que uma cópia idêntica e completa da página foi digitalizada e anexada ao sistema do Programa. A via original ficará em posse do paciente.',
        allExaminations: 'Todos os exames',
        allPatientProcedures: 'Todos os procedimentos',
        allPresentations: 'Todas as apresentações',
        amount: 'Quantidade',
        amountByUser: 'Quantidade por usuário',
        approval: 'Aprovação',
        approvalDate: 'Data da aprovação',
        approvalRejection: 'Aprovação/Rejeição',
        approve: 'Aprovar',
        approveSelected: {
            one: 'Aprovar selecionado',
            other: 'Aprovar {{count}} selecionados',
            zero: 'Aprovar',
        },
        approved: 'Aprovado',
        approvedBy: 'Aprovado por',
        attach: 'Anexar arquivo',
        availableInventory: 'Vouchers disponíveis',
        birthDate: 'Data de nascimento',
        cancel: 'Cancelar',
        caregiverName: 'Nome do cuidador/responsável',
        category: 'Categoria',
        cellphone: 'Celular',
        cep: 'CEP',
        changeEmail: 'Alterar email',
        changePassword: 'Alterar senha',
        chooseAnotherMethod: 'Escolher outro método',
        city: 'Cidade',
        client: 'Cliente',
        clinic: 'Consultório',
        code: 'Código',
        complement: 'Complemento',
        confirm: 'Confirmar',
        confirmEmail: 'Confirme seu e-mail',
        confirmRegistration: 'Confirmar cadastro',
        confirmPassword: 'Confirmar senha',
        contactCellphone: 'Celular de contato',
        consentTerm: 'Termos de consentimento',
        continue: 'Continuar',
        cpf: 'CPF',
        createAccount: 'Criar conta',
        crm: 'CRM',
        currentInventory: 'Vouchers atuais',
        date: 'Data',
        firstDateForSampleWithdrawal: '1ª Data para a retirada (min: 48h)',
        secondDateForSampleWithdrawal: '2ª Data para a retirada (min: 48h)',
        thirdDateForSampleWithdrawal: '3ª Data para a retirada (min: 48h)',
        fourthDateForSampleWithdrawal: '4ª Data para a retirada (min: 48h)',
        defaultForNewUsers: 'Padrão para novos usuários',
        deleteAccount: 'Remover meus dados',
        description: 'Descrição',
        destinationProfile: 'Perfil de destino',
        distribute: 'Distribuir',
        documentType: 'Tipo de documento',
        doctors: 'Médicos',
        patients: 'Pacientes',
        email: 'E-mail',
        errors: 'Erros',
        errorsReport: 'Relatório de erros',
        phone: 'Telefone',
        examGroup: 'Grupo de Exame',
        examRequests: 'Solicitações de exames',
        exam: 'Exame',
        exams: 'Exames',
        executor: 'Executor',
        false: 'Não',
        female: 'Feminino',
        findPharmacies: 'Buscar farmácias',
        firstMedicineRequests: 'Solicitações de liberação',
        followup: 'Acompanhamento',
        forgotPassword: 'Esqueci minha senha',
        fortnightly: 'Quinzenal',
        frequency: 'Periodicidade',
        fullName: 'Nome completo',
        gender: 'Sexo biológico',
        goBack: 'voltar',
        group: 'Grupo',
        holderCpf: 'CPF do portador',
        holderName: 'Nome do portador da amostra',
        holderSectorDepartmentSampleName: 'Nome do portador da amostra, setor, departamento',
        hypotonia: 'Hipotonia',
        inactive: 'Inativo',
        institution: 'Laboratório',
        targetInstitution: 'Laboratório de destino',
        inventory: 'Estoque',
        inventoryDistribution: 'Distribuição de vouchers',
        inventoryReturned: 'Voucher retornado',
        insufficientInventory: 'Vouchers insuficientes',
        insufficientSupplies: 'Kits insuficientes',
        item: 'Item',
        logout: 'Deslogar',
        male: 'Masculino',
        manager: 'Responsável',
        medicalOrders: 'Pedidos médicos',
        monthly: 'Mensal',
        morning: 'Manhã',
        name: 'Nome',
        menu: 'Menu',
        nameInitials: 'Iniciais',
        neighborhood: 'Bairro',
        no: 'Não',
        notInformed: 'Prefiro não informar',
        noInventory: 'Sem estoque',
        number: 'Número',
        observation: 'Observação (setor, complemento, andar)',
        other: "Outro",
        password: 'Senha',
        patient: 'Paciente',
        patientCpf: 'CPF do paciente',
        patientFullName: 'Nome completo do paciente',
        patientProcedure: 'Procedimento',
        patientProcedures: 'Procedimentos',
        patientProcedureRequests: 'Solicitações de procedimentos',
        patientSituation: 'Situação do paciente',
        permissionType: 'Tipo de permissão',
        pickUpTime: 'Horário de preferência para retirada',
        placeDescription: 'Endereço',
        postalCode: 'CEP',
        presentation: 'Apresentação',
        preRegistration: 'Pré-cadastro',
        procedures: 'Solicitações',
        procedureAgreement: 'Forma de aceite do termo pelo paciente/cuidador',
        procedureLogistic: 'Forma de envio da amostra',
        product: 'Produto',
        products: 'Produtos',
        profile: 'Perfil',
        program: 'Programa',
        programModules: 'Módulos do programa',
        programs: 'Programas',
        quantity: 'Quantidade',
        quickAccess: 'Acesso Rápido',
        recipientCpf: 'CPF',
        recipientName: 'Nome do destinatário',
        recipientType: 'Tipo de destinatário',
        register: 'Cadastrar',
        registered: 'Cadastrado',
        regressionofmotormilestones: 'Regressão de marcos motores',
        reject: 'Rejeitar',
        rejectedBy: 'Rejeitado por',
        rejected: 'Rejeitado',
        rejectionDate: 'Data da rejeição',
        request: 'Solicitar',
        requestDate: 'Data da Solicitação',
        requester: 'Solicitante',
        requesterWithoutInventory: 'Solicitante sem estoque',
        requesterCnpj: 'CNPJ do solicitante',
        requesterName: 'Nome do solicitante',
        requesterCpf: 'CPF do solicitante',
        requiredInventory: 'Vouchers necessários',
        rg: 'RG',
        row: 'Linha',
        save: 'Salvar',
        search: 'Buscar',
        selectedUsers: 'Usuários selecionados',
        selectExams: 'Selecione os exames',
        selectMedication: 'Selecione o medicamento',
        selectPatientProcedure: 'Selecione o procedimento',
        selectSupply: 'Selecione o Kit',
        selectTravel: 'Selecione a viagem',
        selectTreatmentLocation: 'Selecione o local de tratamento',
        sendAgainIn: {
            one: 'Enviar novamente em 1 segundo',
            other: 'Enviar novamente em {{count}} segundos',
            zero: 'Enviar novamente',
        },
        sendSmsCode: 'Enviar código por SMS',
        sex: 'Sexo',
        signup: 'Cadastre-se',
        slug: 'Slug',
        sms: 'SMS',
        state: 'Estado',
        status: 'Status',
        styles: 'Estilo',
        supply: 'Kit',
        supplyGroup: 'Categoria',
        supplies: 'Kits',
        telephone: 'Telefone',
        tonguefasciculation: 'Fasciculação de língua',
        true: 'Sim',
        type: 'Tipo',
        uf: 'UF',
        uninformed: 'Não Informado',
        unregistered: 'Sem cadastro',
        uploadFile: 'Enviar arquivo',
        usedDoctorInventory: 'Voucher do médico utilizado',
        userGroup: 'Perfil de usuário',
        userGroups: 'Perfis de usuário',
        userUserGroups: 'Perfis associados',
        addUserUserGroup: 'Adicionar perfil de usuário',
        editUserUserGroup: 'Editar perfil do usuário',
        addUserGroup: 'Adicionar perfil de usuário',
        editUserGroup: 'Editar perfil de usuário',
        addUserGroupPermissions: 'Adicionar permissão',
        userPermissions: 'Permissões do usuário',
        users: 'Usuários',
        clients: 'Clientes',
        vouchers: 'Vouchers',
        yes: 'Sim',
        youAreAt: 'Você está em',
        solicitation: 'Solicitação',
        voucherAuthorization: 'Voucher / Autorização',
        scheduling: 'Agendamento',
        scheduledDate: 'Data do Agendamento',
        concludedDate: 'Data da Conclusão',
        canceledDate: 'Data do Cancelamento',
        rescheduledDate: 'Data do reagendamento',
        collectDate: 'Data da Coleta',
        receivementDate: 'Data do Recebimento',
        waiting: 'Aguardando',
        canceled: 'Cancelado',
        pending: 'Pendente',
        procedureTypeColumn: 'Tipo de Procedimento',
        scheduledDateColumn: 'Data Agendada',
        concludedDateColumn: 'Data Realizada',
        patientName: 'Nome do Paciente',
        collect: 'Coleta',
        concluded: 'Concluído',
        receivement: 'Recebimento',
        instructions: 'Instruções',
        uploadTitle: 'Título',
        none: 'Nenhum',
        userTypeFilter: 'Filtrar por Perfil',
        cancelationReason: 'Motivo de cancelamento',
        wsAcessoPathologyCode: 'Código de patologia no WS',
        wsAcessoCode: 'Código do programa no WS',
        followups: 'Acompanhamentos',
        presentations: 'Apresentações',
        ean: 'EAN',
        discount: 'Desconto',
        requestExamBox: 'Exames de elegibilidade ou monitoramento do tratamento.',
        requestProcedureBox: 'Monitoramento de primeira dose em clínicas parceiras do programa.',
        weakness: 'Fraqueza',
        warning: 'Atenção',
        isSpecialty: 'É área de atuação médica',
        specialty: 'Área de atuação médica',
        specialtySimple: 'Especialidade',
        allowSpecialtyDuringSignup: 'Exibir área de atuação médica no cadastro',
        customHtmlFooter: 'Html customizado para rodapé',
        ZolgensmaInfusionDate: 'Data de infusão do Zolgensma®',
        filterByStatus: 'Filtrar por Status',
        all: 'Todos',
        trackingCode: 'Código de rastreio',
        partial: 'Parcial',
        estimatedDeadline: 'Prazo estimado',
        consultInformation: 'Instruções de consulta',
        open: 'Aberto',
        scheduled: 'Agendado',
        namePatient: 'Nome do paciente',
        caregiver: 'Cuidador',
        phase: 'Fase',
        medicationName: 'Medicamento',
        yearmonth: 'Mês e Ano',
        medications: 'Medicamentos',
        patientData: 'Dados do paciente',
        patientPhase: 'Fase do paciente',
        patientRegister: 'Cadastro',
        patientPreRegister: 'Pré-cadastro',
        patientDiagnosis: 'Diagnóstico',
        numberCallCentersNurses: 'Teleatendimentos com enfermeiros',
        aplicationsQuantities: 'Quantidade de aplicações',
        treatmentStartDate: 'Data de Início do Tratamento',
        accessWay: 'Via de acesso',
        inactivation: 'Inativação',
        reasonInactivation: 'Motivo da Inativação',
        trackApplication: 'Acompanhar aplicação',
        councilType: 'Tipo de conselho',
        address: 'Endereço',
        medicineName: 'Medicamento',
        crmUf: 'UF do CRM',
        doctorName: 'Nome do médico',
        productRuleCode: 'Código de regra do produto',
        inviteAPatient: 'Convide um paciente',
        states: {
            AC: 'Acre',
            AL: 'Alagoas',
            AP: 'Amapá',
            AM: 'Amazonas',
            BA: 'Bahia',
            CE: 'Ceará',
            DF: 'Distrito Federal',
            ES: 'Espirito Santo',
            GO: 'Goiás',
            MA: 'Maranhão',
            MT: 'Mato Grosso',
            MS: 'Mato Grosso do Sul',
            MG: 'Minas Gerais',
            PR: 'Paraná',
            PB: 'Paraíba',
            PA: 'Pará',
            PE: 'Pernambuco',
            PI: 'Piauí',
            RJ: 'Rio de Janeiro',
            RN: 'Rio Grande do Norte',
            RS: 'Rio Grande do Sul',
            RO: 'Rondonia',
            RR: 'Roraima',
            SC: 'Santa Catarina',
            SE: 'Sergipe',
            SP: 'São Paulo',
            TO: 'Tocantins',
            Geral: 'Geral',
        },
    },
};
