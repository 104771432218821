import React from 'react';
import { Grid } from '@material-ui/core';
import { Stethoscope, Key } from 'mdi-material-ui';
import { Followup, t } from '@psp/common';

import { FOLLOWUP_REGISTER_ROUTE, FOLLOWUP_EDIT_ROUTE } from '../../constants';
import Table, { TableProps } from '../Table';
import Chip from '../Chip';

const CompareByEquality = (column: keyof {
    id: string;
    [key: string]: unknown;
}) => (a: {
    id: string;
    [key: string]: unknown;
}, b: {
    id: string;
    [key: string]: unknown;
}) => {
  if (a[column] === b[column]) {
    return 0;
  }
  return 1;
};

const columns = [
  {
    key: 'name',
    label: t('name'),
    compareFunction: CompareByEquality('name'),
  },
  {
    key: 'slug',
    label: t('slug'),
    compareFunction: CompareByEquality('slug'),
  },
  {
    key: 'active',
    label: t('active'),
    compareFunction: CompareByEquality('active'),
    render: function isActive(value:any) {
      return (<Chip color={(value ? 'success' : 'error')} label={(value ? t('active') : t('inactive'))} size="small" />);
    },
  },
];

export type FollowupTableProps = {
  data: Followup[];
} & Pick<
  TableProps,
  'canSearch' | 'count' | 'onChangePage' | 'onChangeRows' | 'onSearch' | 'page' | 'rowsPerPage' | 'canRemove' | 'onRemove'
>;

export default function FollowupTable({ data, ...props }: FollowupTableProps): JSX.Element {
  return (
    <Table
      columns={columns}
      data={data}
      title={t('followups')}
      size="small"
      canAdd
      addRoute={FOLLOWUP_REGISTER_ROUTE}
      canEdit
      editRoute={FOLLOWUP_EDIT_ROUTE}
      canRemove
      {...props}
    />
  );
}
