"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildModulesFromPermissions = exports.buildModules = exports.allModules = exports.defaultModules = exports.ProgramModulesEnum = void 0;
var ProgramModulesEnum;
(function (ProgramModulesEnum) {
    ProgramModulesEnum["FIRST_MEDICINE"] = "FIRST_MEDICINE";
    ProgramModulesEnum["CLINICAL_EXAMINATION"] = "CLINICAL_EXAMINATION";
    ProgramModulesEnum["PATIENT_PROCEDURE"] = "PATIENT_PROCEDURE";
    ProgramModulesEnum["PATIENT_INVITATION"] = "PATIENT_INVITATION";
    ProgramModulesEnum["SALESFORCE_VISIT"] = "SALESFORCE_VISIT";
    ProgramModulesEnum["SUPPLY"] = "SUPPLY";
    ProgramModulesEnum["LIBRARY"] = "LIBRARY";
    ProgramModulesEnum["DOCTOR_BENEFITS"] = "DOCTOR_BENEFITS";
    ProgramModulesEnum["TRAVEL"] = "TRAVEL";
    ProgramModulesEnum["PATIENTS"] = "PATIENTS";
    ProgramModulesEnum["EDIT_USER"] = "EDIT_USER";
    ProgramModulesEnum["CONTENTS"] = "CONTENTS";
    ProgramModulesEnum["DISCOUNTS"] = "DISCOUNTS";
})(ProgramModulesEnum = exports.ProgramModulesEnum || (exports.ProgramModulesEnum = {}));
exports.defaultModules = {
    clinicalExamination: false,
    firstMedicine: false,
    patientProcedure: false,
    patientInvitation: false,
    salesforceVisit: false,
    supply: false,
    library: false,
    doctorBenefits: false,
    travel: false,
    patients: false,
    editUser: false,
    contents: false,
    discounts: false,
};
exports.allModules = Object.values(ProgramModulesEnum).join(',');
var buildModules = function (modules) {
    if (modules === void 0) { modules = ''; }
    return (__assign(__assign({}, exports.defaultModules), { clinicalExamination: modules.split(',').includes(ProgramModulesEnum.CLINICAL_EXAMINATION), firstMedicine: modules.split(',').includes(ProgramModulesEnum.FIRST_MEDICINE), patientProcedure: modules.split(',').includes(ProgramModulesEnum.PATIENT_PROCEDURE), patientInvitation: modules.split(',').includes(ProgramModulesEnum.PATIENT_INVITATION), salesforceVisit: modules.split(',').includes(ProgramModulesEnum.SALESFORCE_VISIT), supply: modules.split(',').includes(ProgramModulesEnum.SUPPLY), library: modules.split(',').includes(ProgramModulesEnum.LIBRARY), doctorBenefits: modules.split(',').includes(ProgramModulesEnum.DOCTOR_BENEFITS), travel: modules.split(',').includes(ProgramModulesEnum.TRAVEL), patients: modules.split(',').includes(ProgramModulesEnum.PATIENTS), editUser: modules.split(',').includes(ProgramModulesEnum.EDIT_USER), contents: modules.split(',').includes(ProgramModulesEnum.CONTENTS), discounts: modules.split(',').includes(ProgramModulesEnum.DISCOUNTS) }));
};
exports.buildModules = buildModules;
var buildModulesFromPermissions = function (modules) { return exports.buildModules(modules || exports.allModules); };
exports.buildModulesFromPermissions = buildModulesFromPermissions;
