import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1, 2),
    marginLeft: 70,
    transition: theme.transitions.create('width, margin-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  flex: {
    flex: 1,
  },
  span: {
    background: 'rgba(255, 255, 255, 0.5)',
  },
  shift: {
    marginLeft: 260,
  },
  logo: {
    width: 180,
  },
  noMargin: {
    marginLeft: 0,
  },
  displayContents: {
    display: 'contents',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
}));
