import React from 'react';
import { t } from '@psp/common';

import Breadcrumbs from '../../../components/Breadcrumbs';
import ProgramRegisterComposer from '../../../composers/ProgramRegisterComposer';
import { PROGRAMS_ROUTE } from '../../../constants';

export default function ProgramRegisterPage(): JSX.Element {
  return (
    <>
      <Breadcrumbs title={t(`navigation.${PROGRAMS_ROUTE}`)} />
      <ProgramRegisterComposer />
    </>
  );
}
