import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: 300,
    maxWidth: '100%',
    position: 'relative',
  },
  innerContainer: {
    padding: theme.spacing(2),
    width: 300,
    maxWidth: '100%',
    position: 'relative',
    cursor: 'pointer',
  },
  left: {
    width: 228,
  },
  description: {
    opacity: 0.7,
  },
  icon: {
    opacity: 0.5,
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  deleteContainer: {
    position: 'absolute',
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    top: '-8px',
    right: '-8px',
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.error.dark,
    },
  },
  deleteIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  primary: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
  error: {
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.error.dark,
    },
  },
  default: {},
}));
