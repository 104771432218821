import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './styles';

export type InfoCardProps = {
  title: string;
  primary: string;
  secondary?: string;
  value: string | number;
  icon?: React.ComponentType<any>;
  color?: 'default' | 'primary' | 'error';
};

export default function InfoCard({
  title,
  primary,
  secondary,
  value,
  icon: Icon,
  color = 'default',
}: InfoCardProps): JSX.Element {
  const classes = useStyles();

  return (
    <Paper className={clsx(classes.container, classes[color])}>
      {Icon && <Icon className={classes.icon} />}
      <Grid container spacing={2}>
        <Grid item>
          <Grid container spacing={0} direction="column">
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <Typography variant="h5" color="inherit">
                    {value}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <Typography variant="body2">{title}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" className={classes.nowrap}>
                    {primary}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
