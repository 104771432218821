import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { CloseCircle, CheckCircle } from 'mdi-material-ui';
import { dt, t, Procedure, ProcedureType, ProcedureStatus, ResultType, formatCPF, ProcedureUtils } from '@psp/common';

import Chip from '../Chip';
import ProcedureStatusChip from '../ProcedureStatusChip';
import TextItem from '../TextItem';
import { useAuth } from '../../contexts/auth.context';

import { useStyles } from './styles';
import PaperTitle from '../PaperTitle';
import PaperContent from '../PaperContent';

export type ProcedureInfoProps = {
  procedure: Procedure;
};

const renderGroup = (procedure: Procedure, classes: any): JSX.Element[] => {
  switch (procedure.type) {
    case ProcedureType.CLINICAL_EXAMINATION:
      return [
        <td key="group-name" className={classes.td}>
          <TextItem label={t('examGroup')} content={procedure.examGroup?.name ?? '-'} />
        </td>,
      ];
    case ProcedureType.FIRST_MEDICINE:
      return [
        <td key="group-name" className={classes.td}>
          <TextItem label={t('product')} content={procedure.product?.name ?? '-'} />
        </td>,
        <td key="item-name" className={classes.td}>
          <TextItem label={t('presentation')} content={procedure.presentation?.name ?? '-'} />
        </td>,
      ];
    case ProcedureType.SUPPLY:
      return [
        <td key="group-name" className={classes.td}>
          <TextItem label={t('supply')} content={procedure.supply?.name ?? '-'} />
        </td>,
        <td key="tracking-code" className={classes.td}>
          <TextItem label={t('trackingCode')} content={procedure.supplyRequests?.length ? procedure.supplyRequests[0].trackingCode ?? '-' : '-'} />
        </td>,
      ];
    default:
      return [];
  }
};

function renderEligibilityResults(procedure: Procedure, classes: any): JSX.Element[] {
  return procedure.eligibilityResults.map((er) => (
    <tr key={er.id}>
      <td className={classes.td}>
        <Chip
          className={classes.eligibilityChip}
          color={
            er.result === ResultType.APPROVE
              ? 'success'
              : [ProcedureStatus.OPEN, ProcedureStatus.REJECTED].includes(procedure.status)
                ? 'error'
                : 'info'
          }
          icon={er.result === ResultType.APPROVE ? <CheckCircle /> : <CloseCircle />}
          label={er.description}
        />
      </td>
    </tr>
  ));
}

export default function ProcedureInfo({ procedure }: ProcedureInfoProps): JSX.Element {
  const { isDoctor } = useAuth();
  const classes = useStyles();
  const procedureStatus = ProcedureUtils.CheckIfIsPartial(procedure.children ?? []) ? 'PARTIAL' : procedure.status;
  return (
    <Paper className={classes.container}>
      <PaperTitle
        title={`${t(`procedureType.${procedure.type}`)}${
          procedure.code ? ` - ${procedure.code}` : ''
        }`}
      />
      <PaperContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ProcedureStatusChip status={procedureStatus} />
          </Grid>
          {isDoctor && procedure.patient && (
            <Grid item xs={12}>
              <table>
                <tr>
                  <td className={classes.td}>
                    <TextItem label={t('patientName')} content={procedure.patient ? procedure.patient.name : ''} />
                  </td>
                  <td className={classes.td}>
                    <TextItem label={t('cpf')} content={procedure.patient ? formatCPF(procedure.patient.cpf) : ''} />
                  </td>
                </tr>
              </table>
            </Grid>
          )}
          <Grid item xs={12}>
            <table>
              <tr>
                <td className={classes.td}>
                  <TextItem
                    label={t('requester')}
                    content={`${procedure.createdBy.name}${
                      procedure.createdBy.doctor
                        ? ` / ${procedure.createdBy.doctor.crm}-${procedure.createdBy.doctor.uf}`
                        : ''
                    }`}
                  />
                </td>
                {procedure.examGroup?.showCreatedAt && (
                <td className={classes.td}>
                  <TextItem label={t('requestDate')} content={dt(procedure.createdAt)} />
                </td>
                )}
                <td className={classes.td}>
                  <TextItem label={t('type')} content={t(`procedureType.${procedure.type}`)} />
                </td>
                {renderGroup(procedure, classes)}
                {procedure.code && (
                  <td>
                    <TextItem label={t('code')} content={procedure.code} />
                  </td>
                )}
              </tr>
              <tr>
                <td className={classes.td}>
                  <TextItem
                    label={
                      procedure.status === ProcedureStatus.REJECTED
                        ? t('rejectedBy')
                        : t('approvedBy')
                    }
                    content={
                      procedure.approvedBy && procedure.approvedBy.id !== procedure.createdBy.id
                        ? procedure.approvedBy.name
                        : '-'
                    }
                  />
                </td>
                {procedure.examGroup?.showApprovedAt && (
                  <td className={classes.td}>
                    <TextItem
                      label={
                        procedure.status === ProcedureStatus.REJECTED
                          ? t('rejectionDate')
                          : t('approvalDate')
                      }
                      content={
                        procedure.approvedBy
                        && procedure.approvedAt
                        && procedure.approvedBy.id !== procedure.createdBy.id
                          ? dt(procedure.approvedAt)
                          : '-'
                      }
                    />
                  </td>
                )}
                {procedure.examGroup?.showScheduledAt && (
                  <td className={classes.td}>
                    <TextItem label={t('scheduledDate')} content={procedure.scheduledAt ? dt(procedure.scheduledAt) : '-'} />
                  </td>
                )}
                {procedure.examGroup?.showCollectedAt && (
                  <td className={classes.td}>
                    <TextItem label={t('collectDate')} content={procedure.collectedAt ? dt(procedure.collectedAt) : '-'} />
                  </td>
                )}
                {procedure.examGroup?.showReceivedAt && (
                  <td className={classes.td}>
                    <TextItem label={t('receivementDate')} content={procedure.receivedAt ? dt(procedure.receivedAt) : '-'} />
                  </td>
                )}
                {procedure.examGroup?.showConcludedAt && (
                  <td className={classes.td}>
                    <TextItem label={t('concludedDate')} content={procedure.concludedAt ? dt(procedure.concludedAt) : '-'} />
                  </td>
                )}
                {procedure.examGroup?.showCanceledAt && (
                  <td className={classes.td}>
                    <TextItem label={t('canceledDate')} content={procedure.canceledAt ? dt(procedure.canceledAt) : '-'} />
                  </td>
                )}
                {procedure.status === ProcedureStatus.CANCELED && (
                  <td className={classes.td}>
                    <TextItem label={t('cancelationReason')} content={procedure.cancelationReason ? procedure.cancelationReason : ''} />
                  </td>
                )}
              </tr>
              <tr>
                {procedure.instructions && (
                <td className={classes.td}>
                  <TextItem label={t('instructions')} content={procedure.instructions} />
                </td>
                )}
              </tr>
            </table>
          </Grid>
          <Grid item xs={12}>
            <table>
              {!!procedure.eligibilityResults.length && (
                <>{renderEligibilityResults(procedure, classes)}</>
              )}
            </table>
          </Grid>
        </Grid>
      </PaperContent>
    </Paper>
  );
}
