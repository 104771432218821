import React from 'react';
import { MenuItem } from '@material-ui/core';
import clsx from 'clsx';

import Select, { SelectProps } from '../Select';
import { useStyles } from './styles';

type DocumentTypeSelectProps = Omit<SelectProps, 'children'> & {
  showOnlyCpf?: boolean;
};

export default function DocumentTypeSelect({ className, showOnlyCpf = false, ...props }:
  DocumentTypeSelectProps): JSX.Element {
  const classes = useStyles();
  return (
    <Select className={clsx(className, classes.formControl)} {...props}>
      {showOnlyCpf ? (
        [<MenuItem key="cpf" value="cpf">CPF</MenuItem>]
      ) : (
        [
          <MenuItem key="cnpj" value="cnpj">CNPJ</MenuItem>,
          <MenuItem key="cpf" value="cpf">CPF</MenuItem>,
        ]
      )}
    </Select>
  );
}
