import React from 'react';
import { t } from '@psp/common';

import Breadcrumbs from '../../../components/Breadcrumbs';
import { TRAVEL_REQUEST_ROUTE } from '../../../constants';
import TravelRequestComposer from '../../../composers/TravelRequestComposer';

export default function TravelRequestPage(): JSX.Element {
  return (
    <>
      <Breadcrumbs title={t(`navigation.${TRAVEL_REQUEST_ROUTE}`)} />
      <TravelRequestComposer />
    </>
  );
}
