import React from 'react';
import { MenuItem } from '@material-ui/core';
import clsx from 'clsx';
import { t } from '@psp/common';

import Select, { SelectProps } from '../Select';
import { useStyles } from './styles';

type DistributionTypeSelectProps = Omit<SelectProps, 'children'>;

export default function DistributionTypeSelect({
  className,
  ...props
}: DistributionTypeSelectProps): JSX.Element {
  const classes = useStyles();
  return (
    <Select
      label={t('distributionType.label')}
      className={clsx(className, classes.formControl)}
      {...props}
    >
      <MenuItem value="" disabled />
      <MenuItem value="INCREASE">{t('distributionType.INCREASE')}</MenuItem>
      <MenuItem value="REDUCE">{t('distributionType.REDUCE')}</MenuItem>
      <MenuItem value="ADD_UNITS">{t('distributionType.ADD_UNITS')}</MenuItem>
      <MenuItem value="REMOVE_UNITS">{t('distributionType.REMOVE_UNITS')}</MenuItem>
    </Select>
  );
}
