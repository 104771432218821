import React from 'react';
import { t } from '@psp/common';

import Breadcrumbs from '../../../components/Breadcrumbs';
import { PATIENT_INVITATION_ROUTE } from '../../../constants';
import PatientInvitationComposer from '../../../composers/PatientInvitationComposer';

export default function PatientInvitationPage(): JSX.Element {
  return (
    <>
      <Breadcrumbs title={t(`navigation.${PATIENT_INVITATION_ROUTE}`)} />
      <PatientInvitationComposer />
    </>
  );
}
