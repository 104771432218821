import React from 'react';
import { ProcedureStatus, t } from '@psp/common';

import Chip, { ChipProps } from '../Chip';

export type ProcedureStatuChipProps = ChipProps & {
  status: ProcedureStatus | 'PARTIAL';
};

export default function ProcedureStatusChip({
  status,
  ...props
}: ProcedureStatuChipProps): JSX.Element {
  const color = status === ProcedureStatus.OPEN
    ? 'warning'
    : status === ProcedureStatus.APPROVED
      ? 'primary'
      : status === ProcedureStatus.SCHEDULED
        ? 'info'
        : status === ProcedureStatus.CONCLUDED
          ? 'success'
          : status === 'PARTIAL'
            ? 'warning'
            : 'error';
  return <Chip color={color} label={t(`procedureStatus.${status}`)} size="small" />;
}
