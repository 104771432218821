import React, { useCallback, useRef, useState } from 'react';
import { MenuItem } from '@material-ui/core';

import { TreatmentLocations, t } from '@psp/common';

import { useLoading } from '../../contexts/loading.context';

import Select from '../Select';

export type TreatmentLocationsSelectValues = {
  treatmentLocations: TreatmentLocations;
  needRegister: boolean;
};

export type TreatmentLocationsSelectProps = {
  treatmentLocations: TreatmentLocations[];
  label: string;
  name: string;
  onSelectedValueChange: (values: TreatmentLocationsSelectValues) => void;
  showOutherOption: boolean;
};

type TreatmentLocationsSelectState = {
  treatmentLocations: TreatmentLocations;
  needRegister: boolean;
};

const defaultState: TreatmentLocationsSelectState = {
  treatmentLocations: {} as TreatmentLocations,
  needRegister: false,
};

export default function TreatmentLocationsSelect({
  treatmentLocations,
  label,
  name,
  onSelectedValueChange,
  showOutherOption = false,
}: TreatmentLocationsSelectProps): JSX.Element {
  const selectRef: any = useRef(null);
  const { showLoading, hideLoading, isLoading } = useLoading();
  const [state, setState] = useState<TreatmentLocationsSelectState>(defaultState);

  const handleTreatmentLocationsChange = useCallback(
    (value) => {
      if (!value) return;
      showLoading();

      (async () => {
        try {
          if (value === 'OTHER') {
            setState({
              treatmentLocations: {} as TreatmentLocations,
              needRegister: true,
            });
            onSelectedValueChange({
              treatmentLocations: {} as TreatmentLocations,
              needRegister: true,
            });
          } else {
            const treatmentLocationSelected = await treatmentLocations.find(
              (tl) => tl.id === value,
            );
            setState({
              treatmentLocations: treatmentLocationSelected ?? {} as TreatmentLocations,
              needRegister: false,
            });
            onSelectedValueChange({
              treatmentLocations: treatmentLocationSelected ?? {} as TreatmentLocations,
              needRegister: false,
            });
          }
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    },
    [state, onSelectedValueChange],
  );

  return (
    <>
      {treatmentLocations && treatmentLocations.length > 0 && (
        <Select
          name={name}
          label={label}
          variant="outlined"
          fullWidth
          showOutherOption={showOutherOption}
          onSelectedValueChange={handleTreatmentLocationsChange}
          disabled={treatmentLocations?.length === 0 || isLoading}
          inputRef={selectRef}
        >
          {treatmentLocations.map((tl) => (
            <MenuItem key={tl.id} value={tl.id}>
              {tl.name}
            </MenuItem>
          ))}
        </Select>
      )}
    </>
  );
}
