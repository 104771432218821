import { makeStyles, StepConnector, withStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  labelContainer: {
    textAlign: 'center',
  },
  green: {
    color: '#FFFFFF',
    background: theme.palette.success.main,
    borderRadius: 24,
  },
  waitingAuthorization: {
    color: '#ffca28',
    scale: 1.2,
  },
  notStarted: {
    color: '#D7D7D8',
    background: '#D7D7D8',
    borderRadius: 24,
  },
  scrollableContainer: {
    overflowX: 'auto',
  },
}));

export const CustomConnector = withStyles((theme) => ({
  active: {
    '& $line': {
      borderColor: '#ffca28',
    },
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.success.main,
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))(StepConnector);
