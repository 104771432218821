import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {},
  td: {
    paddingRight: theme.spacing(2),
  },
  eligibilityChip: {
    width: '100%',
    justifyContent: 'flex-start',
  },
}));
