import React from 'react';
import {
  getProgramFiles,
  getProgramProducts,
  getProductPresentations,
  getProductPatientProcedures,
  getProgramFollowups,
  getFollowupExamGroups,
  getExamGroupExams,
  getProgramSupplyGroups,
  getSupplyGroupSupplies,
  createProgramFile,
  deleteProgramFile,
  downloadProgramFile,
} from '@psp/common';

import LibraryList, { LibraryListProps } from '../../components/Library/List';

const dispatchers: Pick<
  LibraryListProps,
  | 'getProgramFiles'
  | 'getProgramProducts'
  | 'getProductPresentations'
  | 'getProductPatientProcedures'
  | 'getProgramFollowups'
  | 'getFollowupExamGroups'
  | 'getExamGroupExams'
  | 'getProgramSupplyGroups'
  | 'getSupplyGroupSupplies'
  | 'createProgramFile'
  | 'deleteProgramFile'
  | 'downloadProgramFile'
> = {
  getProgramFiles,
  getProgramProducts,
  getProductPresentations,
  getProductPatientProcedures,
  getProgramFollowups,
  getFollowupExamGroups,
  getExamGroupExams,
  getProgramSupplyGroups,
  getSupplyGroupSupplies,
  createProgramFile,
  deleteProgramFile,
  downloadProgramFile,
};

export default function LibraryListComposer(): JSX.Element {
  return <LibraryList {...dispatchers} />;
}
