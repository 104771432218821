import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import {
  t,
  GetProductPatientProcedures,
  GetProgramProducts,
  PatientProcedure,
  Product,
} from '@psp/common';

import { useLoading } from '../../contexts/loading.context';
import Select from '../Select';
import { useProgram } from '../../contexts/program.context';

export type PatientProcedureSelectValues = {
  productId: string;
  patientProcedureId: string;
  patientProcedure?: PatientProcedure;
};

export type PatientProcedureSelectProps = {
  getProgramProducts: GetProgramProducts;
  getProductPatientProcedures: GetProductPatientProcedures;
  onSelectedValueChange: (values: PatientProcedureSelectValues) => void;
  optionalProduct?: boolean;
  optionalPatientProcedure?: boolean;
  formRef: any;
  direction?: 'column' | 'row';
};

type PatientProcedureSelectState = {
  products: Product[];
  productId: string;
  patientProcedures: PatientProcedure[];
  patientProcedureId: string;
};

const defaultState: PatientProcedureSelectState = {
  products: [],
  productId: '',
  patientProcedures: [],
  patientProcedureId: '',
};

export default function PatientProcedureSelect({
  getProgramProducts,
  getProductPatientProcedures,
  onSelectedValueChange,
  optionalProduct = false,
  optionalPatientProcedure = false,
  formRef,
  direction = 'row',
}: PatientProcedureSelectProps): JSX.Element {
  const productRef: any = useRef(null);
  const patientProcedureRef: any = useRef(null);
  const [state, setState] = useState<PatientProcedureSelectState>(defaultState);
  const { showLoading, hideLoading, isLoading } = useLoading();
  const { program } = useProgram();

  useEffect(
    useCallback(() => {
      if (!program) return;
      showLoading();
      setState({
        ...defaultState,
      });
      (async () => {
        try {
          const products = await getProgramProducts.execute({ programId: program?.id });
          setState({
            ...defaultState,
            products,
          });
          onSelectedValueChange({
            productId: '',
            patientProcedureId: '',
          });
          formRef.current.setFieldValue('productId', null);
          formRef.current.setFieldValue('patientProcedureId', null);
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    }, [state, onSelectedValueChange]),
    [program],
  );

  const handleProductChange = useCallback(
    (value) => {
      if (!value) return;
      showLoading();
      setState({
        ...state,
        patientProcedures: [],
      });
      (async () => {
        try {
          const patientProcedures = await getProductPatientProcedures.execute({ productId: value });
          setState({
            ...state,
            productId: value,
            patientProcedures,
          });
          onSelectedValueChange({
            productId: value,
            patientProcedureId: '',
          });
          formRef.current.setFieldValue('patientProcedureId', null);
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
          productRef.current.focus();
        }
      })();
    },
    [state, onSelectedValueChange],
  );

  const handlePatientProcedureChange = useCallback(
    (value) => {
      if (!value) return;
      showLoading();
      setState({
        ...state,
      });
      (async () => {
        try {
          setState({
            ...state,
            patientProcedureId: value,
          });
          onSelectedValueChange({
            productId: state.productId,
            patientProcedureId: value,
            patientProcedure: state.patientProcedures.find((p) => p.id === value),
          });
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
          patientProcedureRef.current.focus();
        }
      })();
    },
    [state, onSelectedValueChange],
  );
  return (
    <Grid container spacing={2} direction={direction}>
      <Grid item xs={12} md={direction === 'column' ? 12 : 6}>
        <Select
          label={t('product')}
          variant="outlined"
          name="productId"
          fullWidth
          onSelectedValueChange={handleProductChange}
          disabled={state.products.length === 0 || isLoading}
          inputRef={productRef}
          optional={optionalProduct}
        >
          {state.products.map((p) => (
            <MenuItem key={p.id} value={p.id}>
              {p.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} md={direction === 'column' ? 12 : 6}>
        <Select
          label={t('patientProcedure')}
          variant="outlined"
          name="patientProcedureId"
          fullWidth
          onSelectedValueChange={handlePatientProcedureChange}
          disabled={state.patientProcedures.length === 0 || isLoading}
          inputRef={patientProcedureRef}
          optional={optionalPatientProcedure}
        >
          {state.patientProcedures.map((p) => (
            <MenuItem key={p.id} value={p.id}>
              {p.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
}
