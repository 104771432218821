import React, { useCallback, useEffect, useState, useMemo, ChangeEvent, useRef } from 'react';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import { t, ApproveUser, GetDoctors, Doctor, doctorsXLSXForm, IPaginated, SortingType, ValidationError, DoctorsXLSX, DoctorsXLSXParams, extractValidationErrors } from '@psp/common';
import { Form } from '@unform/web';

import { useLoading } from '../../../contexts/loading.context';
import { useProgram } from '../../../contexts/program.context';
import DoctorTable from '../../DoctorTable';

import { useStyles } from './styles';
import PaperTitle from '../../PaperTitle';
import PaperContent from '../../PaperContent';
import Button from '../../Button';
import Base64FileUploadInput from '../../Base64FileUploadInput';
import Table from '../../Table';

export type DoctorListProps = {
    approveUser: ApproveUser;
    getDoctors: GetDoctors;
    doctorsXLSX: DoctorsXLSX;
};

type DoctorListState = {
    doctors: IPaginated<Doctor>;
    errorsXLSX: any[];
};

type DoctorListAllState = {
    doctors: IPaginated<Doctor>;
};

type FilterState = {
    filter: string;
    page: number;
    rows: number;
    timeout: number;
    statusType: string;
};

type OldFilterState = {
    oldFilter: string;
    totalStatusType: number;
};

const defaultState: DoctorListState = {
  doctors: {
    data: [],
    total: 0,
  },
  errorsXLSX: [],
};

const defaultStateAll: DoctorListAllState = {
  doctors: {
    data: [],
    total: 0,
  },
};

const defaultFilterState: FilterState = {
  filter: '',
  page: 0,
  rows: 10,
  timeout: 0,
  statusType: '',
};

const defaultOldFilterState: OldFilterState = {
  oldFilter: '',
  totalStatusType: 0,
};

let FirstAcessState = true;

export interface SortingConfiguration {
    propertyName: keyof {
        id: string;
        [key: string]: unknown;
    },
    sortType: SortingType,
    compareFunction: TableDataComparable
}

export type TableDataComparable = ((a: {
    id: string;
    [key: string]: unknown;
}, b: {
    id: string;
    [key: string]: unknown;
}) => number);

const statusTypes = ['registered', 'preRegistration', 'unregistered'];

const CompareByEquality = (column: keyof {
  id: string;
  [key: string]: unknown;
}) => (a: {
    id: string;
    [key: string]: unknown;
  }, b: {
    id: string;
    [key: string]: unknown;
  }) => {
  if (a[column] === b[column]) {
    return 0;
  }
  return 1;
};

const columnsXLSXError = [
  {
    key: 'row',
    label: t('row'),
    compareFunction: CompareByEquality('row'),
  },
  {
    key: 'fullName',
    label: t('fullName'),
    compareFunction: CompareByEquality('fullName'),
  },
  {
    key: 'crm',
    label: t('crm'),
    compareFunction: CompareByEquality('crm'),
  },
  {
    key: 'uf',
    label: t('uf'),
    compareFunction: CompareByEquality('uf'),
  },
  {
    key: 'telephone',
    label: t('telephone'),
    compareFunction: CompareByEquality('telephone'),
  },
  {
    key: 'email',
    label: t('email'),
    compareFunction: CompareByEquality('email'),
  },
  {
    key: 'cpf',
    label: t('cpf'),
    compareFunction: CompareByEquality('cpf'),
  },
  {
    key: 'specialty',
    label: t('specialtySimple'),
    compareFunction: CompareByEquality('specialty'),
  },
  {
    key: 'errors',
    label: t('errors'),
    compareFunction: CompareByEquality('errors'),
    render: (value: string[]) => value.join(', '),
  },
];

export default function DoctorList({
  approveUser,
  getDoctors,
  doctorsXLSX,
}: DoctorListProps): JSX.Element {
  const classes = useStyles();
  const [state, setState] = useState<DoctorListState>(defaultState);
  const [filterState, setFilterState] = useState<FilterState>(defaultFilterState);
  const { showLoading, hideLoading, showBackdrop, hideBackdrop } = useLoading();
  const { program } = useProgram();
  const xlsxFormRef = useRef({} as any);
  const xlsxUploadRef = useRef<{
    resetFileInput:() => void;
    setError:(errorMessage: string) => void;
      }>(null);
  const [sortConfig, updateSortConfig] = useState<SortingConfiguration[]>([]);
  const [stateAll] = useState<DoctorListAllState>(defaultStateAll);
  const [oldFilterState] = useState<OldFilterState>(defaultOldFilterState);
  const statusTypesDoctorObj = statusTypes.map((statusType) => ({
    value: statusType,
    label: t(statusType),
  }));
  statusTypesDoctorObj.unshift({ value: ' ', label: t('all') });
  function storeAllDoctors(doctor: Doctor[]): void {
    const doctorList: Doctor[] = [];
    for (let i = 0; i < doctor.length; i++) {
      doctorList.push(doctor[i]);
    }
    stateAll.doctors.data = doctorList;
  }

  function filterByStatus(doctor: Doctor[]): Doctor[] {
    let doctorList: Doctor[] = [];
    if (filterState.statusType !== '' && filterState.statusType !== ' ') {
      oldFilterState.totalStatusType = 0;
      for (let i = 0; i < doctor.length; i++) {
        const status = doctor[i].user ? doctor[i].user?.approved ? 'registered' : 'preRegistration' : 'unregistered';
        if (filterState.statusType === status) {
          doctorList.push(doctor[i]);
          oldFilterState.totalStatusType++;
        }
      }
      return doctorList;
    }
    doctorList = doctor;
    oldFilterState.totalStatusType = stateAll.doctors.total;
    return doctor;
  }
  function handlePageAndRowsChange(page: number,
    rows: number, doctor: Doctor[]): Doctor[] {
    const doctorList: Doctor[] = [];
    for (let i = page * rows; i < (page + 1) * rows && i < doctor.length; i++) {
      doctorList.push(doctor[i]);
    }
    state.doctors.data = doctorList;
    return state.doctors.data;
  }

  const handleSortBy = useCallback(
    (propertyName: keyof {
            id: string;
            [key: string]: unknown;
        }, compareFunction: TableDataComparable) => {
      let pendingChange = [...sortConfig];
      const index = pendingChange.findIndex((config) => config.propertyName === propertyName);
      if (index > -1) {
        const currentSortType = pendingChange[index].sortType;
        pendingChange.splice(index, 1);
        if (currentSortType === SortingType.Descending) {
          pendingChange = [
            ...pendingChange,
            {
              propertyName,
              sortType: SortingType.Ascending,
              compareFunction,
            },
          ];
        }
      } else {
        pendingChange = [
          ...pendingChange,
          {
            propertyName,
            sortType: SortingType.Descending,
            compareFunction,
          },
        ];
      }
      updateSortConfig([...pendingChange]);
    },
    [sortConfig],
  );

  useMemo(() => {
    if (sortConfig.length === 0) {
      return [...state.doctors.data];
    }
    const sorted = [...stateAll.doctors.data].sort(
      (a: {
                id: string;
                [key: string]: unknown;
            }, b: {
                id: string;
                [key: string]: unknown;
            }) => {
        for (const config of sortConfig) {
          const result = (config.compareFunction(a, b));
          if (result !== 0) {
            if (config.sortType === SortingType.Ascending) {
              return result;
            }
            return -result;
          }
        }
        return 0;
      },
    );
    state.doctors.data = handlePageAndRowsChange(filterState.page,
      filterState.rows, filterByStatus(sorted));
    return (sorted);
  }, [sortConfig, state.doctors.data]);

  useEffect(() => {
    const handler = setTimeout(() => {
      (async () => {
        if (!program) return;
        try {
          if (state.doctors.data.length === 0
                  || oldFilterState.oldFilter !== filterState.filter) {
            oldFilterState.oldFilter = filterState.filter;
            const doctors = await getDoctors.execute({
              programId: program.id,
              filter: filterState.filter,
              skip: filterState.page * filterState.rows,
              take: filterState.rows,
            });

            storeAllDoctors(doctors.data);

            stateAll.doctors.total = doctors.total;
            oldFilterState.totalStatusType = doctors.total;
            doctors.data = handlePageAndRowsChange(filterState.page,
              filterState.rows, filterByStatus(doctors.data));
            doctors.total = oldFilterState.totalStatusType;

            setState({
              ...state,
              doctors,
            });
          } else {
            const { doctors } = state;
            oldFilterState.totalStatusType = doctors.total;
            doctors.data = handlePageAndRowsChange(filterState.page,
              filterState.rows, filterByStatus(stateAll.doctors.data));
            doctors.total = oldFilterState.totalStatusType;
            setState({
              ...state,
              doctors,
            });
          }
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
      if (program?.slug === 'tevacuidar'.toString() && FirstAcessState === true) {
        console.log(program);
        toast.info(t('err.tevaDoctorNotice'), {
          onOpen: showBackdrop,
          onClose: hideBackdrop,
        });
        FirstAcessState = false;
      }
    }, filterState.timeout);
    return () => clearTimeout(handler);
  }, [filterState, program]);

  const handleSearch = useCallback(
    (value: string) => {
      setFilterState({
        filter: value,
        page: 0,
        rows: filterState.rows,
        timeout: 500,
        statusType: filterState.statusType,
      });
    },
    [filterState],
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setFilterState({
        filter: filterState.filter,
        page,
        rows: filterState.rows,
        timeout: 200,
        statusType: filterState.statusType,
      });
    },
    [filterState],
  );

  const handleRowsChange = useCallback(
    (rows: number) => {
      setFilterState({
        filter: filterState.filter,
        page: filterState.page,
        rows,
        timeout: 200,
        statusType: filterState.statusType,
      });
      filterState.rows = rows;
    },
    [filterState],
  );

  const handleSearchBySelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== '') {
      (async () => {
        setFilterState({
          ...filterState,
          statusType: e.target.value,
        });
      })();
    } else {
      (async () => {
        setFilterState({
          ...filterState,
          filter: '',
          statusType: filterState.statusType,
        });
      })();
    }
  };

  const handleApproveUserClick = useCallback(
    (doctor: Doctor) => {
      if (!program || !doctor.userId) return;
      showLoading();
      (async () => {
        try {
          await approveUser.execute({ userId: doctor.userId });
          toast.success(t('msg.successToApproveUser'), {
            onOpen: showBackdrop,
            onClose: hideBackdrop,
          });
          oldFilterState.oldFilter = filterState.filter;

          const doctors = await getDoctors.execute({
            programId: program.id,
            filter: filterState.filter,
            skip: filterState.page * filterState.rows,
            take: filterState.rows,
          });

          storeAllDoctors(doctors.data);

          stateAll.doctors.total = doctors.total;
          oldFilterState.totalStatusType = doctors.total;
          doctors.data = handlePageAndRowsChange(filterState.page,
            filterState.rows, filterByStatus(doctors.data));
          doctors.total = oldFilterState.totalStatusType;

          setState({
            ...state,
            doctors,
          });
        } catch (err) {
          console.log(err);
          toast.warning(t('err.failedToApproveUser'), {
            onOpen: showBackdrop,
            onClose: hideBackdrop,
          });
        } finally {
          hideLoading();
        }
      })();
    },
    [filterState, program],
  );

  const handleUpdateDoctorsList = useCallback(async () => {
    if (!program) return;
    showLoading();
    try {
      const doctors = await getDoctors.execute({
        programId: program.id,
        filter: filterState.filter,
        skip: filterState.page * filterState.rows,
        take: filterState.rows,
      });

      storeAllDoctors(doctors.data);

      stateAll.doctors.total = doctors.total;
      oldFilterState.totalStatusType = doctors.total;
      doctors.data = handlePageAndRowsChange(filterState.page,
        filterState.rows, filterByStatus(doctors.data));
      doctors.total = oldFilterState.totalStatusType;

      setState({
        ...state,
        doctors,
      });
    } catch (err) {
      console.log(err);
      toast.warning(t('err.failedToUpdateDoctorsList'), {
        onOpen: showBackdrop,
        onClose: hideBackdrop,
      });
    } finally {
      hideLoading();
    }
  }, [filterState, program]);

  const handleXlsxSubmit = useCallback(
    (event: any): void => {
      if (!program) return;
      showLoading();
      xlsxFormRef.current.setErrors({});
      (async () => {
        try {
          const data = xlsxFormRef.current.getData();
          const req = (await doctorsXLSXForm.validate(
            {
              xlsxFile: data.xlsxFile,
            },
            {
              abortEarly: false,
            },
          )) as any;

          const xlsxUploadResult = await doctorsXLSX.execute({
            file: data.xlsxFile,
            programId: program.id,
          } as DoctorsXLSXParams);

          if (xlsxUploadResult && xlsxUploadResult.Success) {
            toast.success(t('msg.successToDoctorsUploadXlsx'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
            xlsxFormRef.current.reset();
            xlsxUploadRef.current?.resetFileInput();
            handleUpdateDoctorsList();
            setState({
              ...state,
              errorsXLSX: [],
            });
          } else if (xlsxUploadResult && xlsxUploadResult.Errors.length > 0) {
            console.log(xlsxUploadResult);
            xlsxUploadRef.current?.resetFileInput();
            setState({
              ...state,
              errorsXLSX: xlsxUploadResult.Errors,
            });
            throw new Error('InvalidXlsxFileCheckErrorReport');
          } else {
            throw new Error('FailedToUploadXlsxFile');
          }
        } catch (err: any) {
          let errors = {};
          if (err instanceof ValidationError) {
            errors = extractValidationErrors(err);
            xlsxFormRef.current.setErrors(errors);

            if (err.inner.find((e) => e.path === 'xlsxFile' && e.message === 'validation.requiredField')) {
              xlsxUploadRef.current?.setError(t('validation.requiredField'));
            }
          } else if (err.name === 'BadRequestError') {
            toast.info(err.message, {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } else if (err.message === 'InvalidXlsxFileCheckErrorReport') {
            toast.warn(t('err.invalidXlsxFileCheckErrorReport'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } else {
            toast.info(t('err.failedToUploadXlsxFile'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          }
        } finally {
          hideLoading();
        }
      })();
    },
    [program, state],
  );

  const convertErrorReportArrayToCSV = useCallback(
    (delimiter = ';') => {
      const array = state.errorsXLSX;

      const csvRows = [];

      // Get the headers (keys)
      const headers = Object.keys(array[0]);
      csvRows.push(headers.join(delimiter));

      // Loop over the rows
      for (const row of array) {
        const values = headers.map((header) => {
          let value;

          if (header === 'errors') {
            value = (row[header] ?? []).join(', ');
          } else {
            value = (row[header] ?? '').toString();
          }

          // Escape double quotes by doubling them
          value = value.replace(/"/g, '""');

          // If the value contains commas, double quotes, or line breaks, wrap it in double quotes
          if (value.search(/("|,|\n)/g) >= 0) {
            value = `"${value}"`;
          }
          return value;
        });
        csvRows.push(values.join(delimiter));
      }

      // Join the rows with new line
      return csvRows.join('\n');
    },
    [state],
  );

  const downloadErrorReportCSV = useCallback(
    () => {
      // Add BOM for UTF-8 encoding
      const csvWithBom = `\uFEFF${convertErrorReportArrayToCSV()}`;

      const csvFile = new Blob([csvWithBom], { type: 'text/csvtext/csv;charset=utf-8;' });
      const downloadLink = document.createElement('a');

      downloadLink.href = URL.createObjectURL(csvFile);
      downloadLink.download = 'Relatório de erros.csv';

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    [state],
  );

  return (
    <>
      <Box className={classes.container}>
        <DoctorTable
          count={state.doctors.total}
          data={state.doctors.data}
          onApprove={handleApproveUserClick}
          canSearch
          onChangePage={handlePageChange}
          onChangeRows={handleRowsChange}
          onSearch={handleSearch}
          page={filterState.page}
          rowsPerPage={filterState.rows}
          onSortBy={handleSortBy}
          onSortConfig={sortConfig}
          enableSelectFilter
          selectOptions={statusTypesDoctorObj}
          selectFilterTitle={t('filterByStatus')}
          onSearchBySelect={handleSearchBySelect}
        />
      </Box>
      <Box className={classes.container} style={{ marginTop: '30px' }}>
        <Paper className={classes.container}>
          <PaperTitle title={t('pages.doctors.doctorsXlsx')} />
          <PaperContent>
            <Form onSubmit={handleXlsxSubmit} ref={xlsxFormRef}>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <Typography variant="body1">Passo 1: Baixe o arquivo template e preencha com os dados</Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      window.open('https://bcarestorage.blob.core.windows.net/public/TemplateImportacaoMedicos.xlsx', '_blank', 'noopener,noreferrer');
                    }}
                  >
                    Baixar template
                  </Button>
                </Grid>
                <Grid item>
                  <Typography variant="body1">Passo 2: Faça o upload do arquivo preenchido</Typography>
                </Grid>
                <Grid item>
                  <Base64FileUploadInput name="xlsxFile" ref={xlsxUploadRef} />
                </Grid>
                <Grid item>
                  <Typography variant="body1">Passo 3: Confirme a importação clicando em salvar</Typography>
                </Grid>
                <Grid item>
                  <Button onClick={handleXlsxSubmit} variant="contained" color="primary">
                    {t('save')}
                  </Button>
                </Grid>
              </Grid>
            </Form>

            {state.errorsXLSX.length > 0 && (
              <Box style={{ marginTop: '30px' }}>
                <Table
                  columns={columnsXLSXError}
                  data={state.errorsXLSX}
                  title={t('errorsReport')}
                  size="small"
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    downloadErrorReportCSV();
                  }}
                  style={{ marginTop: '30px' }}
                >
                  Baixar relatório de erros
                </Button>
              </Box>
            )}
          </PaperContent>
        </Paper>
      </Box>
    </>
  );
}
