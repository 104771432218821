import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: '25px 20px',
    borderBottom: '1px solid #DADADA',
  },
  title: {
    fontSize: 20,
  },
}));
