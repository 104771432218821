import React from 'react';
import {
  getDoctor,
  getProgramProducts,
  getProgramFollowups,
  getFollowupExamGroups,
  getProductPresentations,
  getExamGroupExams,
  getProductPatientProcedures,
  getDoctorInventoryAmounts,
  distributeInventory,
} from '@psp/common';

import DoctorDetails, { DoctorDetailsProps } from '../../components/Doctor/Details';

const dispatchers: DoctorDetailsProps = {
  getDoctor,
  getProgramProducts,
  getProgramFollowups,
  getFollowupExamGroups,
  getProductPresentations,
  getExamGroupExams,
  getDoctorInventoryAmounts,
  getProductPatientProcedures,
  distributeInventory,
};

export default function DoctorDetailsComposer(): JSX.Element {
  return <DoctorDetails {...dispatchers} />;
}
