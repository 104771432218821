import React from 'react';
import { useStyles } from './styles';

interface CardProps {
name: string;
activeIngredient: string;
}

export default function Card({ name, activeIngredient }: CardProps) {
  const classes = useStyles();

  const handleClick = () => {
    const url = `https://consultas.anvisa.gov.br/#/bulario/q/?nomeProduto=${encodeURIComponent(name)}`;
    window.location.href = url;
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleClick();
    }
  };

  return (
    <div
      className={classes.cardContainer}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
    >
      <h3 className={classes.cardTitle}>{name}</h3>
      <p className={classes.cardPrincipios}>{activeIngredient}</p>
    </div>
  );
}
