import React from 'react';
import {
  Program,
  t,
  UserGroup,
} from '@psp/common';
import { Key, Pencil } from 'mdi-material-ui';
import { USER_GROUP_PERMISSIONS_ROUTE } from '../../constants';
import Table, { TableProps } from '../Table';

const CompareByEquality = (column: keyof {
    id: string;
    [key: string]: unknown;
}) => (a: {
    id: string;
    [key: string]: unknown;
}, b: {
    id: string;
    [key: string]: unknown;
}) => {
  if (a[column] === b[column]) {
    return 0;
  }
  return 1;
};

const columns = [
  {
    key: 'name',
    label: t('name'),
    compareFunction: CompareByEquality('name'),
  },
  {
    key: 'program',
    label: t('program'),
    compareFunction: CompareByEquality('program'),
    render: (program?: Program) => (program ? program.name : '-'),
  },
];

export type UserGroupsTableProps = Pick<TableProps, 'onRemove'> & {
  data: UserGroup[];
  handleAdd: () => void;
  handleEdit: (item: any) => void;
};

export default function UserGroupsTable({
  data,
  handleAdd,
  handleEdit,
  onRemove,
}: UserGroupsTableProps): JSX.Element {
  const extraActions = [
    {
      icon: <Pencil />,
      label: 'Editar',
      onExecute: (item: any) => handleEdit(item),
    },
    {
      icon: <Key />,
      label: 'Permissões',
      route: USER_GROUP_PERMISSIONS_ROUTE,
    },
  ];

  return (
    <Table
      columns={columns}
      data={data}
      title={t('userGroups')}
      size="small"
      canAdd
      addAction={handleAdd}
      canRemove
      onRemove={onRemove}
      extraActions={extraActions}
    />
  );
}
