import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  dividerContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '20px 0',
  },
  groupCircle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F3F3F4',
    color: '#1849A9',
    width: 30,
    height: 30,
    borderRadius: '50%',
    fontFamily: 'Roboto',
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '21.09px',
    letterSpacing: 0.38,
    textAlign: 'center',
  },
}));
