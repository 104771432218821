import React from 'react';
import {
  saveUserGroupUser,
  deleteUserGroupUser,
  getUserUserGroups,
  getUserGroups,
  getManagers,
} from '@psp/common';

import UserGroupUsers, { UserGroupUsersProps } from '../../components/UserGroups/Users';

const dispatchers: Pick<
  UserGroupUsersProps,
  | 'saveUserGroupUser'
  | 'deleteUserGroupUser'
  | 'getUserUserGroups'
  | 'getUserGroups'
  | 'getManagers'
> = {
  saveUserGroupUser,
  deleteUserGroupUser,
  getUserUserGroups,
  getUserGroups,
  getManagers,
};

export default function UserGroupUsersComposer(): JSX.Element {
  return <UserGroupUsers {...dispatchers} />;
}
