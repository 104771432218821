import React from 'react';
import { getClient, registerClient } from '@psp/common';
import ClientRegister, { ClientRegisterProps } from '../../components/Client/Register';

const dispatchers: ClientRegisterProps = {
  getClient,
  registerClient,
};

export default function ClientRegisterComposer(): JSX.Element {
  return <ClientRegister {...dispatchers} />;
}
