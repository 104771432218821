import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { Form } from '@unform/web';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import {
  Doctor,
  InventoryType,
  GetProgramProducts,
  GetProgramFollowups,
  GetFollowupExamGroups,
  GetProductPresentations,
  GetExamGroupExams,
  GetDoctorInventoryAmounts,
  Inventory,
  DistributeInventory,
  t,
  UserType,
  DistributionType,
  Exam,
  Presentation,
  GetProductPatientProcedures,
} from '@psp/common';

import { useLoading } from '../../contexts/loading.context';
import { useProgram } from '../../contexts/program.context';
import InventoryTypeSelect from '../InventoryTypeSelect';
import ProductGroupSelect, { ProductGroupValues } from '../ProductGroupSelect';
import ExamGroupSelect, { ExamGroupSelectValues } from '../ExamGroupSelect';
import Input from '../Input';
import Button from '../Button';

import { useStyles } from './styles';
import { useAuth } from '../../contexts/auth.context';

export type DoctorInventoryDistributionProps = {
  doctor: Doctor;
  getProgramProducts: GetProgramProducts;
  getProgramFollowups: GetProgramFollowups;
  getFollowupExamGroups: GetFollowupExamGroups;
  getProductPresentations: GetProductPresentations;
  getExamGroupExams: GetExamGroupExams;
  getDoctorInventoryAmounts: GetDoctorInventoryAmounts;
  getProductPatientProcedures: GetProductPatientProcedures;
  distributeInventory: DistributeInventory;
  reloadDoctor: () => void;
};

type DoctorInventoryDistributionState = {
  inventoryType: InventoryType | '';
  followupId: string;
  groupId: string;
  groupName: string;
  items: {
    id: string;
    name: string;
  }[];
  userInventory: {
    [key: string]: Inventory;
  };
  doctorInventory: {
    [key: string]: Inventory;
  };
};

const defaultState: DoctorInventoryDistributionState = {
  inventoryType: '',
  followupId: '',
  groupId: '',
  groupName: '',
  items: [],
  userInventory: {},
  doctorInventory: {},
};

export default function DoctorInventoryDistribution({
  doctor,
  getProgramProducts,
  getProgramFollowups,
  getFollowupExamGroups,
  getProductPresentations,
  getExamGroupExams,
  getProductPatientProcedures,
  getDoctorInventoryAmounts,
  distributeInventory,
  reloadDoctor,
}: DoctorInventoryDistributionProps): JSX.Element {
  const classes = useStyles();
  const [state, setState] = useState<DoctorInventoryDistributionState>(defaultState);
  const formRef = useRef({} as any);
  const { isAdmin, isSysadmin } = useAuth();
  const { program } = useProgram();
  const { showLoading, hideLoading } = useLoading();
  const { showBackdrop, hideBackdrop } = useLoading();

  const handleInventoryTypeChange = useCallback(
    (value: InventoryType): void => {
      console.log(value);
      setState({
        ...defaultState,
        inventoryType: value,
      });
    },
    [state],
  );

  const handleSubmit = useCallback(
    (data) => {
      console.log(data);
      showLoading();
      (async () => {
        omegalul: try {
          const distributions = [];

          const groupId = Object.keys(data.doctor.group)[0];

          let userGroupAmount = state.userInventory[groupId]?.amount || 0;

          if (data.doctor.group[groupId] !== '') {
            let groupAmount = parseInt(data.doctor.group[groupId], 10);

            groupAmount -= state.doctorInventory[groupId]?.amount || 0;

            if (!(isAdmin || isSysadmin) && groupAmount > 0 && userGroupAmount < groupAmount) {
              formRef.current.setFieldError(
                `doctor.group.${groupId}`,
                t('validation.insufficientInventory'),
              );
              break omegalul;
            }
            if (groupAmount !== 0) {
              userGroupAmount -= groupAmount;
              distributions.push({
                programId: program!.id,
                groupId: state.groupId,
                itemId: '',
                userType: UserType.DOCTOR,
                userIds: [doctor.userId],
                distributionType:
                  groupAmount > 0 ? DistributionType.ADD_UNITS : DistributionType.REMOVE_UNITS,
                inventoryType: state.inventoryType as InventoryType,
                amount: Math.abs(groupAmount),
                filter: '',
              });
            }
          }

          const itemIds = Object.keys(data.doctor.item);

          for (let i = 0; i < itemIds.length; i++) {
            const itemId = itemIds[i];
            if (data.doctor.item[itemId] !== '') {
              let itemAmount = parseInt(data.doctor.item[itemId], 10);

              itemAmount -= state.doctorInventory[itemId]?.amount || 0;

              const userItemAmount = state.userInventory[itemId]?.amount || 0;

              if (!(isAdmin || isSysadmin) && itemAmount > 0 && userItemAmount < itemAmount) {
                if (itemAmount - userItemAmount > userGroupAmount) {
                  formRef.current.setFieldError(
                    `doctor.item.${itemId}`,
                    t('validation.insufficientInventory'),
                  );
                  break omegalul;
                }
              }
              if (itemAmount !== 0) {
                userGroupAmount -= Math.max(itemAmount - userItemAmount, 0);
                distributions.push({
                  programId: program!.id,
                  groupId: state.groupId,
                  itemId,
                  userType: UserType.DOCTOR,
                  userIds: [doctor.userId],
                  distributionType:
                    itemAmount > 0 ? DistributionType.ADD_UNITS : DistributionType.REMOVE_UNITS,
                  inventoryType: state.inventoryType as InventoryType,
                  amount: Math.abs(itemAmount),
                  filter: '',
                });
              }
            }
          }

          await Promise.all(
            distributions.map(async (d) => {
              await distributeInventory.execute({
                ...d,
              });
            }),
          );

          toast.success(t('msg.successToDistributeInventory'), {
            onOpen: showBackdrop,
            onClose: hideBackdrop,
          });
          formRef.current.reset();
          setState(defaultState);
          reloadDoctor();
        } catch (err) {
          console.log(err);
          toast.error(t('err.failedToDistributeInventory'), {
            onOpen: showBackdrop,
            onClose: hideBackdrop,
          });
        } finally {
          hideLoading();
        }
      })();
    },
    [state],
  );

  const handleProductGroupChange = useCallback(
    (value: ProductGroupValues) => {
      setState({
        ...state,
        groupId: value.productId,
        groupName: value.product?.name || '',
      });
    },
    [state],
  );

  const handleExamGroupChange = useCallback(
    (value: ExamGroupSelectValues) => {
      setState({
        ...state,
        groupId: value.examGroupId,
        groupName: value.examGroupName,
      });
    },
    [state],
  );

  useEffect(
    useCallback(() => {
      (async () => {
        try {
          let items: { id: string; name: string }[] = [];
          if (state.inventoryType === InventoryType.CLINICAL_EXAMINATION) {
            items = (
              await getExamGroupExams.execute({
                examGroupId: state.groupId,
              })
            ).map((it: Exam) => ({ id: it.id, name: it.examType.name }));
          } else if (state.inventoryType === InventoryType.FIRST_MEDICINE) {
            items = await getProductPresentations.execute({
              productId: state.groupId,
            });
          } else if (state.inventoryType === InventoryType.PATIENT_PROCEDURE) {
            items = await getProductPatientProcedures.execute({
              productId: state.groupId,
            });
          }
          const res = await getDoctorInventoryAmounts.execute({
            inventory: state.inventoryType as InventoryType,
            programId: program!.id,
            groupId: state.groupId,
            userId: doctor.userId,
          });
          setState({
            ...state,
            items,
            userInventory: res.user.reduce(
              (prev, iv) => ({
                ...prev,
                [iv.itemId || iv.groupId]: iv,
              }),
              {},
            ),
            doctorInventory: res.doctor.reduce(
              (prev, iv) => ({
                ...prev,
                [iv.itemId || iv.groupId]: iv,
              }),
              {},
            ),
          });

          const formValues = res.doctor.reduce(
            (prev, iv) => ({
              ...prev,
              [`${iv.itemId ? 'item' : 'group'}`]: {
                ...prev[`${iv.itemId ? 'item' : 'group'}`],
                [`${iv.itemId || iv.groupId}`]: iv.amount,
              },
            }),
            {} as { [key: string]: { [key: string]: number } },
          );

          formRef.current.setErrors({});
          formRef.current.setFieldValue(
            `doctor.group.${state.groupId}`,
            (formValues.group ? formValues.group[state.groupId] : 0) || 0,
          );
          items.forEach((iv: Exam | Presentation) => {
            formRef.current.setFieldValue(
              `doctor.item.${iv.id}`,
              (formValues.item ? formValues.item[iv.id] : 0) || 0,
            );
          });
        } catch (err) {
          console.log(err);
        }
      })();
    }, [state, program, doctor, formRef]),
    [state.groupId],
  );

  return (
    <Box className={classes.container}>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Grid container spacing={2} direction="row">
              <Grid item xs={12} md={4} lg={2}>
                <InventoryTypeSelect
                  name="inventoryType"
                  variant="outlined"
                  fullWidth
                  onSelectedValueChange={handleInventoryTypeChange}
                />
              </Grid>
              <Grid item xs={12} md={8} lg={4}>
                {state.inventoryType === InventoryType.FIRST_MEDICINE && (
                  <ProductGroupSelect
                    getProgramProducts={getProgramProducts}
                    onSelectedValueChange={handleProductGroupChange}
                    formRef={formRef}
                  />
                )}
                {state.inventoryType === InventoryType.CLINICAL_EXAMINATION && (
                  <ExamGroupSelect
                    getProgramFollowups={getProgramFollowups}
                    getFollowupExamGroups={getFollowupExamGroups}
                    onSelectedValueChange={handleExamGroupChange}
                    formRef={formRef}
                  />
                )}
                {state.inventoryType === InventoryType.PATIENT_PROCEDURE && (
                  <ProductGroupSelect
                    getProgramProducts={getProgramProducts}
                    onSelectedValueChange={handleProductGroupChange}
                    formRef={formRef}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          {state.groupId && (
            <>
              <Grid item>
                <table>
                  <thead>
                    <tr>
                      <th />
                      <th>
                        <Typography variant="h6">Meu estoque</Typography>
                      </th>
                      <th>
                        <Typography variant="h6">Estoque do médico</Typography>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className={classes.cell}>
                        <Typography variant="h6">Grupo</Typography>
                      </td>
                    </tr>
                    <tr>
                      <td className={clsx(classes.cell, classes.bottomPadding)}>
                        {state.groupName}
                      </td>
                      <td className={classes.bottomPadding}>
                        <Typography variant="h6" align="center">
                          {state.userInventory[state.groupId]?.amount || 0}
                        </Typography>
                      </td>
                      <td className={classes.bottomPadding}>
                        <Input
                          name={`doctor.group.${state.groupId}`}
                          variant="outlined"
                          type="number"
                          inputProps={{ min: '0' }}
                          FormHelperTextProps={{
                            className: classes.helperText,
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.cell}>
                        <Typography variant="h6">Exames</Typography>
                      </td>
                    </tr>
                    {state.items.map((it) => (
                      <tr key={`${state.groupId}_${it.id}`}>
                        <td className={clsx(classes.cell, classes.bottomPadding)}>{it.name}</td>
                        <td className={classes.bottomPadding}>
                          <Typography variant="h6" align="center">
                            {state.userInventory[it.id]?.amount || 0}
                          </Typography>
                        </td>
                        <td className={classes.bottomPadding}>
                          <Input
                            name={`doctor.item.${it.id}`}
                            variant="outlined"
                            type="number"
                            inputProps={{ min: '0' }}
                            FormHelperTextProps={{
                              className: classes.helperText,
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" color="primary">
                  Salvar
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Form>
    </Box>
  );
}
