import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  medicineCard: {
    height: '200px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  medicineName: {
    color: 'rgb(34, 34, 34)',
    fontSize: 'large',
    fontWeight: 'bolder',
    marginBottom: '10px',
  },

  ruleName: {
    fontSize: 'medium',
    marginBottom: '10px',
    marginTop: '15px',
  },

  medicineDiscount: {
    color: 'rgb(199, 43, 4)',
    fontSize: 'large',
    fontWeight: 'bold',
    marginBottom: '10px',
    marginTop: '10px',
  },
});

export default useStyles;
