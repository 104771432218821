import React from 'react';
import { t } from '@psp/common';

import Breadcrumbs from '../../../components/Breadcrumbs';
import { FOLLOWUPS_ROUTE } from '../../../constants';
import FollowupListComposer from '../../../composers/FollowupListComposer';

export default function FollowupListPage(): JSX.Element {
  return (
    <>
      <Breadcrumbs title={t(`navigation.${FOLLOWUPS_ROUTE}`)} />
      <FollowupListComposer />
    </>
  );
}
