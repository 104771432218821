import React from 'react';
import { getProcedure, getProcedureAuthorizerInfo, updateProcedureStatus } from '@psp/common';

import ProcedureDetails, { ProcedureDetailsProps } from '../../components/Procedure/Details';

type Dispatchers = Pick<ProcedureDetailsProps, 'getProcedure' | 'getProcedureAuthorizerInfo' | 'updateProcedureStatus'>;

const dispatchers: Dispatchers = {
  getProcedure,
  getProcedureAuthorizerInfo,
  updateProcedureStatus,
};

export default function ProcedureDetailsComposer(): JSX.Element {
  return <ProcedureDetails {...dispatchers} />;
}
