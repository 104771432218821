import { lighten, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  input: {
    marginLeft: theme.spacing(1),
    width: '96%',
    backgroundColor: '#ffffff',
    borderRadius: theme.shape.borderRadius,
    '& .MuiOutlinedInput-root': {
      borderRadius: theme.shape.borderRadius,
      height: 38,
      '& fieldset': {
        borderColor: theme.palette.grey[400],
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '& inputCep': {
        height: 'calc(100% - 20px)',
        boxSizing: 'border-box',
      },
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 12px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
  errorText: {
    color: 'red',
    marginLeft: theme.spacing(2),
  },
}));
