import React, { useCallback, useEffect, useState } from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import clsx from 'clsx';
import { GetManagers, User, userTypesMap, t, emptyFunction } from '@psp/common';

import { useAuth } from '../../contexts/auth.context';
import Select, { SelectProps } from '../Select';
import { useStyles } from './styles';
import { useProgram } from '../../contexts/program.context';

type ManagerSelectProps = Omit<
  AutocompleteProps<User, false, false, false>,
  'children' | 'renderInput' | 'options' | 'value'
> & {
  getManagers: GetManagers;
  onSelectedValueChange?: (value: any) => void;
  value?: User | null;
  userId: string;
};

type ManagerSelectState = {
  users: User[];
};

const defaultState: ManagerSelectState = {
  users: [],
};

export default function ManagerSelect({
  className,
  getManagers,
  value,
  userId,
  onSelectedValueChange = emptyFunction,
  ...props
}: ManagerSelectProps): JSX.Element {
  const classes = useStyles();
  const { isSysadmin } = useAuth();
  const userTypes = isSysadmin ? userTypesMap.SYSADMIN : userTypesMap.ADMIN;
  const { program } = useProgram();
  const [state, setState] = useState<ManagerSelectState>({
    ...defaultState,
  });

  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState<any>(value || { name: '' });

  const handleChange = useCallback(
    (event, newValue: User | null) => {
      setSelectedValue(newValue);
      onSelectedValueChange(newValue);
    },
    [state, onSelectedValueChange],
  );

  const handleInputChange = (_event: any, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  useEffect(
    useCallback(() => {
      if (!program) return;
      (async () => {
        try {
          const users = await getManagers.execute({ programId: program.id, userId: userId || '' });
          console.log(users);
          setState({
            users,
          });
        } catch (err) {
          console.log(err);
        }
      })();
    }, [program]),
    [program],
  );

  return (
    <Autocomplete
      {...props}
      id="managerId"
      options={state.users}
      groupBy={(user) => t(`userType.${user.type}`)}
      getOptionLabel={(user) => user.name}
      getOptionSelected={(op: User, vl: User) => !!vl && op.id === vl.id}
      onChange={handleChange}
      value={selectedValue}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" size="small" label={t('manager')} />
      )}
      className={className}
    />
  );
}
