import React, { useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { parse } from 'date-fns';
import { t } from '@psp/common';

type DateInputProps = Omit<KeyboardDatePickerProps, 'value' | 'onChange'> & {
  name: string;
  optional?: boolean;
  [key: string]: any;
};

export default function DateInput({
  name,
  helperText,
  format = 'dd/MM/yyyy',
  label,
  optional = false,
  ...rest
}: DateInputProps): JSX.Element {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [value, setValue] = useState<Date | null>(null);
  const inputLabel = label && !optional ? `${label} *` : label;
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref) => (ref.value ? parse(ref.value, format, 0) : null),
      setValue: (_ref, newValue: Date | string | null) => {
        setValue(newValue ? new Date(newValue) : null);
      },
    });
  }, [fieldName, registerField]);
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        error={!!error}
        helperText={error ? t(error) : helperText}
        margin="none"
        format={format}
        defaultValue={defaultValue}
        label={inputLabel}
        {...rest}
        value={value}
        onChange={setValue}
        inputRef={inputRef}
        name={name}
        size="small"
      />
    </MuiPickersUtilsProvider>
  );
}
