import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 'calc(100vh-24px)',
  },
  footer: {
    height: 24,
    bottom: 0,
    right: 0,
    left: 0,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  span: {
    background: 'rgba(255, 255, 255, 0.3)',
  },
}));
