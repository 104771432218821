import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Container,
  Grid,
  Typography,
  Slide,
  Fade,
  Link,
  ButtonGroup,
  Button as MaterialButton,
  TextField,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import clsx from 'clsx';
import {
  GetProcedureAccredited,
  GetProcedureByCode,
  GetProcedureByCodeResponse,
  GetProgramByProcedureCode,
  Program,
  UpdateProcedurePatient,
  UpdateProcedurePatientParams,
  ValidationError,
  extractValidationErrors,
  patientConfirmationStep1Form,
  patientConfirmationStep2Form,
  t,
  ProgramModulesEnum,
} from '@psp/common';

import { useLoading } from '../../../contexts/loading.context';
import Button from '../../Button';
import CheckboxInput from '../../CheckboxInput';
import DateInput from '../../DateInput';
import DocumentInput from '../../DocumentInput';
import Footer from '../../Footer';
import GenderSelect from '../../GenderSelect';
import Input from '../../Input';
import PharmaciesMap from '../../PharmaciesMap';
import PhoneInput from '../../PhoneInput';
import MaskedPhoneInput from '../../MaskedPhoneInput';

import { useStyles } from './styles';
import ProgramTerms from '../../ProgramTerms';
import ProgramStyles from '../../ProgramStyles';

export type PatientConfirmationProps = {
  getProcedureAccredited: GetProcedureAccredited;
  getProcedureByCode: GetProcedureByCode;
  getProgramByProcedureCode: GetProgramByProcedureCode;
  updateProcedurePatient: UpdateProcedurePatient;
};

type PatientConfirmationState = {
  procedure?: GetProcedureByCodeResponse;
};

const defaultState: PatientConfirmationState = {
  procedure: undefined,
};

const slideTimeout = {
  enter: 500,
  exit: 400,
};
const fadeTimeout = {
  enter: 1000,
  exit: 200,
};

export default function PatientConfirmation({
  getProcedureAccredited,
  getProcedureByCode,
  getProgramByProcedureCode,
  updateProcedurePatient,
}: PatientConfirmationProps): JSX.Element {
  const classes = useStyles();
  const formRef = useRef({} as any);
  const registrationFormRef = useRef({} as any);
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));
  const [step, setStep] = useState(0);
  const [state, setState] = useState<PatientConfirmationState>(defaultState);
  const [showTerms, setShowTerms] = useState(false);
  const { code } = useParams<{ code: string }>();
  const { isLoading, showLoading, hideLoading, showBackdrop, hideBackdrop } = useLoading();
  const [loadedProgram, setLoadedProgram] = useState<Program | Record<string, never> | undefined>(
    undefined,
  );

  if (!code) {
    return <div>token invalido</div>;
  }

  const handleOpenTerms = () => {
    setShowTerms(true);
  };

  const handleCloseTerms = () => {
    setShowTerms(false);
  };

  const handleConfirmRegistrationClick = useCallback(() => {
    setStep(1);
  }, [setStep]);

  const handleFindPharmaciesClick = useCallback(() => {
    setStep(3);
  }, [setStep]);

  const handleBackClick = useCallback(() => {
    setStep(step - 1);
  }, [step]);

  const handleSubmit = useCallback(
    (data) => {
      showLoading();
      (async () => {
        try {
          const req = (await patientConfirmationStep1Form.validate(data, {
            abortEarly: false,
          })) as { cpf: string };
          const procedure = await getProcedureByCode.execute({ code, ...req });
          registrationFormRef.current.setData({
            ...procedure.patient,
            acceptSms: true,
            acceptEmail: true,
            acceptPhone: true,
            acceptTerms: true,
          });
          setState({
            procedure,
          });
          setStep(2);
        } catch (err) {
          console.log(err);
          if (err.message === 'patientProcedureNotFound') {
            toast.warning(t('err.patientProcedureNotFound'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } else if (err instanceof ValidationError) {
            formRef.current.setErrors(extractValidationErrors(err));
          } else {
            toast.error(t('err.failedToFindPatientProcedure'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          }
        } finally {
          hideLoading();
        }
      })();
    },
    [code],
  );

  const handleRegistrationSubmit = useCallback(
    (data) => {
      showLoading();
      (async () => {
        try {
          const req = (await patientConfirmationStep2Form.validate(data, {
            abortEarly: false,
          })) as any;
          await updateProcedurePatient.execute({
            code,
            patient: req,
          });
          setStep(3);
        } catch (err) {
          if (err instanceof ValidationError) {
            registrationFormRef.current.setErrors(extractValidationErrors(err));
          } else {
            toast.warning(t('err.failedToUpdateProcedurePatient'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          }
        } finally {
          hideLoading();
        }
      })();
    },
    [code],
  );

  useEffect(() => {
    if (code) {
      (async () => {
        try {
          const res = await getProgramByProcedureCode.execute({
            code,
          });
          setLoadedProgram(res);
        } catch (err) {
          console.log(err);
        }
      })();
    } else {
      setLoadedProgram({});
    }
  }, [code]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    if (!loadedProgram) return;
    console.log(loadedProgram);
    if (loadedProgram?.modules.includes(ProgramModulesEnum.DOCTOR_BENEFITS)) {
      setStep(3);
      if (loadedProgram?.consentTerm) handleOpenTerms();
    }
  }, [loadedProgram]);

  if (!loadedProgram) {
    return <div />;
  }

  return (
    <Container className={classes.container}>
      <ProgramStyles program={loadedProgram} />
      <Grid
        className={clsx(classes.gridContainer, { [classes.gridContainerFull]: step === 3 })}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Slide in={step === 0} timeout={slideTimeout}>
          <div className={classes.animatedContainer}>
            <Fade in={step === 0} timeout={fadeTimeout}>
              <Grid
                container
                item
                direction="column"
                justify="center"
                className={classes.fullHeight}
              >
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                    className={classes.imgContainer}
                  >
                    <Grid item>
                      <div className="patient-confirmation-client-image" />
                    </Grid>
                    <Grid item>
                      <div className="patient-confirmation-program-image" />
                    </Grid>
                  </Grid>
                  <Typography variant="h1" color="primary" className={classes.h1}>
                    {t('patientConfirmation.firstStep.title')}
                  </Typography>
                  <Typography variant="h1" color="primary" className={classes.h1}>
                    {t('patientConfirmation.firstStep.titleLine2')}
                  </Typography>
                  <br />
                  <Typography variant="h2" className={classes.h2}>
                    {t('patientConfirmation.firstStep.subtitle')}
                  </Typography>
                  <br />
                  <Typography variant="h2" className={classes.h2}>
                    {t('patientConfirmation.firstStep.subtitle2')}
                  </Typography>
                  <br />
                  <br />
                  <Grid
                    container
                    spacing={isXS ? 2 : 8}
                    justify="center"
                    alignItems="center"
                    direction="row"
                  >
                    <Grid item xs={12} sm={6} container justify={isXS ? 'center' : 'flex-end'}>
                      <div className={classes.searchButton}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          type="submit"
                          onClick={handleConfirmRegistrationClick}
                          className={classes.searchButton}
                        >
                          {t('confirmRegistration')}
                        </Button>
                        <Typography variant="body2" className={classes.caption}>
                          {t('patientConfirmation.firstStep.confirmRegistrationCaption')}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} container justify={isXS ? 'center' : 'flex-start'}>
                      <div className={classes.searchButton}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          type="submit"
                          onClick={handleFindPharmaciesClick}
                          className={classes.searchButton}
                          fullWidth
                        >
                          {t('findPharmacies')}
                        </Button>
                        <Typography variant="body2" className={classes.caption}>
                          {t('patientConfirmation.firstStep.findPharmaciesCaption')}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Fade>
          </div>
        </Slide>
        <Slide in={step === 1} timeout={slideTimeout}>
          <div className={classes.animatedContainer}>
            <Fade in={step === 1} timeout={fadeTimeout}>
              <Grid
                container
                item
                direction="column"
                justify="center"
                className={classes.fullHeight}
              >
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                    className={classes.imgContainer}
                  >
                    <Grid item>
                      <div className="patient-confirmation-client-image" />
                    </Grid>
                    <Grid item>
                      <div className="patient-confirmation-program-image" />
                    </Grid>
                  </Grid>
                  <Typography variant="h1" color="primary" className={classes.h1}>
                    {t('patientConfirmation.secondStep.title')}
                  </Typography>
                  <Typography variant="h1" color="primary" className={classes.h1}>
                    {t('patientConfirmation.firstStep.titleLine2')}
                  </Typography>
                  <br />
                  <Typography variant="h2" className={classes.h2}>
                    {t('patientConfirmation.secondStep.subtitle')}
                  </Typography>
                  <br />
                  <br />
                  <Form ref={formRef} onSubmit={handleSubmit}>
                    <Grid container spacing={2} alignItems="center" direction="column">
                      <Grid item xs={12} sm="auto">
                        <Input
                          name="cpf"
                          variant="outlined"
                          label={t('cpf')}
                          autoComplete="off"
                          InputProps={{
                            inputComponent: DocumentInput as any,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm="auto">
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          type="submit"
                          className={classes.searchButton}
                        >
                          {t('continue')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                </Grid>
              </Grid>
            </Fade>
          </div>
        </Slide>
        <Slide in={step === 2} timeout={slideTimeout}>
          <div className={classes.animatedContainer}>
            <Fade in={step === 2} timeout={fadeTimeout}>
              <Grid container item direction="column" className={classes.fullHeight}>
                <Grid item className={classes.padding}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                    className={classes.imgContainer}
                  >
                    <Grid item>
                      <div className="patient-confirmation-client-image" />
                    </Grid>
                    <Grid item>
                      <div className="patient-confirmation-program-image" />
                    </Grid>
                  </Grid>
                  <Typography
                    variant="h1"
                    color="primary"
                    className={clsx(classes.h1, classes.left)}
                  >
                    {t('patientConfirmation.thirdStep.title')}
                  </Typography>
                  <Typography variant="h2" className={clsx(classes.h2, classes.left)}>
                    {t('patientConfirmation.thirdStep.subtitle')}
                  </Typography>
                  <br />
                  <Form
                    ref={registrationFormRef}
                    onSubmit={handleRegistrationSubmit}
                    className={classes.registrationForm}
                  >
                    <Grid container spacing={2} direction="column">
                      <Grid item xs={12} sm="auto">
                        <Input
                          name="cpf"
                          variant="outlined"
                          label={t('cpf')}
                          fullWidth
                          disabled
                          InputProps={{
                            inputComponent: DocumentInput as any,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm="auto">
                        <Input
                          name="name"
                          variant="outlined"
                          label={t('name')}
                          fullWidth
                          autoComplete="off"
                        />
                      </Grid>
                      <Grid item xs={12} sm="auto">
                        <GenderSelect
                          name="gender"
                          variant="outlined"
                          label={t('sex')}
                          displayEmpty
                          fullWidth
                          disabled={isLoading}
                        />
                      </Grid>
                      <Grid item xs={12} sm="auto">
                        <DateInput
                          name="birthDate"
                          inputVariant="outlined"
                          label={t('birthDate')}
                          format="dd/MM/yyyy"
                          fullWidth
                          disabled={isLoading}
                          autoComplete="off"
                        />
                      </Grid>
                      <Grid item xs={12} sm="auto">
                        <Input
                          name="email"
                          variant="outlined"
                          label={t('email')}
                          fullWidth
                          disabled={isLoading}
                          autoComplete="off"
                        />
                      </Grid>
                      <Grid item xs={12} sm="auto">
                        <Input
                          name="phone"
                          variant="outlined"
                          label={t('cellphone')}
                          fullWidth
                          disabled={isLoading}
                          InputProps={{
                            inputComponent: MaskedPhoneInput as any,
                            autoComplete: 'off',
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm="auto">
                        <CheckboxInput
                          name="acceptSms"
                          label={t('patientConfirmation.thirdStep.acceptContactSms')}
                          disabled={isLoading}
                        />
                        <br />
                        <CheckboxInput
                          name="acceptEmail"
                          label={t('patientConfirmation.thirdStep.acceptContactEmail')}
                          disabled={isLoading}
                        />
                        <br />
                        <CheckboxInput
                          name="acceptPhone"
                          label={t('patientConfirmation.thirdStep.acceptContactPhone')}
                          disabled={isLoading}
                        />
                      </Grid>
                      <Grid item xs={12} sm="auto">
                        <Typography variant="body1">
                          <Button variant="text" onClick={handleOpenTerms}>
                            {t('patientConfirmation.thirdStep.readTerms')}
                          </Button>
                        </Typography>
                        <ProgramTerms
                          variant="minimal"
                          open={showTerms && step === 2}
                          onClose={handleCloseTerms}
                          terms={loadedProgram?.consentTerm || ''}
                        />
                      </Grid>
                      <Grid item xs={12} sm="auto">
                        <CheckboxInput
                          name="acceptTerms"
                          label={t('patientConfirmation.thirdStep.acceptTerms')}
                        />
                      </Grid>
                      <Grid container item xs={12} sm="auto" spacing={2}>
                        <Grid item>
                          <Button variant="contained" color="primary" size="large" type="submit">
                            {t('confirm')}
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button variant="text" size="large" onClick={handleBackClick}>
                            {t('goBack')}
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm="auto">
                        <Footer className={clsx({ [classes.hidden]: [0, 1, 3].includes(step) })} />
                      </Grid>
                    </Grid>
                  </Form>
                </Grid>
              </Grid>
            </Fade>
          </div>
        </Slide>
        <Slide in={step === 3} timeout={slideTimeout}>
          <div className={classes.animatedContainer}>
            <ProgramTerms
              open={showTerms && step === 3}
              variant="full"
              onClose={handleCloseTerms}
              terms={loadedProgram?.consentTerm || ''}
            />
            <Fade in={step === 3} timeout={fadeTimeout}>
              <Grid
                container
                item
                direction="column"
                justify="center"
                className={classes.fullHeight}
              >
                <Grid item className={classes.padding}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                    className={classes.imgContainer}
                  >
                    <Grid item>
                      <div className="patient-confirmation-client-image" />
                    </Grid>
                    <Grid item>
                      <div className="patient-confirmation-program-image" />
                    </Grid>
                  </Grid>
                  <Typography variant="h1" color="primary" className={classes.h1}>
                    {t('patientConfirmation.fourthStep.title')}
                  </Typography>
                  <Typography variant="h2" className={classes.h2}>
                    {t('patientConfirmation.fourthStep.subtitle')}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <PharmaciesMap getProcedureAccredited={getProcedureAccredited} />
                </Grid>
              </Grid>
            </Fade>
          </div>
        </Slide>
      </Grid>
      <Footer
        className={clsx({ [classes.hidden]: step === 2, [classes.footerAbsolute]: step === 3 })}
      />
    </Container>
  );
}
