import React from 'react';

import {
  getPatientsMedicationsByCRMFromPatientCare,
} from '@psp/common';

import MedicationAdminList, {
  MedicationAdminListProps,
} from '../../components/Medication/List';

const dispatchers: Pick<MedicationAdminListProps, 'getPatientsMedicationsByCRMFromPatientCare'> = {
  getPatientsMedicationsByCRMFromPatientCare,
};

export default function MedicationAdminListComposer(): JSX.Element {
  return <MedicationAdminList {...dispatchers} />;
}
