import React from 'react';
import { ProcedureType, t } from '@psp/common';

import Breadcrumbs from '../../../../components/Breadcrumbs';
import ProcedureListComposer from '../../../../composers/ProcedureListComposer';
import { TRAVEL_REQUESTS_DETAIL_ROUTE, TRAVEL_REQUESTS_ROUTE } from '../../../../constants';

export default function TravelRequestsListPage(): JSX.Element {
  return (
    <>
      <Breadcrumbs title={t(`navigation.${TRAVEL_REQUESTS_ROUTE}`)} />
      <ProcedureListComposer
        type={ProcedureType.TRAVEL}
        viewRoute={TRAVEL_REQUESTS_DETAIL_ROUTE}
      />
    </>
  );
}
