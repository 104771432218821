import React from 'react';
import { t } from '@psp/common';

import Breadcrumbs from '../../../components/Breadcrumbs';
import DoctorDetailsComposer from '../../../composers/DoctorDetailsComposer';
import { DOCTOR_DETAIL_ROUTE } from '../../../constants';

export default function DoctorDetailsPage(): JSX.Element {
  return (
    <>
      <Breadcrumbs title={t(`navigation.${DOCTOR_DETAIL_ROUTE}`)} />
      <DoctorDetailsComposer />
    </>
  );
}
