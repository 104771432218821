import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  Link as MaterialLink,
} from '@material-ui/core';
import { Pill, HeartOutline, HeartPulse, ChevronRight } from 'mdi-material-ui';
import { Link } from 'react-router-dom';
import {
  GetProgramPresentations,
  GetProceduresCount,
  Presentation,
  t,
  ProcedureCount,
  ProcedureType,
  ProgramModules,
} from '@psp/common';

import { useAuth } from '../../../contexts/auth.context';
import { useProgram } from '../../../contexts/program.context';
import PaperContent from '../../PaperContent';
import {
  EXAM_REQUEST_ROUTE,
  MEDICATION_RELEASE_ROUTE,
  PATIENT_PROCEDURE_REQUEST_ROUTE,
  PROCEDURES_EXAMS_ROUTE,
  PROCEDURES_FIRST_MEDICINE_ROUTE,
  PROCEDURES_PATIENT_PROCEDURES_ROUTE,
} from '../../../constants';

import { useStyles } from './styles';
import PaperTitle from '../../PaperTitle';

export type DoctorDashboardProps = {
  getProgramPresentations: GetProgramPresentations;
  getProceduresCount: GetProceduresCount;
};

export default function DoctorDashboard({
  getProgramPresentations,
  getProceduresCount,
}: DoctorDashboardProps): JSX.Element {
  const { program, modulesMap } = useProgram();
  const { modulesMap: userModulesMap } = useAuth();
  const classes = useStyles();
  const [presentations, setPresentations] = useState<Presentation[]>([]);
  const [proceduresCount, setProceduresCount] = useState<ProcedureCount[]>([]);
  const [modules, setModules] = useState<ProgramModules>({} as any);

  useEffect(
    useCallback(() => {
      if (!program) return;
      (async () => {
        setModules(
          (Object.keys(modulesMap).reduce(
            (m: any, c: string) => ({
              ...m,
              [c]: (modulesMap as any)[c] && (userModulesMap as any)[program.id][c],
            }),
            {},
          ) as unknown) as ProgramModules,
        );
        try {
          const res = await getProgramPresentations.execute({ programId: program.id });
          setPresentations(res);
        } catch (err) {
          console.log(err);
          setPresentations([]);
        }

        try {
          const res = await getProceduresCount.execute({ programId: program.id });
          setProceduresCount(res);
        } catch (err) {
          console.log(err);
          setProceduresCount([]);
        }
      })();
    }, [program]),
    [program],
  );

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="h6">
          <strong>{t('quickAccess')}</strong>
        </Typography>
        <br />
      </Grid>
      <Grid item xs>
        <Grid container spacing={2} direction="row">
          {modules.firstMedicine && (
            <Grid item xs={12} sm={4} xl={3} className={classes.link}>
              <Paper>
                <PaperContent className={classes.paperContent}>
                  <ListItem
                    component={Link}
                    to={PROCEDURES_FIRST_MEDICINE_ROUTE}
                    className={classes.link}
                  >
                    <ListItemIcon>
                      <Pill className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText
                      primary={t(`navigation.${PROCEDURES_FIRST_MEDICINE_ROUTE}`)}
                      primaryTypographyProps={{ className: classes.text }}
                    />
                    {proceduresCount.find((c) => c.type === ProcedureType.FIRST_MEDICINE) && (
                      <Paper className={classes.badge}>
                        <PaperContent className={classes.badgeContent}>
                          <Typography variant="h5" component="span" className={classes.badgeText}>
                            {
                              proceduresCount.find((c) => c.type === ProcedureType.FIRST_MEDICINE)
                                ?.count
                            }
                          </Typography>
                        </PaperContent>
                      </Paper>
                    )}
                  </ListItem>
                </PaperContent>
              </Paper>
            </Grid>
          )}
          {modules.clinicalExamination && (
            <Grid item xs={12} sm={4} xl={3}>
              <Paper>
                <PaperContent className={classes.paperContent}>
                  <ListItem component={Link} to={PROCEDURES_EXAMS_ROUTE} className={classes.link}>
                    <ListItemIcon>
                      <HeartOutline className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText
                      primary={t(`navigation.${PROCEDURES_EXAMS_ROUTE}`)}
                      primaryTypographyProps={{ className: classes.text }}
                    />
                    {proceduresCount.find((c) => c.type === ProcedureType.CLINICAL_EXAMINATION) && (
                      <Paper className={classes.badge}>
                        <PaperContent className={classes.badgeContent}>
                          <Typography variant="h5" component="span" className={classes.badgeText}>
                            {
                              proceduresCount.find(
                                (c) => c.type === ProcedureType.CLINICAL_EXAMINATION,
                              )?.count
                            }
                          </Typography>
                        </PaperContent>
                      </Paper>
                    )}
                  </ListItem>
                </PaperContent>
              </Paper>
            </Grid>
          )}
          {modules.patientProcedure && (
            <Grid item xs={12} sm={4} xl={3}>
              <Paper>
                <PaperContent className={classes.paperContent}>
                  <ListItem
                    component={Link}
                    to={PROCEDURES_PATIENT_PROCEDURES_ROUTE}
                    className={classes.link}
                  >
                    <ListItemIcon>
                      <HeartPulse className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText
                      primary={t(`navigation.${PROCEDURES_PATIENT_PROCEDURES_ROUTE}`)}
                      primaryTypographyProps={{ className: classes.text }}
                    />
                    {proceduresCount.find((c) => c.type === ProcedureType.PATIENT_PROCEDURE) && (
                      <Paper className={classes.badge}>
                        <PaperContent className={classes.badgeContent}>
                          <Typography variant="h5" component="span" className={classes.badgeText}>
                            {
                              proceduresCount.find(
                                (c) => c.type === ProcedureType.PATIENT_PROCEDURE,
                              )?.count
                            }
                          </Typography>
                        </PaperContent>
                      </Paper>
                    )}
                  </ListItem>
                </PaperContent>
              </Paper>
            </Grid>
          )}
        </Grid>
        <br />
        <br />
      </Grid>
      <Grid item xs>
        <Grid container spacing={2} direction="row">
          {modules.firstMedicine && (
            <Grid item xs={12} sm={6} xl={3}>
              <Paper className="dashboard-box">
                <PaperTitle title={t(`navigation.${MEDICATION_RELEASE_ROUTE}`)}>
                  <MaterialLink
                    component={Link}
                    to={MEDICATION_RELEASE_ROUTE}
                    className={classes.boxLink}
                    underline="none"
                  >
                    &nbsp;&nbsp;
                    {t('request')}
                    <ChevronRight />
                  </MaterialLink>
                </PaperTitle>
                <PaperContent>
                  <Grid container spacing={2} direction="row" alignItems="center">
                    <Grid item xs>
                      <Typography variant="body1" className={classes.boxContent}>
                        Produtos participantes:
                        <br />
                        {presentations.map((p) => (
                          <span key={p.id}>
                            <strong>{p.name}</strong>
                            <br />
                          </span>
                        ))}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <img className={classes.moduleCardImg} src="/img_exams.png" alt="Exames" />
                    </Grid>
                  </Grid>
                </PaperContent>
              </Paper>
            </Grid>
          )}
          {modules.clinicalExamination && (
            <Grid item xs={12} sm={6} xl={3}>
              <Paper className="dashboard-box">
                <PaperTitle title={t(`navigation.${EXAM_REQUEST_ROUTE}`)}>
                  <MaterialLink
                    component={Link}
                    to={EXAM_REQUEST_ROUTE}
                    className={classes.boxLink}
                    underline="none"
                  >
                    &nbsp;&nbsp;
                    {t('request')}
                    <ChevronRight />
                  </MaterialLink>
                </PaperTitle>
                <PaperContent>
                  <Grid container spacing={2} direction="row" alignItems="center">
                    <Grid item xs>
                      <Typography variant="body1" className={classes.boxContent}>
                        {t('requestExamBox')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <img className={classes.moduleCardImg} src="/img_exams.png" alt="Exames" />
                    </Grid>
                  </Grid>
                </PaperContent>
              </Paper>
            </Grid>
          )}
          {modules.patientProcedure && (
            <Grid item xs={12} sm={6} xl={3}>
              <Paper className="dashboard-box">
                <PaperTitle title={t(`navigation.${PATIENT_PROCEDURE_REQUEST_ROUTE}`)}>
                  <MaterialLink
                    component={Link}
                    to={PATIENT_PROCEDURE_REQUEST_ROUTE}
                    className={classes.boxLink}
                    underline="none"
                  >
                    &nbsp;&nbsp;
                    {t('request')}
                    <ChevronRight />
                  </MaterialLink>
                </PaperTitle>
                <PaperContent>
                  <Grid container spacing={2} direction="row" alignItems="center">
                    <Grid item xs>
                      <Typography variant="body1" className={classes.boxContent}>
                        {t('requestProcedureBox')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <img
                        className={classes.moduleCardImg}
                        src="/img_procedures.png"
                        alt="Exames"
                      />
                    </Grid>
                  </Grid>
                </PaperContent>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
