import React, { useState, useCallback } from 'react';
import {
  AppBar,
  Avatar,
  Toolbar,
  Typography,
  IconButton,
  Divider,
  Box,
  Button,
  useMediaQuery,
  Fab,
  useTheme,
  Link as MaterialLink,
  Grid,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import { Menu as MenuIcon, AddSharp, Person, Notifications, ChevronLeft } from '@material-ui/icons';
import clsx from 'clsx';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { t } from '@psp/common';

import { PASSWORD_CHANGE_ROUTE, LOGOUT_ROUTE, USER_EMAIL_CHANGE_ROUTE } from '../../constants';
import RemoveAccountDialogComposer from '../../composers/RemoveAccountDialogComposer';
import { useAuth } from '../../contexts/auth.context';
import ProgramSwitcher from '../ProgramSwitcher';
import { useStyles } from './styles';
import { useProgram } from '../../contexts/program.context';

type NavbarProps = {
  isDrawerOpen?: boolean;
  onDrawerOpen?: () => void;
  onDrawerClose?: () => void;
};

export default function Navbar({
  isDrawerOpen,
  onDrawerOpen,
  onDrawerClose,
}: NavbarProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));
  // const { pathname } = useLocation();
  const [menu, setMenu] = useState(null);
  const [showOptout, setShowOptout] = useState(false);
  const { user, isAdmin, isSysadmin } = useAuth();
  const { program } = useProgram();
  const { push } = useHistory();

  const handleMenuOpen = (event: any) => {
    setMenu(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenu(null);
  };

  const handleChangeEmailClick = () => {
    push(USER_EMAIL_CHANGE_ROUTE);
  };

  const handleChangePasswordClick = () => {
    window.location.href = PASSWORD_CHANGE_ROUTE;
  };

  const handleOptoutClick = () => {
    handleMenuClose();
    setShowOptout(true);
  };

  const handleOptoutClose = () => {
    setShowOptout(false);
  };

  const handleLogoutClick = useCallback(() => {
    if (program && program.slug) {
      window.location.href = `${LOGOUT_ROUTE}?program=${program.slug}`;
    } else {
      window.location.href = LOGOUT_ROUTE;
    }
  }, [program]);

  return (
    <AppBar
      color="default"
      elevation={0}
      className={clsx(classes.appBar, 'navbar-container', {
        [classes.appBarShift]: isDrawerOpen,
      })}
    >
      <Box className={classes.container}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            onClick={onDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: isDrawerOpen,
            })}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.flex}>
            {/* <IconButton className={classes.navItem}>
              <Notifications />
            </IconButton> */}
            {!isXS && (
              <>
                <Typography variant="h1" className={classes.appName}>
                  {' '}
                  {t('appName')}
                  {' '}
                </Typography>
                <div className={clsx('navbar-client-image')} />
                <div className={classes.flexGrow} />
                <ProgramSwitcher
                  className={clsx(classes.programSwitcher, {
                    [classes.programSwitcherShift]: !isDrawerOpen,
                    [classes.hide]: isXS,
                  })}
                />
              </>
            )}
            <List disablePadding className={classes.navUserList}>
              <ListItem
                button
                dense
                disableGutters
                onClick={handleMenuOpen}
                className={classes.navUserButton}
                aria-haspopup="true"
                aria-controls="user-menu"
              >
                <ListItemAvatar>
                  <Avatar>
                    <Person />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={user.name}
                  secondary={user.email}
                  className={classes.navUserButtonText}
                />
              </ListItem>
            </List>
            <Menu
              id="user-menu"
              anchorEl={menu}
              open={Boolean(menu)}
              keepMounted
              onClose={handleMenuClose}
            >
              {!(isAdmin || isSysadmin) && (<MenuItem onClick={handleChangeEmailClick}>{t('changeEmail')}</MenuItem>)}
              <MenuItem onClick={handleChangePasswordClick}>{t('changePassword')}</MenuItem>
              <MenuItem onClick={handleOptoutClick}>{t('deleteAccount')}</MenuItem>
              <MenuItem onClick={handleLogoutClick}>{t('logout')}</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </Box>
      <Divider />
      <IconButton
        className={clsx(classes.drawerToggleButton, 'navbar-drawer-toggle', {
          [classes.hide]: !isDrawerOpen,
        })}
        color="secondary"
        onClick={onDrawerClose}
      >
        <ChevronLeft />
      </IconButton>
      <RemoveAccountDialogComposer open={showOptout} onClose={handleOptoutClose} />
    </AppBar>
  );
}
