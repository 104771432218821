import React from 'react';
import { t } from '@psp/common';

import Breadcrumbs from '../../../components/Breadcrumbs';
import { FOLLOWUP_REGISTER_ROUTE } from '../../../constants';
import FollowupRegisterComposer from '../../../composers/FollowupRegisterComposer';

export default function FollowupRegisterPage(): JSX.Element {
  return (
    <>
      <Breadcrumbs title={t(`navigation.${FOLLOWUP_REGISTER_ROUTE}`)} />
      <FollowupRegisterComposer />
    </>
  );
}
