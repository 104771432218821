import React from 'react';
import {
  getClientProgramsPaged,
  deleteProgram,
} from '@psp/common';

import ProgramList, { ProgramListProps } from '../../components/Program/List';

const dispatchers: Pick<ProgramListProps,
  'getClientProgramsPaged'
  | 'deleteProgram'
> = {
  getClientProgramsPaged,
  deleteProgram,
};

export default function ProgramListComposer(): JSX.Element {
  return <ProgramList {...dispatchers} />;
}
