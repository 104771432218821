import React from 'react';

import {
  getProgramPresentations,
  getProceduresCount,
} from '@psp/common';

import Dashboard, { DashboardProps } from '../../components/Dashboard';

const dispatchers: DashboardProps = {
  getProgramPresentations,
  getProceduresCount,
};

export default function DashboardComposer(): JSX.Element {
  return <Dashboard {...dispatchers} />;
}
