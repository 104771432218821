import React, { useCallback, useEffect, useState } from 'react';
import { MenuItem } from '@material-ui/core';

import {
  Pathology,
  GetProgramPathologies,
  ProcedureFieldType,
  t,
} from '@psp/common';

import createDomPurify from 'dompurify';
import Input from '../Input';
import { useStyles } from './styles';
import { useLoading } from '../../contexts/loading.context';
import MaskedPhoneInput from '../MaskedPhoneInput';
import DateInput from '../DateInput';
import Select from '../Select';
import CheckboxInput from '../CheckboxInput';
import CheckboxSelect from '../CheckboxSelect';
import { useProgram } from '../../contexts/program.context';

export type DynamicProcedureField = {
  name: string;
  active: boolean;
  fieldType: string;
  viewType?: string;
  max?: number;
  min?: number;
  mask?: (string | RegExp)[];
  options?: any;
  onValueChange?: (value: any) => void;
  optional?: boolean;
  getProgramPathologies: GetProgramPathologies;
  [key: string]: any;
};

const domPurify = createDomPurify(window);

export default function DynamicProcedureField({
  name,
  mask,
  fieldType,
  options,
  max,
  helperText,
  onValueChange,
  optional = false,
  label,
  getProgramPathologies,
  ...rest
}: DynamicProcedureField): JSX.Element {
  const classes = useStyles();
  const { isLoading, showLoading, hideLoading } = useLoading();
  const { program } = useProgram();
  const [formatedOptions, setFormatedOptions] = useState<string[] | { key: string, value: string}[]>(options ? options.split(';') : []);
  const yesNoOptions: any[] = [
    {
      key: 'Sim',
      value: true,
    },
    {
      key: 'Não',
      value: false,
    },
  ];

  useEffect(() => {
    (async () => {
      if (fieldType === ProcedureFieldType.PATHOLOGYSELECT) {
        try {
          showLoading();
          const acceptedPathologies = options ? options.split(';').filter((o: string) => !o.startsWith('!')).map((o: string) => o.toLowerCase()) : [];
          const rejectedPathologies = options ? options.split(';').filter((o: string) => o.startsWith('!')).map((o: string) => o.toLowerCase().substring(1)) : [];

          let pathologies = await getProgramPathologies.execute({ programSlug: program?.slug ?? '' });

          if (acceptedPathologies.length) {
            pathologies = pathologies.filter(
              (p) => acceptedPathologies.includes(p.description.toLowerCase()),
            );
          }

          if (rejectedPathologies.length) {
            pathologies = pathologies.filter(
              (p) => !rejectedPathologies.includes(p.description.toLowerCase()),
            );
          }

          setFormatedOptions(
            pathologies.map((p: Pathology) => ({ key: p.idPathology, value: p.description })),
          );
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      } else if (options) {
        setFormatedOptions(options.split(';'));
      }
    })();
  }, [fieldType, options]);

  return (
    <>
      {fieldType === ProcedureFieldType.TEXT && (
        <Input
          name={name}
          maxLength={max}
          variant="outlined"
          label={label}
          optional={optional}
          className={classes.input}
          disabled={isLoading}
          autoComplete="nope"
        />
      )}
      {fieldType === ProcedureFieldType.CELLPHONE && (
        <Input
          name={name}
          variant="outlined"
          label={label}
          optional={optional}
          className={classes.input}
          disabled={isLoading}
          autoComplete="nope"
          InputProps={{
            inputComponent: MaskedPhoneInput as any,
          }}
        />
      )}
      {fieldType === ProcedureFieldType.DATE && (
        <DateInput
          name={name}
          inputVariant="outlined"
          label={t(label)}
          optional={optional}
          format="dd/MM/yyyy"
          className={classes.input}
          disabled={isLoading}
        />
      )}
      {fieldType === ProcedureFieldType.SELECT && (
        <Select
          label={t(label)}
          variant="outlined"
          optional={optional}
          name={name}
          fullWidth
          disabled={isLoading}
        >
          {(formatedOptions as string[]).map((eg: any) => (
            <MenuItem key={eg} value={eg}>
              {t(eg.toLowerCase())}
            </MenuItem>
          ))}
        </Select>
      )}
      {fieldType === ProcedureFieldType.MULTIPLESELECT && (
        <CheckboxSelect
          name={name}
          label={t(label)}
          items={(formatedOptions as string[]).map((eg: any) => ({
            id: eg, name: t(eg.toLowerCase()),
          }))}
          style={{ size: 'small' }}
          multiple
        />
      )}
      {fieldType === ProcedureFieldType.CHECKBOX && (
        <CheckboxInput
          name={name}
          label={(
            <span
              dangerouslySetInnerHTML={{ __html: domPurify.sanitize(options) }}
            />
      )}
          disabled={isLoading}
        />
      )}
      {fieldType === ProcedureFieldType.YESNO && (
        <Select
          label={label}
          variant="outlined"
          name={name}
          fullWidth
          disabled={isLoading}
        >
          {yesNoOptions.map((eg: any) => (
            <MenuItem key={eg.key} value={eg.value}>
              {eg.key}
            </MenuItem>
          ))}
        </Select>
      )}
      {fieldType === ProcedureFieldType.PATHOLOGYSELECT && (
        <Select
          label={t(label)}
          variant="outlined"
          optional={optional}
          name={name}
          fullWidth
          disabled={isLoading}
        >
          {(formatedOptions as { key: string, value: string}[]).map((eg) => (
            <MenuItem key={eg.key} value={eg.key}>
              {eg.value}
            </MenuItem>
          ))}
        </Select>
      )}
    </>
  );
}
