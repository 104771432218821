import React, { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Typography } from '@material-ui/core';
import { Document, Page, pdfjs } from 'react-pdf';
import { useField } from '@unform/core';
import { t } from '@psp/common';

import { useStyles } from './styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export type Base64FileUploadInputProps = {
  name: string;
  dialogMessage?: string;
};

const getBase64 = (file: File) => new Promise<string>((resolve, reject) => {
  const reader = new FileReader();

  reader.readAsDataURL(file);

  reader.onload = () => {
    if (reader.result) {
      console.log('File read successfully:', reader.result);
      resolve(reader.result as string);
    } else {
      console.error('File read failed or result is empty');
    }
  };

  reader.onerror = (error) => {
    console.error('Error reading file:', error);
    reject(error);
  };
});

type Base64FileUploadInputState = {
  buffer: string;
  contentType: string;
  fileName: string;
};

const Base64FileUploadInput = forwardRef(
  ({ name, dialogMessage }: Base64FileUploadInputProps, ref): JSX.Element => {
    const { fieldName, registerField } = useField(name);
    const [error, setError] = useState('');
    const [file, setFile] = useState<Base64FileUploadInputState | undefined>(undefined);
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (event?.currentTarget?.files?.length
        && (event?.currentTarget?.files[0]?.size / 1024) >= 1
        && (event?.currentTarget?.files[0]?.size / 1024) <= 11000) {
        const newFile = event.currentTarget.files[0];
        setError('');

        (async () => {
          setFile({
            buffer: (await getBase64(newFile)).split(',')[1],
            contentType: newFile.type,
            fileName: newFile.name,
          });
        })();
      } else {
        setError(t('err.fileSizeIsInvalid'));
      }
    };
    useEffect(() => {
      registerField({
        name: fieldName,
        getValue: () => file,
      });
    }, [fieldName, registerField, file]);

    const input = useRef<any>(null);
    const pdfWidth = (window.innerWidth / 100) * 55;

    const handleClickOpen = () => {
      if (dialogMessage) {
        setOpen(true);
      } else {
        // reset input value to allow the same file to be uploaded again
        input.current.value = '';
        setFile(undefined);
        input.current?.click();
      }
    };

    const handleClose = () => {
      setOpen(false);
      input.current?.click();
    };

    useImperativeHandle(ref, () => ({
      resetFileInput: () => {
        if (input.current) {
          input.current.value = '';
          setFile(undefined);
          setError('');
        }
      },
      setError: (errorMessage: string) => {
        setError(errorMessage);
      },
    }));

    return (
      /* eslint-disable-next-line */
      <>
        <input
          ref={input}
          id="contained-button-file"
          type="file"
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        <Button onClick={handleClickOpen} variant="contained" color="secondary" component="span">
          {file?.fileName || t('uploadFile')}
        </Button>
        {dialogMessage && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-description"
          classes={{ paperWidthSm: classes.documentStyle }}
        >
          <DialogContent className={classes.textStyle}>
            <DialogContentText id="alert-dialog-description">
              Modelo de arquivo para anexar
            </DialogContentText>
          </DialogContent>

          <DialogContent>

            <Document file="/Termo_de_informacao_e_adesao_do_Programa_Bem_Estar.pdf">
              {}
              <Page
                pageNumber={1}
                width={pdfWidth}
              />
            </Document>
          </DialogContent>
          <DialogContent className={classes.textStyle}>
            <DialogContentText id="alert-dialog-description">
              {dialogMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              {t('attach')}
            </Button>
          </DialogActions>
        </Dialog>
        )}
        <br />
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      </>
    );
  },
);

Base64FileUploadInput.displayName = 'Base64FileUploadInput';

export default Base64FileUploadInput;
