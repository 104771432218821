"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientExamSubStatusLabel = exports.PatientExamSubStatus = void 0;
var PatientExamSubStatus;
(function (PatientExamSubStatus) {
    PatientExamSubStatus[PatientExamSubStatus["PENDING_LABORATORY"] = 0] = "PENDING_LABORATORY";
    PatientExamSubStatus[PatientExamSubStatus["PROSPECT_LABORATORY"] = 1] = "PROSPECT_LABORATORY";
    PatientExamSubStatus[PatientExamSubStatus["PENDING_PATIENT"] = 2] = "PENDING_PATIENT";
    PatientExamSubStatus[PatientExamSubStatus["UNDER_REVIEW"] = 3] = "UNDER_REVIEW";
    PatientExamSubStatus[PatientExamSubStatus["SCHEDULED_EXAM"] = 4] = "SCHEDULED_EXAM";
    PatientExamSubStatus[PatientExamSubStatus["RESCHEDULED"] = 5] = "RESCHEDULED";
    PatientExamSubStatus[PatientExamSubStatus["RECOLLECTION"] = 6] = "RECOLLECTION";
    PatientExamSubStatus[PatientExamSubStatus["CONCLUDED"] = 7] = "CONCLUDED";
    PatientExamSubStatus[PatientExamSubStatus["EXAM_CANCELED_BY_PATIENT"] = 8] = "EXAM_CANCELED_BY_PATIENT";
    PatientExamSubStatus[PatientExamSubStatus["EXAM_CANCELED_BY_LABORATORY"] = 9] = "EXAM_CANCELED_BY_LABORATORY";
    PatientExamSubStatus[PatientExamSubStatus["EXAM_CANCELED_BY_DOCTOR"] = 10] = "EXAM_CANCELED_BY_DOCTOR";
    PatientExamSubStatus[PatientExamSubStatus["DENIED_OUT_OF_SCOPE"] = 11] = "DENIED_OUT_OF_SCOPE";
    PatientExamSubStatus[PatientExamSubStatus["DENIED_AMOUNT_NOT_APPROVED"] = 12] = "DENIED_AMOUNT_NOT_APPROVED";
    PatientExamSubStatus[PatientExamSubStatus["EXAM_FRUSTRADO"] = 13] = "EXAM_FRUSTRADO";
})(PatientExamSubStatus = exports.PatientExamSubStatus || (exports.PatientExamSubStatus = {}));
exports.PatientExamSubStatusLabel = (_a = {},
    _a[PatientExamSubStatus.PENDING_LABORATORY] = 'Pendente Laboratório',
    _a[PatientExamSubStatus.PROSPECT_LABORATORY] = 'Prospectar Laboratório',
    _a[PatientExamSubStatus.PENDING_PATIENT] = 'Pendente Paciente',
    _a[PatientExamSubStatus.UNDER_REVIEW] = 'Em análise',
    _a[PatientExamSubStatus.SCHEDULED_EXAM] = 'Exame Agendado',
    _a[PatientExamSubStatus.RESCHEDULED] = 'Reagendado',
    _a[PatientExamSubStatus.RECOLLECTION] = 'Recoleta',
    _a[PatientExamSubStatus.CONCLUDED] = 'Concluído/Finalizado',
    _a[PatientExamSubStatus.EXAM_CANCELED_BY_PATIENT] = 'Exame cancelado pelo Paciente',
    _a[PatientExamSubStatus.EXAM_CANCELED_BY_LABORATORY] = 'Exame cancelado pelo Laboratório',
    _a[PatientExamSubStatus.EXAM_CANCELED_BY_DOCTOR] = 'Exame cancelado pelo Médico',
    _a[PatientExamSubStatus.DENIED_OUT_OF_SCOPE] = 'Negado – Fora do Escopo',
    _a[PatientExamSubStatus.DENIED_AMOUNT_NOT_APPROVED] = 'Negado – Valor não aprovado',
    _a[PatientExamSubStatus.EXAM_FRUSTRADO] = 'Exame frustrado (paciente não compareceu)',
    _a);
