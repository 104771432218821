import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { Form } from '@unform/web';
import {
  t,
  extractValidationErrors,
  SaveUserGroupPermission,
  DeleteUserGroupPermission,
  GetProductPresentations,
  GetProgramProducts,
  GetUserGroupPermissions,
  Presentation,
  Product,
  UserGroup,
  ValidationError,
  Exam,
  ExamGroup,
  GetFollowupExamGroups,
  GetProgramFollowups,
  Followup,
  GetExamGroupExams,
  PermissionType,
  GetProductPatientProcedures,
  PatientProcedure,
  GetProgramSupplyGroups,
  GetSupplyGroupSupplies,
  SupplyGroup,
  Supply,
  UserGroupPermission,
} from '@psp/common';
import { toast } from 'react-toastify';
import { useParams, useHistory } from 'react-router-dom';

import { useLoading } from '../../../contexts/loading.context';

import { useStyles } from './styles';
import UserGroupPermissionsTable from '../../UserGroupPermissionsTable';
import Button from '../../Button';
import { useProgram } from '../../../contexts/program.context';
import ProductSelect from '../../ProductSelect';
import ExamSelect from '../../ExamSelect';
import PermissionTypeSelect from '../../PermissionTypeSelect';
import PatientProcedureSelect from '../../PatientProcedureSelect';
import SupplySelect from '../../SupplySelect';

export type UserGroupPermissionsProps = {
  saveUserGroupPermission: SaveUserGroupPermission;
  deleteUserGroupPermission: DeleteUserGroupPermission;
  getUserGroupPermissions: GetUserGroupPermissions;
  getProgramProducts: GetProgramProducts;
  getProductPresentations: GetProductPresentations;
  getProductPatientProcedures: GetProductPatientProcedures;
  getProgramFollowups: GetProgramFollowups;
  getFollowupExamGroups: GetFollowupExamGroups;
  getExamGroupExams: GetExamGroupExams;
  getProgramSupplyGroups: GetProgramSupplyGroups;
  getSupplyGroupSupplies: GetSupplyGroupSupplies;
};

type UserGroupsState = {
  userGroupPermissions: UserGroupPermission[];
  userGroup: UserGroup | undefined;
  presentationId?: string;
  presentations: Presentation[];
  patientProcedures: PatientProcedure[];
  patientProcedureId?: string;
  productId?: string;
  products: Product[];
  followups: Followup[];
  followupId?: string;
  examGroups: ExamGroup[];
  examGroupId?: string;
  exams: Exam[];
  examId?: string;
  supplyGroups: SupplyGroup[];
  supplyGroupId?: string;
  supplies: Supply[];
  supplyId?: string;
  type?: PermissionType;
  adding: boolean;
};

const defaultState: UserGroupsState = {
  userGroupPermissions: [],
  userGroup: undefined,
  presentationId: undefined,
  presentations: [],
  patientProcedureId: undefined,
  patientProcedures: [],
  productId: undefined,
  products: [],
  followupId: undefined,
  followups: [],
  examGroupId: undefined,
  examGroups: [],
  examId: undefined,
  exams: [],
  supplyGroups: [],
  supplyGroupId: undefined,
  supplies: [],
  supplyId: undefined,
  type: undefined,
  adding: false,
};

export default function UserGroups({
  saveUserGroupPermission,
  deleteUserGroupPermission,
  getProgramProducts,
  getProductPresentations,
  getUserGroupPermissions,
  getProgramFollowups,
  getFollowupExamGroups,
  getExamGroupExams,
  getProductPatientProcedures,
  getProgramSupplyGroups,
  getSupplyGroupSupplies,
}: UserGroupPermissionsProps): JSX.Element {
  const classes = useStyles();
  const formRef = useRef({} as any);
  const clientRef: any = useRef(null);
  const { showLoading, hideLoading } = useLoading();
  const { program } = useProgram();
  const { goBack } = useHistory();
  const [state, setState] = useState<UserGroupsState>(defaultState);
  const { id } = useParams<{ id: string }>();

  const loadUserGroupPermissions = useCallback(
    (userGroupId) => {
      showLoading();
      (async () => {
        try {
          const response = await getUserGroupPermissions.execute({ userGroupId });
          setState({
            ...defaultState,
            userGroupPermissions: response.userGroupPermissions,
            userGroup: response.userGroup,
          });
        } catch (err) {
          console.log(err);
          toast.error(t('err.failedToLoadUserGroupPermissions'));
          goBack();
        } finally {
          hideLoading();
        }
      })();
    },
    [state],
  );

  /* eslint-disable-next-line */
  const handleSubmit = useCallback(() => { }, []);

  const handleCloseAddDialog = useCallback(() => {
    formRef.current.reset();

    setState({
      ...defaultState,
      userGroupPermissions: state.userGroupPermissions,
      userGroup: state.userGroup,
    });
  }, [state]);

  const handleConfirmAddDialog = useCallback(() => {
    if (!program) return;
    formRef.current.reset();
    showLoading();
    (async () => {
      try {
        const { type, productId, presentationId, examGroupId, examId,
          patientProcedureId, supplyGroupId, supplyId } = state;

        await saveUserGroupPermission.execute({
          userGroupId: id,
          type,
          groupId: productId || examGroupId || supplyGroupId || undefined,
          itemId: presentationId || examId || patientProcedureId || supplyId || undefined,
        });

        loadUserGroupPermissions(id);
        handleCloseAddDialog();
      } catch (err) {
        console.log(err);
        let errors = {};
        if (err instanceof ValidationError) {
          errors = extractValidationErrors(err);
          formRef.current.setErrors(errors);
        } else {
          toast.error(t('err.failedToSaveUserGroupPermission'));
        }
        hideLoading();
      }
    })();
  }, [program, state]);

  const handleOnRemove = useCallback(
    (item: any) => {
      showLoading();
      (async () => {
        try {
          await deleteUserGroupPermission.execute({
            userGroupPermissionId: item.id,
            userGroupId: id,
          });
          loadUserGroupPermissions(id);
        } catch (err) {
          console.log(err);
          toast.error(t('err.failedToDeleteUserGroupPermission'));
          hideLoading();
        }
      })();
    },
    [program],
  );

  const handleTypeChange = useCallback(
    (value: PermissionType) => {
      if (!value) return;
      setState({
        ...state,
        productId: undefined,
        presentationId: undefined,
        examGroupId: undefined,
        examId: undefined,
        patientProcedureId: undefined,
        supplyGroupId: undefined,
        supplyId: undefined,
        type: value,
      });
    },
    [state, clientRef],
  );

  const handlePresentationChange = useCallback(
    (value) => {
      if (!value) return;
      setState({
        ...state,
        ...value,
      });
    },
    [state],
  );

  const handleExamChange = useCallback(
    (value) => {
      if (!value) return;
      setState({
        ...state,
        ...value,
      });
    },
    [state],
  );

  const handlePatientProcedureChange = useCallback(
    (value) => {
      if (!value) return;
      setState({
        ...state,
        ...value,
      });
    },
    [state],
  );

  const handleSupplyChange = useCallback(
    (value) => {
      if (!value) return;
      setState({
        ...state,
        ...value,
      });
    },
    [state],
  );

  const handleAdd = useCallback(
    () => {
      setState({
        ...defaultState,
        userGroupPermissions: state.userGroupPermissions,
        userGroup: state.userGroup,
        adding: true,
      });
    },
    [state],
  );

  useEffect(() => {
    if (program) {
      loadUserGroupPermissions(id);
    }
  }, [program]);

  return (
    <Box className={classes.container}>
      <UserGroupPermissionsTable
        data={state.userGroupPermissions}
        userGroupName={state.userGroup?.name ?? ''}
        handleAdd={handleAdd}
        onRemove={handleOnRemove}
      />
      {state.adding && (
        <Dialog open onClose={handleCloseAddDialog}>
          <DialogTitle>{t('addUserGroupPermissions')}</DialogTitle>
          <DialogContent dividers>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Grid container spacing={2} direction="column">
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    value={program?.name}
                    size="small"
                    label={t('program')}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <PermissionTypeSelect
                    variant="outlined"
                    name="type"
                    onSelectedValueChange={handleTypeChange}
                    fullWidth
                    optional
                  />
                </Grid>
                {state.type === PermissionType.FIRST_MEDICINE && (
                  <Grid item xs={12}>
                    <ProductSelect
                      formRef={formRef}
                      getProgramProducts={getProgramProducts}
                      getProductPresentations={getProductPresentations}
                      onSelectedValueChange={handlePresentationChange}
                      direction="column"
                      optionalProduct
                      optionalPresentation
                    />
                  </Grid>
                )}
                {state.type === PermissionType.CLINICAL_EXAMINATION && (
                  <Grid item xs={12}>
                    <ExamSelect
                      formRef={formRef}
                      getProgramFollowups={getProgramFollowups}
                      getFollowupExamGroups={getFollowupExamGroups}
                      getExamGroupExams={getExamGroupExams}
                      onSelectedValueChange={handleExamChange}
                      direction="column"
                      optionalFollowup
                      optionalExamGroup
                      optionalExam
                    />
                  </Grid>
                )}
                {state.type === PermissionType.PATIENT_PROCEDURE && (
                  <Grid item xs={12}>
                    <PatientProcedureSelect
                      formRef={formRef}
                      getProgramProducts={getProgramProducts}
                      getProductPatientProcedures={getProductPatientProcedures}
                      onSelectedValueChange={handlePatientProcedureChange}
                      direction="column"
                      optionalProduct
                      optionalPatientProcedure
                    />
                  </Grid>
                )}
                {state.type === PermissionType.SUPPLY && (
                  <Grid item xs={12}>
                    <SupplySelect
                      formRef={formRef}
                      getProgramSupplyGroups={getProgramSupplyGroups}
                      getSupplyGroupSupplies={getSupplyGroupSupplies}
                      onSelectedValueChange={handleSupplyChange}
                      direction="column"
                      optionalSupplyGroup
                      optionalSupply
                      settingPermissions
                    />
                  </Grid>
                )}
              </Grid>
            </Form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAddDialog} color="primary">
              {t('cancel')}
            </Button>
            <Button onClick={handleConfirmAddDialog} color="primary">
              {t('confirm')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}
