import React from 'react';
import { MenuItem } from '@material-ui/core';
import clsx from 'clsx';
import { t, procedureLogisticTypes } from '@psp/common';
import Select, { SelectProps } from '../Select';
import { useStyles } from './styles';

type ProcedureLogisticTypeSelectProps = Omit<SelectProps, 'children'> & {
  off?: string[];
};

export default function ProcedureLogisticTypeSelect({
  className,
  off = [],
  ...props
}: ProcedureLogisticTypeSelectProps): JSX.Element {
  const classes = useStyles();

  return (
    <Select
      label={t('procedureLogistic')}
      className={clsx(className, classes.formControl)}
      {...props}
      classes={{ select: classes.selectContainer }}
    >
      {[
        <MenuItem value="" disabled key="disabled_item" />,
        ...procedureLogisticTypes
          .filter((pat) => !off.includes(pat))
          .map((pat: string) => (
            <MenuItem value={pat} key={pat}>
              {t(`procedureLogisticType.${pat}`)}
            </MenuItem>
          )),
      ]}
    </Select>
  );
}
