import React from 'react';
import { getProgramBySlug, login } from '@psp/common';
import Signin, { SigninProps } from '../../components/Signin';

const dispatchers: Pick<SigninProps, 'getProgramBySlug' | 'login'> = {
  getProgramBySlug,
  login,
};

export default function SigninComposer(): JSX.Element {
  return <Signin {...dispatchers} />;
}
