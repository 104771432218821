import React from 'react';
import { Typography, useTheme, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import createDomPurify from 'dompurify';
import { useStyles } from './styles';
import { useProgram } from '../../contexts/program.context';

export type FooterProps = {
  className?: string;
  isDrawerOpen: boolean;
};

export default function Footer2({ className, isDrawerOpen }: FooterProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const { program } = useProgram();
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));
  const isMD = useMediaQuery(theme.breakpoints.down('md'));
  const domPurify = createDomPurify(window);

  if (program?.customHtmlFooter) {
    return (
      <div
        className={clsx(classes.footer, className, {
          [classes.shift]: !isXS && isDrawerOpen,
          [classes.noMargin]: isXS,
        })}
      >
        <span
          className={clsx(classes.displayContents, {
            [classes.textAlignCenter]: isXS,
          })}
          dangerouslySetInnerHTML={{
            __html: domPurify.sanitize(
              program?.customHtmlFooter,
              { ADD_ATTR: ['target'] },
            ),
          }}
        />
        <div className={classes.flex} />
        {!isMD && (
          <>
            <Typography variant="caption" className={classes.span}>
              Desenvolvido por:&nbsp;&nbsp;
            </Typography>
            <img src="/logo.png" alt="bCare | Funcional Health Tech" className={classes.logo} />
          </>
        )}
      </div>
    );
  }

  return (
    <div
      className={clsx(classes.footer, className, {
        [classes.shift]: !isXS && isDrawerOpen,
      })}
    >
      {!isXS && (
        <>
          <Typography variant="caption" className={classes.span}>
            © 2021 . Todos os direitos reservados.
          </Typography>
          <div className={classes.flex} />
        </>
      )}
      <Typography variant="caption" className={classes.span}>
        Desenvolvido por:&nbsp;&nbsp;
      </Typography>
      <img src="/logo.png" alt="bCare | Funcional Health Tech" className={classes.logo} />
    </div>
  );
}
