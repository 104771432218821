import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { Form } from '@unform/web';
import {
  t,
  extractValidationErrors,
  SaveUserGroupUser,
  DeleteUserGroupUser,
  GetUserUserGroups,
  GetUserGroups,
  GetManagers,
  User,
  UserGroupUser,
  ValidationError,
} from '@psp/common';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';

import { useLoading } from '../../../contexts/loading.context';

import { useStyles } from './styles';
import UserGroupUsersTable from '../../UserGroupUsersTable';
import Button from '../../Button';
import { useProgram } from '../../../contexts/program.context';
import ManagerSelect from '../../ManagerSelect';
import UserGroupSelect from '../../UserGroupSelect';

export type UserGroupUsersProps = {
  saveUserGroupUser: SaveUserGroupUser;
  deleteUserGroupUser: DeleteUserGroupUser;
  getUserUserGroups: GetUserUserGroups;
  getUserGroups: GetUserGroups;
  getManagers: GetManagers;
};

type UserGroupUsersState = {
  userGroupUsers: UserGroupUser[];
  userGroupUserId?: string;
  userGroupId: string;
  manager?: User | null;
  adding: boolean;
  editing: boolean;
};

const defaultState: UserGroupUsersState = {
  userGroupUsers: [],
  userGroupUserId: undefined,
  userGroupId: '',
  manager: null,
  adding: false,
  editing: false,
};

export default function UserGroupUsers({
  saveUserGroupUser,
  deleteUserGroupUser,
  getUserUserGroups,
  getUserGroups,
  getManagers,
}: UserGroupUsersProps): JSX.Element {
  const classes = useStyles();
  const formRef = useRef({} as any);
  const { isLoading, showLoading, hideLoading } = useLoading();
  const { id } = useParams<{ id: string }>();
  const { program } = useProgram();
  const { goBack } = useHistory();
  const [state, setState] = useState<UserGroupUsersState>(defaultState);

  const loadUserGroupUsers = useCallback(
    (userId) => {
      if (!program) return;
      showLoading();
      (async () => {
        try {
          const userGroupUsers = await getUserUserGroups.execute({
            userId,
            programId: program.id,
          });
          setState({
            ...defaultState,
            userGroupUsers,
          });
        } catch (err) {
          console.log(err);
          toast.error(t('err.failedToLoadUserUserGroups'));
          goBack();
        } finally {
          hideLoading();
        }
      })();
    },
    [program, state],
  );

  /* eslint-disable-next-line */
  const handleSubmit = useCallback(() => { }, []);

  const handleCloseAddDialog = useCallback(() => {
    formRef.current.reset();

    setState({
      ...defaultState,
      userGroupUsers: state.userGroupUsers,
    });
  }, [state]);

  const handleConfirmAddDialog = useCallback(() => {
    if (!program) return;
    formRef.current.reset();
    showLoading();
    (async () => {
      try {
        const { userGroupUserId, userGroupId, manager } = state;

        await saveUserGroupUser.execute({
          userGroupUserId,
          userGroupId,
          userId: id,
          managerId: manager?.id,
        });

        loadUserGroupUsers(id);
        handleCloseAddDialog();
      } catch (err) {
        console.log(err);
        let errors = {};
        if (err instanceof ValidationError) {
          errors = extractValidationErrors(err);
          formRef.current.setErrors(errors);
        } else {
          toast.error(t('err.failedToSaveUserUserGroup'));
        }
        hideLoading();
      }
    })();
  }, [program, state]);

  const handleOnRemove = useCallback(
    (item: any) => {
      showLoading();
      (async () => {
        try {
          await deleteUserGroupUser.execute({
            userGroupUserId: item.id,
            userGroupId: id,
          });
          loadUserGroupUsers(id);
        } catch (err) {
          console.log(err);
          toast.error(t('err.failedToDeleteUserUserGroup'));
          hideLoading();
        }
      })();
    },
    [program],
  );

  const handleUserGroupChange = useCallback(
    (value) => {
      if (!value) return;
      setState({
        ...state,
        ...value,
      });
    },
    [state],
  );

  const handleManagerChange = useCallback(
    (value: any) => {
      setState({
        ...state,
        manager: value,
      });
    },
    [state],
  );

  const handleAdd = useCallback(
    () => {
      setState({
        ...defaultState,
        userGroupUsers: state.userGroupUsers,
        adding: true,
      });
    },
    [state],
  );

  const handleEdit = useCallback(
    (userGroupUser) => {
      setState({
        ...state,
        userGroupUserId: userGroupUser.id,
        userGroupId: userGroupUser.userGroupId,
        manager: userGroupUser.manager,
        editing: true,
      });
    },
    [state],
  );

  useEffect(() => {
    if (program) {
      loadUserGroupUsers(id);
    }
  }, [program]);

  return (
    <Box className={classes.container}>
      <UserGroupUsersTable
        data={state.userGroupUsers}
        handleAdd={handleAdd}
        handleEdit={handleEdit}
        onRemove={handleOnRemove}
      />
      {(state.adding || state.editing) && (
        <Dialog open onClose={handleCloseAddDialog}>
          <DialogTitle>
            {(state.adding ? t('addUserUserGroup') : t('editUserUserGroup'))}
          </DialogTitle>
          <DialogContent dividers>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Grid container spacing={2} direction="column">
                <Grid item xs={12}>
                  <UserGroupSelect
                    formRef={formRef}
                    getUserGroups={getUserGroups}
                    onSelectedValueChange={handleUserGroupChange}
                    initialValue={state.userGroupId}
                    direction="column"
                  />
                </Grid>
                <Grid item xs={12} sm="auto">
                  <ManagerSelect
                    className={classes.input}
                    disabled={isLoading}
                    onSelectedValueChange={handleManagerChange}
                    getManagers={getManagers}
                    value={state.manager}
                    userId={id}
                  />
                </Grid>
              </Grid>
            </Form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAddDialog} color="primary">
              {t('cancel')}
            </Button>
            <Button onClick={handleConfirmAddDialog} color="primary">
              {t('confirm')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}
