import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { toast } from 'react-toastify';
import { t, GetClientsPaginate, IPaginated, Client, getClients, removeClient } from '@psp/common';

import { useLoading } from '../../../contexts/loading.context';
import { useProgram } from '../../../contexts/program.context';
import ClientTable from '../../ClientTable';

import { useStyles } from './styles';

export type ClientListProps = {
  getClientsPaginate: GetClientsPaginate;
};

type ClientListState = {
  clients: IPaginated<Client>;
};

type FilterState = {
  filter: string;
  page: number;
  rows: number;
  timeout: number;
};

const defaultState: ClientListState = {
  clients: {
    data: [],
    total: 0,
  },
};

const defaultFilterState: FilterState = {
  filter: '',
  page: 0,
  rows: 10,
  timeout: 0,
};

export default function ClientList({ getClientsPaginate }: ClientListProps): JSX.Element {
  const classes = useStyles();
  const [state, setState] = useState<ClientListState>(defaultState);
  const [filterState, setFilterState] = useState<FilterState>(defaultFilterState);
  const { showLoading, hideLoading, showBackdrop, hideBackdrop } = useLoading();
  const { program } = useProgram();

  const handleApproveClientClick = useCallback(
    (client: Client) => {
      if (!program) return;
      showLoading();
      (async () => {
        try {
          const clients = await getClientsPaginate.execute({
            filter: filterState.filter,
            skip: filterState.page * filterState.rows,
            take: filterState.rows,
            programId: program.id,
          });
          setState({
            clients,
          });
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    },
    [filterState, program],
  );

  const handleRemove = useCallback(
    (client: Client) => {
      if (!program) return;
      showLoading();
      (async () => {
        try {
          await removeClient.execute({ id: client.id });
          toast.success(t('msg.successToRemoveClient'), {
            onOpen: showBackdrop,
            onClose: hideBackdrop,
          });
          const clients = await getClientsPaginate.execute({
            filter: filterState.filter,
            skip: filterState.page * filterState.rows,
            take: filterState.rows,
            programId: program.id,
          });
          setState({
            clients,
          });
        } catch (err) {
          if (err.message === 'clientNotFound') {
            toast.warning(t('err.clientNotFound'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } else if (err.message === 'clientIsLinkedToOtherEntitie') {
            toast.warning(t('err.clientIsLinkedToOtherEntitie'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } else {
            toast.warning(t('err.clientNotFound'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          }
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    },
    [filterState, program],
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      if (!program) return;
      showLoading();
      (async () => {
        try {
          if (!program) return;
          const clients = await getClientsPaginate.execute({
            filter: filterState.filter,
            skip: filterState.page * filterState.rows,
            take: filterState.rows,
            programId: program.id,
          });
          setState({
            clients,
          });
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    }, filterState.timeout);
    return () => clearTimeout(handler);
  }, [filterState, program]);

  const handleSearch = useCallback(
    (value: string) => {
      setFilterState({
        ...filterState,
        filter: value,
        page: 0,
        timeout: 500,
      });
    },
    [filterState],
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setFilterState({
        ...filterState,
        page,
        timeout: 200,
      });
    },
    [filterState],
  );

  const handleRowsChange = useCallback(
    (rows: number) => {
      setFilterState({
        ...filterState,
        rows,
        timeout: 200,
      });
    },
    [filterState],
  );

  return (
    <Box className={classes.container}>
      <ClientTable
        count={state.clients.total || 0}
        data={state.clients.data || []}
        canSearch
        canRemove
        onRemove={handleRemove}
        onChangePage={handlePageChange}
        onChangeRows={handleRowsChange}
        onSearch={handleSearch}
        page={filterState.page}
        rowsPerPage={filterState.rows}
      />
    </Box>
  );
}
