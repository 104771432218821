import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { toast } from 'react-toastify';
import {
  t,
  GetClientProgramsPaged,
  Program,
  IPaginated,
  DeleteProgram,
} from '@psp/common';

import { useLoading } from '../../../contexts/loading.context';
import { useProgram } from '../../../contexts/program.context';
import ProgramTable from '../../ProgramTable';

import { useStyles } from './styles';

export type ProgramListProps = {
  getClientProgramsPaged: GetClientProgramsPaged;
  deleteProgram: DeleteProgram;
};

type ProgramListState = {
  programs: IPaginated<Program>;
};

type FilterState = {
  filter: string;
  page: number;
  rows: number;
  timeout: number;
};

const defaultState: ProgramListState = {
  programs: {
    data: [],
    total: 0,
  },
};

const defaultFilterState: FilterState = {
  filter: '',
  page: 0,
  rows: 10,
  timeout: 0,
};

export default function ProgramList({
  getClientProgramsPaged,
  deleteProgram,
}: ProgramListProps): JSX.Element {
  const classes = useStyles();
  const [state, setState] = useState<ProgramListState>(defaultState);
  const [filterState, setFilterState] = useState<FilterState>(defaultFilterState);
  const { showLoading, hideLoading, showBackdrop, hideBackdrop } = useLoading();
  const { program } = useProgram();

  useEffect(() => {
    const handler = setTimeout(() => {
      if (!program) return;
      showLoading();
      (async () => {
        try {
          if (!program) return;
          const programs = await getClientProgramsPaged.execute({
            clientId: program.client.id,
            filter: filterState.filter,
            skip: filterState.page * filterState.rows,
            take: filterState.rows,
          });
          setState({
            programs,
          });
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    }, filterState.timeout);
    return () => clearTimeout(handler);
  }, [filterState, program]);

  const handleSearch = useCallback(
    (value: string) => {
      setFilterState({
        ...filterState,
        filter: value,
        page: 0,
        timeout: 500,
      });
    },
    [filterState],
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setFilterState({
        ...filterState,
        page,
        timeout: 200,
      });
    },
    [filterState],
  );

  const handleRowsChange = useCallback(
    (rows: number) => {
      setFilterState({
        ...filterState,
        rows,
        timeout: 200,
      });
    },
    [filterState],
  );

  const handleOnRemove = useCallback(
    (_program: any) => {
      showLoading();
      (async () => {
        try {
          await deleteProgram.execute({
            programId: _program.id,
          });

          setState({
            programs: {
              data: state.programs.data.filter(
                (p: Program) => p.id !== _program.id,
              ),
              total: state.programs.total - 1,
            },
          });

          toast.success(t('msg.successToDeleteProgram'), {
            onOpen: showBackdrop,
            onClose: hideBackdrop,
          });
        } catch (err) {
          console.log(err);
          if (err.message === 'cannotDeleteProgram') {
            toast.info(t('err.cannotDeleteProgram'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } else if (err.name === 'BadRequestError') {
            toast.info(err.message, {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } else {
            toast.info(t('err.failedToDeleteProgram'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          }
        } finally {
          hideLoading();
        }
      })();
    },
    [state],
  );

  return (
    <Box className={classes.container}>
      <ProgramTable
        count={state.programs.total || 0}
        data={state.programs.data || []}
        canSearch
        onChangePage={handlePageChange}
        onChangeRows={handleRowsChange}
        onSearch={handleSearch}
        page={filterState.page}
        rowsPerPage={filterState.rows}
        onRemove={handleOnRemove}
      />
    </Box>
  );
}
