import React from 'react';
import { t } from '@psp/common';

import Breadcrumbs from '../../../components/Breadcrumbs';
import InventoryComposer from '../../../composers/Inventory';
import { INVENTORY_ROUTE } from '../../../constants';

export default function InventoryPage(): JSX.Element {
  return (
    <>
      <Breadcrumbs title={t(`navigation.${INVENTORY_ROUTE}`)} />
      <InventoryComposer />
    </>
  );
}
