import { lighten, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    overflowX: 'hidden',
  },
  mainContainer: {
    paddingLeft: 0,
    paddingRight: 0,
    flex: 1,
  },
  gridContainer: {
    height: '100%',
  },
  navbarContainer: {
    padding: '0 30px',
  },
  footerContainer: {
    padding: '0 30px',
  },
  main: {
    padding: '0  30px',
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'flex',
  },
  title: {
    fontSize: 35,
    lineHeight: 1,
  },
  subtitle: {
    fontSize: 40,
    fontWeight: 'bold',
    lineHeight: 1,
  },
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  listItem: {
    fontSize: 18,
  },
  inline: {
    display: 'inline-block',
    verticalAlign: 'top',
  },
  loginLabel: {
    fontWeight: 'bold',
  },
  helperText: {
    position: 'initial',
    bottom: -2,
    width: '100%',
  },
  box: {
    background: theme.palette.common.white,
    borderRadius: 8,
    padding: theme.spacing(0),
    boxShadow: '3px 6px 12px 0px rgba(0, 0, 0, 0.05)',
    border: '1px solid rgba(229, 229, 229, 1)',
  },
  verticalDivider: {
    height: 55,
    width: 1,
  },
  link: {
    textDecoration: 'none',
  },
}));
