import React from 'react';
import { t } from '@psp/common';

import Breadcrumbs from '../../../components/Breadcrumbs';
import { CLIENTS_ROUTES } from '../../../constants';
import ClientListComposer from '../../../composers/ClientListComposer';

export default function ClientListPage(): JSX.Element {
  return (
    <>
      <Breadcrumbs title={t(`navigation.${CLIENTS_ROUTES}`)} />
      <ClientListComposer />
    </>
  );
}
