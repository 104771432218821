import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { toast } from 'react-toastify';
import { t, GetPresentations, RemovePresentation, IPaginated, Presentation } from '@psp/common';

import { useLoading } from '../../../contexts/loading.context';
import { useProgram } from '../../../contexts/program.context';

import { useStyles } from './styles';
import PresentationTable from '../../PresentationTable';

export type PresentationListProps = {
  getPresentations: GetPresentations;
  removePresentation: RemovePresentation;
};

type PresentationListState = {
  presentations: IPaginated<Presentation>;
};

type FilterState = {
  filter: string;
  page: number;
  rows: number;
  timeout: number;
};

const defaultState: PresentationListState = {
  presentations: {
    data: [],
    total: 0,
  },
};

const defaultFilterState: FilterState = {
  filter: '',
  page: 0,
  rows: 10,
  timeout: 0,
};

export default function PresentationList({
  getPresentations,
  removePresentation,
}: PresentationListProps): JSX.Element {
  const classes = useStyles();
  const [state, setState] = useState<PresentationListState>(defaultState);
  const [filterState, setFilterState] = useState<FilterState>(defaultFilterState);
  const { showLoading, hideLoading, showBackdrop, hideBackdrop } = useLoading();
  const { program } = useProgram();

  useEffect(() => {
    const handler = setTimeout(() => {
      if (!program) return;
      showLoading();
      (async () => {
        try {
          if (!program) return;
          const presentations = await getPresentations.execute({
            filter: filterState.filter,
            skip: filterState.page * filterState.rows,
            take: filterState.rows,
            programId: program.id,
          });
          setState({
            presentations,
          });
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    }, filterState.timeout);
    return () => clearTimeout(handler);
  }, [filterState, program]);

  const handleSearch = useCallback(
    (value: string) => {
      setFilterState({
        ...filterState,
        filter: value,
        page: 0,
        timeout: 500,
      });
    },
    [filterState],
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setFilterState({
        ...filterState,
        page,
        timeout: 200,
      });
    },
    [filterState],
  );

  const handleRowsChange = useCallback(
    (rows: number) => {
      setFilterState({
        ...filterState,
        rows,
        timeout: 200,
      });
    },
    [filterState],
  );

  const handleRemove = useCallback(
    (presentation: Presentation) => {
      if (!program) return;
      showLoading();
      (async () => {
        try {
          await removePresentation.execute({ id: presentation.id });
          toast.success(t('msg.successToRemovePresentation'), {
            onOpen: showBackdrop,
            onClose: hideBackdrop,
          });
          const presentations = await getPresentations.execute({
            filter: filterState.filter,
            skip: filterState.page * filterState.rows,
            take: filterState.rows,
            programId: program.id,
          });
          setState({
            presentations,
          });
        } catch (err) {
          console.log(err);
          if (err.message === 'presentationNotFound') {
            toast.warning(t('err.presentationNotFound'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } else if (err.message === 'presentationIsLinkedToOtherEntitie') {
            toast.warning(t('err.presentationIsLinkedToOtherEntitie'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } else {
            toast.warning(t('err.failedToRemovePresentation'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          }
        } finally {
          hideLoading();
        }
      })();
    },
    [filterState, program],
  );

  return (
    <Box className={classes.container}>
      <PresentationTable
        count={state.presentations.total || 0}
        data={state.presentations.data || []}
        canSearch
        onChangePage={handlePageChange}
        onChangeRows={handleRowsChange}
        onSearch={handleSearch}
        canRemove
        onRemove={handleRemove}
        page={filterState.page}
        rowsPerPage={filterState.rows}
      />
    </Box>
  );
}
