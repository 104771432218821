import React, { useCallback, useEffect, useState } from 'react';
import { Box, Dialog, DialogActions, DialogTitle, Grid } from '@material-ui/core';
import { toast } from 'react-toastify';
import {
  t,
  ProgramFile,
  GetProgramFiles,
  GetProgramProducts,
  GetProductPresentations,
  GetProductPatientProcedures,
  GetProgramFollowups,
  GetFollowupExamGroups,
  GetExamGroupExams,
  GetProgramSupplyGroups,
  GetSupplyGroupSupplies,
  CreateProgramFile,
  DeleteProgramFile,
  DownloadProgramFile,
  LibraryType,
} from '@psp/common';

import { useLoading } from '../../../contexts/loading.context';
import { useProgram } from '../../../contexts/program.context';
import { useAuth } from '../../../contexts/auth.context';

import { useStyles } from './styles';
import LibraryDisplay from '../../LibraryDisplay';
import LibraryUpload from '../../LibraryUpload';
import Button from '../../Button';

export type LibraryListProps = {
  getProgramFiles: GetProgramFiles;
  getProgramProducts: GetProgramProducts;
  getProductPresentations: GetProductPresentations;
  getProductPatientProcedures: GetProductPatientProcedures;
  getProgramFollowups: GetProgramFollowups;
  getFollowupExamGroups: GetFollowupExamGroups;
  getExamGroupExams: GetExamGroupExams;
  getProgramSupplyGroups: GetProgramSupplyGroups;
  getSupplyGroupSupplies: GetSupplyGroupSupplies;
  createProgramFile: CreateProgramFile;
  deleteProgramFile: DeleteProgramFile;
  downloadProgramFile: DownloadProgramFile
};

type LibraryListState = {
  programFiles: ProgramFile[];
  removalDialog: boolean
  programFileForRemoval?: ProgramFile;
  fileTypes: LibraryType[];
};

const defaultState: LibraryListState = {
  programFiles: [] as ProgramFile[],
  removalDialog: false,
  programFileForRemoval: undefined,
  fileTypes: [] as LibraryType[],
};

export default function LibraryList({
  getProgramFiles,
  deleteProgramFile,
  downloadProgramFile,
  ...dispatchers
}: LibraryListProps): JSX.Element {
  const classes = useStyles();
  const [state, setState] = useState<LibraryListState>(defaultState);
  const { showLoading, hideLoading, showBackdrop, hideBackdrop } = useLoading();
  const { program, modules } = useProgram();
  const { isAdmin, isSysadmin } = useAuth();

  const handleNewProgramFile = (programFile: ProgramFile): void => {
    setState((prevState) => ({
      ...prevState,
      programFiles: [...prevState.programFiles, programFile],
    }));
  };

  const handleDeleteProgramFile = (programFile: ProgramFile): void => {
    setState((prevState) => ({
      ...prevState,
      removalDialog: true,
      programFileForRemoval: programFile,
    }));
  };

  const handleRemovalDialogClose = (): void => {
    setState((prevState) => ({
      ...prevState,
      removalDialog: false,
      programFileForRemoval: undefined,
    }));
  };

  const handleRemovalConfirm = (): void => {
    showLoading();
    (async () => {
      try {
        await deleteProgramFile.execute({
          programFileId: state.programFileForRemoval?.id ?? '',
        });
        toast.success(t('msg.successToDeleteProgramFile'), {
          onOpen: showBackdrop,
          onClose: hideBackdrop,
        });
        setState((prevState) => ({
          ...prevState,
          removalDialog: false,
          programFiles: prevState.programFiles.filter(
            (pf) => pf.id !== prevState.programFileForRemoval?.id,
          ),
          programFileForRemoval: undefined,
        }));
      } catch (err) {
        if (err.name === 'BadRequestError') {
          toast.info(err.message, {
            onOpen: showBackdrop,
            onClose: hideBackdrop,
          });
        } else {
          toast.info(t('err.failedToDeleteProgramFile'), {
            onOpen: showBackdrop,
            onClose: hideBackdrop,
          });
        }

        handleRemovalDialogClose();
      } finally {
        hideLoading();
      }
    })();
  };

  useEffect(() => {
    if (!program) return;
    showLoading();
    (async () => {
      try {
        if (!program) return;
        const programFiles = await getProgramFiles.execute({
          programId: program.id,
        });
        let fileTypes = (modules.filter((m) => m in LibraryType) as unknown as LibraryType[]);
        fileTypes = fileTypes.concat([LibraryType.PROGRAM, LibraryType.GENERAL]);
        setState({
          ...defaultState,
          programFiles,
          fileTypes,
        });
      } catch (err) {
        console.log(err);
      } finally {
        hideLoading();
      }
    })();
  }, [program]);

  return (
    <Box className={classes.container}>
      <Grid container spacing={2} direction="column">
        {(isAdmin || isSysadmin) && (
          <Grid item>
            <LibraryUpload
              onUpload={handleNewProgramFile}
              filesTypes={state.fileTypes}
              {...dispatchers}
            />
          </Grid>
        )}
        <Grid item>
          <LibraryDisplay
            programFiles={state.programFiles}
            downloadProgramFile={downloadProgramFile}
            showDelete={isAdmin || isSysadmin}
            onDelete={handleDeleteProgramFile}
          />
        </Grid>
      </Grid>
      <Dialog
        open={!!state.removalDialog}
        onClose={handleRemovalDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('title.confirmRemoval')}</DialogTitle>
        <DialogActions>
          <Button onClick={handleRemovalDialogClose} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={handleRemovalConfirm} color="primary" autoFocus>
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
