import React from 'react';
import { t } from '@psp/common';

import Breadcrumbs from '../../components/Breadcrumbs';
import UserGroupsComposer from '../../composers/UserGroupsComposer';
import { USER_GROUPS_ROUTE } from '../../constants';

export default function UserGroupsPage(): JSX.Element {
  return (
    <>
      <Breadcrumbs title={t(`navigation.${USER_GROUPS_ROUTE}`)} />
      <UserGroupsComposer />
    </>
  );
}
