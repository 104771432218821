import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { toast } from 'react-toastify';
import { t, ApproveUser, GetUsers, User, IPaginated, userTypesMap } from '@psp/common';

import { useLoading } from '../../../contexts/loading.context';
import { useProgram } from '../../../contexts/program.context';
import UserTable from '../../UserTable';

import { useStyles } from './styles';
import { useAuth } from '../../../contexts/auth.context';

export type UserListProps = {
  approveUser: ApproveUser;
  getUsers: GetUsers;
};

type UserListState = {
  users: IPaginated<User>;
};

type FilterState = {
  filter: string;
  page: number;
  rows: number;
  timeout: number;
  userType: string;
};

const defaultState: UserListState = {
  users: {
    data: [],
    total: 0,
  },
};

const defaultFilterState: FilterState = {
  filter: '',
  page: 0,
  rows: 10,
  timeout: 0,
  userType: '',
};

export default function UserList({ approveUser, getUsers }: UserListProps): JSX.Element {
  const classes = useStyles();
  const [state, setState] = useState<UserListState>(defaultState);
  const [filterState, setFilterState] = useState<FilterState>(defaultFilterState);
  const { showLoading, hideLoading, showBackdrop, hideBackdrop } = useLoading();
  const { program } = useProgram();
  const { role } = useAuth();
  const userTypes = userTypesMap[role];
  const userTypesObj = userTypes.map((x) => ({ value: x, label: t(`userType.${x}`) }));
  userTypesObj.unshift({ value: ' ', label: t('none') });
  const handleApproveUserClick = useCallback(
    (user: User) => {
      if (!program) return;
      showLoading();
      (async () => {
        try {
          await approveUser.execute({ userId: user.id });
          toast.success(t('msg.successToApproveUser'), {
            onOpen: showBackdrop,
            onClose: hideBackdrop,
          });
          const users = await getUsers.execute({
            filter: filterState.filter,
            skip: filterState.page * filterState.rows,
            take: filterState.rows,
            programId: program.id,
          });
          setState({
            users,
          });
        } catch (err) {
          console.log(err);
          toast.warning(t('err.failedToApproveUser'), {
            onOpen: showBackdrop,
            onClose: hideBackdrop,
          });
        } finally {
          hideLoading();
        }
      })();
    },
    [filterState, program],
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      if (!program) return;
      showLoading();
      (async () => {
        try {
          if (!program) return;
          const users = await getUsers.execute({
            filter: filterState.filter,
            skip: filterState.page * filterState.rows,
            take: filterState.rows,
            programId: program.id,
            userType: filterState.userType,
          });
          setState({
            users,
          });
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    }, filterState.timeout);
    return () => clearTimeout(handler);
  }, [filterState, program]);

  const handleSearch = useCallback(
    (value: string) => {
      setFilterState({
        ...filterState,
        filter: value,
        page: 0,
        timeout: 500,
      });
    },
    [filterState],
  );

  const handleSearchBySelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== '') {
      (async () => {
        setFilterState({
          ...filterState,
          userType: e.target.value,
        });
      })();
    } else {
      (async () => {
        setFilterState({
          ...filterState,
          filter: '',
          userType: '',
        });
      })();
    }
  };

  const handlePageChange = useCallback(
    (page: number) => {
      setFilterState({
        ...filterState,
        page,
        timeout: 200,
      });
    },
    [filterState],
  );

  const handleRowsChange = useCallback(
    (rows: number) => {
      setFilterState({
        ...filterState,
        rows,
        timeout: 200,
      });
    },
    [filterState],
  );

  return (
    <Box className={classes.container}>
      <UserTable
        count={state.users.total || 0}
        data={state.users.data || []}
        onApprove={handleApproveUserClick}
        canSearch
        onChangePage={handlePageChange}
        onChangeRows={handleRowsChange}
        onSearch={handleSearch}
        page={filterState.page}
        rowsPerPage={filterState.rows}
        enableSelectFilter
        selectFilterTitle={t('userTypeFilter')}
        selectOptions={userTypesObj}
        onSearchBySelect={handleSearchBySelect}
      />
    </Box>
  );
}
