import React from 'react';
import { AppBar, Toolbar, Divider, Button, useMediaQuery, useTheme, Grid } from '@material-ui/core';
import { Link, Switch, Route, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { t } from '@psp/common';

import { useQuery } from '../../hooks/use-query';
import { useStyles } from './styles';

// type SigninNavbarProps = { };

export default function SigninNavbar(): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));
  const { program } = useQuery<{ program: string }>();
  const location = useLocation();

  if (program === 'tevacuidar'.toString()) {
    return (
      <AppBar
        color="default"
        elevation={0}
        className={clsx(classes.appBar, {
          'signin-navbar-login': location.pathname === '/login',
        })}
        position="relative"
      >
        <Toolbar className={classes.toolbar}>
          <Grid container spacing={2} direction="row" alignItems="center">
            <Grid item>
              <div className="signin-navbar-program-image" />
            </Grid>
            <Grid item>
              <Divider className={classes.verticalDivider} />
            </Grid>
            <Grid item>
              <div className="signin-navbar-client-image" />
            </Grid>
            <Grid item xs />
            {!isXS && (
            <>
              <Grid item>
                <span className="signin-navbar-sac-label" />
                <span className="signin-navbar-sac-content" />
              </Grid>
              <Grid item>
                <Switch>
                  <Route path="/signup">
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to={`/login?program=${program || ''}`}
                      className={classes.button}
                      size="large"
                    >
                      {t('accessPlatform')}
                    </Button>
                  </Route>
                </Switch>
              </Grid>
            </>
            )}
          </Grid>
        </Toolbar>
        <Divider />
      </AppBar>
    );
  }
  return (
    <AppBar
      color="default"
      elevation={0}
      className={clsx(classes.appBar, {
        'signin-navbar-login': location.pathname === '/login',
      })}
      position="relative"
    >
      <Toolbar className={classes.toolbar}>
        <Grid container spacing={2} direction="row" alignItems="center">
          <Grid item>
            <div className="signin-navbar-program-image" />
          </Grid>
          <Grid item>
            <Divider className={classes.verticalDivider} />
          </Grid>
          <Grid item>
            <div className="signin-navbar-client-image" />
          </Grid>
          <Grid item xs />
          {!isXS && (
            <>
              <Grid item>
                <span className="signin-navbar-sac-label" />
                <span className="signin-navbar-sac-content" />
              </Grid>
              <Grid item>
                <Switch>
                  <Route path="/signup">
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to={`/login?program=${program || ''}`}
                      className={classes.button}
                      size="large"
                    >
                      {t('accessPlatform')}
                    </Button>
                  </Route>
                  <Route path="/login">
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to={`/signup?program=${program || ''}`}
                      className={classes.button}
                      size="large"
                    >
                      {t('signup')}
                    </Button>
                  </Route>
                </Switch>
              </Grid>
            </>
          )}
        </Grid>
      </Toolbar>
      <Divider />
    </AppBar>
  );
}
