import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {},
  card: {
    background: theme.palette.primary.light,
    color: theme.palette.common.white,
    minWidth: 200,
  },
  redCard: {
    background: theme.palette.error.light,
  },
  yellowCard: {
    background: theme.palette.warning.light,
  },
}));
