import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import {
  t,
  TravelGroup,
  GetFollowupTravelGroup,
  Followup,
  GetProgramFollowupTravels,
} from '@psp/common';

import { useLoading } from '../../contexts/loading.context';
import Select from '../Select';
import { useProgram } from '../../contexts/program.context';

export type TravelSelectValues = {
  followupId: string;
  followupName: string;
  travelGroupId: string;
  travelGroupName: string;
  travelGroup: TravelGroup;
};

type FormFieldProps = {
  name?: string;
};

export type TravelGroupSelectProps = {
  getProgramFollowupTravels: GetProgramFollowupTravels;
  getFollowupTravelGroup: GetFollowupTravelGroup;
  onSelectedValueChange: (values: TravelSelectValues) => void;
  formRef: any;
  followupsFieldProps?: FormFieldProps;
};

type TravelGroupSelectState = {
  travelGroup: TravelGroup;
  travelGroupId: string;
  followups: Followup[];
  followupId: string;
};

const defaultState: TravelGroupSelectState = {
  travelGroup: {} as TravelGroup,
  travelGroupId: '',
  followups: [],
  followupId: '',
};

export default function TravelSelect({
  getProgramFollowupTravels,
  getFollowupTravelGroup,
  onSelectedValueChange,
  formRef,
  followupsFieldProps,
}: TravelGroupSelectProps): JSX.Element {
  const followupRef: any = useRef(null);
  const [state, setState] = useState<TravelGroupSelectState>(defaultState);
  const { showLoading, hideLoading, isLoading } = useLoading();
  const { program } = useProgram();

  useEffect(
    useCallback(() => {
      if (!program) return;
      showLoading();
      setState({
        ...defaultState,
      });
      (async () => {
        try {
          const followups = await getProgramFollowupTravels.execute({ programId: program?.id });
          setState({
            ...defaultState,
            followups,
          });
          onSelectedValueChange({
            followupId: '',
            followupName: '',
            travelGroupId: '',
            travelGroupName: '',
            travelGroup: {} as TravelGroup,
          });
          formRef.current.setFieldValue('travelGroupId', null);
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    }, [state, onSelectedValueChange]),
    [program],
  );

  const handleFollowupChange = useCallback(
    (value) => {
      if (!value) return;
      showLoading();
      setState({
        ...state,
        travelGroup: {} as TravelGroup,
      });
      (async () => {
        try {
          const travelGroup = await getFollowupTravelGroup.execute({ followupId: value });
          setState({
            ...state,
            followupId: value,
            travelGroup: travelGroup ?? {} as TravelGroup,
          });
          onSelectedValueChange({
            followupId: value,
            followupName: state.followups.find((p) => p.id === value)!.name,
            travelGroupId: travelGroup.id,
            travelGroupName: travelGroup.name,
            travelGroup: travelGroup ?? {} as TravelGroup,
          });
          formRef.current.setFieldValue('travelGroupId', null);
        } catch (err) {
          console.log(err);
        } finally {
          if (followupRef.current) {
            followupRef.current.focus();
          }
        }
      })();
    },
    [state, onSelectedValueChange],
  );

  return (
    <Grid container spacing={2} direction="row">
      <Grid item xs={12} md={6}>
        <Select
          label={t('followup')}
          variant="outlined"
          name="followupId"
          fullWidth
          onSelectedValueChange={handleFollowupChange}
          disabled={state.followups.length === 0 || isLoading}
          inputRef={followupRef}
          preSelected={state.followups.length === 1 ? state.followups[0].id : null}
          {...followupsFieldProps}
        >
          {state.followups.map((f) => (
            <MenuItem key={f.id} value={f.id}>
              {f.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
}
