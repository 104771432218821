import React from 'react';
import { Grid } from '@material-ui/core';
import { Stethoscope, Key, History } from 'mdi-material-ui';
import { t, User } from '@psp/common';

import { USER_REGISTER_ROUTE, USER_EDIT_ROUTE, USER_USER_GROUPS_ROUTE, USER_USER_GROUPS_HISTORY_ROUTE } from '../../constants';
import Table, { TableProps } from '../Table';

const CompareByEquality = (column: keyof {
    id: string;
    [key: string]: unknown;
}) => (a: {
    id: string;
    [key: string]: unknown;
}, b: {
    id: string;
    [key: string]: unknown;
}) => {
  if (a[column] === b[column]) {
    return 0;
  }
  return 1;
};

const columns = [
  {
    key: 'name',
    label: t('name'),
    compareFunction: CompareByEquality('name'),
  },
  {
    key: 'email',
    label: t('email'),
    compareFunction: CompareByEquality('email'),
  },
  {
    key: 'phone',
    label: t('cellphone'),
    compareFunction: CompareByEquality('phone'),
    render: (value: any) => (value.length === 10
      ? value.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')
      : (value.length === 11
        ? value.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
        : value.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, '($2) $3-$4'))),
  },
  {
    key: 'type',
    label: t('profile'),
    compareFunction: CompareByEquality('type'),
    render: function UserTypeRender(value: any) {
      return t(`userType.${value}`);
      // return (
      //   <Grid container spacing={2} alignItems="center">
      //     <Grid item>{value === 'DOCTOR' && <Stethoscope fontSize="small" />}</Grid>
      //     <Grid item>{t(`userType.${value}`)}</Grid>
      //   </Grid>
      // );
    },
  },
  {
    key: 'approved',
    label: t('approved'),
    compareFunction: CompareByEquality('approved'),
    render: function UserApprovedRender(value: boolean) {
      return t(value.toString());
    },
  },
];

const extraActions = [
  {
    icon: <Key />,
    label: '',
    route: USER_USER_GROUPS_ROUTE,
  },
  {
    icon: <History />,
    label: '',
    route: USER_USER_GROUPS_HISTORY_ROUTE,
  },
];

export type UserTableProps = {
  data: User[];
  onApprove: (item: User) => void;
} & Pick<
  TableProps,
  'canSearch' | 'count' | 'onChangePage' | 'onChangeRows' | 'onSearch' | 'page' | 'rowsPerPage' | 'enableSelectFilter' | 'selectOptions' | 'onSearchBySelect' | 'selectFilterTitle'
>;

export default function UserTable(
  {
    data,
    onApprove,
    ...props
  }: UserTableProps,
): JSX.Element {
  return (
    <Table
      columns={columns}
      data={data}
      title={t('users')}
      size="small"
      canAdd
      addRoute={USER_REGISTER_ROUTE}
      canEdit
      editRoute={USER_EDIT_ROUTE}
      extraActions={extraActions}
      enableSelectFilter
      {...props}
    />
  );
}
