import React from 'react';
import { Chip as MaterialChip, ChipProps as MaterialChipProps } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './styles';

export type ChipProps = Omit<MaterialChipProps, 'color'> & {
  color?: 'default' | 'primary' | 'secondary' | 'warning' | 'info' | 'success' | 'error';
};

export default function Chip({ color = 'default', className, ...props }: ChipProps): JSX.Element {
  const classes = useStyles();
  return <MaterialChip {...props} color="primary" className={clsx(classes[color], className)} />;
}
