import React from 'react';
import { Chip, Grid, Paper, Typography } from '@material-ui/core';
import { formatPhone, t, Doctor } from '@psp/common';

import TextItem from '../TextItem';

import { useStyles } from './styles';
import PaperTitle from '../PaperTitle';
import PaperContent from '../PaperContent';

export type DoctorInfoProps = {
  doctor?: Doctor;
};

export default function DoctorInfo({ doctor }: DoctorInfoProps): JSX.Element {
  const classes = useStyles();

  return (
    <Paper className={classes.container}>
      <PaperTitle title={doctor ? `${doctor.name}` : ''} />
      <PaperContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" />
          </Grid>
          <Grid item xs={12}>
            {doctor && (
              <Chip
                color={doctor && doctor.userId ? 'primary' : 'default'}
                label={doctor && doctor.userId ? t('registered') : t('unregistered')}
                size="small"
              />
            )}
          </Grid>
          <Grid item>
            <TextItem label={t('crm')} content={doctor ? `${doctor.crm}/${doctor.uf}` : '-'} />
          </Grid>
          <Grid item>
            <TextItem label={t('name')} content={doctor ? doctor.name : '-'} />
          </Grid>
          <Grid item>
            <TextItem label={t('email')} content={doctor && doctor.email ? doctor.email : '-'} />
          </Grid>
          <Grid item>
            <TextItem
              label={t('cellphone')}
              content={doctor && doctor.phone ? formatPhone(doctor.phone) : '-'}
            />
          </Grid>
        </Grid>
      </PaperContent>
    </Paper>
  );
}
