import React from 'react';
import { getInventory } from '@psp/common';
import Inventory, { InventoryProps } from '../../components/Inventory/List';

const dispatchers: Pick<InventoryProps, 'getInventory'> = {
  getInventory,
};

export default function InventoryComposer(): JSX.Element {
  return <Inventory {...dispatchers} />;
}
