import React from 'react';

import DiscountsComposer from '../../composers/Discounts';

export default function DiscountsPage(): JSX.Element {
  return (
    <>
      <DiscountsComposer />
    </>
  );
}
