import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Paper } from '@material-ui/core';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import {
  extractValidationErrors,
  t,
  GetProgram,
  RegisterProgram,
  RegisterProgramParams,
  programRegisterForm,
  ValidationError,
} from '@psp/common';
import { CheckboxBlankCircleOutline, CheckboxMarkedCircle } from 'mdi-material-ui';

import { PROGRAM_REGISTER_ROUTE, PROGRAMS_ROUTE, PROGRAM_EDIT_ROUTE } from '../../../constants';
import { useLoading } from '../../../contexts/loading.context';
import Button from '../../Button';
import Input from '../../Input';

import { useStyles } from './styles';
import { useProgram } from '../../../contexts/program.context';
import PaperTitle from '../../PaperTitle';
import PaperContent from '../../PaperContent';
import ProgramModulesSelect from '../../ProgramModulesSelect';

export type ProgramRegisterProps = {
  getProgram: GetProgram;
  registerProgram: RegisterProgram;
};

type ProgramRegisterState = {
  programModules: string;
  allowSpecialtyDuringSignup: boolean;
};

const defaultState: ProgramRegisterState = {
  programModules: '',
  allowSpecialtyDuringSignup: false,
};

export default function ProgramRegister({
  getProgram,
  registerProgram,
}: ProgramRegisterProps): JSX.Element {
  const classes = useStyles();
  const formRef = useRef({} as any);
  const [state, setState] = useState<ProgramRegisterState>(defaultState);
  const { isLoading, showLoading, hideLoading, showBackdrop, hideBackdrop } = useLoading();
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const { program } = useProgram();

  const handleProgramModuleChange = useCallback(
    (value: string) => {
      setState({
        ...defaultState,
        programModules: value ?? '',
      });
    },
    [state],
  );

  const handleAllowSpecialtyDuringSignupChange = useCallback(
    (event, checked) => {
      if (!event) return;
      setState({
        ...state,
        allowSpecialtyDuringSignup: checked,
      });
    },
    [state],
  );

  const handleSubmit = useCallback(
    (_data) => {
      showLoading();
      async function runAsync() {
        try {
          const data = _data;

          Object.keys(_data).forEach((key) => {
            if (_data[key] === '') {
              data[key] = undefined;
            }
          });

          const req = (await programRegisterForm.validate({
            ...data,
            allowSpecialtyDuringSignup: state.allowSpecialtyDuringSignup,
          }, {
            abortEarly: false,
          })) as RegisterProgramParams;
          req.clientId = program?.client?.id ?? '';
          req.modules = state.programModules;
          req.allowSpecialtyDuringSignup = state.allowSpecialtyDuringSignup;
          await registerProgram.execute({
            id,
            ...req,
          });
          toast.success(id ? t('msg.successToUpdateProgram') : t('msg.successToRegisterProgram'), {
            onOpen: showBackdrop,
            onClose: hideBackdrop,
          });
          push(PROGRAMS_ROUTE);
        } catch (err) {
          let errors = {};
          if (err instanceof ValidationError) {
            errors = extractValidationErrors(err);
            formRef.current.setErrors(errors);
          } else {
            toast.error(t('err.failedToRegisterProgram'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          }
        } finally {
          hideLoading();
        }
      }
      runAsync();
    },
    [id, program, state],
  );

  useEffect(() => {
    if (!id) return;
    showLoading();
    async function runAsync() {
      try {
        const _program = await getProgram.execute({ id });
        setState({
          programModules: _program.modules,
          allowSpecialtyDuringSignup: _program.allowSpecialtyDuringSignup,
        });
        formRef.current.setData(_program);
      } catch (err) {
        toast.error(t('err.failedToLoadData'));
        push(PROGRAMS_ROUTE);
      } finally {
        hideLoading();
      }
    }
    runAsync();
  }, [id, program]);

  return (
    <Box className={classes.container}>
      <Paper className={classes.container}>
        {!id ? (
          <PaperTitle title={t(`navigation.${PROGRAM_REGISTER_ROUTE}`)} />
        ) : (
          <PaperTitle title={t(`navigation.${PROGRAM_EDIT_ROUTE}`)} />
        )}
        <PaperContent>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Grid container spacing={2} direction="column">
              <Grid item xs={12} sm="auto">
                <Input
                  name="name"
                  variant="outlined"
                  label={t('name')}
                  className={classes.input}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm="auto">
                <ProgramModulesSelect
                  value={state.programModules}
                  onChange={handleProgramModuleChange}
                />
              </Grid>
              <Grid item xs={12} sm="auto">
                <Input
                  name="consentTerm"
                  variant="outlined"
                  label={t('consentTerm')}
                  className={classes.input}
                  disabled={isLoading}
                  multiline
                  rows={4}
                  optional
                />
              </Grid>
              <Grid item xs={12} sm="auto">
                <Input
                  name="slug"
                  variant="outlined"
                  label={t('slug')}
                  className={classes.input}
                  disabled={isLoading}
                  optional
                />
              </Grid>
              <Grid item xs={12} sm="auto">
                <Input
                  name="styles"
                  variant="outlined"
                  label={t('styles')}
                  className={classes.input}
                  disabled={isLoading}
                  multiline
                  rows={6}
                  optional
                />
              </Grid>
              <Grid item xs={12} sm="auto">
                <Input
                  name="wsAcessoCode"
                  variant="outlined"
                  label={t('wsAcessoCode')}
                  className={classes.input}
                  disabled={isLoading}
                  optional
                />
              </Grid>
              <Grid item xs={12} sm="auto">
                <Input
                  name="customHtmlFooter"
                  variant="outlined"
                  label={t('customHtmlFooter')}
                  className={classes.input}
                  disabled={isLoading}
                  multiline
                  rows={6}
                  optional
                />
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={state.allowSpecialtyDuringSignup}
                        onChange={handleAllowSpecialtyDuringSignupChange}
                        icon={<CheckboxBlankCircleOutline />}
                        checkedIcon={<CheckboxMarkedCircle />}
                      />
                    )}
                    label={t('allowSpecialtyDuringSignup')}
                  />
                </FormGroup>
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" color="primary">
                  {t('save')}
                </Button>
              </Grid>
            </Grid>
          </Form>
        </PaperContent>
      </Paper>
    </Box>
  );
}
