import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  cell: {
    paddingRight: theme.spacing(2),
  },
  bottomPadding: {
    paddingBottom: 20,
  },
  helperText: {
    position: 'absolute',
    bottom: -18,
  },
}));
