import React from 'react';
import {
  User,
  t,
  UserGroupUser,
  UserGroup,
} from '@psp/common';
import { Pencil } from 'mdi-material-ui';
import Table, { TableProps } from '../Table';

const CompareByEquality = (column: keyof {
    id: string;
    [key: string]: unknown;
}) => (a: {
    id: string;
    [key: string]: unknown;
}, b: {
    id: string;
    [key: string]: unknown;
}) => {
  if (a[column] === b[column]) {
    return 0;
  }
  return 1;
};

const columns = [
  {
    key: 'userGroup',
    label: t('name'),
    compareFunction: CompareByEquality('userGroup'),
    render: (userGroup?: UserGroup) => (userGroup ? userGroup.name : '-'),
  },
  {
    key: 'manager',
    label: t('manager'),
    compareFunction: CompareByEquality('manager'),
    render: (manager?: User) => (manager ? manager.name : '-'),
  },
];

export type UserGroupUsersTableProps = Pick<TableProps, 'onRemove'> & {
  data: UserGroupUser[];
  handleAdd: () => void;
  handleEdit: (item: any) => void;
};

export default function UserGroupUsersTable({
  data,
  handleAdd,
  handleEdit,
  onRemove,
}: UserGroupUsersTableProps): JSX.Element {
  const extraActions = [
    {
      icon: <Pencil />,
      label: '',
      onExecute: (item: any) => handleEdit(item),
    },
  ];

  return (
    <Table
      columns={columns}
      data={data}
      title={t('userUserGroups')}
      size="small"
      canAdd
      addAction={handleAdd}
      canRemove
      onRemove={onRemove}
      extraActions={extraActions}
    />
  );
}
