"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateCpf = void 0;
var getVerifierDigit = function (digits) {
    var numbers = digits.split('').map(function (n) { return parseInt(n, 10); });
    var modulus = numbers.length + 1;
    var multiplied = numbers.map(function (number, index) { return number * (modulus - index); });
    var mod = multiplied.reduce(function (buffer, number) { return buffer + number; }) % 11;
    return mod < 2 ? 0 : 11 - mod;
};
var validateCpf = function (value, isOptional) {
    if (isOptional === void 0) { isOptional = false; }
    if (!value)
        return isOptional;
    var digits = value.replace(/\D/g, '');
    if (digits.length !== 11) {
        return false;
    }
    var numbersCPF = digits.split('');
    var number = numbersCPF[0];
    var repeatedNumber = true;
    for (var i = 1; i < digits.length; i++) {
        if (number !== numbersCPF[i]) {
            repeatedNumber = false;
        }
    }
    if (repeatedNumber === true) {
        return false;
    }
    var numbers = digits.substr(0, 9);
    numbers += getVerifierDigit(numbers);
    numbers += getVerifierDigit(numbers);
    return numbers.substr(-2) === value.substr(-2);
};
exports.validateCpf = validateCpf;
