import React from 'react';
import { Grid, Step, StepLabel, StepLabelProps, StepProps, Stepper, Typography } from '@material-ui/core';
import { dt } from '@psp/common';

import { Done, HourglassEmptySharp, WatchLaterSharp } from '@material-ui/icons';

import { useStyles, CustomConnector } from './styles';

export type StepperProps = {
  id: string;
  description: string;
  date: string;
  status: 'COMPLETED' | 'WAITING' | 'NOT_STARTED' // Adjust status type
}

export type PatientAccessPhaseProps = {
  data: StepperProps[];
};

export default function PatientAccessPhase({ data }: PatientAccessPhaseProps): JSX.Element {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <div className={classes.scrollableContainer}>
        <Stepper alternativeLabel connector={<CustomConnector />}>
          {data.reverse().map((item) => {
            const stepProps: StepProps = {};
            const labelProps: StepLabelProps = {};

            labelProps.optional = (
              <Typography variant="caption">{dt(new Date(item.date))}</Typography>
            );

            if (item.status === 'COMPLETED') {
              stepProps.completed = true;
              labelProps.StepIconComponent = Done;
              labelProps.StepIconProps = {
                className: classes.green,
              };
            }

            if (item.status === 'WAITING') {
              stepProps.completed = false;
              stepProps.active = true;
              labelProps.StepIconComponent = WatchLaterSharp;
              labelProps.StepIconProps = {
                className: classes.waitingAuthorization,
              };
            }

            if (item.status === 'NOT_STARTED') {
              stepProps.completed = false;

              labelProps.StepIconComponent = HourglassEmptySharp;
              labelProps.StepIconProps = {
                className: classes.notStarted,
              };
            }

            return (
              <Step key={item.id} {...stepProps}>
                <StepLabel
                  className={classes.labelContainer}
                  {...labelProps}
                >
                  {item.description}
                </StepLabel>

              </Step>
            );
          })}

        </Stepper>
      </div>

    </Grid>

  );
}
