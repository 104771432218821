import React, { useCallback, useRef, useState } from 'react';
import {
  Box,
  Grid,
  Paper,
  MenuItem,
} from '@material-ui/core';
import { Form } from '@unform/web';
import {
  RegisterProcedure,
  RegisterProcedureParams,
  ValidationError,
  extractValidationErrors,
  supplyRequestForm,
  t,
  ProcedureType,
  GetProgramSupplyGroups,
  GetSupplyGroupSupplies,
  GetUserAddress,
  Supply,
  UserAddress,
  Institution,
  GetAddressByCEP,
} from '@psp/common';
import { toast } from 'react-toastify';

import { useLoading } from '../../../contexts/loading.context';
import Input from '../../Input';
import CheckboxInput from '../../CheckboxInput';
import Button from '../../Button';
import SupplySelect, { SupplySelectValues } from '../../SupplySelect';

import { useStyles } from './styles';
import { useProgram } from '../../../contexts/program.context';
import PaperTitle from '../../PaperTitle';
import PaperContent from '../../PaperContent';
import { useAuth } from '../../../contexts/auth.context';
import Select from '../../Select';
import DocumentInput from '../../DocumentInput';

export type SupplyRequestProps = {
  getProgramSupplyGroups: GetProgramSupplyGroups;
  getSupplyGroupSupplies: GetSupplyGroupSupplies;
  getUserAddress: GetUserAddress;
  getAddressByCEP: GetAddressByCEP;
  registerProcedure: RegisterProcedure;
};

type SupplyRequestState = {
  supply?: Supply;
  recipientTypes: string[];
  showFieldCpfRequester: boolean;
  showFieldRecipientTypes: boolean;
  institution?: Institution;
  quantityOptions: number[];
};

const defaultState: SupplyRequestState = {
  supply: undefined,
  recipientTypes: [],
  showFieldCpfRequester: false,
  showFieldRecipientTypes: false,
  institution: undefined,
  quantityOptions: [],
};

export default function SupplyRequest({
  getUserAddress,
  getAddressByCEP,
  registerProcedure,
  ...dispatchers
}: SupplyRequestProps): JSX.Element {
  const classes = useStyles();
  const formRef = useRef({} as any);
  const { isLoading, showLoading, hideLoading, showBackdrop, hideBackdrop } = useLoading();
  const { program } = useProgram();
  const { user } = useAuth();
  const [state, setState] = useState<SupplyRequestState>(defaultState);

  const handleSupplyChange = useCallback(
    (value: SupplySelectValues) => {
      if (!value || !value.supplyGroupId || !value.supplyId || !value.institutionId) return;
      showLoading();
      const recipientTypes = value.supply?.recipientTypes?.split(',') ?? [];
      const quantityOptions: any[] = [];
      if (value?.supply?.maxQuantityPerRequest) {
        for (let i = 1; i <= value?.supply?.maxQuantityPerRequest; i++) {
          quantityOptions.push(i);
        }
      }
      setState({
        ...state,
        supply: value.supply,
        showFieldCpfRequester: value.supply?.showFieldCpfRequester ?? false,
        showFieldRecipientTypes: value.supply?.showFieldRecipientTypes ?? false,
        recipientTypes,
        quantityOptions,
        institution: value.institution,
      });
      formRef.current.setErrors({});
      (async () => {
        try {
          let userAddress: UserAddress | undefined = await getUserAddress.execute({});
          if (userAddress && recipientTypes.includes(userAddress.recipientType)) {
            userAddress.reuse = true;
          } else {
            userAddress = undefined;
          }
          formRef.current.setData({
            supplyGroupId: value.supplyGroupId,
            supplyId: value.supplyId,
            institutionId: value.institutionId,
            quantity: '',
            email: user.email,
            userAddress,
          });
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    },
    [state],
  );

  const handleCEPChange = (value: string) => {
    let cep = value;
    cep = cep.replace(/\D/g, '');

    if (cep.length > 7) {
      cep = cep.substring(0, 8);

      (async () => {
        showLoading();
        try {
          const addressByCEP = await getAddressByCEP.execute({
            cep,
          });

          if (!addressByCEP.erro) {
            formRef.current.setData({
              ...formRef.current.getData(),
              userAddress: {
                ...formRef.current.getData().userAddress,
                cep: addressByCEP.cep,
                placeDescription: addressByCEP.logradouro,
                complement: addressByCEP.complemento,
                neighborhood: addressByCEP.bairro,
                city: addressByCEP.localidade,
                state: addressByCEP.uf,
              },
            });
          } else {
            formRef.current.setData({
              ...formRef.current.getData(),
              userAddress: {
                cep,
                placeDescription: '',
                number: '',
                complement: '',
                neighborhood: '',
                city: '',
                state: '',
                reuse: formRef.current.getData().userAddress.reuse,
              },
            });
          }
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    } else if (cep.length > 5) {
      cep = `${cep.slice(0, 5)}-${cep.slice(5)}`;

      formRef.current.setData({
        ...formRef.current.getData(),
        userAddress: {
          ...formRef.current.getData().userAddress,
          cep,
        },
      });
    }
  };

  const handleSubmit = useCallback(
    (data: any): void => {
      if (!program) return;
      showLoading();
      (async () => {
        try {
          console.log(state);
          const req = ((await supplyRequestForm.validate(
            {
              ...data,
              type: ProcedureType.SUPPLY,
              programId: program!.id,
            },
            {
              abortEarly: false,
              context: {
                maxQuantity: state.supply?.maxQuantityPerRequest,
                showFieldCpfRequester: state.showFieldCpfRequester,
                showFieldRecipientTypes: state.showFieldRecipientTypes,
              },
            },
          )) as unknown) as RegisterProcedureParams;
          await registerProcedure.execute({
            ...req,
            groupId: data.supplyGroupId,
            itemIds: [data.supplyId],
            institutionId: data.institutionId,
          });
          toast.success(t('msg.successToRequestSupply'), {
            onOpen: showBackdrop,
            onClose: hideBackdrop,
          });
          formRef.current.reset();
          setState(defaultState);
        } catch (err) {
          console.log(err);
          if (err.message === 'existingProcedure') {
            toast.info(t('err.existingSupplyRequest'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } else {
            let errors = {};
            if (err instanceof ValidationError) {
              errors = extractValidationErrors(err);
              formRef.current.setErrors(errors);
            } else if (err.name === 'BadRequestError') {
              toast.info(err.message, {
                onOpen: showBackdrop,
                onClose: hideBackdrop,
              });
            } else {
              toast.info(t('err.failedToRequestSupply'), {
                onOpen: showBackdrop,
                onClose: hideBackdrop,
              });
            }
          }
        } finally {
          hideLoading();
        }
      })();
    },
    [state, program],
  );

  return (
    <Box className={classes.container}>
      <Paper className={classes.paper}>
        <PaperTitle title={t('selectSupply')} />
        <PaperContent>
          <Form
            onSubmit={handleSubmit}
            ref={formRef}
            initialData={{ patient: { acceptSms: true, acceptEmail: true } }}
          >
            <Grid container spacing={2} direction="column">
              <Grid item xs={12} sm={12} lg={12}>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  alignItems="center"
                  className={classes.nowrap}
                >
                  <Grid item xs={12} sm={12} lg={12}>
                    <SupplySelect
                      {...dispatchers}
                      formRef={formRef}
                      onSelectedValueChange={handleSupplyChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {state.institution !== undefined && (
                <Grid item>
                  <Grid container spacing={2} direction="column" className={classes.nowrap}>
                    <Grid item>
                      <Grid container spacing={2} direction="row">
                        {state.quantityOptions && (
                          <Grid item xs={12} sm={3} lg={3}>
                            <Select
                              label={t('quantity')}
                              variant="outlined"
                              name="quantity"
                              fullWidth
                              disabled={isLoading}
                            >
                              {state.quantityOptions?.map((qo, i) => (
                                <MenuItem key={i} value={qo}>
                                  {qo}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                        )}
                        {state.showFieldRecipientTypes && (
                          <Grid item xs={12} sm={3} lg={3}>
                            <Select
                              name="userAddress.recipientType"
                              label={t('recipientType')}
                              variant="outlined"
                              fullWidth
                            >
                              {state.recipientTypes?.map((rt, i) => (
                                <MenuItem
                                  key={i}
                                  value={rt}
                                >
                                  {t(rt.toLowerCase())}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                        )}
                        {state.showFieldCpfRequester && (
                          <Grid item xs={12} sm={3} lg={3}>
                            <Input
                              name="userAddress.recipientCpf"
                              variant="outlined"
                              label={t('recipientCpf')}
                              className={classes.input}
                              disabled={isLoading}
                              autoComplete="off"
                              InputProps={{
                                inputComponent: DocumentInput as any,
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={5} lg={5}>
                          <Input
                            name="userAddress.recipientName"
                            variant="outlined"
                            label={t('recipientName')}
                            className={classes.input}
                            disabled={isLoading}
                            autoComplete="off"
                          />
                        </Grid>
                        <Grid item xs={12} sm={5} lg={5}>
                          <Input
                            name="email"
                            variant="outlined"
                            label={t('email')}
                            className={classes.input}
                            disabled={isLoading}
                            autoComplete="off"
                            maxLength={200}
                          />
                        </Grid>
                        <Grid item sm={2} lg={2} />
                        <Grid item xs={12} sm={3} lg={3}>
                          <Input
                            name="userAddress.cep"
                            variant="outlined"
                            label={t('cep')}
                            className={classes.input}
                            disabled={isLoading}
                            autoComplete="off"
                            maxLength={9}
                            onValueChange={handleCEPChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={9} lg={9}>
                          <Input
                            name="userAddress.placeDescription"
                            variant="outlined"
                            label={t('placeDescription')}
                            className={classes.input}
                            disabled={isLoading}
                            autoComplete="nope"
                            maxLength={150}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3} lg={3}>
                          <Input
                            name="userAddress.number"
                            variant="outlined"
                            label={t('number')}
                            className={classes.input}
                            disabled={isLoading}
                            autoComplete="nope"
                            maxLength={20}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4}>
                          <Input
                            name="userAddress.complement"
                            variant="outlined"
                            label={t('complement')}
                            className={classes.input}
                            disabled={isLoading}
                            autoComplete="nope"
                            maxLength={50}
                            optional
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                          <Input
                            name="userAddress.observation"
                            variant="outlined"
                            label={t('observation')}
                            className={classes.input}
                            disabled={isLoading}
                            autoComplete="nope"
                            maxLength={50}
                            optional
                          />
                        </Grid>
                        <Grid item xs={12} sm={5} lg={5}>
                          <Input
                            name="userAddress.neighborhood"
                            variant="outlined"
                            label={t('neighborhood')}
                            className={classes.input}
                            disabled={isLoading}
                            autoComplete="nope"
                            maxLength={50}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4}>
                          <Input
                            name="userAddress.city"
                            variant="outlined"
                            label={t('city')}
                            className={classes.input}
                            disabled={isLoading}
                            autoComplete="nope"
                            maxLength={50}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4}>
                          <Input
                            name="userAddress.state"
                            variant="outlined"
                            label={t('state')}
                            className={classes.input}
                            disabled={isLoading}
                            autoComplete="nope"
                            maxLength={50}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={2} direction="row" alignItems="center">
                        <Grid item xs={12} sm>
                          <CheckboxInput
                            name="userAddress.reuse"
                            label={t('pages.supplies.request.saveAddress')}
                            disabled={isLoading}
                          />
                        </Grid>
                        <Grid item>
                          <Button type="submit" variant="contained" color="primary">
                            Solicitar
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Form>
        </PaperContent>
      </Paper>
    </Box>
  );
}
