import React from 'react';

export type TabPanelProps = {
  children: React.ReactElement;
  index: number;
  name: string;
  value: number;
  [key: string]: any;
};

export default function TabPanel({
  children,
  index,
  name,
  value,
  ...rest
}: TabPanelProps): JSX.Element {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${name}-tabpanel-${index}`}
      aria-labelledby={`${name}-tab-${index}`}
      {...rest}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
