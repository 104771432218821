import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  t,
  emptyFunction,
  ProcedureStatus,
  ProcedureStatusLabel,
} from '@psp/common';
import { Form } from '@unform/web';
import { useProgram } from '../../contexts/program.context';
import { useLoading } from '../../contexts/loading.context';
import CheckboxSelect from '../CheckboxSelect';

export type StatusSelectProps = {
  onChange: (e: any) => void;
  multiple: boolean;
};

type StatusSelectState = {
  status: any[];
};

const defaultState: StatusSelectState = {
  status: [],
};

export default function StatusSelect({
  onChange,
  multiple,
}: StatusSelectProps): JSX.Element {
  const { showLoading, hideLoading } = useLoading();
  const { program } = useProgram();
  const [state, setState] = useState<StatusSelectState>(defaultState);
  const formRef = useRef({} as any);

  useEffect(
    useCallback(() => {
      if (!program) return;
      showLoading();
      setState({
        ...defaultState,
      });
      (async () => {
        try {
          const status = Object.keys(ProcedureStatus).map((key) => ({
            id: key,
            name: ProcedureStatusLabel[ProcedureStatus[key as keyof typeof ProcedureStatus]],
          }));
          setState({
            ...defaultState,
            status,
          });
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    }, [state]),
    [program],
  );

  return (
    <Form ref={formRef} onSubmit={emptyFunction}>
      <CheckboxSelect
        name="status"
        label={t('status')}
        items={state.status.map((f: any) => ({
          id: f.id, name: f.name,
        }))}
        onChange={onChange}
        style={{ width: 150 }}
        multiple={multiple}
      />
    </Form>
  );
}
