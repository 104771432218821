"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcedureUtils = void 0;
var ducks_1 = require("../../ducks");
var ProcedureUtils = /** @class */ (function () {
    function ProcedureUtils() {
    }
    ProcedureUtils.CheckIfIsPartial = function (children) {
        var concludedStatus = [ducks_1.ProcedureStatus.CONCLUDED];
        var pendingStatus = [ducks_1.ProcedureStatus.OPEN, ducks_1.ProcedureStatus.APPROVED, ducks_1.ProcedureStatus.SCHEDULED];
        var partialStatus = [ducks_1.ProcedureStatus.APPROVED, ducks_1.ProcedureStatus.SCHEDULED];
        var hasConcluded = children.some(function (c) { return concludedStatus.includes(c.status); });
        var hasPending = children.some(function (c) { return pendingStatus.includes(c.status); });
        var hasPartialStatus = children.every(function (c) { return partialStatus.includes(c.status); });
        var allTypes = [];
        var hasDifferentPendingTypes = false;
        for (var _i = 0, children_1 = children; _i < children_1.length; _i++) {
            var procedure = children_1[_i];
            allTypes.push(procedure.status);
        }
        hasDifferentPendingTypes = !allElementsAreSame(allTypes) && hasPartialStatus;
        return (hasConcluded && hasPending) || hasDifferentPendingTypes;
    };
    ProcedureUtils.CheckIfPartial = function (children, isTravel, multiboxPosition) {
        var concludedStatus = [ducks_1.ProcedureStatus.CONCLUDED];
        var pendingStatus = [ducks_1.ProcedureStatus.OPEN, ducks_1.ProcedureStatus.APPROVED, ducks_1.ProcedureStatus.SCHEDULED];
        var partialStatus = [ducks_1.ProcedureStatus.APPROVED, ducks_1.ProcedureStatus.SCHEDULED];
        var hasConcluded = children.some(function (c) { return concludedStatus.includes(c.status); });
        var hasPending = children.some(function (c) { return pendingStatus.includes(c.status); });
        var hasPartialStatus = children.every(function (c) { return partialStatus.includes(c.status); });
        if (!isTravel)
            return (hasConcluded && hasPending);
        var allTypes = [];
        var hasDifferentPendingTypes = false;
        for (var _i = 0, children_2 = children; _i < children_2.length; _i++) {
            var procedure = children_2[_i];
            allTypes.push(procedure.status);
        }
        hasDifferentPendingTypes = !allElementsAreSame(allTypes) && hasPartialStatus;
        return (hasConcluded && hasPending && multiboxPosition === ducks_1.ProcedureStatus.CONCLUDED) || hasDifferentPendingTypes;
    };
    return ProcedureUtils;
}());
exports.ProcedureUtils = ProcedureUtils;
function allElementsAreSame(array) {
    if (array.length <= 1) {
        return true;
    }
    for (var i = 1; i < array.length; i++) {
        if (array[i] !== array[i - 1]) {
            return false;
        }
    }
    return true;
}
