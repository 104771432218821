import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  link: {
    color: '#000',
    textDecoration: 'none',
  },
  boxLink: {
    display: 'flex',
    alignItems: 'center',
  },
  boxContent: {
    // fontSize: 18,
  },
  icon: {
    color: '#000',
    width: 36,
    height: 36,
  },
  text: {
    fontSize: 20,
    marginLeft: theme.spacing(2),
  },
  paperContent: {
    padding: '4px 0px',
    position: 'relative',
  },
  badge: {
    position: 'absolute',
    right: 16,
    top: 9,
    height: 60,
    minWidth: 60,
    color: theme.palette.common.white,
    background: 'var(--primary-main)',
    borderRadius: 4,
    '@media (min-width: 600px) and (max-width: 1200px)': {
      height: 40,
      minWidth: 40,
      top: 7,
      right: 12,
    },
  },
  badgeContent: {
    padding: '0 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  badgeText: {
    '@media (min-width: 600px) and (max-width: 1200px)': {
      fontSize: '0.8rem',
    },
  },
  moduleCardImg: {
    height: 100,
    width: 'auto',
  },
}));
