import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    '& .column': {
      width: 300,
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    width: '96%',
    height: 38,
  },
}));
