import React from 'react';
import { getFollowupsList } from '@psp/common';

import FollowupList, { FollowupListProps } from '../../components/Followup/List';

const dispatchers: Pick<FollowupListProps, 'getFollowups'> = {
  getFollowups: getFollowupsList,
};

export default function FollowupListComposer(): JSX.Element {
  return <FollowupList {...dispatchers} />;
}
