import React from 'react';
import { PatientExamSubStatus, PatientExamSubStatusLabel } from '@psp/common';
import Chip, { ChipProps } from '../Chip';

export type ProcedureSubStatusChipProps = ChipProps & {
  subStatus: PatientExamSubStatus;
};

export default function ProcedureSubStatusChip({
  subStatus,
  ...props
}: ProcedureSubStatusChipProps): JSX.Element {
  const statusLabel = PatientExamSubStatusLabel[subStatus];
  if (!statusLabel) {
    return <>-</>;
  }
  return <Chip label={statusLabel} size="small" {...props} />;
}
