"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultMimeType = exports.fileMimeTypes = void 0;
exports.fileMimeTypes = {
    '.jpg': 'image/jpeg',
    '.jpeg': 'image/jpeg',
    '.png': 'image/png',
    '.gif': 'image/gif',
    '.svg': 'image/svg+xml',
    '.webp': 'image/webp',
    '.bmp': 'image/bmp',
    '.tiff': 'image/tiff',
    '.tif': 'image/tiff',
    '.heic': 'image/heic',
    '.heif': 'image/heif',
};
exports.defaultMimeType = 'application/octet-stream';
