import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Grid, Paper } from '@material-ui/core';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import {
  t,
  GetUser,
  RegisterUser,
  extractValidationErrors,
  ValidationError,
  RegisterUserParams,
  userDoctorRegisterForm,
  signupDoctorSearchForm,
  GetDoctorByCrmParams,
  GetDoctorByCrm,
  GetDoctorAddress,
  RegisterDoctorAddress,
  DoctorAddress,
  Doctor,
} from '@psp/common';

import { USER_DETAILS_ROUTE, PASSWORD_CHANGE_ROUTE } from '../../../constants';
import { useLoading } from '../../../contexts/loading.context';
import { useAuth } from '../../../contexts/auth.context';
import Button from '../../Button';
import CrmInput from '../../CrmInput';
import Input from '../../Input';
import MaskedPhoneInput from '../../MaskedPhoneInput';
import PageTitle from '../../PageTitle';
import PageSubtitle from '../../PageSubtitle';
import StateSelect from '../../StateSelect';

import { useStyles } from './styles';
import { useProgram } from '../../../contexts/program.context';
import PaperTitle from '../../PaperTitle';
import PaperContent from '../../PaperContent';
import DateInput from '../../DateInput';
import DocumentInput from '../../DocumentInput';
import CepRegister from '../../CepRegister';

export type UserDetailsProps = {
  getUser: GetUser;
  registerUser: RegisterUser;
  getDoctorByCrm: GetDoctorByCrm;
  getDoctorAddress: GetDoctorAddress
  registerDoctorAddress: RegisterDoctorAddress
};

export default function UserDetails({
  getUser,
  registerUser,
  getDoctorByCrm,
  getDoctorAddress,
  registerDoctorAddress,
}: UserDetailsProps): JSX.Element {
  const classes = useStyles();
  const formRef = useRef({} as any);
  const [isAddressEdited, setIsAddressEdited] = useState(false);
  const [isUserEdited, setIsUserEdited] = useState(false);
  const [address, setAddress] = useState({} as DoctorAddress);
  const [doctor, setDoctor] = useState({} as Doctor);
  const { user, isDoctor } = useAuth();
  const { userId } = user;
  const { isLoading, showLoading, hideLoading, showBackdrop, hideBackdrop } = useLoading();
  const { program } = useProgram();

  const checkCRM = async (crm: string, uf: string) => {
    try {
      const data = {
        uf,
        crm: crm.replace(/^0*(.*)$/, '$1'),
      };
      if (data.crm !== doctor.crm) {
        const req = (await signupDoctorSearchForm.validate(data, {
          abortEarly: false,
        })) as GetDoctorByCrmParams;
        const res = await getDoctorByCrm.execute({
          ...req,
          programId: program?.id,
        });
      }
    } catch (err) {
      if (err.message === 'doctorExists') {
        toast.info(t('err.crmArealdyInUse'), {
          onOpen: showBackdrop,
          onClose: hideBackdrop,
        });
      } else if (err.message === 'invalidCRM') {
        toast.info(t('validation.invalidCRM'), {
          onOpen: showBackdrop,
          onClose: hideBackdrop,
        });
      } else {
        let errors = {};
        if (err instanceof ValidationError) {
          errors = extractValidationErrors(err);
          formRef.current.setErrors(errors);
        } else {
          toast.info(t('err.failedToSearchDoctor'), {
            onOpen: showBackdrop,
            onClose: hideBackdrop,
          });
        }
      }
    }
  };

  const writeAddress = (userAddress: DoctorAddress) => {
    const formData = formRef.current.getData();
    for (const key in formData.userAddress) {
      if (key) {
        const fieldName = key === 'placeDescription' ? 'street' : key;
        formRef.current.setFieldValue(`userAddress.${key}`, userAddress[fieldName as keyof DoctorAddress]);
      }
    }
    setAddress({ ...userAddress });
  };

  const readAddress = (): DoctorAddress => {
    const userAddress = {} as DoctorAddress;
    const formData = formRef.current.getData();
    for (const key in formData.userAddress) {
      if (key) {
        const fieldName = key === 'placeDescription' ? 'street' : key;
        userAddress[fieldName as keyof DoctorAddress] = formData.userAddress[key];
      }
    }
    userAddress.id = address.id;
    setAddress({ ...userAddress });
    return userAddress;
  };

  const setData = async () => {
    if (!userId) return;
    showLoading();
    async function runAsync() {
      try {
        const userInfos = await getUser.execute({ userId, programId: program?.id });
        formRef.current.setData(userInfos);

        if (isDoctor && userInfos.doctor && program) {
          setDoctor({ ...userInfos.doctor });

          const userAddress = await getDoctorAddress.execute({
            doctorId: userInfos.doctor.id,
            programId: program.id,
          });

          if (userAddress.length) {
            writeAddress(userAddress[0]);
            setAddress(userAddress[0]);
          }
        }
      } catch (err) {
        console.log({ err });
        toast.error(t('err.failedToLoadUser'));
      } finally {
        hideLoading();
      }
    }
    runAsync();
  };

  const handleSubmit = useCallback(
    async (data) => {
      if (!isAddressEdited && !isUserEdited) {
        return;
      }
      showLoading();
      try {
        if (isUserEdited) {
          await checkCRM(data.doctor.crm, data.doctor.uf);

          const req = (await userDoctorRegisterForm.validate({
            ...data,
            type: 'DOCTOR',
          }, {
            abortEarly: false,
          })) as RegisterUserParams;

          await registerUser.execute({
            id: userId,
            ...req,
            programId: program?.id || '',
          });
          setIsUserEdited(false);
        }

        if (isAddressEdited) {
          const userAddress = readAddress();
          await registerDoctorAddress.execute({
            doctorId: doctor.id,
            programId: program?.id || '',
            address: {
              ...userAddress,
              recipientName: formRef.current.getFieldValue('name'),
            },
          });
          setIsAddressEdited(false);
        }

        setData();
        toast.success(t('msg.successToUpdateUser'), {
          onOpen: showBackdrop,
          onClose: hideBackdrop,
        });
      } catch (err) {
        console.debug({ err });
        let errors = {};
        if (err instanceof ValidationError) {
          errors = extractValidationErrors(err);
          formRef.current.setErrors(errors);
        } else if (err.message === 'emailAlreadyInUse') {
          toast.warning(t('err.emailAlreadyInUse'), {
            onOpen: showBackdrop,
            onClose: hideBackdrop,
          });
        } else {
          toast.error(t('err.failedToUpdateUser'), {
            onOpen: showBackdrop,
            onClose: hideBackdrop,
          });
        }
      } finally {
        hideLoading();
      }
    },
    [userId, program, isAddressEdited, isUserEdited],
  );

  const handleReset = useCallback(
    (data) => {
      if (isUserEdited || isAddressEdited) {
        setData();
        setIsAddressEdited(false);
        setIsUserEdited(false);
      }
    },
    [userId, program, isUserEdited, isAddressEdited],
  );

  const handleChangePasswordClick = () => {
    window.location.href = PASSWORD_CHANGE_ROUTE;
  };

  const handleChange = (event: React.FormEvent<HTMLFormElement>) => {
    const target = event.target as HTMLInputElement;
    if (target.name.includes('userAddress')) {
      setIsAddressEdited(true);
    } else {
      setIsUserEdited(true);
    }
  };

  useEffect(() => {
    setData();
  }, [userId, program]);

  return (
    <Box className={classes.container}>
      <Paper className={classes.container}>
        <PaperTitle title={t(`navigation.${USER_DETAILS_ROUTE}`)} />
        <PaperContent>
          <Form ref={formRef} onSubmit={handleSubmit} onChange={handleChange}>
            <Grid container justify="space-evenly" direction="row">
              <Grid className="column" item>
                <Grid container spacing={2} direction="column">
                  <Grid item xs={12} sm="auto">
                    <Input
                      name="name"
                      variant="outlined"
                      label={t('name')}
                      className={classes.input}
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12} sm="auto">
                    <Input
                      name="email"
                      variant="outlined"
                      label={t('email')}
                      className={classes.input}
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12} sm="auto">
                    <Input
                      name="phone"
                      variant="outlined"
                      label={t('cellphone')}
                      className={classes.input}
                      disabled={isLoading}
                      autocomplete="nope"
                      InputProps={{
                        inputComponent: MaskedPhoneInput as any,
                      }}
                    />
                  </Grid>
                  {isDoctor && (
                    <Grid item container spacing={2} direction="column">
                      <Grid item xs={12} sm="auto">
                        <Input
                          name="doctor.cpf"
                          variant="outlined"
                          label={t('cpf')}
                          className={classes.input}
                          disabled={isLoading || doctor.cpf !== null}
                          optional
                          autoComplete="off"
                          InputProps={{
                            inputComponent: DocumentInput as any,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm="auto">
                        <DateInput
                          name="doctor.birthDate"
                          inputVariant="outlined"
                          label={t('birthDate')}
                          format="dd/MM/yyyy"
                          optional
                          className={classes.input}
                          disabled={isLoading || doctor.birthDate !== null}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm="auto">
                        <Input
                          name="doctor.crm"
                          variant="outlined"
                          label={t('crm')}
                          className={classes.input}
                          disabled={isLoading}
                          InputProps={{
                            inputComponent: CrmInput as any,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm="auto">
                        <StateSelect
                          name="doctor.uf"
                          variant="outlined"
                          label={t('uf')}
                          fullWidth
                          displayEmpty
                          className={classes.input}
                          disabled={isLoading}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid className="column" item>
                <CepRegister formRef={formRef} />
              </Grid>
            </Grid>
            <Grid container justify="space-evenly" direction="row" style={{ marginTop: '40px' }}>
              <Button type="reset" variant="contained" color="primary" onClick={handleReset}>
                {t('cancel')}
              </Button>
              <Button type="button" variant="contained" color="primary" onClick={handleChangePasswordClick}>
                {t('changePassword')}
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {t('save')}
              </Button>
            </Grid>
          </Form>
        </PaperContent>
      </Paper>
    </Box>
  );
}
