import React from 'react';
import { acceptPolicy } from '@psp/common';
import PendingUsagePolicies, { PendingUsagePoliciesProps } from '../../components/PendingUsagePolicies';

const dispatchers: Pick<
  PendingUsagePoliciesProps,
  'acceptPolicy'
> = {
  acceptPolicy,
};

export default function SignupComposer(): JSX.Element {
  return <PendingUsagePolicies {...dispatchers} />;
}
