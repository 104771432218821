import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  checkboxTable: {
    border: '1px dashed rgba(85, 98, 117, 0.5)',
    borderSpacing: 0,
    borderRadius: 8,
  },
  checkboxRowGroup: {},
  checkboxRow: {
    width: '100%',
    borderBottom: '1px solid #DADADA',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  checkboxCell: {
    padding: theme.spacing(0, 2),
    fontSize: 12,
    borderBottom: 'none !important',
    width: '50%',
  },
  checkboxCellSubvalue: {
    padding: theme.spacing(1, 2),
    verticalAlign: 'middle',
  },
  formControlLabel: {
    fontSize: 12,
  },
  formControlLabelRequired: {
    fontWeight: 'bold',
  },
}));
