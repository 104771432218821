"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
__exportStar(require("./add-user-permission-form"), exports);
__exportStar(require("./change-user-email-form"), exports);
__exportStar(require("./create-program-file-form"), exports);
__exportStar(require("./distribute-inventory-xlsx-form"), exports);
__exportStar(require("./exam-request-form"), exports);
__exportStar(require("./doctors-xlsx-form"), exports);
__exportStar(require("./medicine-release-form"), exports);
__exportStar(require("./patient-procedure-request-form"), exports);
__exportStar(require("./patient-confirmation-step-1-form"), exports);
__exportStar(require("./patient-confirmation-step-2-form"), exports);
__exportStar(require("./product-register-form"), exports);
__exportStar(require("./program-register-form"), exports);
__exportStar(require("./save-user-group-form"), exports);
__exportStar(require("./signin-form"), exports);
__exportStar(require("./signup-doctor-registration"), exports);
__exportStar(require("./signup-doctor-search"), exports);
__exportStar(require("./supply-request-form"), exports);
__exportStar(require("./user-register-form"), exports);
__exportStar(require("./followup-register-form"), exports);
__exportStar(require("./presentation-register-form"), exports);
__exportStar(require("./client-register-form"), exports);
__exportStar(require("./travel-request-form"), exports);
