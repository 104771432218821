import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Box } from '@material-ui/core';
import { SortingType, PatientMedicationFromPatientCareForList, GetPatientsMedicationsByCRMFromPatientCare } from '@psp/common';

import { useHistory, useParams } from 'react-router-dom';
import { useProgram } from '../../../contexts/program.context';

import { useStyles } from './styles';
import { PATIENT_DETAIL_ROUTE } from '../../../constants';
import MedicationAdminTable from '../../MedicationAdminTable';
import { useAuth } from '../../../contexts/auth.context';

export type MedicationAdminListProps = {
  getPatientsMedicationsByCRMFromPatientCare: GetPatientsMedicationsByCRMFromPatientCare;
};

type MedicationAdminListState = {
  patients: PatientMedicationFromPatientCareForList[];
  total: number;
};

type MedicationAdminListAllState = {
  patients: PatientMedicationFromPatientCareForList[];
  total: number;
};

type FilterState = {
  filter: {
    patientId: string ;
  };
  page: number;
  rows: number;
  timeout: number;
};

const defaultState: MedicationAdminListState = {
  patients: [],
  total: 0,
};

const defaultStateAll: MedicationAdminListAllState = {
  patients: [],
  total: 0,
};

export interface SortingConfiguration {
  propertyName: keyof {
    id: string;
    [key: string]: unknown;
  },
  sortType: SortingType,
  compareFunction: TableDataComparable
}

export type TableDataComparable = ((a: {
  id: string;
  [key: string]: unknown;
}, b: {
  id: string;
  [key: string]: unknown;
}) => number);

export default function MedicationAdminList(
  { getPatientsMedicationsByCRMFromPatientCare }: MedicationAdminListProps,
): JSX.Element {
  const { user } = useAuth();
  const classes = useStyles();
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();

  const defaultFilterState: FilterState = {
    filter: {
      patientId: id,
    },
    page: 0,
    rows: 10,
    timeout: 0,
  };

  const [state, setState] = useState<MedicationAdminListState>(defaultState);
  const [filterState, setFilterState] = useState<FilterState>(defaultFilterState);
  const { program } = useProgram();
  const [sortConfig, updateSortConfig] = useState<SortingConfiguration[]>([]);
  const [stateAll] = useState<MedicationAdminListAllState>(defaultStateAll);

  const handleSortBy = useCallback(
    (propertyName: keyof {
            id: string;
            [key: string]: unknown;
        }, compareFunction: TableDataComparable) => {
      let pendingChange = [...sortConfig];
      const index = pendingChange.findIndex((config) => config.propertyName === propertyName);
      if (index > -1) {
        const currentSortType = pendingChange[index].sortType;
        pendingChange.splice(index, 1);
        if (currentSortType === SortingType.Descending) {
          pendingChange = [
            ...pendingChange,
            {
              propertyName,
              sortType: SortingType.Ascending,
              compareFunction,
            },
          ];
        }
      } else {
        pendingChange = [
          ...pendingChange,
          {
            propertyName,
            sortType: SortingType.Descending,
            compareFunction,
          },
        ];
      }
      updateSortConfig([...pendingChange]);
    },
    [sortConfig],
  );

  useMemo(() => {
    if (sortConfig.length === 0) {
      return [...state.patients];
    }

    const sorted = [...stateAll.patients].sort(
      (a: {
                id: string;
                [key: string]: unknown;
            }, b: {
                id: string;
                [key: string]: unknown;
            }) => {
        for (const config of sortConfig) {
          const result = (config.compareFunction(a, b));
          if (result !== 0) {
            if (config.sortType === SortingType.Ascending) {
              return result;
            }
            return -result;
          }
        }
        return 0;
      },
    );

    setState({ ...state, patients: sorted });

    return (sorted);
  }, [sortConfig]);

  useEffect(() => {
    const handler = setTimeout(() => {
      (async () => {
        if (!program) return;
        try {
          const response = await getPatientsMedicationsByCRMFromPatientCare.execute({
            userId: user!.userId,
            pageNumber: (filterState.page + 1).toString(),
            pageSize: filterState.rows.toString(),
            patientId: filterState.filter.patientId ?? null,
          });

          stateAll.patients = response.patients;
          stateAll.total = response.total;
          setState({ ...response });
        } catch (err) {
          console.log(err);
        }
      })();
    }, filterState.timeout);
    return () => clearTimeout(handler);
  }, [filterState, program]);

  const handlePageChange = useCallback(
    (page: number) => {
      setFilterState({
        filter: filterState.filter,
        page,
        rows: filterState.rows,
        timeout: 200,
      });
    },
    [filterState],
  );

  const handleRowsChange = useCallback(
    (rows: number) => {
      setFilterState({
        filter: filterState.filter,
        page: filterState.page,
        rows,
        timeout: 200,
      });
      filterState.rows = rows;
    },
    [filterState],
  );

  const handleNavigateToPatientDetails = useCallback(
    (patient) => {
      push(PATIENT_DETAIL_ROUTE.replace(':id', patient.id));
    },
    [],
  );

  return (
    <Box className={classes.container}>
      <MedicationAdminTable
        count={state.total}
        data={state.patients}
        onChangePage={handlePageChange}
        onChangeRows={handleRowsChange}
        page={filterState.page}
        rowsPerPage={filterState.rows}
        onSortBy={handleSortBy}
        onSortConfig={sortConfig}
        handleNavigateToPatientDetails={handleNavigateToPatientDetails}
      />
    </Box>
  );
}
