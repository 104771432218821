import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: '200px',
  },
  hidden: {
    display: 'none',
  },
  nowrap: {
    flexWrap: 'nowrap',
  },
  input: {
    width: '100%',
    minWidth: '200px',
    margin: theme.spacing(1),
  },
  buttonSpacing: {
    marginTop: theme.spacing(2),
  },
  paper: {},
}));
