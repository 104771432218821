"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.userTypesMap = exports.userTypes = exports.UserType = void 0;
var UserType;
(function (UserType) {
    UserType["SYSADMIN"] = "SYSADMIN";
    UserType["ADMIN"] = "ADMIN";
    UserType["REGIONAL_MANAGER"] = "REGIONAL_MANAGER";
    UserType["DISTRICT_MANAGER"] = "DISTRICT_MANAGER";
    UserType["REPRESENTATIVE"] = "REPRESENTATIVE";
    UserType["DOCTOR"] = "DOCTOR";
})(UserType = exports.UserType || (exports.UserType = {}));
exports.userTypes = [
    'SYSADMIN',
    'ADMIN',
    'REGIONAL_MANAGER',
    'DISTRICT_MANAGER',
    'REPRESENTATIVE',
    'DOCTOR',
];
exports.userTypesMap = {
    SYSADMIN: __spreadArrays(exports.userTypes),
    ADMIN: exports.userTypes.slice(1),
    REGIONAL_MANAGER: exports.userTypes.slice(2),
    DISTRICT_MANAGER: exports.userTypes.slice(3),
    REPRESENTATIVE: exports.userTypes.slice(4),
};
