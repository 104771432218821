"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validatePhone = void 0;
var validatePhone = function (value) {
    return value && (value.length === 10 || value.length === 11);
};
exports.validatePhone = validatePhone;
/*
  value !== undefined
  && !!value.countryCode
  && value.countryCode.length === 2
  && !!value.areaCode
  && value.areaCode.length === 2
  && !!value.number
  && value.number.length === 9;
*/
