import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { t } from '@psp/common';

import { useStyles } from './styles';

export type BreadcrumbsProps = {
  title: string;
};

export default function Breadcrumbs({ title }: BreadcrumbsProps): JSX.Element {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item>
        <Typography variant="h2" className={classes.title}>
          {`${t('youAreAt')}:`}
          &nbsp;
          <strong>{title}</strong>
        </Typography>
      </Grid>
    </Grid>
  );
}
