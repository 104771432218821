import { colors, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    overflowX: 'hidden',
  },
  h1: {
    fontWeight: 700,
    fontSize: 64,
    textAlign: 'center',
    color: theme.palette.primary.light,
  },
  h2: {
    fontWeight: 700,
    fontSize: 24,
    textAlign: 'center',
    color: theme.palette.grey[500],
  },
  divButton: {
    position: 'absolute',
    top: '4px',
    right: '4px',
  },
  button: {
    background: 'var(--primary-main)',
    color: theme.palette.common.white,
  },
}));
