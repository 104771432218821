import React from 'react';
import { t } from '@psp/common';

import Breadcrumbs from '../../../components/Breadcrumbs';
import SupplyRequestComposer from '../../../composers/SupplyRequestComposer';
import { SUPPLY_REQUEST_ROUTE } from '../../../constants';

export default function SupplyRequestPage(): JSX.Element {
  return (
    <>
      <Breadcrumbs title={t(`navigation.${SUPPLY_REQUEST_ROUTE}`)} />
      <SupplyRequestComposer />
    </>
  );
}
