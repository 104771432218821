import React from 'react';
import { Box } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './styles';

export type PaperContentProps = {
  children?: React.ReactNode;
  className?: string;
};

export default function PaperContent({ children, className }: PaperContentProps): JSX.Element {
  const classes = useStyles();
  return <Box className={clsx(classes.container, className)}>{children}</Box>;
}
