import { makeStyles, Theme } from '@material-ui/core';

const minDrawerWidth = 70;
const drawerWidth = 260;

export const useStyles = makeStyles((theme: Theme) => ({
  brandImage: {},
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxShadow: '3px 0px 6px rgba(142, 142, 142, 0.2)',
  },
  drawerOpen: {
    overflowX: 'hidden',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: minDrawerWidth,
  },
  drawerList: {
    paddingLeft: 14,
    fontFamily: 'Arial, sans-serif',
  },
  drawerListShift: {},
  drawerListItem: {
    paddingLeft: 8,
    fontWeight: 'bold',
    paddingTop: 6,
    paddingBottom: 6,
  },
  drawerListItemShift: {
    paddingLeft: 8,
    paddingTop: 9,
    paddingBottom: 9,
  },
  listSubheader: {
    fontSize: 11,
    lineHeight: '32px',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    ...theme.mixins.toolbar,
    minHeight: 140,
  },
  listItemText: {
    whiteSpace: 'normal',
  },
  faqLink: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: '#063970',
    '&:visited': {
      color: '#063970',
    },
  },
  faq: {
    paddingLeft: '6px',
  },
}));
