import React from 'react';

import { getPatientDetailsFromPatientCare } from '@psp/common';
import PatientDetails, { PatientDetailsProps } from '../../components/Patient/Details';

const dispatchers: Pick<PatientDetailsProps, 'getPatientDetailsFromPatientCare'> = {
  getPatientDetailsFromPatientCare,
};

export default function PatientDetailsComposer(): JSX.Element {
  return <PatientDetails {...dispatchers} />;
}
