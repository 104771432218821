import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  content: {
    flex: 1,
    padding: 30,
  },
  flex: {
    flex: 1,
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  mainLayout: {
    background: '#F8F8F8',
    display: 'flex',
    height: 'calc(100vh - 50px)',
  },
  main: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    // height: '100vh',
    overflowX: 'hidden',
    // padding: theme.spacing(2, 0),
    marginTop: theme.mixins.toolbar.minHeight,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    height: 72,
    [theme.breakpoints.up('sm')]: {
      height: 100,
    },
  },
}));
