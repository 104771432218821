import React from 'react';
import { useStyles } from './styles';

interface GroupDividerProps {
  letter: string;
}

export default function GroupDivider({ letter }: GroupDividerProps) {
  const classes = useStyles();

  return (
    <div className={classes.dividerContainer}>
      <span className={classes.groupCircle}>{letter}</span>
    </div>
  );
}
