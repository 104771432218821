import React, { ChangeEvent } from 'react';
import { t, Doctor, SortingType } from '@psp/common';

import { DOCTOR_DETAIL_ROUTE } from '../../constants';
import Table, { TableProps } from '../Table';
import Chip from '../Chip';

export interface SortingConfiguration {
    propertyName: keyof {
        id: string;
        [key: string]: unknown;
    },
    sortType: SortingType,
    compareFunction: TableDataComparable
}

export type TableDataComparable = ((a: {
    id: string;
    [key: string]: unknown;
}, b: {
    id: string;
    [key: string]: unknown;
    }) => number);

const CompareByEquality = (column: keyof {
    id: string;
    [key: string]: unknown;
}) => (a: {
    id: string;
    [key: string]: unknown;
}, b: {
    id: string;
    [key: string]: unknown;
    }) => {
  if (column === 'user') {
    if (a[column] === null && b[column] === null) {
      return 0;
    }
    if (a[column] === null) {
      return -1;
    }
    if (b[column] === null) {
      return 1;
    }
    const doctorA = a as Doctor;
    const doctorB = b as Doctor;
    if (doctorA.user?.approved === doctorB.user?.approved) {
      return 0;
    }
    if (doctorA.user?.approved) {
      return 1;
    }
    return -1;
  }
  if (a[column] === b[column]) {
    return 0;
  }
  if ((a[column] as any).toString() > (b[column] as any).toString()) {
    return -1;
  }
  return 1;
};

const columns = [
  {
    key: 'name',
    label: t('name'),
    compareFunction: CompareByEquality('name'),
  },
  {
    key: 'crm',
    label: t('crm'),
    compareFunction: CompareByEquality('crm'),
  },
  {
    key: 'uf',
    label: t('uf'),
    compareFunction: CompareByEquality('uf'),
  },
  {
    key: 'email',
    label: t('email'),
    compareFunction: CompareByEquality('email'),
  },
  {
    key: 'phone',
    label: t('cellphone'),
    compareFunction: CompareByEquality('phone'),
    render: (value: any) => value.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3'),
  },
  {
    key: 'userId',
    label: t('status'),
    compareFunction: CompareByEquality('user'),
    render: function DoctorStatusRender(value: any, doctor: Doctor) {
      return (
        <Chip
          color={doctor.user ? (doctor.user.approved ? 'primary' : 'warning') : 'default'}
          label={
            doctor.user
              ? doctor.user.approved
                ? t('registered')
                : t('preRegistration')
              : t('unregistered')
          }
          size="small"
        />
      );
    },
  },
];

export type DoctorTableProps = {
  data: Doctor[];
  onApprove: (item: Doctor) => void;
  onSortConfig: SortingConfiguration[];
  selectFilterTitle: string;
  enableSelectFilter: boolean;
  selectOptions: any;
  onSearchBySelect: (e: ChangeEvent<HTMLInputElement>) => void;
} & Pick<
  TableProps,
    'canSearch' | 'count' | 'onChangePage' | 'onChangeRows' | 'onSearch' | 'page' | 'rowsPerPage' | 'onSortBy'
>;

export default function DoctorTable({ data,
  onApprove, onSortConfig, selectFilterTitle,
  selectOptions, onSearchBySelect, ...rest }: DoctorTableProps): JSX.Element {
  return (
    <Table
      {...rest}
      columns={columns}
      data={data}
      title={t('doctors')}
      size="small"
      canView
      viewRoute={DOCTOR_DETAIL_ROUTE}
      canApprove
      onApprove={onApprove}
      onSortConfig={onSortConfig}
      selectFilterTitle={selectFilterTitle}
      enableSelectFilter
      selectOptions={selectOptions}
      onSearchBySelect={onSearchBySelect}
    />
  );
}
