import React from 'react';
import { t } from '@psp/common';

import Breadcrumbs from '../../../components/Breadcrumbs';
import { PRESENTATIONS_ROUTE } from '../../../constants';
import PresentationListComposer from '../../../composers/PresentationListComposer';

export default function PresentationListPage(): JSX.Element {
  return (
    <>
      <Breadcrumbs title={t(`navigation.${PRESENTATIONS_ROUTE}`)} />
      <PresentationListComposer />
    </>
  );
}
