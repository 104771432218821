import React from 'react';
import { t } from '@psp/common';

import Breadcrumbs from '../../components/Breadcrumbs';
import { MEDICINE_LEAFLETS_ROUTE } from '../../constants';
import MedicineLeafletsComposer from '../../composers/MedicineLeafletComposer';

export default function MedicineLeafletsPage(): JSX.Element {
  return (
    <>
      <Breadcrumbs title={t(`navigation.${MEDICINE_LEAFLETS_ROUTE}`)} />
      <MedicineLeafletsComposer />
    </>
  );
}
