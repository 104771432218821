import React, { useEffect } from 'react';
import { Program } from '@psp/common';

export type ProgramStylesProps = {
  program?: Program | Record<string, never>;
};

export default function ProgramStyles({ program }: ProgramStylesProps): JSX.Element {
  useEffect(() => {
    const styles = document.createElement('style');
    document.body.appendChild(styles);
    if (program && program.styles) {
      styles.innerHTML = program?.styles;
    }
    return function cleanup() {
      document.body.removeChild(styles);
    };
  }, [program]);
  return <></>;
}
