import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { toast } from 'react-toastify';
import { t, IPaginated, Followup, GetFollowupsList, removeFollowup } from '@psp/common';

import { useLoading } from '../../../contexts/loading.context';
import { useProgram } from '../../../contexts/program.context';

import { useStyles } from './styles';
import FollowupTable from '../../FollowupTable';

export type FollowupListProps = {
  getFollowups: GetFollowupsList;
};

type FollowupListState = {
  followups: IPaginated<Followup>;
};

type FilterState = {
  filter: string;
  page: number;
  rows: number;
  timeout: number;
};

const defaultState: FollowupListState = {
  followups: {
    data: [],
    total: 0,
  },
};

const defaultFilterState: FilterState = {
  filter: '',
  page: 0,
  rows: 10,
  timeout: 0,
};

export default function FollowupList({ getFollowups }: FollowupListProps): JSX.Element {
  const classes = useStyles();
  const [state, setState] = useState<FollowupListState>(defaultState);
  const [filterState, setFilterState] = useState<FilterState>(defaultFilterState);
  const { showLoading, hideLoading, showBackdrop, hideBackdrop } = useLoading();
  const { program } = useProgram();

  useEffect(() => {
    const handler = setTimeout(() => {
      if (!program) return;
      showLoading();
      (async () => {
        try {
          if (!program) return;
          const followups = await getFollowups.execute({
            filter: filterState.filter,
            skip: filterState.page * filterState.rows,
            take: filterState.rows,
            programId: program.id,
          });
          setState({
            followups,
          });
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    }, filterState.timeout);
    return () => clearTimeout(handler);
  }, [filterState, program]);

  const handleSearch = useCallback(
    (value: string) => {
      setFilterState({
        ...filterState,
        filter: value,
        page: 0,
        timeout: 500,
      });
    },
    [filterState],
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setFilterState({
        ...filterState,
        page,
        timeout: 200,
      });
    },
    [filterState],
  );

  const handleRowsChange = useCallback(
    (rows: number) => {
      setFilterState({
        ...filterState,
        rows,
        timeout: 200,
      });
    },
    [filterState],
  );

  const handleRemove = useCallback(
    (followup: Followup) => {
      if (!program) return;
      showLoading();
      (async () => {
        try {
          await removeFollowup.execute({ id: followup.id });
          toast.success(t('msg.successToRemoveFollowup'), {
            onOpen: showBackdrop,
            onClose: hideBackdrop,
          });
          const followups = await getFollowups.execute({
            filter: filterState.filter,
            skip: filterState.page * filterState.rows,
            take: filterState.rows,
            programId: program.id,
          });
          setState({
            followups,
          });
        } catch (err) {
          console.log(err);
          if (err.message === 'followupNotFound') {
            toast.warning(t('err.followupNotFound'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } else if (err.message === 'followupIsLinkedToOtherEntitie') {
            toast.warning(t('err.followupIsLinkedToOtherEntitie'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } else {
            toast.warning(t('err.failedToRemoveFollowup'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          }
        } finally {
          hideLoading();
        }
      })();
    },
    [filterState, program],
  );

  return (
    <Box className={classes.container}>
      <FollowupTable
        count={state.followups.total || 0}
        data={state.followups.data || []}
        canSearch
        onChangePage={handlePageChange}
        onChangeRows={handleRowsChange}
        onSearch={handleSearch}
        onRemove={handleRemove}
        page={filterState.page}
        rowsPerPage={filterState.rows}
      />
    </Box>
  );
}
