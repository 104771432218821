import React from 'react';
import { t } from '@psp/common';

import Breadcrumbs from '../../../components/Breadcrumbs';
import ProductRegisterComposer from '../../../composers/ProductRegisterComposer';
import { PRODUCT_REGISTER_ROUTE } from '../../../constants';

export default function ProductRegisterPage(): JSX.Element {
  return (
    <>
      <Breadcrumbs title={t(`navigation.${PRODUCT_REGISTER_ROUTE}`)} />
      <ProductRegisterComposer />
    </>
  );
}
