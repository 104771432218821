import React from 'react';
import {
  saveUserGroup,
  deleteUserGroup,
  getUserGroups,
} from '@psp/common';

import UserGroups, { UserGroupsProps } from '../../components/UserGroups';

const dispatchers: Pick<
  UserGroupsProps,
  | 'saveUserGroup'
  | 'deleteUserGroup'
  | 'getUserGroups'
> = {
  saveUserGroup,
  deleteUserGroup,
  getUserGroups,
};

export default function UserGroupsComposer(): JSX.Element {
  return <UserGroups {...dispatchers} />;
}
