"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcedureStatusLabel = exports.ProcedureStatus = void 0;
var ProcedureStatus;
(function (ProcedureStatus) {
    ProcedureStatus["OPEN"] = "OPEN";
    ProcedureStatus["APPROVED"] = "APPROVED";
    ProcedureStatus["REJECTED"] = "REJECTED";
    ProcedureStatus["SCHEDULED"] = "SCHEDULED";
    ProcedureStatus["CANCELED"] = "CANCELED";
    ProcedureStatus["CONCLUDED"] = "CONCLUDED";
})(ProcedureStatus = exports.ProcedureStatus || (exports.ProcedureStatus = {}));
exports.ProcedureStatusLabel = (_a = {},
    _a[ProcedureStatus.OPEN] = 'Solicitado',
    _a[ProcedureStatus.APPROVED] = 'Aprovado',
    _a[ProcedureStatus.REJECTED] = 'Rejeitado',
    _a[ProcedureStatus.SCHEDULED] = 'Agendado',
    _a[ProcedureStatus.CANCELED] = 'Cancelado',
    _a[ProcedureStatus.CONCLUDED] = 'Concluído',
    _a);
