import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  styledContainer: {
    padding: '20px',
  },
  title: {
    color: 'rgb(59, 59, 59)',
    fontSize: '2rem',
  },
  information: {
    color: 'black',
  },
  banner: {
    backgroundColor: '#fff3cd',
    color: '#854204',
    padding: '16px',
    borderRadius: '15px',
    marginBottom: '40px',
    marginTop: '30px',
    fontSize: '1rem',
    fontWeight: 'normal',
    '& strong': {
      fontWeight: 'bold',
    },
  },
  errorText: {
    color: 'red',
  },
}));

export default useStyles;
