import React from 'react';
import { ProcedureType, t } from '@psp/common';

import Breadcrumbs from '../../../../components/Breadcrumbs';
import ProcedureListComposer from '../../../../composers/ProcedureListComposer';
import { SUPPLY_REQUESTS_DETAIL_ROUTE, SUPPLY_REQUESTS_ROUTE } from '../../../../constants';

export default function SupplyRequestsListPage(): JSX.Element {
  return (
    <>
      <Breadcrumbs title={t(`navigation.${SUPPLY_REQUESTS_ROUTE}`)} />
      <ProcedureListComposer
        type={ProcedureType.SUPPLY}
        viewRoute={SUPPLY_REQUESTS_DETAIL_ROUTE}
      />
    </>
  );
}
