import React from 'react';
import { t } from '@psp/common';

import Breadcrumbs from '../../../components/Breadcrumbs';
import UserGroupHistoryComposer from '../../../composers/UserGroupHistoryComposer';
import { USER_USER_GROUPS_HISTORY_ROUTE } from '../../../constants';

export default function UserGroupHistoryPage(): JSX.Element {
  return (
    <>
      <Breadcrumbs title={t(`navigation.${USER_USER_GROUPS_HISTORY_ROUTE}`)} />
      <UserGroupHistoryComposer />
    </>
  );
}
