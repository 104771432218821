import React from 'react';
import { dt, t, Procedure, Product, Presentation, User } from '@psp/common';

import Table from '../Table';
import ProcedureStatusChip from '../ProcedureStatusChip';

const CompareByEquality = (column: keyof {
    id: string;
    [key: string]: unknown;
}) => (a: {
    id: string;
    [key: string]: unknown;
}, b: {
    id: string;
    [key: string]: unknown;
}) => {
  if (a[column] === b[column]) {
    return 0;
  }
  return 1;
};

const columns = [
  {
    key: 'createdAt',
    label: t('requestDate'),
    compareFunction: CompareByEquality('createdAt'),
    render: (value: any) => dt(value),
  },
  {
    key: 'product',
    label: t('product'),
    compareFunction: CompareByEquality('product'),
    render: (product?: Product) => (product ? product.name : '-'),
  },
  {
    key: 'presentation',
    label: t('presentation'),
    compareFunction: CompareByEquality('presentation'),
    render: (presentation?: Presentation) => (presentation ? presentation.name : '-'),
  },
  {
    key: 'code',
    label: t('code'),
    compareFunction: CompareByEquality('code'),
  },
  {
    key: 'status',
    label: t('status'),
    compareFunction: CompareByEquality('status'),
    render: function ProcedureStatusRender(value: any) {
      return <ProcedureStatusChip status={value} />;
    },
  },
  {
    key: 'approvedBy',
    label: t('approvalRejection'),
    compareFunction: CompareByEquality('approvedBy'),
    render: function ProcedureApprovedByRender(value: User, item: Procedure) {
      return value && value.id !== item.createdBy.id && item.approvedAt
        ? `${dt(item.approvedAt)} - ${value.name}`
        : '-';
    },
  },
];

export type MedicationReleaseTableProps = {
  data: Procedure[];
};

export default function MedicationReleaseTable({ data }: MedicationReleaseTableProps): JSX.Element {
  return <Table columns={columns} data={data} size="small" />;
}
