import React from 'react';
import { getPresentation, registerPresentation } from '@psp/common';

import PresentationRegister, { PresentationRegisterProps } from '../../components/Presentation/Register';

const dispatchers: PresentationRegisterProps = {
  getPresentation,
  registerPresentation,
};

export default function PresentationRegisterComposer(): JSX.Element {
  return <PresentationRegister {...dispatchers} />;
}
