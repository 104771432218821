import React from 'react';

import { t } from '@psp/common';
import ContentsComposer from '../../composers/ContentsComposer';
import Breadcrumbs from '../../components/Breadcrumbs';
import { CONTENTS_ROUTE } from '../../constants';

export default function ContentsPage(): JSX.Element {
  return (
    <>
      <Breadcrumbs title={t(`navigation.${CONTENTS_ROUTE}`)} />
      <ContentsComposer />
    </>
  );
}
