"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.signupDoctorRegistrationForm = void 0;
var Yup = __importStar(require("yup"));
var only_numbers_1 = require("../transforms/only-numbers");
var phone_1 = require("../types/phone");
exports.signupDoctorRegistrationForm = Yup.object().shape({
    crm: Yup.string().min(1).transform(only_numbers_1.onlyNumbers).required(),
    uf: Yup.string().required(),
    name: Yup.string().min(10).required(),
    email: Yup.string().email().required(),
    confirmEmail: Yup.string().when('$userExists', function (userExists, schema) {
        return userExists ? schema : schema.oneOf([Yup.ref('email')], 'validation.emailMustMatch');
    }),
    phone: phone_1.phone.required(),
    password: Yup.string().when('$userExists', function (userExists, schema) {
        return userExists ? schema : schema.min(6).required();
    }),
    confirmPassword: Yup.string().when('$userExists', function (userExists, schema) {
        return userExists ? schema : schema.oneOf([Yup.ref('password')], 'validation.passwordMustMatch');
    }),
    requestVisit: Yup.boolean(),
    cpf: Yup.string().transform(only_numbers_1.onlyNumbers).min(11).when('$doctorBenefits', function (doctorBenefits, schema) {
        return doctorBenefits ? schema.required() : schema.optional();
    }),
    birthDate: Yup.date().when('$doctorBenefits', function (doctorBenefits, schema) {
        return doctorBenefits ? schema.required() : schema.optional();
    }),
    specialties: Yup.array().when('$requireSpecialty', function (requireSpecialty, schema) {
        return requireSpecialty ? schema.min(1) : schema.optional();
    }),
});
