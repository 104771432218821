import React from 'react';
import { Presentation, Product, t } from '@psp/common';

import { PRESENTATION_REGISTER_ROUTE, PRESENTATION_EDIT_ROUTE } from '../../constants';
import Table, { TableProps } from '../Table';

const CompareByEquality = (column: keyof {
    id: string;
    [key: string]: unknown;
}) => (a: {
    id: string;
    [key: string]: unknown;
}, b: {
    id: string;
    [key: string]: unknown;
}) => {
  if (a[column] === b[column]) {
    return 0;
  }
  return 1;
};

const columns = [
  {
    key: 'name',
    label: t('name'),
    compareFunction: CompareByEquality('name'),
  },
  {
    key: 'product',
    label: t('product'),
    compareFunction: CompareByEquality('product'),
    render: (product?: Product) => (product ? product.name : '-'),
  },
  {
    key: 'discount',
    label: t('discount'),
    compareFunction: CompareByEquality('discount'),
    render: (discount?: string) => (discount ? `${discount}%` : '-'),
  },
  {
    key: 'ean',
    label: t('ean'),
    compareFunction: CompareByEquality('ean'),
    render: (ean?: string) => (ean || '-'),
  },
  {
    key: 'wsAcessoCode',
    label: t('wsAcessoCode'),
    compareFunction: CompareByEquality('wsAcessoCode'),
    render: (wsAcessoCode?: string) => (wsAcessoCode || '-'),
  },
];

export type PresentationTableProps = {
  data: Presentation[];
} & Pick<
  TableProps,
  'canSearch' | 'count' | 'onChangePage' | 'onChangeRows' | 'onSearch' | 'page' | 'rowsPerPage' | 'canRemove' | 'onRemove'
>;

export default function PresentationTable({ data, ...props }: PresentationTableProps): JSX.Element {
  return (
    <Table
      columns={columns}
      data={data}
      title={t('presentations')}
      size="small"
      canAdd
      addRoute={PRESENTATION_REGISTER_ROUTE}
      canEdit
      editRoute={PRESENTATION_EDIT_ROUTE}
      {...props}
    />
  );
}
