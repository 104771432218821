import React from 'react';

import {
  getProgramFiles,
  deleteProgramFile,
  downloadProgramFile,
  getProgramProducts,
  getProductPresentations,
  getProductPatientProcedures,
  getProgramFollowups,
  getFollowupExamGroups,
  getExamGroupExams,
  getProgramSupplyGroups,
  getSupplyGroupSupplies,
  createProgramFile,
} from '@psp/common';
import Contents, { ContentsProps } from '../../components/Contents';

const dispatchers: ContentsProps = {
  getProgramFiles,
  deleteProgramFile,
  downloadProgramFile,
  getProgramProducts,
  getProductPresentations,
  getProductPatientProcedures,
  getProgramFollowups,
  getFollowupExamGroups,
  getExamGroupExams,
  getProgramSupplyGroups,
  getSupplyGroupSupplies,
  createProgramFile,
};

export default function ContentsComposer(): JSX.Element {
  return <Contents {...dispatchers} />;
}
