import React, { useMemo } from 'react';
import { MenuItem } from '@material-ui/core';
import clsx from 'clsx';
import { t } from '@psp/common';

import { useProgram } from '../../contexts/program.context';
import Select, { SelectProps } from '../Select';
import { useStyles } from './styles';

type InventoryTypeSelectProps = Omit<SelectProps, 'children'>;

export default function InventoryTypeSelect({
  className,
  ...props
}: InventoryTypeSelectProps): JSX.Element {
  const classes = useStyles();
  const { modulesMap } = useProgram();
  const options = useMemo(() => {
    const items = [<MenuItem key="empty" value="" disabled />];
    if (modulesMap.firstMedicine) {
      items.push(
        <MenuItem key="FIRST_MEDICINE" value="FIRST_MEDICINE">
          {t('inventoryType.FIRST_MEDICINE')}
        </MenuItem>,
      );
    }
    if (modulesMap.clinicalExamination) {
      items.push(
        <MenuItem key="CLINICAL_EXAMINATION" value="CLINICAL_EXAMINATION">
          {t('inventoryType.CLINICAL_EXAMINATION')}
        </MenuItem>,
      );
    }
    if (modulesMap.patientProcedure) {
      items.push(
        <MenuItem key="PATIENT_PROCEDURE" value="PATIENT_PROCEDURE">
          {t('inventoryType.PATIENT_PROCEDURE')}
        </MenuItem>,
      );
    }
    return items;
  }, [modulesMap]);
  return (
    <Select label={t('inventory')} className={clsx(className, classes.formControl)} {...props}>
      {options}
    </Select>
  );
}
