import React, { useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import {
  FormControlLabel,
  Checkbox,
  FormControl,
  FormHelperText,
  CheckboxProps,
  FormGroup,
  FormLabel,
} from '@material-ui/core';
import { CheckboxBlankCircleOutline, CheckboxMarkedCircle } from 'mdi-material-ui';
import { t } from '@psp/common';

type InputProps = CheckboxProps & {
  name: string;
  groupLabel?: string;
  [key: string]: any;
};

export default function CheckboxInput({
  name,
  label,
  helperText,
  className,
  groupLabel = '',
  defaultChecked = false,
  onChange,
  ...rest
}: InputProps): JSX.Element {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [checked, setChecked] = useState(defaultChecked);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked',
      setValue: (_ref: any, value: string | boolean) => {
        if (value === undefined) return;
        setChecked(value === 'true' || value === true);
      },
    });
  }, [fieldName, registerField]);
  function handleChange(_event: any, value: boolean): void {
    setChecked(value);
  }
  return (
    <FormControl component="fieldset" className={className}>
      {groupLabel && <FormLabel component="legend">{groupLabel}</FormLabel>}
      <FormGroup>
        <FormControlLabel
          control={(
            <Checkbox
              defaultValue={defaultValue}
              {...rest}
              inputRef={inputRef}
              name={name}
              checked={checked}
              onChange={onChange ?? handleChange}
              icon={<CheckboxBlankCircleOutline />}
              checkedIcon={<CheckboxMarkedCircle />}
            />
          )}
          label={label}
        />
        <FormHelperText error={!!error}>{error ? t(error) : helperText}</FormHelperText>
      </FormGroup>
    </FormControl>
  );
}
