"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InventoryType = void 0;
var InventoryType;
(function (InventoryType) {
    InventoryType["FIRST_MEDICINE"] = "FIRST_MEDICINE";
    InventoryType["CLINICAL_EXAMINATION"] = "CLINICAL_EXAMINATION";
    InventoryType["PATIENT_PROCEDURE"] = "PATIENT_PROCEDURE";
    InventoryType["SUPPLY"] = "SUPPLY";
})(InventoryType = exports.InventoryType || (exports.InventoryType = {}));
