import React from 'react';
import { getProduct, registerProduct } from '@psp/common';

import ProductRegister, { ProductRegisterProps } from '../../components/Product/Register';

const dispatchers: ProductRegisterProps = {
  getProduct,
  registerProduct,
};

export default function ProductRegisterComposer(): JSX.Element {
  return <ProductRegister {...dispatchers} />;
}
