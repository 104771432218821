import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    background: theme.palette.common.white,
    zIndex: theme.zIndex.drawer + 1,
    height: 85,
  },
  toolbar: {
    height: 84,
    padding: 0,
  },
  clientImage: {
    height: 30,
    [theme.breakpoints.down('xs')]: {
      height: 20,
    },
  },
  button: {
    textTransform: 'unset',
  },
  verticalDivider: {
    height: 55,
    width: 1,
  },
}));
