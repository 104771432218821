import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { toast } from 'react-toastify';
import { t, GetProgramProcedures, Procedure, IPaginated, ProcedureType } from '@psp/common';

import { ProcedureStatus } from '@psp/common/dist/ducks/procedures/procedure-status';
import { useLocation } from 'react-router-dom';
import { useLoading } from '../../../contexts/loading.context';
import ProcedureTable from '../../ProcedureTable';

import { useStyles } from './styles';
import { useProgram } from '../../../contexts/program.context';
import MedicationReleaseTable from '../../MedicationReleaseTable';

export type ProcedureListProps = {
  getProgramProcedures: GetProgramProcedures;
  type?: ProcedureType;
  viewRoute?: string;
};

type ProcedureListState = {
  procedures: IPaginated<Procedure>;
};

type FilterState = {
  filter: string;
  page: number;
  rows: number;
  timeout: number;
  followupIds: string[];
  selectedStatus: ProcedureStatus[];
  examGroups: string[];
  yearMonth: string[];
};

const defaultState: ProcedureListState = {
  procedures: {
    data: [],
    total: 0,
  },
};

const defaultFilterState: FilterState = {
  filter: '',
  page: 0,
  rows: 10,
  timeout: 0,
  followupIds: [],
  selectedStatus: [],
  examGroups: [],
  yearMonth: [],
};

export default function ProcedureList({
  getProgramProcedures,
  type,
  viewRoute,
}: ProcedureListProps): JSX.Element {
  const classes = useStyles();
  const [state, setState] = useState<ProcedureListState>(defaultState);
  const [filterState, setFilterState] = useState<FilterState>(defaultFilterState);
  const { program } = useProgram();
  const { showLoading, hideLoading, showBackdrop, hideBackdrop } = useLoading();
  const { pathname } = useLocation();

  useEffect(() => {
    const handler = setTimeout(() => {
      (async () => {
        if (!program || !type) return;
        try {
          const procedures = await getProgramProcedures.execute({
            filter: filterState.filter,
            skip: filterState.page * filterState.rows,
            take: filterState.rows,
            programId: program.id,
            status: filterState.selectedStatus,
            type,
            followupIds: filterState.followupIds,
            examGroupIds: filterState.examGroups,
            yearMonth: filterState.yearMonth,
          });
          setState({
            procedures,
          });
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    }, filterState.timeout);
    return () => clearTimeout(handler);
  }, [filterState, program, type]);

  const handleSearch = useCallback(
    (value: string) => {
      setFilterState({
        filter: value,
        page: 0,
        rows: filterState.rows,
        timeout: 500,
        followupIds: [],
        selectedStatus: [],
        examGroups: [],
        yearMonth: [],
      });
    },
    [filterState],
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setFilterState({
        filter: filterState.filter,
        page,
        rows: filterState.rows,
        timeout: 200,
        followupIds: filterState.followupIds,
        selectedStatus: filterState.selectedStatus,
        examGroups: filterState.examGroups,
        yearMonth: filterState.yearMonth,
      });
    },
    [filterState],
  );

  const handleFollowupChange = useCallback(
    (value) => {
      if (!value) return;
      showLoading();
      (async () => {
        try {
          setFilterState({
            filter: filterState.filter,
            page: 0,
            rows: filterState.rows,
            timeout: 200,
            followupIds: value,
            selectedStatus: filterState.selectedStatus,
            examGroups: filterState.examGroups,
            yearMonth: filterState.yearMonth,
          });
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    },
    [filterState],
  );

  const handleStatusChange = useCallback(
    (value) => {
      if (!value) return;
      showLoading();
      (async () => {
        try {
          const statusArray : ProcedureStatus[] = [];
          statusArray.push(value);
          setFilterState({
            filter: filterState.filter,
            page: 0,
            rows: filterState.rows,
            timeout: 200,
            followupIds: filterState.followupIds,
            selectedStatus: statusArray,
            examGroups: filterState.examGroups,
            yearMonth: filterState.yearMonth,
          });
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    },
    [filterState],
  );

  const handleExamGroupChange = useCallback(
    (value) => {
      if (!value) return;
      showLoading();
      (async () => {
        try {
          setFilterState({
            filter: filterState.filter,
            page: 0,
            rows: filterState.rows,
            timeout: 200,
            followupIds: filterState.followupIds,
            selectedStatus: filterState.selectedStatus,
            examGroups: value,
            yearMonth: filterState.yearMonth,
          });
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    },
    [filterState],
  );

  const handleDateChange = useCallback(
    (value) => {
      if (!value) return;
      showLoading();
      (async () => {
        try {
          setFilterState({
            filter: filterState.filter,
            page: 0,
            rows: filterState.rows,
            timeout: 200,
            followupIds: filterState.followupIds,
            selectedStatus: filterState.selectedStatus,
            examGroups: filterState.examGroups,
            yearMonth: value,
          });
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    },
    [filterState],
  );

  const handleRowsChange = useCallback(
    (rows: number) => {
      setFilterState({
        filter: filterState.filter,
        page: filterState.page,
        rows,
        timeout: 200,
        followupIds: filterState.followupIds,
        selectedStatus: filterState.selectedStatus,
        examGroups: filterState.examGroups,
        yearMonth: filterState.yearMonth,
      });
    },
    [filterState],
  );

  return (
    <Box className={classes.container}>
      <ProcedureTable
        count={state.procedures.total}
        data={state.procedures.data}
        onChangePage={handlePageChange}
        onChangeRows={handleRowsChange}
        onSearch={handleSearch}
        enableFollowupFilter={type as ProcedureType === ProcedureType.CLINICAL_EXAMINATION}
        followupFilterMultiSelect={false}
        onChangeFollowupfilter={handleFollowupChange}
        enableStatusFilter={type as ProcedureType === ProcedureType.CLINICAL_EXAMINATION}
        statusFilterMultiSelect={false}
        onChangeStatusfilter={handleStatusChange}
        enableExamGroupFilter={type as ProcedureType === ProcedureType.CLINICAL_EXAMINATION}
        examGroupFilterMultiSelect={false}
        onChangeExamGroupfilter={handleExamGroupChange}
        enableDateFilter={type as ProcedureType === ProcedureType.CLINICAL_EXAMINATION}
        dateFilterMultiSelect={false}
        onChangeDatefilter={handleDateChange}
        page={filterState.page}
        rowsPerPage={filterState.rows}
        title={t(`navigation.${pathname}`)}
        viewRoute={viewRoute}
      />
    </Box>
  );
}
