"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LibraryType = void 0;
var LibraryType;
(function (LibraryType) {
    LibraryType["FIRST_MEDICINE"] = "FIRST_MEDICINE";
    LibraryType["CLINICAL_EXAMINATION"] = "CLINICAL_EXAMINATION";
    LibraryType["PATIENT_PROCEDURE"] = "PATIENT_PROCEDURE";
    LibraryType["SALESFORCE_VISIT"] = "SALESFORCE_VISIT";
    LibraryType["SUPPLY"] = "SUPPLY";
    LibraryType["DOCTOR_BENEFITS"] = "DOCTOR_BENEFITS";
    LibraryType["AID"] = "AID";
    LibraryType["TRAVEL"] = "TRAVEL";
    LibraryType["PATIENTS"] = "PATIENTS";
    LibraryType["GENERAL"] = "GENERAL";
    LibraryType["PROGRAM"] = "PROGRAM";
    LibraryType["PCDT"] = "PCDT";
})(LibraryType = exports.LibraryType || (exports.LibraryType = {}));
