import React from 'react';
import { Box, Container, Toolbar, Grid, Divider, useMediaQuery, useTheme } from '@material-ui/core';
import { Link, Switch, Route } from 'react-router-dom';
import clsx from 'clsx';
import createDomPurify from 'dompurify';
import { Program } from '@psp/common';
import { useQuery } from '../../hooks/use-query';
import { useStyles } from './styles';

export type SigninFooterProps = {
  program?: Program | Record<string, never> | undefined;
};

export default function SigninFooter({ program }: SigninFooterProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const { program: programQuery = '' } = useQuery();
  const isMD = useMediaQuery(theme.breakpoints.down('md'));
  const domPurify = createDomPurify(window);

  if (program?.customHtmlFooter) {
    if (programQuery?.toString() === 'tevacuidar'.toString()) {
      return (
        <Box className={clsx(classes.container, classes.heightUnset)}>
          <Divider />
          <Toolbar className={clsx(classes.toolbar, classes.heightUnset)}>
            <Grid container spacing={2} direction="row" alignItems="center">
              <Grid item xs>
                <Grid container direction="column">
                  <Grid item xs>
                    <Switch>
                      <Route path="/signup">
                        <span className="signup-footer-login">
                          Já possui uma conta?&nbsp;
                          <Link to={`/login?program=${programQuery}`} className={classes.link}>
                            Clique aqui
                          </Link>
                                                    &nbsp;para acessar a plataforma.
                        </span>
                      </Route>
                    </Switch>
                  </Grid>
                  <Grid item xs>
                    <span
                      className={clsx(classes.displayFlex)}
                      dangerouslySetInnerHTML={{
                        __html: domPurify.sanitize(
                          program?.customHtmlFooter,
                          { ADD_ATTR: ['target'] },
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {!isMD && (
              <Grid item>
                <img
                  src="/logo_developed.svg"
                  alt="bCare | Funcional Health Tech"
                  className={classes.brandImage}
                />
              </Grid>
              )}
            </Grid>
          </Toolbar>
        </Box>
      );
    }
    return (
      <Box className={clsx(classes.container, classes.heightUnset)}>
        <Divider />
        <Toolbar className={clsx(classes.toolbar, classes.heightUnset)}>
          <Grid container spacing={2} direction="row" alignItems="center">
            <Grid item xs>
              <Grid container direction="column">
                <Grid item xs>
                  <Switch>
                    <Route path="/login">
                      <span>
                        Não possui uma conta?&nbsp;
                        <Link to={`/signup?program=${programQuery}`} className={classes.link}>
                          Clique aqui
                        </Link>
                        &nbsp;para se cadastrar.
                      </span>
                    </Route>
                    <Route path="/signup">
                      <span className="signup-footer-login">
                        Já possui uma conta?&nbsp;
                        <Link to={`/login?program=${programQuery}`} className={classes.link}>
                          Clique aqui
                        </Link>
                        &nbsp;para acessar a plataforma.
                      </span>
                    </Route>
                  </Switch>
                </Grid>
                <Grid item xs>
                  <span
                    className={clsx(classes.displayFlex)}
                    dangerouslySetInnerHTML={{
                      __html: domPurify.sanitize(
                        program?.customHtmlFooter,
                        { ADD_ATTR: ['target'] },
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            {!isMD && (
              <Grid item>
                <img
                  src="/logo_developed.svg"
                  alt="bCare | Funcional Health Tech"
                  className={classes.brandImage}
                />
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </Box>
    );
  }
  if (programQuery?.toString() === 'tevacuidar'.toString()) {
    return (
      <Box className={classes.container}>
        <Divider />
        <Toolbar className={classes.toolbar}>
          <Grid container spacing={2} direction="row" alignItems="center">
            <Grid item xs>
              <Switch>
                <Route path="/signup">
                  <span className="signup-footer-login">
                    Já possui uma conta?&nbsp;
                    <Link to={`/login?program=${programQuery}`} className={classes.link}>
                      Clique aqui
                    </Link>
                                        &nbsp;para acessar a plataforma.
                  </span>
                </Route>
              </Switch>
            </Grid>
            <Grid item>
              <img
                src="/logo_developed.svg"
                alt="bCare | Funcional Health Tech"
                className={classes.brandImage}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </Box>
    );
  }
  return (
    <Box className={classes.container}>
      <Divider />
      <Toolbar className={classes.toolbar}>
        <Grid container spacing={2} direction="row" alignItems="center">
          <Grid item xs>
            <Switch>
              <Route path="/login">
                <span>
                  Não possui uma conta?&nbsp;
                  <Link to={`/signup?program=${programQuery}`} className={classes.link}>
                    Clique aqui
                  </Link>
                  &nbsp;para se cadastrar.
                </span>
              </Route>
              <Route path="/signup">
                <span className="signup-footer-login">
                  Já possui uma conta?&nbsp;
                  <Link to={`/login?program=${programQuery}`} className={classes.link}>
                    Clique aqui
                  </Link>
                  &nbsp;para acessar a plataforma.
                </span>
              </Route>
            </Switch>
          </Grid>
          <Grid item>
            <img
              src="/logo_developed.svg"
              alt="bCare | Funcional Health Tech"
              className={classes.brandImage}
            />
          </Grid>
        </Grid>
      </Toolbar>
    </Box>
  );
}
