import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import {
  t,
  GetUserGroups,
  UserGroup,
} from '@psp/common';

import { useLoading } from '../../contexts/loading.context';
import Select from '../Select';
import { useProgram } from '../../contexts/program.context';

export type UserGroupSelectValues = {
  userGroupId: string;
  userGroup?: UserGroup;
};

export type UserGroupSelectProps = {
  getUserGroups: GetUserGroups;
  onSelectedValueChange: (values: UserGroupSelectValues) => void;
  initialValue?: string | null;
  optionalUserGroup?: boolean;
  formRef: any;
  direction?: 'column' | 'row';
};

type UserGroupSelectState = {
  userGroups: UserGroup[];
  userGroupId: string;
  userGroup?: UserGroup;
};

const defaultState: UserGroupSelectState = {
  userGroups: [],
  userGroupId: '',
  userGroup: undefined,
};

export default function UserGroupSelect({
  getUserGroups,
  onSelectedValueChange,
  initialValue,
  optionalUserGroup = false,
  formRef,
  direction = 'row',
}: UserGroupSelectProps): JSX.Element {
  const userGroupRef: any = useRef(null);
  const [state, setState] = useState<UserGroupSelectState>(defaultState);
  const { showLoading, hideLoading, isLoading } = useLoading();
  const [selectedValue, setSelectedValue] = useState<any>(initialValue);
  const { program } = useProgram();

  useEffect(
    useCallback(() => {
      if (!program) return;
      showLoading();
      setState({
        ...defaultState,
      });
      (async () => {
        try {
          const userGroups = await getUserGroups.execute({ programId: program?.id });
          setState({
            ...defaultState,
            userGroups,
          });
          onSelectedValueChange({
            userGroupId: initialValue ?? '',
            userGroup: undefined,
          });
          formRef.current.setFieldValue('userGroupId', selectedValue);
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    }, [state, onSelectedValueChange]),
    [],
  );

  const handleUserGroupChange = useCallback(
    (value) => {
      if (!value) return;
      showLoading();
      setSelectedValue(value);
      setState({
        ...state,
        userGroupId: value,
        userGroup: state.userGroups.find((p) => p.id === value),
      });
      onSelectedValueChange({
        userGroupId: value,
        userGroup: state.userGroups.find((p) => p.id === value),
      });
      hideLoading();
    },
    [state, onSelectedValueChange],
  );

  return (
    <Grid container spacing={2} direction={direction}>
      <Grid item xs={12} md={direction === 'column' ? 12 : 4} lg={direction === 'column' ? 12 : 4}>
        <Select
          label={t('userGroup')}
          variant="outlined"
          name="userGroupId"
          fullWidth
          onSelectedValueChange={handleUserGroupChange}
          disabled={state.userGroups.length === 0 || isLoading}
          inputRef={userGroupRef}
          optional={optionalUserGroup}
        >
          {state.userGroups.map((p) => (
            <MenuItem key={p.id} value={p.id}>
              {p.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
}
