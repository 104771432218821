import React, { useEffect, useRef, useState, ChangeEvent } from 'react';
import { useField } from '@unform/core';
import {
  FormControl,
  InputLabel,
  FormHelperText,
  MenuItem,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@material-ui/core';
import { CheckboxBlankCircleOutline, CheckboxMarkedCircle } from 'mdi-material-ui';
import clsx from 'clsx';
import { emptyFunction, t } from '@psp/common';
import Select from '../Select';

import { useStyles } from './styles';

export type Value = {
  key: string;
  value: string;
  caption: string;
  subvalues?: Subvalue[];
  required: boolean;
}

export type Subvalue = {
  key: string;
  value: string;
  optionsLabel?: string;
  options?: Option[];
  supplementaryData?: any;
}

export type Option = {
  key: string;
  value: string;
}

export type CheckboxGroupProps = {
  label?: string;
  name: string;
  subvalueName: string;
  values: Value[];
  variant: 'filled' | 'outlined' | 'standard';
  optional?: boolean;
  className?: string;
  fullWidth?: boolean;
  placeholder?: string;
  withCaption?: boolean;
  onChange?: (selectedValues: Value[]) => void;
};

export default function CheckboxGroup({
  values,
  label,
  name,
  subvalueName,
  variant,
  className,
  fullWidth = false,
  placeholder,
  optional = false,
  withCaption = false,
  onChange = undefined,
  ...rest
}: CheckboxGroupProps): JSX.Element {
  const classes = useStyles();

  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [value, setValue] = useState<{
    [key: string]: { subvalues: { [subKey: string]: string }; }
  }>({});
  const inputLabel = label && !optional ? `${label} *` : label;
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (_ref: any) => Object.keys(JSON.parse(_ref.dataset.value)),
      clearValue: () => {
        setValue({});
      },
    });
  }, [fieldName, registerField]);

  const subvalueField = useField(subvalueName);
  useEffect(() => {
    subvalueField.registerField({
      name: subvalueField.fieldName,
      ref: inputRef.current,
      getValue: (_ref: any) => {
        const _values = JSON.parse(_ref.dataset.value);
        const _keys = Object.keys(_values);

        const result = _keys.map(
          (k) => Object.keys(_values[k].subvalues).map(
            (sbk) => ({
              itemId: k,
              supplyId: sbk,
              institutionId: _values[k].subvalues[sbk],
            }),
          ),
        );

        const array: any[] = [];
        return array.concat(...result);
      },
      clearValue: () => {
        setValue({});
      },
    });
  }, [subvalueField.fieldName, subvalueField.registerField]);

  function handleChange(key: string) {
    return function handleChangeEvent(event: ChangeEvent<{ name?: string; value: unknown }>): void {
      if (values.find((v) => v.key === key)?.required) { return; }
      if (value[key]) {
        const { ...newValue } = value;
        delete newValue[key];
        setValue(newValue);
        if (onChange) onChange(values.filter((v) => newValue[v.key]));
      } else {
        setValue({
          ...value,
          [key]: {
            subvalues: {},
          },
        });
        if (onChange) onChange(values.filter((v) => value[v.key] || key === v.key));
      }
    };
  }

  function handleSubvalueChange(key: string, subvalueKey: string, optionKey: string) {
    const { ...newValue } = value;
    newValue[key].subvalues[subvalueKey] = optionKey;
    setValue(newValue);
  }

  useEffect(() => {
    let defaultSelecteds : {[key: string]: { subvalues: { [subKey: string]: string }; }} = {};

    for (let i = 0; i < values.length; i++) {
      if (values[i].required) {
        defaultSelecteds = {
          ...defaultSelecteds,
          [values[i].key]: {
            subvalues: {},
          },
        };
        if (onChange) onChange(values.filter((v) => value[v.key] || values[i].key === v.key));
      }
    }

    setValue(defaultSelecteds);
  }, []);

  return (
    <FormControl
      variant={variant}
      ref={inputRef}
      data-value={JSON.stringify(value)}
      className={className}
      error={!!error}
      fullWidth={fullWidth}
      size="small"
    >
      <FormLabel component="legend">{inputLabel}</FormLabel>
      <FormGroup>
        {withCaption ? (
          <table className={clsx('checkbox-group-table', classes.checkboxTable)}>
            {values.map((v, i) => (
              <tr key={v.key} className={classes.checkboxRow}>
                <td className={classes.checkboxCell}>
                  <strong>{v.value}</strong>
                </td>
                <td className={clsx(classes.checkboxCell)}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={!!value[v.key]}
                        onChange={handleChange(v.key)}
                        name={v.value}
                        icon={<CheckboxBlankCircleOutline />}
                        checkedIcon={<CheckboxMarkedCircle />}
                        disabled={v.caption === 'Sem estoque'}
                        required={v.required}
                      />
                    )}
                    label={(
                      <Typography variant="body1" className={classes.formControlLabel}>
                        {v.caption}
                        {v.required && (
                          <>
                            {' - '}
                            <span className={classes.formControlLabelRequired}>
                              Obrigatório
                            </span>
                          </>
                        )}
                      </Typography>
                    )}
                  />
                </td>

                <div>
                  {!!value[v.key]
                    && v.subvalues?.map((sv, j) => (
                      <tr key={sv.key} className={clsx(classes.checkboxRow)}>
                        <td className={clsx(classes.checkboxCell, classes.checkboxCellSubvalue)}>
                          <Typography variant="body1" className={classes.formControlLabel}>
                            <strong>
                              {sv.value}
                            </strong>
                          </Typography>
                        </td>
                        <td className={clsx(classes.checkboxCell, classes.checkboxCellSubvalue)}>
                          {sv.options
                            && sv.options.length > 0
                            && (
                              <Select
                                name={sv.key + j}
                                label={sv?.optionsLabel}
                                variant="outlined"
                                fullWidth
                                onSelectedValueChange={(_value) => {
                                  handleSubvalueChange(v.key, sv.key, _value as string);
                                }}
                              >
                                {sv.options?.map((o, k) => (
                                  <MenuItem key={o.key} value={o.key}>{o.value}</MenuItem>
                                ))}
                              </Select>
                            )}
                        </td>
                      </tr>
                    ))}
                </div>
              </tr>
            ))}
          </table>
        ) : (
          values.map((v) => (
            <FormControlLabel
              key={v.key}
              control={(
                <Checkbox
                  checked={!!value[v.key]}
                  onChange={handleChange(v.key)}
                  name={v.value}
                  icon={<CheckboxBlankCircleOutline />}
                  checkedIcon={<CheckboxMarkedCircle />}
                />
              )}
              label={v.value}
            />
          ))
        )}
      </FormGroup>
      <FormHelperText>{error ? t(error) : ''}</FormHelperText>
    </FormControl>
  );
}
