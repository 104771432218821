import React from 'react';
import {
  getProcedureAccredited,
  getProcedureByCode,
  getProgramByProcedureCode,
  updateProcedurePatient,
} from '@psp/common';

import PatientConfirmation, {
  PatientConfirmationProps,
} from '../../components/Patient/Confirmation';

const dispatchers: Pick<
  PatientConfirmationProps,
  | 'getProcedureAccredited'
  | 'getProcedureByCode'
  | 'getProgramByProcedureCode'
  | 'updateProcedurePatient'
> = {
  getProcedureAccredited,
  getProcedureByCode,
  getProgramByProcedureCode,
  updateProcedurePatient,
};

export default function PatientConfirmationComposer(): JSX.Element {
  return <PatientConfirmation {...dispatchers} />;
}
