import React from 'react';
import {
  getDoctorByCrm,
  getProgramBySlug,
  sendVerificationCode,
  signup,
  getProgramPoliciesByUserType,
  getSpecialtyUserGroups,
} from '@psp/common';
import Signup, { SignupProps } from '../../components/Signup';

const dispatchers: Pick<
  SignupProps,
  'getDoctorByCrm'
  | 'getProgramBySlug'
  | 'sendVerificationCode'
  | 'signup'
  | 'getProgramPoliciesByUserType'
  | 'getSpecialtyUserGroups'
> = {
  getDoctorByCrm,
  getProgramBySlug,
  sendVerificationCode,
  signup,
  getProgramPoliciesByUserType,
  getSpecialtyUserGroups,
};

export default function SignupComposer(): JSX.Element {
  return <Signup {...dispatchers} />;
}
