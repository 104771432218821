import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  t,
  emptyFunction,
} from '@psp/common';
import { Form } from '@unform/web';
import { useProgram } from '../../contexts/program.context';
import { useLoading } from '../../contexts/loading.context';
import CheckboxSelect from '../CheckboxSelect';

export type YearMonthSelectProps = {
  onChange: (e: any) => void;
  multiple: boolean;
};

type YearMonthSelectState = {
  yearMonth: any[];
};

const defaultState: YearMonthSelectState = {
  yearMonth: [],
};

const generateOptions = () => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + 1;

  const options = [];
  for (let year = currentYear; year >= 2000; year--) {
    const startMonth = year === currentYear ? currentMonth : 12;
    const endMonth = year === 2000 ? 1 : 1;

    for (let month = startMonth; month >= endMonth; month--) {
      const formattedMonth = month < 10 ? `0${month}` : month;
      const id = `${year}-${formattedMonth}`;
      options.push({ id, name: id });
    }
  }

  return options;
};

export default function YearMonthSelect({
  onChange,
  multiple,
}: YearMonthSelectProps): JSX.Element {
  const { showLoading, hideLoading } = useLoading();
  const { program } = useProgram();
  const [state, setState] = useState<YearMonthSelectState>(defaultState);
  const formRef = useRef({} as any);

  useEffect(
    useCallback(() => {
      if (!program) return;
      showLoading();
      setState({
        ...defaultState,
      });
      (async () => {
        try {
          const yearMonth = generateOptions();

          setState({
            ...defaultState,
            yearMonth,
          });
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    }, [state]),
    [program],
  );

  return (
    <Form ref={formRef} onSubmit={emptyFunction}>
      <CheckboxSelect
        name="yearmonth"
        label={t('yearmonth')}
        items={state.yearMonth.map((f: any) => ({
          id: f.id, name: f.name,
        }))}
        onChange={onChange}
        style={{ width: 150 }}
        multiple={multiple}
      />
    </Form>
  );
}
