import React from 'react';
import { t } from '@psp/common';

import { MEDICATION_ADMINISTRATION } from '../../../constants';
import Breadcrumbs from '../../../components/Breadcrumbs';
import MedicationAdminListComposer from '../../../composers/MedicationAdminListComposer';

export default function MedicationAdministrationListPage(): JSX.Element {
  return (
    <>
      <Breadcrumbs title={t(`navigation.${MEDICATION_ADMINISTRATION}`)} />

      <MedicationAdminListComposer />
    </>
  );
}
