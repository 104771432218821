import React from 'react';
import {
  getUserGroupHistory,
} from '@psp/common';

import UserGroupHistory, { UserGroupHistoryProps } from '../../components/UserGroups/History';

const dispatchers: Pick<
  UserGroupHistoryProps,
  | 'getUserGroupHistory'
> = {
  getUserGroupHistory,
};

export default function UserGroupHistoryComposer(): JSX.Element {
  return <UserGroupHistory {...dispatchers} />;
}
