import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import {
  t,
  GetFollowupExamGroups,
  GetExamGroupExams,
  ExamGroup,
  Exam,
  GetProgramFollowups,
  Followup,
} from '@psp/common';

import { useLoading } from '../../contexts/loading.context';
import Select from '../Select';
import { useProgram } from '../../contexts/program.context';

export type ExamGroupSelectValues = {
  followupId: string;
  followupName: string;
  examGroupId: string;
  examGroupName: string;
  examGroup?: ExamGroup;
};

type FormFieldProps = {
  name?: string;
};

export type ExamGroupSelectProps = {
  getProgramFollowups: GetProgramFollowups;
  getFollowupExamGroups: GetFollowupExamGroups;
  onSelectedValueChange: (values: ExamGroupSelectValues) => void;
  optionalExam?: boolean;
  formRef: any;
  followupsFieldProps?: FormFieldProps;
  examGroupFieldProps?: FormFieldProps;
};

type ExamGroupSelectState = {
  examGroups: ExamGroup[];
  examGroupId: string;
  followups: Followup[];
  followupId: string;
};

const defaultState: ExamGroupSelectState = {
  examGroups: [],
  examGroupId: '',
  followups: [],
  followupId: '',
};

export default function ExamGroupSelect({
  getProgramFollowups,
  getFollowupExamGroups,
  onSelectedValueChange,
  formRef,
  followupsFieldProps,
  examGroupFieldProps,
}: ExamGroupSelectProps): JSX.Element {
  const followupRef: any = useRef(null);
  const examGroupRef: any = useRef(null);
  const examRef: any = useRef(null);
  const [state, setState] = useState<ExamGroupSelectState>(defaultState);
  const { showLoading, hideLoading, isLoading } = useLoading();
  const { program } = useProgram();

  useEffect(
    useCallback(() => {
      if (!program) return;
      showLoading();
      setState({
        ...defaultState,
      });
      (async () => {
        try {
          const followups = await getProgramFollowups.execute({ programId: program?.id });
          setState({
            ...defaultState,
            followups,
          });
          onSelectedValueChange({
            followupId: '',
            followupName: '',
            examGroupId: '',
            examGroupName: '',
          });
          formRef.current.setFieldValue('examGroupId', null);
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    }, [state, onSelectedValueChange]),
    [program],
  );

  const handleFollowupChange = useCallback(
    (value) => {
      if (!value) return;
      showLoading();
      setState({
        ...state,
        examGroups: [],
      });
      (async () => {
        try {
          const examGroups = await getFollowupExamGroups.execute({ followupId: value });
          setState({
            ...state,
            followupId: value,
            examGroups,
          });
          onSelectedValueChange({
            followupId: value,
            followupName: state.followups.find((p) => p.id === value)!.name,
            examGroupId: '',
            examGroupName: '',
          });
          formRef.current.setFieldValue('examGroupId', null);
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
          if (followupRef.current) {
            followupRef.current.focus();
          }
        }
      })();
    },
    [state, onSelectedValueChange],
  );

  const handleExamGroupChange = useCallback(
    (value) => {
      if (!value) return;
      showLoading();
      (async () => {
        try {
          setState({
            ...state,
            examGroupId: value,
          });
          const examGroup = state.examGroups.find((p) => p.id === value);
          onSelectedValueChange({
            followupId: state.followupId,
            followupName: state.followups.find((p) => p.id === state.followupId)!.name,
            examGroupId: value,
            examGroupName: examGroup!.name,
            examGroup,
          });
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
          if (examGroupRef.current) {
            examGroupRef.current.focus();
          }
        }
      })();
    },
    [state, onSelectedValueChange],
  );

  return (
    <Grid container spacing={2} direction="row">
      <Grid item xs={12} md={6}>
        <Select
          label={t('followup')}
          variant="outlined"
          name="followupId"
          fullWidth
          onSelectedValueChange={handleFollowupChange}
          disabled={state.followups.length === 0 || isLoading}
          inputRef={followupRef}
          {...followupsFieldProps}
        >
          {state.followups.map((f) => (
            <MenuItem key={f.id} value={f.id}>
              {f.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} md={6}>
        <Select
          label={t('examGroup')}
          variant="outlined"
          name="examGroupId"
          fullWidth
          onSelectedValueChange={handleExamGroupChange}
          disabled={state.examGroups.length === 0 || isLoading}
          inputRef={examGroupRef}
          {...examGroupFieldProps}
        >
          {state.examGroups.map((eg) => (
            <MenuItem key={eg.id} value={eg.id}>
              {eg.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
}
