import React, { useCallback, useState } from 'react';
import { List, ListItem, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { ExpandMoreRounded } from '@material-ui/icons';
import clsx from 'clsx';
import { Program } from '@psp/common';

import { useProgram } from '../../contexts/program.context';
import { useStyles } from './styles';

export type ProgramSwitcherProps = {
  className?: string;
  [key: string]: unknown;
};

export default function ProgramSwitcher({ className }: ProgramSwitcherProps): JSX.Element {
  const classes = useStyles();
  const [menu, setMenu] = useState(null);
  const { programs, program, selectProgram } = useProgram();

  const handleMenuOpen = useCallback(
    (event: any) => {
      setMenu(event.currentTarget);
    },
    [setMenu],
  );

  const handleMenuClose = useCallback(() => {
    setMenu(null);
  }, [setMenu]);

  const handleSelectProgram = useCallback(
    (selectedProgram: Program) => () => {
      selectProgram(selectedProgram);
      handleMenuClose();
    },
    [selectProgram, handleMenuClose],
  );

  return (
    <>
      <List disablePadding className={clsx(classes.list, className)}>
        <ListItem
          button
          dense
          disableGutters
          aria-haspopup="true"
          aria-controls="program-menu"
          className={classes.listItem}
        >
          {program ? (
            <ListItemText
              primary={program.client.name}
              secondary={program.name}
              className={classes.itemText}
              onClick={handleMenuOpen}
              primaryTypographyProps={{
                className: classes.primary,
              }}
              secondaryTypographyProps={{
                className: classes.secondary,
              }}
            />
          ) : (
            <ListItemText
              primary="Selecione o"
              secondary="Programa"
              className={classes.itemText}
              onClick={handleMenuOpen}
              primaryTypographyProps={{
                className: classes.primary,
              }}
              secondaryTypographyProps={{
                className: classes.secondary,
              }}
            />
          )}
          <ExpandMoreRounded className={classes.icon} onClick={handleMenuOpen} />
        </ListItem>
      </List>
      <Menu
        id="program-menu"
        anchorEl={menu}
        open={Boolean(menu)}
        keepMounted
        onClose={handleMenuClose}
        MenuListProps={{
          className: classes.menu,
        }}
      >
        {programs
          && programs.map((p) => (
            <MenuItem key={`program-menu-${p.id}`} button dense onClick={handleSelectProgram(p)}>
              <ListItemText
                primary={p.client.name}
                secondary={p.name}
                className={classes.itemText}
                primaryTypographyProps={{
                  className: classes.primary,
                }}
                secondaryTypographyProps={{
                  className: classes.secondary,
                }}
              />
            </MenuItem>
          ))}
      </Menu>
    </>
  );
}
