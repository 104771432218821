import React from 'react';
import { t } from '@psp/common';

import Breadcrumbs from '../../../components/Breadcrumbs';
import DoctorListComposer from '../../../composers/DoctorListComposer';
import { DOCTORS_ROUTE } from '../../../constants';

export default function DoctorListPage(): JSX.Element {
  return (
    <>
      <Breadcrumbs title={t(`navigation.${DOCTORS_ROUTE}`)} />
      <DoctorListComposer />
    </>
  );
}
