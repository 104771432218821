import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  Container: {
    border: 'none',
    backgroundColor: 'transparent',
    display: 'flex',
  },
  Solicitation: {
    fontSize: 11,
    marginTop: 5,
  },
  Name: {
    fontSize: 15,
    marginTop: 5,
    fontWeight: 'bold',
  },
  Document: {
    fontSize: 15,
    marginTop: 5,
  },
});
