import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Pill, Stethoscope, HeartPulse, FileDownload } from 'mdi-material-ui';
import {
  ProgramFile,
  LibraryType,
  ProgramModulesEnum,
  t,
  emptyFunction,
  DownloadProgramFile,
} from '@psp/common';
import { toast } from 'react-toastify';
import FileCard from '../FileCard';
import { useProgram } from '../../contexts/program.context';
import { useLoading } from '../../contexts/loading.context';

export type LibraryDisplayProps = {
  programFiles: ProgramFile[];
  downloadProgramFile: DownloadProgramFile;
  showDelete: boolean;
  onDelete?: (programFile: ProgramFile) => void;
};

export type LibraryDisplayState = {
  groups: {
    [key in keyof typeof LibraryType]?: ProgramFile[];
  };
};

const defaultState: LibraryDisplayState = {
  groups: {},
};

export default function LibraryDisplay({
  programFiles,
  downloadProgramFile,
  showDelete = false,
  onDelete = emptyFunction,
}: LibraryDisplayProps): JSX.Element {
  const [state, setState] = useState<LibraryDisplayState>(defaultState);
  const { modules } = useProgram();
  const { isLoading, showLoading, hideLoading, showBackdrop, hideBackdrop } = useLoading();

  useEffect(() => {
    if (programFiles.length) {
      setState({
        groups: {
          GENERAL: programFiles.filter(
            (pf: ProgramFile) => !pf.programId,
          ),
          PROGRAM: programFiles.filter(
            (pf: ProgramFile) => (!pf.procedureType || pf.procedureType === LibraryType.PROGRAM),
          ),
          [LibraryType.CLINICAL_EXAMINATION]: programFiles.filter(
            (pf: ProgramFile) => pf.procedureType === LibraryType.CLINICAL_EXAMINATION,
          ),
          [LibraryType.FIRST_MEDICINE]: programFiles.filter(
            (pf: ProgramFile) => pf.procedureType === LibraryType.FIRST_MEDICINE,
          ),
          [LibraryType.PATIENT_PROCEDURE]: programFiles.filter(
            (pf: ProgramFile) => pf.procedureType === LibraryType.PATIENT_PROCEDURE,
          ),
          [LibraryType.SALESFORCE_VISIT]: programFiles.filter(
            (pf: ProgramFile) => pf.procedureType === LibraryType.SALESFORCE_VISIT,
          ),
          [LibraryType.SUPPLY]: programFiles.filter(
            (pf: ProgramFile) => pf.procedureType === LibraryType.SUPPLY,
          ),
          [LibraryType.DOCTOR_BENEFITS]: programFiles.filter(
            (pf: ProgramFile) => pf.procedureType === LibraryType.DOCTOR_BENEFITS,
          ),
          [LibraryType.PCDT]: programFiles.filter(
            (pf: ProgramFile) => pf.procedureType === LibraryType.PCDT,
          ),
        },
      });
    }
  }, [programFiles]);

  const download = useCallback(
    (programFile: ProgramFile): void => {
      showLoading();
      (async () => {
        try {
          const downloadUrl = await downloadProgramFile.execute({ programFileId: programFile.id });

          if (downloadUrl && downloadUrl !== '') {
            window.open(downloadUrl, '_blank');
          } else {
            throw new Error('failedToDownloadFile');
          }
        } catch (err) {
          console.log(err);
          if (err.message === 'err.failedToDownloadFile') {
            toast.info(t(`err.${err.message}`), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } else {
            toast.info(err.message, {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          }
        } finally {
          hideLoading();
        }
      })();
    }, [downloadProgramFile],
  );

  return (
    <Box>
      {Object.keys(state.groups)
        .filter((tp) => (state?.groups[tp as LibraryType]?.length ?? 0) > 0)
        .filter((tp) => tp === 'GENERAL' || tp === 'PROGRAM' || modules.includes(tp as ProgramModulesEnum))
        .map((tp) => (
          <Accordion key={`programFiles_${tp}`} defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h6">
                <strong>{t(`library.${tp}`)}</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} direction="row">
                {state?.groups[tp as LibraryType]?.map((pf) => (
                  <Grid key={pf.id} item>
                    <FileCard
                      title={pf.name}
                      description={pf.description ?? ''}
                      color="primary"
                      icon={FileDownload}
                      onClick={() => download(pf)}
                      showDelete={showDelete}
                      onDelete={() => onDelete(pf)}
                    />
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
    </Box>
  );
}
