import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { FileDownload } from 'mdi-material-ui';

import {
  ProgramFile,
  LibraryType,
  t,
  DownloadProgramFile,
  DeleteProgramFile,
  GetProgramFiles,
  GetProgramProducts,
  GetProductPresentations,
  GetProductPatientProcedures,
  GetProgramFollowups,
  GetFollowupExamGroups,
  GetExamGroupExams,
  GetProgramSupplyGroups,
  GetSupplyGroupSupplies,
  CreateProgramFile,
} from '@psp/common';

import { useLoading } from '../../../contexts/loading.context';
import { useProgram } from '../../../contexts/program.context';
import { useAuth } from '../../../contexts/auth.context';
import { useStyles } from './styles';

import FileCard from '../../FileCard';
import Button from '../../Button';
import LibraryUpload from '../../LibraryUpload';

/* eslint-disable no-shadow */
enum States {
  Geral = 'Geral',
  AC = 'AC',
  AL = 'AL',
  AP = 'AP',
  AM = 'AM',
  BA = 'BA',
  CE = 'CE',
  DF = 'DF',
  ES = 'ES',
  GO = 'GO',
  MA = 'MA',
  MT = 'MT',
  MS = 'MS',
  MG = 'MG',
  PR = 'PR',
  PB = 'PB',
  PA = 'PA',
  PE = 'PE',
  PI = 'PI',
  RJ = 'RJ',
  RN = 'RN',
  RS = 'RS',
  RO = 'RO',
  RR = 'RR',
  SC = 'SC',
  SE = 'SE',
  SP = 'SP',
  TO = 'TO',
}

export type PcdtDisplayProps = {
  getProgramFiles: GetProgramFiles;
  downloadProgramFile: DownloadProgramFile
  deleteProgramFile: DeleteProgramFile;
  getProgramProducts: GetProgramProducts;
  getProductPresentations: GetProductPresentations;
  getProductPatientProcedures: GetProductPatientProcedures;
  getProgramFollowups: GetProgramFollowups;
  getFollowupExamGroups: GetFollowupExamGroups;
  getExamGroupExams: GetExamGroupExams;
  getProgramSupplyGroups: GetProgramSupplyGroups;
  getSupplyGroupSupplies: GetSupplyGroupSupplies;
  createProgramFile: CreateProgramFile;
};

export type LibraryDisplayState = {
  groups: {
    [key in keyof typeof States]?: ProgramFile[];
  };
  programFiles: ProgramFile[];
  removalDialog: boolean;
  programFileForRemoval?: ProgramFile;
};

const defaultState: LibraryDisplayState = {
  groups: {},
  programFiles: [] as ProgramFile[],
  removalDialog: false,
  programFileForRemoval: undefined,
};

export default function PcdtDisplay({
  getProgramFiles,
  downloadProgramFile,
  deleteProgramFile,
  ...dispatchers
}: PcdtDisplayProps): JSX.Element {
  const classes = useStyles();
  const [state, setState] = useState<LibraryDisplayState>(defaultState);
  const { isLoading, showLoading, hideLoading, showBackdrop, hideBackdrop } = useLoading();
  const { program } = useProgram();
  const { isAdmin, isSysadmin } = useAuth();

  useEffect(() => {
    if (state.programFiles.length) {
      const groups = Object.fromEntries(
        Object.entries(States).map(
          ([uf, value]) => [uf, state.programFiles.filter((pf) => pf.name.startsWith(uf))],
        ).filter(([uf, files]) => files.length > 0),
      );
      setState((prevState) => ({
        ...prevState,
        groups,
      }));
    }
  }, [state.programFiles]);

  const download = useCallback(
    (programFile: ProgramFile): void => {
      showLoading();
      (async () => {
        try {
          const downloadUrl = await downloadProgramFile.execute({ programFileId: programFile.id });

          if (downloadUrl && downloadUrl !== '') {
            window.open(downloadUrl, '_blank');
          } else {
            throw new Error('failedToDownloadFile');
          }
        } catch (err) {
          console.log(err);
          if (err.message === 'err.failedToDownloadFile') {
            toast.info(t(`err.${err.message}`), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } else {
            toast.info(err.message, {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          }
        } finally {
          hideLoading();
        }
      })();
    }, [downloadProgramFile],
  );

  useEffect(() => {
    if (!program) return;
    showLoading();
    (async () => {
      try {
        if (!program) return;
        const programFiles = await getProgramFiles.execute({
          programId: program.id,
        });

        setState({
          ...defaultState,
          programFiles: programFiles.filter((pf) => pf.procedureType === LibraryType.PCDT),
        });
      } catch (err) {
        console.log(err);
      } finally {
        hideLoading();
      }
    })();
  }, [program]);

  const handleDeleteProgramFile = (programFile: ProgramFile): void => {
    setState((prevState) => ({
      ...prevState,
      removalDialog: true,
      programFileForRemoval: programFile,
    }));
  };

  const handleRemovalDialogClose = (): void => {
    setState((prevState) => ({
      ...prevState,
      removalDialog: false,
      programFileForRemoval: undefined,
    }));
  };

  const handleRemovalConfirm = (): void => {
    showLoading();
    (async () => {
      try {
        await deleteProgramFile.execute({
          programFileId: state.programFileForRemoval?.id ?? '',
        });
        toast.success(t('msg.successToDeleteProgramFile'), {
          onOpen: showBackdrop,
          onClose: hideBackdrop,
        });
        setState((prevState) => ({
          ...prevState,
          removalDialog: false,
          programFiles: prevState.programFiles.filter(
            (pf) => pf.id !== prevState.programFileForRemoval?.id,
          ),
          programFileForRemoval: undefined,
        }));
      } catch (err) {
        if (err.name === 'BadRequestError') {
          toast.info(err.message, {
            onOpen: showBackdrop,
            onClose: hideBackdrop,
          });
        } else {
          toast.info(t('err.failedToDeleteProgramFile'), {
            onOpen: showBackdrop,
            onClose: hideBackdrop,
          });
        }

        handleRemovalDialogClose();
      } finally {
        hideLoading();
      }
    })();
  };

  const handleNewProgramFile = (programFile: ProgramFile): void => {
    setState((prevState) => ({
      ...prevState,
      programFiles: [...prevState.programFiles, programFile],
    }));
  };

  return (
    <Box className={classes.container}>
      <Grid container spacing={2} direction="column">
        {(isAdmin || isSysadmin) && (
          <Grid item>
            <LibraryUpload
              onUpload={handleNewProgramFile}
              filesTypes={[LibraryType.PCDT]}
              {...dispatchers}
            />
          </Grid>
        )}
        <Grid item>
          {Object.keys(States)
            .map((uf) => (
              <Accordion key={`files_PCDT_${uf}`} defaultExpanded={Object.keys(state.groups).includes(uf)}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant="h6">
                    <strong>{t(`states.${uf}`)}</strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2} direction="row">
                    {state?.groups[uf as States]?.map((pf) => (
                      <Grid key={pf.id} item>
                        <FileCard
                          title={pf.name}
                          description={pf.description ?? ''}
                          color="primary"
                          icon={FileDownload}
                          onClick={() => download(pf)}
                          showDelete={isAdmin || isSysadmin}
                          onDelete={() => handleDeleteProgramFile(pf)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
        </Grid>
      </Grid>
      <Dialog
        open={!!state.removalDialog}
        onClose={handleRemovalDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('title.confirmRemoval')}</DialogTitle>
        <DialogActions>
          <Button onClick={handleRemovalDialogClose} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={handleRemovalConfirm} color="primary" autoFocus>
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
