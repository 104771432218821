"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
__exportStar(require("./aid"), exports);
__exportStar(require("./auth"), exports);
__exportStar(require("./clients"), exports);
__exportStar(require("./codes"), exports);
__exportStar(require("./doctors"), exports);
__exportStar(require("./exam-groups"), exports);
__exportStar(require("./exam-types"), exports);
__exportStar(require("./exams"), exports);
__exportStar(require("./files"), exports);
__exportStar(require("./followups"), exports);
__exportStar(require("./institutions"), exports);
__exportStar(require("./movements"), exports);
__exportStar(require("./pathologies"), exports);
__exportStar(require("./patient-procedures"), exports);
__exportStar(require("./patients"), exports);
__exportStar(require("./presentations"), exports);
__exportStar(require("./procedures"), exports);
__exportStar(require("./products"), exports);
__exportStar(require("./programs"), exports);
__exportStar(require("./rules"), exports);
__exportStar(require("./tables"), exports);
__exportStar(require("./supplies"), exports);
__exportStar(require("./travels"), exports);
__exportStar(require("./treatment-locations"), exports);
__exportStar(require("./tables"), exports);
__exportStar(require("./usage-policies"), exports);
__exportStar(require("./user-addresses"), exports);
__exportStar(require("./user-groups"), exports);
__exportStar(require("./users"), exports);
__exportStar(require("./field-configs"), exports);
__exportStar(require("./via-cep"), exports);
__exportStar(require("./funcional-address"), exports);
__exportStar(require("./pharma-rule-program"), exports);
__exportStar(require("./wsacesso"), exports);
