import React, { useState, useEffect, ChangeEvent, useCallback, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
  Table as TableComponent,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
  TableHead,
  TableCell,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  Paper,
  Typography,
  Avatar,
  Radio,
  TableRowProps,
  Grid,
  Checkbox,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { AddBox, Edit, Check, Close, ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { CheckboxBlankCircleOutline, CheckboxMarkedCircle } from 'mdi-material-ui';
import { t, emptyFunction, SortingType, GetProgramFollowups, GetFollowupExamGroups } from '@psp/common';
import { useStyles } from './styles';
import SearchBar from '../SearchBar';
import PaperTitle from '../PaperTitle';
import StatusSelect from '../StatusSelect';
import YearMonthSelect from '../YearMonthSelect';
import FollowupExamGroupSelect from '../FollowupExamGroupSelect';

export interface SortingConfiguration {
    propertyName: keyof {
        id: string;
        [key: string]: unknown;
    },
    sortType: SortingType,
    compareFunction: TableDataComparable
}

export type TableDataComparable = ((a: {
    id: string;
    [key: string]: unknown;
}, b: {
    id: string;
    [key: string]: unknown;
}) => number);

export type TableColumn = {
  avatar?: boolean;
  avatarFallback?: string;
  currency?: boolean;
  key: string;
  label: string;
  numeric?: boolean;
  width?: number | string;
  maxWidth?: number | string;
  render?: (value: any, item?: any) => JSX.Element | string;
  compareFunction: TableDataComparable;
};

export type TableProps = {
  addRoute?: string;
  addAction?: () => void;
  canAdd?: boolean;
  canApprove?: boolean;
  canEdit?: boolean;
  canRemove?: boolean;
  canSearch?: boolean;
  enableSelectFilter?: boolean;
  selectOptions?: any;
  selectFilterTitle?: string;
  canSelect?: boolean;
  enableFollowupFilter?: boolean;
  followupFilterMultiSelect?: boolean;
  enableStatusFilter?: boolean;
  statusFilterMultiSelect?: boolean;
  enableExamGroupFilter?: boolean;
  examGroupFilterMultiSelect?: boolean;
  enableDateFilter?: boolean;
  dateFilterMultiSelect?: boolean;
  getProgramFollowups?: GetProgramFollowups;
  getFollowupExamGroups?: GetFollowupExamGroups;
  canView?: boolean;
  columns: TableColumn[];
  count?: number;
  data: ReadonlyArray<{
    id: string;
    [key: string]: unknown;
  }>;
  editRoute?: string;
  elevation?: number;
  extraActions?: {
    icon: JSX.Element;
    label: string;
    onExecute?: (item: any) => void;
    route?: string;
  }[];
  loading?: boolean;
  onApprove?: (item: any) => void;
  onChangePage?: (page: number) => void;
  onChangeRows?: (rows: number) => void;
  onRemove?: (item: any) => void;
  onSearch?: (query: string) => void;
  onSearchBySelect?: (e: ChangeEvent<HTMLInputElement>) => void;
  onSelect?: (item: any) => void;
  onChangeFollowupfilter?: (item: any) => void;
  onChangeStatusfilter?: (item: any) => void;
  onChangeExamGroupfilter?: (item: any) => void;
  onChangeDatefilter?: (item: any) => void;
  page?: number;
  rowsPerPage?: number;
  size?: 'small' | 'medium';
  striped?: boolean;
  title?: string;
  viewRoute?: string;
  selectedIds?: string[];
  onSortBy?: (column: string, compareFunction: TableDataComparable) => void;
  onSortConfig?: SortingConfiguration[];
};

type TableState = {
  page: number;
  approvalDialogItem: any;
  removalDialogItem: any;
  rowsPerPage: number;
  userType: string;
  followupId?: string;
  groupId?: string;
  groupName?: string;
};

export default function Table({
  addRoute,
  addAction,
  canAdd,
  canApprove,
  canEdit,
  canRemove,
  canSearch,
  enableSelectFilter,
  selectOptions,
  selectFilterTitle,
  canSelect,
  enableFollowupFilter,
  followupFilterMultiSelect = false,
  enableStatusFilter,
  statusFilterMultiSelect = false,
  enableExamGroupFilter,
  examGroupFilterMultiSelect = false,
  enableDateFilter,
  dateFilterMultiSelect = false,
  getFollowupExamGroups,
  canView,
  columns,
  count,
  data,
  editRoute,
  elevation = 1,
  extraActions,
  loading,
  onApprove = emptyFunction,
  onChangePage = emptyFunction,
  onChangeRows = emptyFunction,
  onRemove = emptyFunction,
  onSearch = emptyFunction,
  onSearchBySelect = emptyFunction,
  onSelect = emptyFunction,
  onChangeFollowupfilter = emptyFunction,
  onChangeStatusfilter = emptyFunction,
  onChangeExamGroupfilter = emptyFunction,
  onChangeDatefilter = emptyFunction,
  page,
  rowsPerPage,
  size = 'medium',
  striped = true,
  title,
  viewRoute,
  selectedIds = [],
  onSortBy = emptyFunction,
  onSortConfig = [],
}: TableProps): JSX.Element {
  const classes = useStyles();
  const { push } = useHistory();
  const [state, setState] = useState<TableState>({
    page: 0,
    approvalDialogItem: undefined,
    removalDialogItem: undefined,
    rowsPerPage: 10,
    userType: '',
    followupId: undefined,
    groupId: undefined,
    groupName: undefined,
  });
  const [selected, setSelected] = useState<any>([]);
  useEffect(() => {
    if (selected.length) {
      setSelected([]);
    }
  }, [data]);

  function handlePageChange(_event: any, newPage: number): void {
    onChangePage(newPage);
    setState({
      ...state,
      page: newPage,
    });
  }

  function handleSortBy(column: string, compareFunction: TableDataComparable): void {
    onSortBy(column, compareFunction);
  }

  function handleRowsPerPageChange(event: any): void {
    onChangeRows(+event.target.value);
    onChangePage(0);
    setState({
      ...state,
      page: 0,
      rowsPerPage: +event.target.value,
    });
  }

  function handleApprove(item: any): void {
    setState({
      ...state,
      approvalDialogItem: item,
    });
  }

  function handleApprovalConfirm(): void {
    onApprove(state.approvalDialogItem);
    setState({
      ...state,
      approvalDialogItem: undefined,
    });
  }

  function handleApprovalDialogClose(): void {
    setState({
      ...state,
      approvalDialogItem: undefined,
    });
  }

  function handleRemove(item: any): void {
    setState({
      ...state,
      removalDialogItem: item,
    });
  }

  function handleRemovalConfirm(): void {
    onRemove(state.removalDialogItem);
    setState({
      ...state,
      removalDialogItem: undefined,
    });
  }

  function handleRemovalDialogClose(): void {
    setState({
      ...state,
      removalDialogItem: undefined,
    });
  }

  function handleSelect(item: any): any {
    return function handleItemSelect(): void {
      const index = selected.indexOf(item.id);
      if (index >= 0) {
        const newSelected = [...selected];
        newSelected.splice(index, 1);
        setSelected(newSelected);
      } else {
        setSelected([...selected, item.id]);
      }
      onSelect(item);
    };
  }

  function handleClick(item: any) {
    return function handleItemClick(): void {
      if (!viewRoute) return;
      push(viewRoute.replace(':id', item.id));
    };
  }

  const getSortDirection = (property: keyof {
        id: string;
        [key: string]: unknown;
    }) => {
    const config = onSortConfig.find((onSortConf) => onSortConf.propertyName === property);
    return config
      ? config.sortType === SortingType.Descending
        ? <ArrowDownward />
        : <ArrowUpward />
      : null;
  };
  function renderHead(): JSX.Element[] {
    let cells: JSX.Element[] = [];
    if (canSelect) {
      cells = [<TableCell key="checkbox" />];
    }
    cells = [
      ...cells,
      ...columns.map((c, index) => (
        <TableCell
          key={`${c.key}[${index}]`}
          align={c.numeric || c.currency ? 'right' : 'left'}
          className={c.avatar ? classes.avatarCell : ''}
          onClick={(): void => handleSortBy(c.key, c.compareFunction)}
          style={c.width && c.maxWidth
            ? { width: c.width, maxWidth: c.maxWidth }
            : c.width
              ? { width: c.width }
              : c.maxWidth
                ? { maxWidth: c.maxWidth }
                : {}}
        >
          <span>
            {c.label}
            {getSortDirection(c.key)}
          </span>
        </TableCell>
      )),
    ];
    if (canEdit || canRemove || canApprove || extraActions) {
      cells = [
        ...cells,
        <TableCell key="actions">
          <strong>Ações</strong>
        </TableCell>,
      ];
    }
    return cells;
  }

  function renderActions(item: any): JSX.Element[] {
    const actions: JSX.Element[] = [];
    if (canApprove && onApprove && item.user && !item.user.approved) {
      actions.push(
        <IconButton
          key={`tablerow[${item.id}][action][approve]`}
          aria-label="approve"
          onClick={(): void => handleApprove(item)}
          style={{
            maxHeight: 28,
            maxWidth: 28,
          }}
        >
          <Check />
        </IconButton>,
      );
    }
    if (canEdit && editRoute) {
      actions.push(
        <IconButton
          key={`tablerow[${item.id}][action][edit]`}
          aria-label="edit"
          component={Link}
          to={editRoute.replace(':id', item.id)}
        >
          <Edit />
        </IconButton>,
      );
    }
    if (extraActions && extraActions.length) {
      extraActions.forEach((e) => actions.push(
        e.route ? (
          <IconButton
            key={`tablerow[${item.id}][action][${e.label}]`}
            aria-label={e.label}
            component={Link}
            to={e.route.replace(':id', item.id)}
          >
            {e.icon}
          </IconButton>
        ) : (
          <IconButton
            key={`tablerow[${item.id}][action][${e.label}]`}
            aria-label={e.label}
            onClick={(): void => e.onExecute && e.onExecute(item)}
          >
            {e.icon}
          </IconButton>
        ),
      ));
    }
    if (canRemove && onRemove) {
      actions.push(
        <IconButton
          key={`tablerow[${item.id}][action][remove]`}
          aria-label="remove"
          onClick={(): void => handleRemove(item)}
        >
          <Close />
        </IconButton>,
      );
    }
    return actions;
  }

  function renderBody(): JSX.Element[] {
    const rows = count
      ? data
      : data.slice(
        state.page * state.rowsPerPage,
        state.page * state.rowsPerPage + state.rowsPerPage,
      );
    return rows.map((d: any) => (
      <TableRow
        hover={canSelect || canView}
        key={`tablerow[${d.id}]`}
        className={clsx({
          [classes.striped]: striped,
          [classes.selectableRow]: canSelect || canView,
        })}
        onClick={
          canApprove
            ? undefined
            : canView
              ? handleClick(d)
              : canSelect
                ? handleSelect(d)
                : undefined
        }
      >
        {canSelect && (
          <TableCell key={`tablerow[${d.id}][check]`} align="center" padding="checkbox">
            <Checkbox
              checked={selected.includes(d.id) || selectedIds.includes(d.id)}
              onClick={canView ? handleSelect(d) : undefined}
              icon={<CheckboxBlankCircleOutline />}
              checkedIcon={<CheckboxMarkedCircle />}
            />
          </TableCell>
        )}
        {columns.map((c: TableColumn, index: number) => (
          <TableCell
            key={`tablerow[${d.id}][${c.key}][${index}]`}
            className={clsx({ [classes.avatarCell]: c.avatar })}
            style={c.width && c.maxWidth
              ? { width: c.width, maxWidth: c.maxWidth }
              : c.width
                ? { width: c.width }
                : c.maxWidth
                  ? { maxWidth: c.maxWidth }
                  : {}}
            onClick={
              canApprove
                ? canView
                  ? handleClick(d)
                  : canSelect
                    ? handleSelect(d)
                    : undefined
                : undefined
            }
          >
            {c.avatar ? (
              <Avatar src={d[c.key]}>
                {!d[c.key] && c.avatarFallback !== undefined && d[c.avatarFallback]
                  ? d[c.avatarFallback].substring(0, 2).toUpperCase()
                  : ''}
              </Avatar>
            ) : c.render ? (
              c.render(d[c.key], d)
            ) : (
              d[c.key]
            )}
          </TableCell>
        ))}
        {((canEdit && editRoute)
          || (canRemove && onRemove)
          || (canApprove && onApprove)
          || extraActions) && (
          <TableCell key={`tablerow[${d.id}][actions]`} align="left">
            {renderActions(d)}
          </TableCell>
        )}
      </TableRow>
    ));
  }

  const { approvalDialogItem, removalDialogItem } = state;
  return (
    <Paper className={classes.container} elevation={elevation}>
      <PaperTitle title={title || ''}>
        {canSearch && (
          <Grid item>
            <SearchBar onChange={onSearch} label={t('search')} />
          </Grid>
        )}
        <Grid item>
          {enableSelectFilter && selectOptions && (
            <TextField
              className={classes.select}
              select
              label={selectFilterTitle || ''}
              defaultValue=" "
              value={selectOptions['option.label']}
              onChange={onSearchBySelect}
              variant="outlined"
            >
              {selectOptions.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Grid>
        <Grid container className={classes.container} spacing={2} direction="row">
          {enableFollowupFilter && enableExamGroupFilter && (
            <Grid item>
              <FollowupExamGroupSelect
                onFollowupChange={onChangeFollowupfilter}
                onExamGroupChange={onChangeExamGroupfilter}
                multiple={followupFilterMultiSelect}
              />
            </Grid>
          )}
          {enableStatusFilter && (
            <Grid item>
              <StatusSelect onChange={onChangeStatusfilter} multiple={statusFilterMultiSelect} />
            </Grid>
          )}
          {enableDateFilter && (
            <Grid item>
              <YearMonthSelect onChange={onChangeDatefilter} multiple={dateFilterMultiSelect} />
            </Grid>
          )}
        </Grid>
        {canAdd && addRoute && (
          <Grid item>
            <Button
              component={Link}
              variant="contained"
              color="primary"
              startIcon={<AddBox />}
              to={addRoute}
            >
              Incluir
            </Button>
          </Grid>
        )}
        {canAdd && addAction && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddBox />}
              onClick={addAction}
            >
              Incluir
            </Button>
          </Grid>
        )}
      </PaperTitle>
      <TableContainer>
        <TableComponent size={size}>
          <TableHead>
            <TableRow>{renderHead()}</TableRow>
          </TableHead>
          <TableBody>{renderBody()}</TableBody>
        </TableComponent>
        <TablePagination
          component="div"
          page={page ?? state.page}
          rowsPerPage={rowsPerPage ?? state.rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
          count={count ?? data.length}
          colSpan={columns.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
        />
      </TableContainer>
      <Dialog
        open={!!approvalDialogItem}
        onClose={handleApprovalDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-approval">{t('title.confirmApproval')}</DialogTitle>
        <DialogActions>
          <Button onClick={handleApprovalDialogClose} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={handleApprovalConfirm} color="primary" autoFocus>
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={!!removalDialogItem}
        onClose={handleRemovalDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('title.confirmRemoval')}</DialogTitle>
        <DialogActions>
          <Button onClick={handleRemovalDialogClose} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={handleRemovalConfirm} color="primary" autoFocus>
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
