import React, { useEffect, useState, useRef } from 'react';
import { useField } from '@unform/core';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  FormHelperText,
} from '@material-ui/core';
import { t } from '@psp/common';
import { useStyles } from './styles';

type CheckboxSelectStyle = {
  width?: string | number | undefined,
  size?: 'small' | 'medium' | undefined,
}

type CheckboxSelectItem = {
  id: string,
  name: string,
}

export type CheckboxSelectProps = {
  name: string;
  label: string;
  items: CheckboxSelectItem[];
  multiple: boolean;
  onChange?: (e: any) => void;
  auxiliarFunction?: (e: any) => void;
  helperText?: string
  style?: CheckboxSelectStyle;
  disabled?: boolean,
};

type CheckboxSelectState = {
  selectedIds: string[];
};

const defaultState: CheckboxSelectState = {
  selectedIds: [],
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function CheckboxSelect({
  name,
  label,
  items,
  multiple,
  onChange,
  helperText,
  style,
  disabled,
  auxiliarFunction,
}: CheckboxSelectProps): JSX.Element {
  const inputRef = useRef(null);
  const classes = useStyles();
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [value, setValue] = useState<string[]>([]);

  const handleChange = (e: any): void => {
    setValue(e.target.value);
    if (inputRef?.current) {
      (inputRef.current as any).value = e.target.value;
    }
    if (onChange) {
      onChange(e.target.value);
    }
    if (auxiliarFunction) {
      auxiliarFunction(e.target.value);
    }
  };

  useEffect(() => {
    setValue([]);
  },
  [JSON.stringify(items)]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue: (ref, newValue: string[]) => {
        setValue(newValue ?? []);
        if (ref && (newValue ?? [])) {
          // eslint-disable-next-line no-param-reassign
          ref.value = newValue ?? [];
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <div>
      <FormControl
        style={{
          width: style?.width ?? '100%',
        }}
        variant="outlined"
        size={style?.size}
      >
        <InputLabel>{label}</InputLabel>
        <Select
          name={name}
          ref={inputRef}
          className={classes.select}
          error={!!error}
          label={label}
          multiple={multiple}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          renderValue={(selected: any) => {
            const selectedItems: any = [];
            if (Array.isArray(selected)) {
              selected.map((x: string) => {
                if (items.find((i) => i.id === x)) {
                  selectedItems.push(items.find((i) => i.id === x)?.name);
                }
                return selectedItems;
              });
            } else {
              selectedItems.push(items.find((i) => i.id === selected)?.name);
            }

            return selectedItems.join(', ');
          }}
          MenuProps={MenuProps}
        >
          {items.map((item) => (
            <MenuItem style={{ alignItems: 'center' }} key={item.id} value={item.id}>
              <Checkbox checked={value.indexOf(item.id) > -1} />
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error={!!error}>{error ? t(error) : helperText}</FormHelperText>
      </FormControl>
    </div>
  );
}
