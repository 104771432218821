import React from 'react';
import { getUser, registerUser } from '@psp/common';

import UserRegister, { UserRegisterProps } from '../../components/User/Register';

const dispatchers: UserRegisterProps = {
  getUser,
  registerUser,
};

export default function UserRegisterComposer(): JSX.Element {
  return <UserRegister {...dispatchers} />;
}
