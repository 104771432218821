import React, { useState, ChangeEvent } from 'react';
import { InputBase } from '@material-ui/core';
import { SearchSharp } from '@material-ui/icons';

import { emptyFunction } from '@psp/common';
import { useStyles } from './styles';

type SearchBarProps = {
  label?: string;
  onChange?: (query: string) => void;
};

export default function SearchBar({
  label,
  onChange = emptyFunction,
}: SearchBarProps): JSX.Element {
  const classes = useStyles();
  const [value, setValue] = useState('');

  function handleValueChange(e: ChangeEvent<HTMLInputElement>): void {
    setValue(e.target.value);
    onChange(e.target.value);
  }

  return (
    <div className={classes.container}>
      <div className={classes.icon}>
        <SearchSharp />
      </div>
      <InputBase
        placeholder={label}
        value={value}
        onChange={handleValueChange}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
      />
    </div>
  );
}
