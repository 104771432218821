import { lighten, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    overflowX: 'hidden',
  },
  mainContainer: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  navbarContainer: {
    padding: '0 30px',
  },
  footerContainer: {
    padding: '0 30px',
  },
  main: {
    padding: '0  30px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  gridContainer: {
    height: 'calc(100vh - 25px)',
    overflowX: 'hidden',
  },
  titleContainer: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
  },
  title: {
    fontSize: 25,
    fontWeight: 700,
  },
  subtitle: {
    fontSize: 16,
    color: theme.palette.grey[600],
  },
  h1: {
    fontWeight: 700,
    fontSize: 64,
    textAlign: 'center',
    color: theme.palette.primary.light,
    [theme.breakpoints.down('xs')]: {
      fontSize: 32,
    },
  },
  h2: {
    fontWeight: 700,
    fontSize: 24,
    textAlign: 'center',
    color: theme.palette.grey[500],
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
      marginLeft: 10,
      marginRight: 10,
    },
  },
  hidden: {
    display: 'none',
  },
  buttonGrid: {
    display: 'flex',
  },
  codeButton: {
    width: 120,
  },
  codeField: {
    width: 40,
    marginLeft: 5,
    marginRight: 5,
  },
  left: {
    textAlign: 'left',
  },
  padding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  registrationForm: {
    [theme.breakpoints.up('sm')]: {
      // maxWidth: 400,
    },
  },
  searchButton: {
    height: 40,
  },
  box: {
    background: '#F5F7FC',
    borderRadius: 8,
    padding: theme.spacing(2),
  },
  input: {
    background: theme.palette.common.white,
    boxShadow: '3px 6px 12px 0px rgba(0, 0, 0, 0.05)',
  },
  crmInput: {
    [theme.breakpoints.up('sm')]: {
      width: 130,
    },
  },
  blueBox: {
    background: 'var(--primary-main)',
    color: theme.palette.common.white,
    padding: theme.spacing(3),
  },
  blueBoxTitle: {
    fontSize: 30,
    fontWeight: 'bold',
  },
  blueBoxSubtitle: {
    fontSize: 18,
  },
  blueBoxIcon: {
    fontSize: 76,
  },
}));

export const useTooltipStyles = makeStyles((theme) => ({
  tooltip: {
    background: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    fontSize: 20,
  },
}));
