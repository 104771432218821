import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Grid, Paper } from '@material-ui/core';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import {
  extractValidationErrors,
  t,
  GetProduct,
  RegisterProduct,
  RegisterProductParams,
  productRegisterForm,
  ValidationError,
  Product,
} from '@psp/common';

import { PRODUCT_REGISTER_ROUTE, PRODUCTS_ROUTE, PRODUCT_EDIT_ROUTE } from '../../../constants';
import { useLoading } from '../../../contexts/loading.context';
import Button from '../../Button';
import Input from '../../Input';

import { useStyles } from './styles';
import { useProgram } from '../../../contexts/program.context';
import PaperTitle from '../../PaperTitle';
import PaperContent from '../../PaperContent';

export type ProductRegisterProps = {
  getProduct: GetProduct;
  registerProduct: RegisterProduct;
};

type ProductRegisterState = {
  product?: Product;
};

const defaultState: ProductRegisterState = {
  product: undefined,
};

export default function ProductRegister({
  getProduct,
  registerProduct,
}: ProductRegisterProps): JSX.Element {
  const classes = useStyles();
  const formRef = useRef({} as any);
  const [state, setState] = useState<ProductRegisterState>(defaultState);
  const { isLoading, showLoading, hideLoading, showBackdrop, hideBackdrop } = useLoading();
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const { program } = useProgram();

  const handleSubmit = useCallback(
    (_data) => {
      showLoading();
      async function runAsync() {
        try {
          const data = _data;

          Object.keys(_data).forEach((key) => {
            if (_data[key] === '') {
              data[key] = undefined;
            }
          });

          const req = (await productRegisterForm.validate(data, {
            abortEarly: false,
          })) as RegisterProductParams;
          req.programId = program?.id ?? '';
          await registerProduct.execute({
            id,
            ...req,
          });
          toast.success(id ? t('msg.successToUpdateProduct') : t('msg.successToRegisterProduct'), {
            onOpen: showBackdrop,
            onClose: hideBackdrop,
          });
          push(PRODUCTS_ROUTE);
        } catch (err) {
          let errors = {};
          if (err instanceof ValidationError) {
            errors = extractValidationErrors(err);
            formRef.current.setErrors(errors);
          } else {
            toast.error(t('err.failedToRegisterProduct'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          }
        } finally {
          hideLoading();
        }
      }
      runAsync();
    },
    [id, program, state],
  );

  useEffect(() => {
    if (!id) return;
    showLoading();
    async function runAsync() {
      try {
        const product = await getProduct.execute({ id });
        setState({
          product,
        });
        formRef.current.setData(product);
      } catch (err) {
        toast.error(t('err.failedToLoadData'));
        push(PRODUCTS_ROUTE);
      } finally {
        hideLoading();
      }
    }
    runAsync();
  }, [id, program]);

  return (
    <Box className={classes.container}>
      <Paper className={classes.container}>
        {!id ? (
          <PaperTitle title={t(`navigation.${PRODUCT_REGISTER_ROUTE}`)} />
        ) : (
          <PaperTitle title={t(`navigation.${PRODUCT_EDIT_ROUTE}`)} />
        )}
        <PaperContent>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Grid container spacing={2} direction="column">
              <Grid item xs={12} sm="auto">
                <Input
                  name="name"
                  variant="outlined"
                  label={t('name')}
                  className={classes.input}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm="auto">
                <Input
                  name="wsAcessoPathologyCode"
                  variant="outlined"
                  label={t('wsAcessoPathologyCode')}
                  className={classes.input}
                  disabled={isLoading}
                  optional
                />
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" color="primary">
                  {t('save')}
                </Button>
              </Grid>
            </Grid>
          </Form>
        </PaperContent>
      </Paper>
    </Box>
  );
}
