import React from 'react';
import { getClientsPaginate } from '@psp/common';

import ClientList, { ClientListProps } from '../../components/Client/List';

const dispatchers: Pick<ClientListProps, 'getClientsPaginate'> = {
  getClientsPaginate,
};

export default function ClientListComposer(): JSX.Element {
  return <ClientList {...dispatchers} />;
}
