import React, { useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@material-ui/core';
import { t, ProgramModulesEnum } from '@psp/common';

import { CheckboxBlankCircleOutline, CheckboxMarkedCircle } from 'mdi-material-ui';

export type ProgramModulesSelectProps = {
  value: string;
  onChange: (value: string) => void,
};

type ProgramModulesSelectState = {
  selectedModules: string[];
};

const defaultState: ProgramModulesSelectState = {
  selectedModules: [],
};

export default function ProgramModulesSelect({
  value,
  onChange,
}: ProgramModulesSelectProps): JSX.Element {
  const [state, setState] = useState<ProgramModulesSelectState>(defaultState);

  useEffect(() => {
    setState({
      selectedModules: value.split(','),
    });
  }, [value]);

  const handleChange = (_event: any, _value: boolean, moduleName: string) => {
    let selectedModules: string[];

    if (moduleName === '') {
      selectedModules = [];
    } else if (_value) {
      selectedModules = [...state.selectedModules, moduleName];
    } else {
      selectedModules = state.selectedModules.filter((m) => m !== moduleName);
    }

    setState({
      selectedModules,
    });

    onChange(selectedModules.filter((sm) => sm !== '').join(','));
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{t('programModules')}</FormLabel>
      <FormGroup>
        {Object.keys(ProgramModulesEnum).map((m) => (
          <FormControlLabel
            key={m}
            control={(
              <Checkbox
                name={m}
                checked={state.selectedModules.includes(m)}
                onChange={(_event: any, _value: boolean) => handleChange(_event, _value, m)}
                icon={<CheckboxBlankCircleOutline />}
                checkedIcon={<CheckboxMarkedCircle />}
              />
            )}
            label={t(`moduleNames.${m}`)}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}
