import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import {
  t,
  GetFollowupExamGroups,
  GetExamGroupExams,
  ExamGroup,
  Exam,
  GetProgramFollowups,
  Followup,
} from '@psp/common';

import { useLoading } from '../../contexts/loading.context';
import Select from '../Select';
import { useProgram } from '../../contexts/program.context';

export type ExamSelectValues = {
  followupId: string;
  examGroupId: string;
  examId: string;
};

export type ExamSelectProps = {
  getProgramFollowups: GetProgramFollowups;
  getFollowupExamGroups: GetFollowupExamGroups;
  getExamGroupExams: GetExamGroupExams;
  onSelectedValueChange: (values: ExamSelectValues) => void;
  optionalFollowup?: boolean;
  optionalExamGroup?: boolean;
  optionalExam?: boolean;
  formRef: any;
  direction?: 'column' | 'row';
};

type ExamSelectState = {
  examGroups: ExamGroup[];
  examGroupId: string;
  exams: Exam[];
  examId: string;
  followups: Followup[];
  followupId: string;
};

const defaultState: ExamSelectState = {
  examGroups: [],
  examGroupId: '',
  exams: [],
  examId: '',
  followups: [],
  followupId: '',
};

export default function ExamSelect({
  getProgramFollowups,
  getFollowupExamGroups,
  getExamGroupExams,
  onSelectedValueChange,
  optionalFollowup = false,
  optionalExamGroup = false,
  optionalExam = false,
  formRef,
  direction = 'row',
}: ExamSelectProps): JSX.Element {
  const followupRef: any = useRef(null);
  const examGroupRef: any = useRef(null);
  const examRef: any = useRef(null);
  const [state, setState] = useState<ExamSelectState>(defaultState);
  const { showLoading, hideLoading, isLoading } = useLoading();
  const { program } = useProgram();

  useEffect(
    useCallback(() => {
      if (!program) return;
      showLoading();
      setState({
        ...defaultState,
      });
      (async () => {
        try {
          const followups = await getProgramFollowups.execute({ programId: program?.id });
          setState({
            ...defaultState,
            followups,
          });
          onSelectedValueChange({
            followupId: '',
            examGroupId: '',
            examId: '',
          });
          formRef.current.setFieldValue('examGroupId', null);
          formRef.current.setFieldValue('examId', null);
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    }, [state, onSelectedValueChange]),
    [program],
  );

  const handleFollowupChange = useCallback(
    (value) => {
      if (!value) return;
      showLoading();
      setState({
        ...state,
        examGroups: [],
      });
      (async () => {
        try {
          const examGroups = await getFollowupExamGroups.execute({ followupId: value });
          setState({
            ...state,
            followupId: value,
            examGroups,
          });
          onSelectedValueChange({
            followupId: value,
            examGroupId: '',
            examId: '',
          });
          formRef.current.setFieldValue('examGroupId', null);
          formRef.current.setFieldValue('examId', null);
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
          if (followupRef.current) {
            followupRef.current.focus();
          }
        }
      })();
    },
    [state, onSelectedValueChange],
  );

  const handleExamGroupChange = useCallback(
    (value) => {
      if (!value) return;
      showLoading();
      setState({
        ...state,
        exams: [],
      });
      (async () => {
        try {
          const exams = await getExamGroupExams.execute({ examGroupId: value });
          setState({
            ...state,
            examGroupId: value,
            exams,
          });
          onSelectedValueChange({
            followupId: state.followupId,
            examGroupId: value,
            examId: '',
          });
          formRef.current.setFieldValue('examId', null);
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
          if (examGroupRef.current) {
            examGroupRef.current.focus();
          }
        }
      })();
    },
    [state, onSelectedValueChange],
  );

  const handleExamChange = useCallback(
    (value) => {
      if (!value) return;
      showLoading();
      (async () => {
        try {
          setState({
            ...state,
            examId: value,
          });
          onSelectedValueChange({
            followupId: state.followupId,
            examGroupId: state.examGroupId,
            examId: value,
          });
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
          if (examRef.current) {
            examRef.current.focus();
          }
        }
      })();
    },
    [state, onSelectedValueChange],
  );
  return (
    <Grid container spacing={2} direction={direction}>
      <Grid item xs={12} md={direction === 'column' ? 12 : 6}>
        <Select
          label={t('followup')}
          variant="outlined"
          name="followupId"
          fullWidth
          onSelectedValueChange={handleFollowupChange}
          disabled={state.followups.length === 0 || isLoading}
          inputRef={followupRef}
          optional={optionalFollowup}
        >
          {state.followups.map((f) => (
            <MenuItem key={f.id} value={f.id}>
              {f.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} md={direction === 'column' ? 12 : 6}>
        <Select
          label={t('examGroup')}
          variant="outlined"
          name="examGroupId"
          fullWidth
          onSelectedValueChange={handleExamGroupChange}
          disabled={state.examGroups.length === 0 || isLoading}
          inputRef={examGroupRef}
          optional={optionalExamGroup}
        >
          {state.examGroups.map((eg) => (
            <MenuItem key={eg.id} value={eg.id}>
              {eg.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} md={direction === 'column' ? 12 : 6}>
        <Select
          label={t('exam')}
          variant="outlined"
          name="examId"
          fullWidth
          onSelectedValueChange={handleExamChange}
          disabled={state.exams.length === 0 || isLoading}
          inputRef={examRef}
          optional={optionalExam}
        >
          {state.exams.map((e) => (
            <MenuItem key={e.id} value={e.id}>
              {e.examType.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
}
