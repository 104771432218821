/* eslint-disable */

import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import useStyles from './DiscountCardStyles';

interface RangeDto {
  boxesFrom: number | null;
  boxesTo: number | null;
  discountPercentage: number;
}

interface RuleDto {
  ruleName: string;
  ruleId: number;
  productDescription: string;
  ranges: RangeDto[];
}

interface Discount {
  name: string | null;
  familyDescription: string;
  rules: RuleDto[];
}

interface DiscountCardProps {
  discount: Discount;
}

const DiscountCard: React.FC<DiscountCardProps> = ({ discount }) => {
  const classes = useStyles();

  return (
    <Card className={classes.medicineCard}>
      <CardContent>
        <Typography variant="h5" component="div" className={classes.medicineName}>
          {discount.familyDescription}
        </Typography>
        {discount.rules.map((rule, index) => (
          <div key={index}>
            <Typography variant="h6" className={classes.ruleName}>
              {rule.ruleName}
            </Typography>
            <Typography variant="body2">
              {rule.productDescription}
            </Typography>
            {rule.ranges.map((range, rangeIndex) => (
              <Typography key={rangeIndex} variant="body2" className={classes.medicineDiscount}>
                {range.discountPercentage}% OFF
              </Typography>
            ))}
          </div>
        ))}
      </CardContent>
    </Card>
  );
};

export default DiscountCard;
