import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Grid, Paper } from '@material-ui/core';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import {
  extractValidationErrors,
  t,
  GetUser,
  RegisterUser,
  RegisterUserParams,
  userRegisterForm,
  userDoctorRegisterForm,
  ValidationError,
  User,
} from '@psp/common';

import { USER_REGISTER_ROUTE, USERS_ROUTE } from '../../../constants';
import { useLoading } from '../../../contexts/loading.context';
import Button from '../../Button';
import CrmInput from '../../CrmInput';
import Input from '../../Input';
import MaskedPhoneInput from '../../MaskedPhoneInput';
import PageTitle from '../../PageTitle';
import PageSubtitle from '../../PageSubtitle';
import StateSelect from '../../StateSelect';
import UserTypeSelect from '../../UserTypeSelect';

import { useStyles } from './styles';
import { useProgram } from '../../../contexts/program.context';
import PaperTitle from '../../PaperTitle';
import PaperContent from '../../PaperContent';

export type UserRegisterProps = {
  getUser: GetUser;
  registerUser: RegisterUser;
};

type UserRegisterState = {
  userType: string;
};

const defaultState: UserRegisterState = {
  userType: '',
};

export default function UserRegister({
  getUser,
  registerUser,
}: UserRegisterProps): JSX.Element {
  const classes = useStyles();
  const formRef = useRef({} as any);
  const [state, setState] = useState<UserRegisterState>(defaultState);
  const { isLoading, showLoading, hideLoading, showBackdrop, hideBackdrop } = useLoading();
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const { program } = useProgram();

  const handleUserTypeChange = useCallback(
    (value: string) => {
      setState({
        ...state,
        userType: value,
      });
    },
    [state],
  );

  const handleSubmit = useCallback(
    (data) => {
      showLoading();
      async function runAsync() {
        try {
          let req: RegisterUserParams;
          if (data.type === 'DOCTOR') {
            req = (await userDoctorRegisterForm.validate(data, {
              abortEarly: false,
            })) as RegisterUserParams;
          } else {
            req = (await userRegisterForm.validate(data, {
              abortEarly: false,
            })) as RegisterUserParams;
          }
          await registerUser.execute({
            id,
            ...req,
            programId: program?.id || '',
          });
          toast.success(id ? t('msg.successToUpdateUser') : t('msg.successToRegisterUser'), {
            onOpen: showBackdrop,
            onClose: hideBackdrop,
          });
          push(USERS_ROUTE);
        } catch (err) {
          let errors = {};
          if (err instanceof ValidationError) {
            errors = extractValidationErrors(err);
            formRef.current.setErrors(errors);
          } else if (err.message === 'emailAlreadyInUse') {
            toast.warning(t('err.emailAlreadyInUse'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } else {
            toast.error(id ? t('err.failedToUpdateUser') : t('err.failedToRegisterUser'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          }
        } finally {
          hideLoading();
        }
      }
      runAsync();
    },
    [id, program, state],
  );

  useEffect(() => {
    if (!id) return;
    showLoading();
    async function runAsync() {
      try {
        const user = await getUser.execute({ userId: id, programId: program?.id });
        console.log(user);
        setState({
          userType: user.type,
        });
        formRef.current.setData(user);
      } catch (err) {
        toast.error(t('err.failedToLoadUser'));
        push(USERS_ROUTE);
      } finally {
        hideLoading();
      }
    }
    runAsync();
  }, [id, program]);

  return (
    <Box className={classes.container}>
      <Paper className={classes.container}>
        <PaperTitle title={t(`navigation.${USER_REGISTER_ROUTE}`)} />
        <PaperContent>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Grid container spacing={2} direction="column">
              <Grid item xs={12} sm="auto">
                <Input
                  name="name"
                  variant="outlined"
                  label={t('name')}
                  className={classes.input}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm="auto">
                <Input
                  name="email"
                  variant="outlined"
                  label={t('email')}
                  className={classes.input}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm="auto">
                <Input
                  name="phone"
                  variant="outlined"
                  label={t('cellphone')}
                  className={classes.input}
                  disabled={isLoading}
                  autocomplete="nope"
                  InputProps={{
                    inputComponent: MaskedPhoneInput as any,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm="auto">
                <UserTypeSelect
                  name="type"
                  variant="outlined"
                  label={t('profile')}
                  displayEmpty
                  className={classes.input}
                  disabled={isLoading}
                  onSelectedValueChange={handleUserTypeChange}
                />
              </Grid>
              {state.userType === 'DOCTOR' && (
                <Grid item container spacing={2} direction="column">
                  <Grid item xs={12} sm="auto">
                    <Input
                      name="doctor.crm"
                      variant="outlined"
                      label={t('crm')}
                      className={classes.input}
                      disabled={isLoading}
                      InputProps={{
                        inputComponent: CrmInput as any,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm="auto">
                    <StateSelect
                      name="doctor.uf"
                      variant="outlined"
                      label={t('uf')}
                      fullWidth
                      displayEmpty
                      className={classes.input}
                      disabled={isLoading}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid item>
                <Button type="submit" variant="contained" color="primary">
                  {t('save')}
                </Button>
              </Grid>
            </Grid>
          </Form>
        </PaperContent>
      </Paper>
    </Box>
  );
}
