import React from 'react';
import { PatientMedicationStatus, t } from '@psp/common';

import Chip, { ChipProps } from '../Chip';

export type MedicationAdminStatusChipProps = ChipProps & {
  status: PatientMedicationStatus | 'PARTIAL';
};

export default function MedicationAdminStatusChip({
  status,
  ...props
}: MedicationAdminStatusChipProps): JSX.Element {
  const color = status === PatientMedicationStatus.OPEN
    ? 'warning'
    : status === PatientMedicationStatus.SCHEDULED
      ? 'info'
      : status === PatientMedicationStatus.REALIZED
        ? 'success'
        : status === PatientMedicationStatus.CANCELED
          ? 'error'
          : 'warning';
  return <Chip color={color} label={t(`medicationAdminStatus.${status}`)} size="small" />;
}
