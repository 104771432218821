import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { Box, Grid, Paper, Typography, Card, CardContent } from '@material-ui/core';
import { Infinity } from 'mdi-material-ui';
import { Form } from '@unform/web';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import {
  t,
  DistributeInventory,
  DistributeInventoryParams,
  DistributeInventoryXLSX,
  DistributeInventoryXLSXParams,
  GetDistributionRequiredAmount,
  GetExamGroupExams,
  GetFollowupExamGroups,
  GetInventoryAmount,
  GetProductPresentations,
  GetProgramFollowups,
  GetProgramProducts,
  GetPrograms,
  GetUsersForDistribution,
  IPaginated,
  UserInventory,
  UserType,
  InventoryType,
  GetProductPatientProcedures,
  distributeInventoryXLSXForm,
  ValidationError,
  extractValidationErrors,
} from '@psp/common';

import { useAuth } from '../../../contexts/auth.context';
import { useLoading } from '../../../contexts/loading.context';
import { INVENTORY_DISTRIBUTION_ROUTE } from '../../../constants';
import DistributionTypeSelect from '../../DistributionTypeSelect';
import Button from '../../Button';
import Input from '../../Input';
import InventoryTypeSelect from '../../InventoryTypeSelect';
import ProductSelect, { ProductSelectValues } from '../../ProductSelect';
import PatientProcedureSelect, { PatientProcedureSelectValues } from '../../PatientProcedureSelect';
import UserTypeSelect from '../../UserTypeSelect';
import UserInventoryTable from '../../UserInventoryTable';

import { useStyles } from './styles';
import { useProgram } from '../../../contexts/program.context';
import ExamSelect, { ExamSelectValues } from '../../ExamSelect';
import PaperTitle from '../../PaperTitle';
import PaperContent from '../../PaperContent';
import Base64FileUploadInput from '../../Base64FileUploadInput';
import Table from '../../Table';

export type InventoryDistributionProps = {
  distributeInventory: DistributeInventory;
  distributeInventoryXLSX: DistributeInventoryXLSX
  getDistributionRequiredAmount: GetDistributionRequiredAmount;
  getExamGroupExams: GetExamGroupExams;
  getFollowupExamGroups: GetFollowupExamGroups;
  getPrograms: GetPrograms;
  getProgramFollowups: GetProgramFollowups;
  getProgramProducts: GetProgramProducts;
  getProductPresentations: GetProductPresentations;
  getProductPatientProcedures: GetProductPatientProcedures;
  getInventoryAmount: GetInventoryAmount;
  getUsersForDistribution: GetUsersForDistribution;
};

type InventoryDistributionState = {
  inventory?: number;
  requiredAmount: number;
  users: IPaginated<UserInventory>;
  errorsDistributeVoucherXLSX: any[];
};

type FilterState = {
  filter: string;
  page: number;
  rows: number;
  timeout: number;
  groupId: string;
  itemId: string;
  userType?: UserType;
  distributionType: '' | 'INCREASE' | 'REDUCE' | 'ADD_UNITS' | 'REMOVE_UNITS';
  inventoryType: '' | InventoryType;
  amount?: number;
};

type Action =
  | { type: 'RESET' }
  | { type: 'SET_USER_TYPE'; payload: UserType }
  | { type: 'SET_PRODUCT_VALUES'; payload: ProductSelectValues }
  | { type: 'SET_USERS'; payload: IPaginated<UserInventory> }
  | { type: 'SET_INVENTORY'; payload: number }
  | { type: 'SET_REQUIRED_AMOUNT'; payload: number }
  | { type: 'SET_ERRORS_DISTRIBUTE_VOUCHER_XLSX'; payload: any[] }

const defaultState: InventoryDistributionState = {
  inventory: undefined,
  requiredAmount: 0,
  users: {
    data: [],
    total: 0,
  },
  errorsDistributeVoucherXLSX: [],
};

const CompareByEquality = (column: keyof {
  id: string;
  [key: string]: unknown;
}) => (a: {
    id: string;
    [key: string]: unknown;
  }, b: {
    id: string;
    [key: string]: unknown;
  }) => {
  if (a[column] === b[column]) {
    return 0;
  }
  return 1;
};

const columnsVoucherXLSXError = [
  {
    key: 'row',
    label: t('row'),
    compareFunction: CompareByEquality('row'),
  },
  {
    key: 'crm',
    label: t('crm'),
    compareFunction: CompareByEquality('crm'),
  },
  {
    key: 'uf',
    label: t('uf'),
    compareFunction: CompareByEquality('uf'),
  },
  {
    key: 'ean',
    label: t('ean'),
    compareFunction: CompareByEquality('ean'),
  },
  {
    key: 'amount',
    label: t('amount'),
    compareFunction: CompareByEquality('amount'),
  },
  {
    key: 'errors',
    label: t('errors'),
    compareFunction: CompareByEquality('errors'),
    render: (value: string[]) => value.join(', '),
  },
];

const reducer = (state: InventoryDistributionState, action: Action): InventoryDistributionState => {
  switch (action.type) {
    case 'RESET':
      return {
        ...defaultState,
      };
    case 'SET_PRODUCT_VALUES':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_USERS':
      return {
        ...state,
        users: action.payload,
      };
    case 'SET_INVENTORY':
      return {
        ...state,
        inventory: action.payload,
      };
    case 'SET_REQUIRED_AMOUNT':
      return { ...state, requiredAmount: action.payload };
    case 'SET_ERRORS_DISTRIBUTE_VOUCHER_XLSX':
      return { ...state, errorsDistributeVoucherXLSX: action.payload };
    default:
      return state;
  }
};

const defaultFilterState: FilterState = {
  filter: '',
  page: 0,
  rows: 10,
  timeout: 0,
  groupId: '',
  itemId: '',
  distributionType: '',
  inventoryType: '',
};

export default function InventoryDistribution({
  distributeInventory,
  distributeInventoryXLSX,
  getDistributionRequiredAmount,
  getInventoryAmount,
  getUsersForDistribution,
  ...dispatchers
}: InventoryDistributionProps): JSX.Element {
  const classes = useStyles();
  const formRef = useRef({} as any);
  const xlsxFormRef = useRef({} as any);
  const xlsxUploadRef = useRef<{
    resetFileInput:() => void;
    setError:(errorMessage: string) => void;
      }>(null);
  const [state, dispatch] = useReducer(reducer, defaultState);
  const [filterState, setFilterState] = useState<FilterState>(defaultFilterState);
  const { showLoading, hideLoading, showBackdrop, hideBackdrop } = useLoading();
  const { isSysadmin, isAdmin, role } = useAuth();
  const { program } = useProgram();

  useEffect(() => {
    const handler = setTimeout(() => {
      (async () => {
        try {
          if (
            !program
            || !filterState.groupId
            || !filterState.userType
            || !filterState.inventoryType
          ) return;
          const users = await getUsersForDistribution.execute({
            filter: filterState.filter,
            skip: filterState.page * filterState.rows,
            take: filterState.rows,
            inventoryType: filterState.inventoryType,
            userType: filterState.userType || UserType.DOCTOR,
            programId: program.id,
            groupId: filterState.groupId,
            itemId: filterState.itemId,
          });
          dispatch({ type: 'SET_USERS', payload: users });
          const inventory = await getInventoryAmount.execute({
            inventory: filterState.inventoryType,
            groupId: filterState.groupId,
            itemId: filterState.itemId,
          });
          dispatch({ type: 'SET_INVENTORY', payload: inventory });
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    }, filterState.timeout);
    return () => clearTimeout(handler);
  }, [filterState, program]);

  const handleSearch = useCallback(
    (value: string) => {
      setFilterState({
        ...filterState,
        filter: value,
        page: 0,
        rows: filterState.rows,
        timeout: 500,
      });
    },
    [filterState],
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setFilterState({
        ...filterState,
        filter: filterState.filter,
        page,
        rows: filterState.rows,
        timeout: 200,
      });
    },
    [filterState],
  );

  const handleRowsChange = useCallback(
    (rows: number) => {
      setFilterState({
        ...filterState,
        filter: filterState.filter,
        page: filterState.page,
        rows,
        timeout: 200,
      });
    },
    [filterState],
  );

  const handleSubmit = useCallback(
    (data) => {
      showLoading();
      (async () => {
        try {
          await distributeInventory.execute({
            ...data,
            programId: (program || {}).id,
            groupId: filterState.groupId,
            itemId: filterState.itemId,
            filter: filterState.filter,
          } as DistributeInventoryParams);
          toast.success(t('msg.successToDistributeInventory'), {
            onOpen: showBackdrop,
            onClose: hideBackdrop,
          });
          dispatch({ type: 'RESET' });
          setFilterState(defaultFilterState);
          formRef.current.reset();
        } catch (err) {
          console.log(err);
          toast.error(t('err.failedToDistributeInventory'), {
            onOpen: showBackdrop,
            onClose: hideBackdrop,
          });
        } finally {
          hideLoading();
        }
      })();
    },
    [filterState, program],
  );

  const handleInventoryTypeChange = useCallback(
    (value: FilterState['inventoryType']): void => {
      setFilterState({
        ...filterState,
        inventoryType: value,
      });
    },
    [filterState],
  );

  const handleDistributionTypeChange = useCallback(
    (value: FilterState['distributionType']): void => {
      setFilterState({
        ...filterState,
        distributionType: value,
      });
    },
    [filterState],
  );

  const handleAmountChange = useCallback(
    (value: any) => {
      const amount = value ? parseInt(value, 10) : 0;
      setFilterState({
        ...filterState,
        amount,
      });
    },
    [filterState],
  );

  const handleUserTypeChange = useCallback(
    (value: UserType): void => {
      setFilterState({
        ...filterState,
        userType: value,
      });
    },
    [filterState],
  );

  const handleProductChange = useCallback(
    (values: ProductSelectValues) => {
      setFilterState({
        ...filterState,
        groupId: values.productId,
        itemId: values.presentationId,
      });
    },
    [filterState],
  );

  const handleExamChange = useCallback(
    (values: ExamSelectValues) => {
      setFilterState({
        ...filterState,
        groupId: values.examGroupId,
        itemId: values.examId,
      });
    },
    [filterState],
  );

  const handlePatientProcedureChange = useCallback(
    (values: PatientProcedureSelectValues) => {
      setFilterState({
        ...filterState,
        groupId: values.productId,
        itemId: values.patientProcedureId,
      });
    },
    [filterState],
  );

  const convertErrorReportArrayToCSV = useCallback(
    (delimiter = ';') => {
      const array = state.errorsDistributeVoucherXLSX;

      const csvRows = [];

      // Get the headers (keys)
      const headers = Object.keys(array[0]);
      csvRows.push(headers.join(delimiter));

      // Loop over the rows
      for (const row of array) {
        const values = headers.map((header) => {
          let value;

          if (header === 'errors') {
            value = (row[header] ?? []).join(', ');
          } else {
            value = (row[header] ?? '').toString();
          }

          // Escape double quotes by doubling them
          value = value.replace(/"/g, '""');

          // If the value contains commas, double quotes, or line breaks, wrap it in double quotes
          if (value.search(/("|,|\n)/g) >= 0) {
            value = `"${value}"`;
          }
          return value;
        });
        csvRows.push(values.join(delimiter));
      }

      // Join the rows with new line
      return csvRows.join('\n');
    },
    [state],
  );

  const downloadErrorReportCSV = useCallback(
    () => {
      // Add BOM for UTF-8 encoding
      const csvWithBom = `\uFEFF${convertErrorReportArrayToCSV()}`;

      const csvFile = new Blob([csvWithBom], { type: 'text/csvtext/csv;charset=utf-8;' });
      const downloadLink = document.createElement('a');

      downloadLink.href = URL.createObjectURL(csvFile);
      downloadLink.download = 'Relatorio de erros.csv';

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    [state],
  );

  useEffect(() => {
    if (!program || !state.users || !filterState.inventoryType || !filterState.distributionType) {
      return;
    }
    if (isAdmin || isSysadmin) {
      dispatch({ type: 'SET_REQUIRED_AMOUNT', payload: -1 });
      return;
    }
    switch (filterState.distributionType) {
      case 'INCREASE':
        (async () => {
          try {
            showLoading();
            const data = formRef.current.getData();
            const res = await getDistributionRequiredAmount.execute({
              ...data,
              programId: program.id,
              groupId: filterState.groupId,
              itemId: filterState.itemId,
              filter: filterState.filter,
            });
            dispatch({ type: 'SET_REQUIRED_AMOUNT', payload: res });
          } catch (err) {
            toast.error(t('err.failedToDistributeInventory'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } finally {
            hideLoading();
          }
        })();
        break;
      case 'ADD_UNITS':
        dispatch({
          type: 'SET_REQUIRED_AMOUNT',
          payload: (filterState.amount || 0) * state.users.total,
        });
        break;
      case 'REDUCE':
      case 'REMOVE_UNITS':
      default:
        dispatch({ type: 'SET_REQUIRED_AMOUNT', payload: 0 });
        break;
    }
  }, [filterState, state.users]);

  const handleXlsxSubmit = useCallback(
    (event: any): void => {
      if (!program) return;
      showLoading();
      xlsxFormRef.current.setErrors({});
      (async () => {
        try {
          const data = xlsxFormRef.current.getData();
          const req = (await distributeInventoryXLSXForm.validate(
            {
              xlsxFile: data.xlsxFile,
            },
            {
              abortEarly: false,
            },
          )) as any;

          const xlsxUploadResult = await distributeInventoryXLSX.execute({
            file: data.xlsxFile,
            programId: program.id,
          } as DistributeInventoryXLSXParams);

          if (xlsxUploadResult && xlsxUploadResult.Success) {
            toast.success(t('msg.successToDistributeUploadXlsx'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
            xlsxFormRef.current.reset();
            xlsxUploadRef.current?.resetFileInput();
            dispatch({ type: 'SET_ERRORS_DISTRIBUTE_VOUCHER_XLSX', payload: [] });
          } else if (xlsxUploadResult && xlsxUploadResult.Errors.length > 0) {
            console.log(xlsxUploadResult);
            dispatch({ type: 'SET_ERRORS_DISTRIBUTE_VOUCHER_XLSX', payload: xlsxUploadResult.Errors });
            xlsxUploadRef.current?.resetFileInput();
            throw new Error('InvalidXlsxFileCheckErrorReport');
          } else {
            throw new Error('FailedToUploadXlsxFile');
          }
        } catch (err: any) {
          let errors = {};
          if (err instanceof ValidationError) {
            errors = extractValidationErrors(err);
            xlsxFormRef.current.setErrors(errors);

            if (err.inner.find((e) => e.path === 'xlsxFile' && e.message === 'validation.requiredField')) {
              xlsxUploadRef.current?.setError(t('validation.requiredField'));
            }
          } else if (err.name === 'BadRequestError') {
            toast.info(err.message, {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } else if (err.message === 'InvalidXlsxFileCheckErrorReport') {
            toast.warn(t('err.invalidXlsxFileCheckErrorReport'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } else {
            toast.info(t('err.failedToUploadXlsxFile'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          }
        } finally {
          hideLoading();
        }
      })();
    },
    [program, state],
  );

  return (
    <>
      <Box className={classes.container}>
        <Paper className={classes.container}>
          <PaperTitle title={t(`navigation.${INVENTORY_DISTRIBUTION_ROUTE}`)} />
          <PaperContent>
            <Form
              onSubmit={handleSubmit}
              ref={formRef}
              initialData={{ patient: { acceptSms: true, acceptEmail: true } }}
            >
              <Grid container item spacing={2} direction="column">
                <Grid container item spacing={2} direction="row">
                  <Grid container item spacing={2} direction="row">
                    <Grid item xs={12} sm={3} md={2}>
                      <InventoryTypeSelect
                        name="inventoryType"
                        variant="outlined"
                        fullWidth
                        onSelectedValueChange={handleInventoryTypeChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} md={2}>
                      <UserTypeSelect
                        name="userType"
                        variant="outlined"
                        fullWidth
                        off={[role]}
                        label={t('destinationProfile')}
                        onSelectedValueChange={handleUserTypeChange}
                      />
                    </Grid>
                  </Grid>
                  {filterState.inventoryType === InventoryType.FIRST_MEDICINE && (
                    <Grid item xs={12} sm={8} md={6} lg={4}>
                      <ProductSelect
                        {...dispatchers}
                        onSelectedValueChange={handleProductChange}
                        optionalPresentation
                        formRef={formRef}
                      />
                    </Grid>
                  )}
                  {filterState.inventoryType === InventoryType.CLINICAL_EXAMINATION && (
                    <Grid item xs={12} sm={8} md={6} lg={4}>
                      <ExamSelect
                        {...dispatchers}
                        onSelectedValueChange={handleExamChange}
                        optionalExam
                        formRef={formRef}
                      />
                    </Grid>
                  )}
                  {filterState.inventoryType === InventoryType.PATIENT_PROCEDURE && (
                    <Grid item xs={12} sm={8} md={6} lg={4}>
                      <PatientProcedureSelect
                        {...dispatchers}
                        onSelectedValueChange={handlePatientProcedureChange}
                        optionalPatientProcedure
                        formRef={formRef}
                      />
                    </Grid>
                  )}
                  {!!filterState.userType && (
                    <Grid item xs={12}>
                      <UserInventoryTable
                        count={state.users.total}
                        data={state.users.data}
                        userType={filterState.userType}
                        canSearch
                        onChangePage={handlePageChange}
                        onChangeRows={handleRowsChange}
                        onSearch={handleSearch}
                        page={filterState.page}
                        rowsPerPage={filterState.rows}
                        distribution={{
                          type: filterState.distributionType,
                          amount: filterState.amount,
                        }}
                      />
                    </Grid>
                  )}
                  {state.inventory !== undefined && (
                    <Grid container item spacing={2} direction="row">
                      <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={2}
                        spacing={1}
                        direction="column"
                      >
                        <Grid item>
                          <DistributionTypeSelect
                            name="distributionType"
                            variant="outlined"
                            fullWidth
                            onSelectedValueChange={handleDistributionTypeChange}
                          />
                        </Grid>
                        <Grid item>
                          <Input
                            label={t('amount')}
                            name="amount"
                            variant="outlined"
                            type="number"
                            fullWidth
                            onValueChange={handleAmountChange}
                            error={state.requiredAmount > state.inventory}
                            helperText={
                              state.requiredAmount > state.inventory ? t('insufficientInventory') : ''
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm />
                      {state.inventory !== undefined && (
                        <Grid item xs={12} sm="auto">
                          <Card
                            variant="outlined"
                            className={clsx(classes.card, {
                              [classes.redCard]: !isAdmin && !isSysadmin && state.inventory === 0,
                            })}
                          >
                            <CardContent>
                              <Typography variant="body1" align="center">
                                <strong>Estoque disponível</strong>
                              </Typography>
                              <Typography variant="h5" component="h2" color="inherit" align="center">
                                {isSysadmin || isAdmin ? (
                                  <Infinity />
                                ) : (
                                  <strong>{state.inventory}</strong>
                                )}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      )}
                      {state.users && (
                        <Grid item xs={12} sm="auto">
                          <Card variant="outlined" className={classes.card}>
                            <CardContent>
                              <Typography variant="body1" align="center">
                                <strong>{t('selectedUsers')}</strong>
                              </Typography>
                              <Typography variant="h5" component="h2" color="inherit" align="center">
                                <strong>{state.users.total}</strong>
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      )}
                      {filterState.distributionType && state.requiredAmount > 0 && (
                        <Grid item xs={12} sm="auto">
                          <Card
                            variant="outlined"
                            className={clsx(classes.card, {
                              [classes.redCard]: state.requiredAmount > state.inventory,
                              [classes.yellowCard]: state.requiredAmount === state.inventory,
                            })}
                          >
                            <CardContent>
                              <Typography variant="body1" align="center">
                                <strong>{t('requiredInventory')}</strong>
                              </Typography>
                              <Typography variant="h5" component="h2" color="inherit" align="center">
                                <strong>{state.requiredAmount}</strong>
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      )}
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={
                        (filterState.amount || 0) <= 0
                        || state.requiredAmount > (state.inventory || 0)
                      }
                    >
                      {t('distribute')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </PaperContent>
        </Paper>
      </Box>
      <Box className={classes.container} style={{ marginTop: '30px' }}>
        <Paper className={classes.container}>
          <PaperTitle title={t('pages.movements.distributeInventoryXlsx')} />
          <PaperContent>
            <Form onSubmit={handleXlsxSubmit} ref={xlsxFormRef}>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <Typography variant="body1">Passo 1: Baixe o arquivo template e preencha com os dados</Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      window.open('https://bcarestorage.blob.core.windows.net/public/TemplateDistribuicaoDeVouchers.xlsx', '_blank', 'noopener,noreferrer');
                    }}
                  >
                    Baixar template
                  </Button>
                </Grid>
                <Grid item>
                  <Typography variant="body1">Passo 2: Faça o upload do arquivo preenchido</Typography>
                </Grid>
                <Grid item>
                  <Base64FileUploadInput name="xlsxFile" ref={xlsxUploadRef} />
                </Grid>
                <Grid item>
                  <Typography variant="body1">Passo 3: Confirme a distribuição clicando em salvar</Typography>
                </Grid>
                <Grid item>
                  <Button onClick={handleXlsxSubmit} variant="contained" color="primary">
                    {t('save')}
                  </Button>
                </Grid>
              </Grid>
            </Form>

            {state.errorsDistributeVoucherXLSX.length > 0 && (
              <Box style={{ marginTop: '30px' }}>
                <Table
                  columns={columnsVoucherXLSXError}
                  data={state.errorsDistributeVoucherXLSX}
                  title={t('errorsReport')}
                  size="small"
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    downloadErrorReportCSV();
                  }}
                  style={{ marginTop: '30px' }}
                >
                  Baixar relatório de erros
                </Button>
              </Box>
            )}
          </PaperContent>
        </Paper>
      </Box>
    </>
  );
}
