import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Form } from '@unform/web';
import {
  t,
  saveUserGroupForm,
  extractValidationErrors,
  SaveUserGroup,
  DeleteUserGroup,
  GetUserGroups,
  UserGroup,
  ValidationError,
} from '@psp/common';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { CheckboxBlankCircleOutline, CheckboxMarkedCircle } from 'mdi-material-ui';
import { useLoading } from '../../contexts/loading.context';
import { useStyles } from './styles';
import UserGroupsTable from '../UserGroupsTable';
import Button from '../Button';
import { useProgram } from '../../contexts/program.context';

export type UserGroupsProps = {
  saveUserGroup: SaveUserGroup;
  deleteUserGroup: DeleteUserGroup;
  getUserGroups: GetUserGroups;
};

type UserGroupsState = {
  userGroups: UserGroup[];
  userGroupId?: string;
  name: string;
  defaultForNewUsers: boolean;
  specialty: boolean;
  adding: boolean;
  editing: boolean;
};

const defaultState: UserGroupsState = {
  userGroups: [],
  userGroupId: undefined,
  name: '',
  defaultForNewUsers: false,
  specialty: false,
  adding: false,
  editing: false,
};

export default function UserGroups({
  saveUserGroup,
  deleteUserGroup,
  getUserGroups,
}: UserGroupsProps): JSX.Element {
  const classes = useStyles();
  const formRef = useRef({} as any);
  const { showLoading, hideLoading } = useLoading();
  const { program } = useProgram();
  const { goBack } = useHistory();
  const [state, setState] = useState<UserGroupsState>(defaultState);

  const loadUserGroups = useCallback(
    (programId) => {
      showLoading();
      (async () => {
        try {
          const userGroups = await getUserGroups.execute({ programId });
          setState({
            ...defaultState,
            userGroups,
          });
        } catch (err) {
          console.log(err);
          toast.error(t('err.failedToLoadUserGroups'));
          goBack();
        } finally {
          hideLoading();
        }
      })();
    },
    [state],
  );

  /* eslint-disable-next-line */
  const handleSubmit = useCallback(() => { }, []);

  const handleCloseAddDialog = useCallback(() => {
    formRef.current.reset();

    setState({
      ...defaultState,
      userGroups: state.userGroups,
    });
  }, [state]);

  const handleConfirmAddDialog = useCallback(() => {
    if (!program) return;
    showLoading();
    (async () => {
      try {
        const { userGroupId, name, defaultForNewUsers, specialty } = state;

        await saveUserGroupForm.validate(
          {
            name,
            defaultForNewUsers,
            specialty,
          },
          { abortEarly: false },
        );
        await saveUserGroup.execute({
          userGroupId,
          clientId: program.client.id,
          programId: program.id,
          name,
          defaultForNewUsers,
          specialty,
        });
        loadUserGroups(program?.id);
        handleCloseAddDialog();
      } catch (err) {
        console.log(err);
        let errors = {};
        if (err instanceof ValidationError) {
          errors = extractValidationErrors(err);
          formRef.current.setErrors(errors);
        } else {
          toast.error(t('err.failedToSaveUserGroups'));
        }
        hideLoading();
      }
    })();
  }, [program, state]);

  const handleOnRemove = useCallback(
    (item: any) => {
      showLoading();
      (async () => {
        try {
          await deleteUserGroup.execute({
            userGroupId: item.id,
          });
          loadUserGroups(program?.id);
        } catch (err) {
          console.log(err);
          toast.error(t('err.failedToDeleteUserGroups'));
          hideLoading();
        }
      })();
    },
    [program],
  );

  const handleNameChange = useCallback(
    (event) => {
      if (!event) return;
      setState({
        ...state,
        name: event.target.value,
      });
    },
    [state],
  );

  const handleDefaultForNewUsersChange = useCallback(
    (event, checked) => {
      if (!event) return;
      setState({
        ...state,
        defaultForNewUsers: checked,
      });
    },
    [state],
  );

  const handleSpecialtyChange = useCallback(
    (event, checked) => {
      if (!event) return;
      setState({
        ...state,
        specialty: checked,
      });
    },
    [state],
  );

  const handleAdd = useCallback(
    () => {
      setState({
        ...defaultState,
        userGroups: state.userGroups,
        adding: true,
      });
    },
    [state],
  );

  const handleEdit = (userGroup: UserGroup) => {
    setState({
      ...state,
      userGroupId: userGroup.id,
      name: userGroup.name,
      defaultForNewUsers: userGroup.defaultForNewUsers,
      specialty: userGroup.specialty,
      userGroups: state.userGroups,
      editing: true,
    });
  };

  useEffect(() => {
    if (program) {
      loadUserGroups(program.id);
    }
  }, [program]);

  return (
    <Box className={classes.container}>
      <UserGroupsTable
        data={state.userGroups}
        handleAdd={handleAdd}
        handleEdit={handleEdit}
        onRemove={handleOnRemove}
      />

      <Form ref={formRef} onSubmit={handleSubmit}>
        <Dialog open={state.adding || state.editing} onClose={handleCloseAddDialog}>
          <DialogTitle>
            {(state.adding ? t('addUserGroup') : t('editUserGroup'))}
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2} direction="column">
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  value={state.name}
                  onChange={handleNameChange}
                  size="small"
                  label={t('name')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  value={program?.name}
                  size="small"
                  label={t('program')}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={state.defaultForNewUsers}
                        onChange={handleDefaultForNewUsersChange}
                        icon={<CheckboxBlankCircleOutline />}
                        checkedIcon={<CheckboxMarkedCircle />}
                      />
                    )}
                    label={t('defaultForNewUsers')}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={state.specialty}
                        onChange={handleSpecialtyChange}
                        icon={<CheckboxBlankCircleOutline />}
                        checkedIcon={<CheckboxMarkedCircle />}
                      />
                    )}
                    label={t('isSpecialty')}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAddDialog} color="primary">
              {t('cancel')}
            </Button>
            <Button onClick={handleConfirmAddDialog} color="primary">
              {t('confirm')}
            </Button>
          </DialogActions>
        </Dialog>
      </Form>
    </Box>
  );
}
