import React from 'react';
import { t } from '@psp/common';

import Breadcrumbs from '../../../components/Breadcrumbs';
import UserGroupPermissionsComposer from '../../../composers/UserGroupPermissionsComposer';
import { USER_GROUP_PERMISSIONS_ROUTE } from '../../../constants';

export default function UserGroupPermissionsPage(): JSX.Element {
  return (
    <>
      <Breadcrumbs title={t(`navigation.${USER_GROUP_PERMISSIONS_ROUTE}`)} />
      <UserGroupPermissionsComposer />
    </>
  );
}
