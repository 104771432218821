import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  list: {
    // position: 'fixed',
    // left: 0,
    // transition: theme.transitions.create(['transform'], {
    //   easing: theme.transitions.easing.easeOut,
    //   duration: theme.transitions.duration.short,
    // }),
  },
  listItem: {
    paddingLeft: 12,
  },
  icon: {
    marginLeft: 10,
  },
  menu: {
    minWidth: 150,
  },
  itemText: {
    marginTop: 2,
    marginBottom: 2,
  },
  primary: {
    fontSize: 12,
  },
  secondary: {
    fontSize: 18,
    marginTop: -6,
  },
}));
