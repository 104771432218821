import React from 'react';
import { t } from '@psp/common';

import Breadcrumbs from '../../../components/Breadcrumbs';
import { PRESENTATION_REGISTER_ROUTE } from '../../../constants';
import PresentationRegisterComposer from '../../../composers/PresentationRegisterComposer';

export default function PresentationRegisterPage(): JSX.Element {
  return (
    <>
      <Breadcrumbs title={t(`navigation.${PRESENTATION_REGISTER_ROUTE}`)} />
      <PresentationRegisterComposer />
    </>
  );
}
