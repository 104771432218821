import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import {
  t,
  GetProgramSupplyGroups,
  GetSupplyGroupSupplies,
  SupplyGroup,
  Supply,
  Institution,
} from '@psp/common';

import { useLoading } from '../../contexts/loading.context';
import Select from '../Select';
import { useProgram } from '../../contexts/program.context';

export type SupplySelectValues = {
  supplyGroupId: string;
  supplyId: string;
  supply?: Supply;
  institutionId: string;
  institution?: Institution;
};

export type SupplySelectProps = {
  getProgramSupplyGroups: GetProgramSupplyGroups;
  getSupplyGroupSupplies: GetSupplyGroupSupplies;
  onSelectedValueChange: (values: SupplySelectValues) => void;
  optionalSupplyGroup?: boolean;
  optionalSupply?: boolean;
  settingPermissions?: boolean;
  formRef: any;
  direction?: 'column' | 'row';
};

type SupplySelectState = {
  supplyGroups: SupplyGroup[];
  supplyGroupId: string;
  supplies: Supply[];
  supplyId: string;
  institutions: Institution[];
  institutionId: string;
};

const defaultState: SupplySelectState = {
  supplyGroups: [],
  supplyGroupId: '',
  supplies: [],
  supplyId: '',
  institutions: [],
  institutionId: '',
};

export default function SupplySelect({
  getProgramSupplyGroups,
  getSupplyGroupSupplies,
  onSelectedValueChange,
  optionalSupplyGroup = false,
  optionalSupply = false,
  settingPermissions = false,
  formRef,
  direction = 'row',
}: SupplySelectProps): JSX.Element {
  const supplyGroupRef: any = useRef(null);
  const supplyRef: any = useRef(null);
  const institutionRef: any = useRef(null);
  const [state, setState] = useState<SupplySelectState>(defaultState);
  const { showLoading, hideLoading, isLoading } = useLoading();
  const { program } = useProgram();

  useEffect(
    useCallback(() => {
      if (!program) return;
      showLoading();
      setState({
        ...defaultState,
      });
      (async () => {
        try {
          const supplyGroups = await getProgramSupplyGroups.execute({ programId: program?.id });
          setState({
            ...defaultState,
            supplyGroups,
          });
          onSelectedValueChange({
            supplyGroupId: '',
            supplyId: '',
            supply: undefined,
            institutionId: '',
            institution: undefined,
          });
          formRef.current.setFieldValue('supplyGroupId', null);
          formRef.current.setFieldValue('supplyId', null);
          formRef.current.setFieldValue('institutionId', null);
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    }, [state, onSelectedValueChange]),
    [program],
  );

  const handleSupplyGroupChange = useCallback(
    (value) => {
      if (!value) return;
      showLoading();
      setState({
        ...state,
        supplies: [],
        institutions: [],
        institutionId: '',
      });
      (async () => {
        try {
          const supplies = await getSupplyGroupSupplies.execute({ supplyGroupId: value });
          setState({
            ...state,
            supplyGroupId: value,
            supplies,
            institutions: [],
            institutionId: '',
          });
          onSelectedValueChange({
            supplyGroupId: value,
            supplyId: '',
            supply: undefined,
            institutionId: '',
            institution: undefined,
          });
          formRef.current.setFieldValue('supplyId', null);
          formRef.current.setFieldValue('institutionId', null);
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
          supplyGroupRef.current.focus();
        }
      })();
    },
    [state, onSelectedValueChange],
  );

  const handleSupplyChange = useCallback(
    (value) => {
      if (!value) return;
      showLoading();
      setState({
        ...state,
      });
      (async () => {
        try {
          setState({
            ...state,
            supplyId: value,
            institutions: state.supplies.find((p) => p.id === value)?.institutions ?? [],
            institutionId: '',
          });
          onSelectedValueChange({
            supplyGroupId: state.supplyGroupId,
            supplyId: value,
            supply: state.supplies.find((p) => p.id === value),
            institutionId: '',
            institution: undefined,
          });
          formRef.current.setFieldValue('institutionId', null);
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
          if (!settingPermissions) {
            institutionRef.current.focus();
          }
        }
      })();
    },
    [state, onSelectedValueChange],
  );

  const handleInstitutionChange = useCallback(
    (value) => {
      if (!value) return;
      showLoading();
      setState({
        ...state,
      });
      (async () => {
        try {
          setState({
            ...state,
            institutionId: value,
          });
          onSelectedValueChange({
            supplyGroupId: state.supplyGroupId,
            supplyId: state.supplyId,
            supply: state.supplies.find((p) => p.id === state.supplyId),
            institutionId: value,
            institution: state.institutions.find((p) => p.id === value),
          });
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    },
    [state, onSelectedValueChange],
  );

  return (
    <Grid container spacing={2} direction={direction}>
      <Grid item xs={12} md={direction === 'column' ? 12 : 4} lg={direction === 'column' ? 12 : 4}>
        <Select
          label={t('supplyGroup')}
          variant="outlined"
          name="supplyGroupId"
          fullWidth
          onSelectedValueChange={handleSupplyGroupChange}
          disabled={state.supplyGroups.length === 0 || isLoading}
          inputRef={supplyGroupRef}
          optional={optionalSupplyGroup}
          preSelected={state.supplyGroups.length === 1 ? state.supplyGroups[0].id : null}
        >
          {state.supplyGroups.map((p) => (
            <MenuItem key={p.id} value={p.id}>
              {p.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} md={direction === 'column' ? 12 : 4} lg={direction === 'column' ? 12 : 4}>
        <Select
          label={t('supply')}
          variant="outlined"
          name="supplyId"
          fullWidth
          onSelectedValueChange={handleSupplyChange}
          disabled={state.supplies.length === 0 || isLoading}
          inputRef={supplyRef}
          optional={optionalSupply}
          preSelected={state.supplies.length === 1 ? state.supplies[0].id : null}
        >
          {state.supplies.map((p) => (
            <MenuItem key={p.id} value={p.id}>
              {p.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      {!settingPermissions
      && (
        <Grid item xs={12} md={direction === 'column' ? 12 : 4} lg={direction === 'column' ? 12 : 4}>
          <Select
            label={t('institution')}
            variant="outlined"
            name="institutionId"
            fullWidth
            onSelectedValueChange={handleInstitutionChange}
            disabled={state.supplies.length === 0 || isLoading}
            inputRef={institutionRef}
            optional={false}
            preSelected={state.institutions.length === 1 ? state.institutions[0].id : null}
          >
            {state.institutions.map((p) => (
              <MenuItem key={p.id} value={p.id}>
                {p.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      )}
    </Grid>
  );
}
