"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
__exportStar(require("./get-procedure"), exports);
__exportStar(require("./get-procedure-remotely"), exports);
__exportStar(require("./get-procedures-count"), exports);
__exportStar(require("./get-procedures-count-remotely"), exports);
__exportStar(require("./get-procedure-accredited"), exports);
__exportStar(require("./get-procedure-accredited-remotely"), exports);
__exportStar(require("./get-procedure-authorizer-info"), exports);
__exportStar(require("./get-procedure-authorizer-info-remotely"), exports);
__exportStar(require("./get-procedure-by-code"), exports);
__exportStar(require("./get-procedure-by-code-remotely"), exports);
__exportStar(require("./get-program-procedures"), exports);
__exportStar(require("./get-program-procedures-remotely"), exports);
__exportStar(require("./procedure"), exports);
__exportStar(require("./procedure-agreement-types"), exports);
__exportStar(require("./procedure-logistic-types"), exports);
__exportStar(require("./procedure-authorizer-info"), exports);
__exportStar(require("./procedure-count"), exports);
__exportStar(require("./procedure-status"), exports);
__exportStar(require("./procedure-type"), exports);
__exportStar(require("./procedure-field-type"), exports);
__exportStar(require("./register-procedure"), exports);
__exportStar(require("./register-procedures-remotely"), exports);
__exportStar(require("./update-procedure-patient"), exports);
__exportStar(require("./update-procedure-patient-remotely"), exports);
__exportStar(require("./update-procedure-status"), exports);
__exportStar(require("./update-procedure-status-remotely"), exports);
__exportStar(require("./procedure-subStatus"), exports);
