import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  cardContainer: {
    backgroundColor: '#FAFBFB',
    borderRadius: '8px',
    padding: '20px',
    margin: '10px',
    border: '1px solid #F3F3F4',
    flex: '1 1 calc(50% - 40px)',
    boxSizing: 'border-box',
    maxWidth: 'calc(50% - 40px)',
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
    [theme.breakpoints.down('sm')]: {
      flex: '1 1 100%',
      maxWidth: '100%',
    },
  },
  cardTitle: {
    color: '#1570EF',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '18.75px',
    textAlign: 'left',
  },
  cardPrincipios: {
    color: '#555555',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16.41px',
    textAlign: 'left',
  },
}));
