import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {},
  paper: {},
  input: {
    width: '100%',
  },
  nowrap: {
    flexWrap: 'nowrap',
  },
}));
