import React from 'react';
import { t } from '@psp/common';

import Breadcrumbs from '../../../components/Breadcrumbs';
import { CLIENT_REGISTER_ROUTE } from '../../../constants';
import ClientRegisterComposer from '../../../composers/ClientRegisterComposer';

export default function ClientRegisterPage(): JSX.Element {
  return (
    <>
      <Breadcrumbs title={t(`navigation.${CLIENT_REGISTER_ROUTE}`)} />
      <ClientRegisterComposer />
    </>
  );
}
