"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateCnpj = void 0;
var getVerifierDigit = function (digits) {
    var numbers = digits.split('').map(function (n) { return parseInt(n, 10); }).reverse();
    var index = 2;
    var multiplied = numbers.reduce(function (buffer, number) {
        buffer += number * index;
        index = (index === 9 ? 2 : index + 1);
        return buffer;
    }, 0);
    var mod = multiplied % 11;
    return mod < 2 ? 0 : 11 - mod;
};
var validateCnpj = function (value) {
    if (!value)
        return false;
    var digits = value.replace(/\D/g, '');
    if (digits.length !== 14) {
        return false;
    }
    var numbersCNPJ = digits.split('');
    var number = numbersCNPJ[0];
    var repeatedNumber = true;
    for (var i = 1; i < digits.length; i++) {
        if (number !== numbersCNPJ[i]) {
            repeatedNumber = false;
        }
    }
    if (repeatedNumber === true) {
        return false;
    }
    var numbers = digits.substr(0, 12);
    numbers += getVerifierDigit(numbers);
    numbers += getVerifierDigit(numbers);
    return numbers.substr(-2) === value.substr(-2);
};
exports.validateCnpj = validateCnpj;
