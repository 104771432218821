import React from 'react';
import { t, Program } from '@psp/common';

import { PROGRAM_REGISTER_ROUTE, PROGRAM_EDIT_ROUTE } from '../../constants';
import Table, { TableProps } from '../Table';

const columns = [
  {
    key: 'name',
    label: t('name'),
    compareFunction:
            (a: {
                id: string;
                [key: string]: unknown;
            }, b: {
                id: string;
                [key: string]: unknown;
            }) => a.id.localeCompare(b.id as string),
  },
];

export type UserTableProps = {
  data: Program[];
} & Pick<
  TableProps,
  'canSearch'
  | 'count'
  | 'onChangePage'
  | 'onChangeRows'
  | 'onSearch'
  | 'page'
  | 'rowsPerPage'
  | 'onRemove'
>;

export default function ProgramTable({
  data,
  onRemove,
  ...props
}: UserTableProps): JSX.Element {
  return (
    <Table
      columns={columns}
      data={data}
      title={t('programs')}
      size="small"
      canAdd
      addRoute={PROGRAM_REGISTER_ROUTE}
      canEdit
      editRoute={PROGRAM_EDIT_ROUTE}
      canRemove
      onRemove={onRemove}
      {...props}
    />
  );
}
