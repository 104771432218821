import React, { useState, useEffect, useCallback } from 'react';
import Divider from '@material-ui/core/Divider';
import { GetProgramProducts } from '@psp/common';
import Typography from '@material-ui/core/Typography';
import Card from './MedicineLeafletCard/index';
import Filter from './MedicineLeafletFilter/index';
import GroupDivider from './MedicineLeafletGroupDivider/index';
import { useStyles } from './styles';
import { useProgram } from '../../contexts/program.context';

export type MedicineLeafletsProps = {
  getProducts: GetProgramProducts;
};

interface Medicine {
  name: string;
  activeIngredient: string;
}

interface Groups {
  [letter: string]: Medicine[];
}

export type MedicineState = {
  medicines: Medicine[];
};

const defaultMedicineState: MedicineState = {
  medicines: [],
};

export default function MedicineLeaflets({ getProducts }: MedicineLeafletsProps) {
  const classes = useStyles();
  const [filter, setFilter] = useState('');
  const [medicineState, setMedicineState] = useState<MedicineState>(defaultMedicineState);
  const { program } = useProgram();

  useEffect(() => {
    (async () => {
      try {
        const getProductsResponse = !program ? [] : await getProducts.execute(
          { programId: program.id },
        );

        const medicines: Medicine[] = getProductsResponse.map((product) => ({
          name: product.name,
          activeIngredient: `Princípio ativo: ${product.activeIngredients}`,
        }));

        setMedicineState({
          ...medicineState,
          medicines,
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, [program, getProducts]);

  const filteredMedicines = medicineState.medicines.filter(
    (medicine) => medicine.name.toLowerCase().includes(filter.toLowerCase()),
  );

  const groupedMedicines: Groups = filteredMedicines.reduce(
    (groups: Groups, medicine: Medicine) => {
      const letter = medicine.name[0].toUpperCase();
      return {
        ...groups,
        [letter]: [...(groups[letter] || []), medicine],
      };
    }, {},
  );

  return (
    <div className={classes.appContainer}>
      <Typography variant="h2" className={classes.title}>
        Bula de medicamentos
      </Typography>
      <Divider className={classes.horizontalLine} />
      <Filter filter={filter} setFilter={setFilter} />
      {Object.keys(groupedMedicines).sort().map((letter) => (
        <div key={letter}>
          <GroupDivider letter={letter} />
          <div className={classes.cardsContainer}>
            {groupedMedicines[letter].map((medicine) => (
              <Card key={medicine.name} {...medicine} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
