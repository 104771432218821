import React, { useCallback } from 'react';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import {
  t,
} from '@psp/common';

import Button from '../../Button';

export type UnselectedExamsDialogProps = {
  onCancel: () => void;
  onConfirm: () => void;
};

export default function UnselectedExamsDialog({
  onCancel,
  onConfirm,
}: UnselectedExamsDialogProps): JSX.Element {
  const handleCancelAction = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleConfirmAction = useCallback(() => {
    onConfirm();
  }, [onConfirm]);

  return (
    <Dialog open onClose={handleCancelAction}>
      <DialogTitle>
        {t('warning')}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2} direction="column">
          <Grid item xs={12}>
            {t('dialogs.unselectedExams.message')}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelAction} color="primary">
          {t('dialogs.unselectedExams.selectExams')}
        </Button>
        <Button onClick={handleConfirmAction} color="primary">
          {t('dialogs.unselectedExams.proceedWithSelectedExams')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
