import { createMuiTheme } from '@material-ui/core';
import { amber } from '@material-ui/core/colors';
import { ptBR } from '@material-ui/core/locale';

export default createMuiTheme(
  {
    palette: {
      type: 'light',
      primary: {
        main: '#135ABE',
        light: '#13B5E8',
      },
      secondary: {
        main: '#135ABE',
        light: '#13B5E8',
      },
      warning: {
        main: amber['400'],
      },
      // secondary: {
      //   // main: '#F24B0F',
      // },
      // grey: {
      //   // 300: '#D8DEEE',
      //   // 200: '#F3F6F9',
      // },
      // divider: '#D8DEEE',
      text: {
        primary: '#556275',
        // secondary: '#7B86A3',
      },
    },
    mixins: {
      toolbar: {
        minHeight: 90,
      },
    },
    shape: {
      borderRadius: 8,
    },
    typography: {
      fontFamily: 'Arial, sans-serif',
      h1: {
        fontFamily: 'Arial, sans-serif',
      },
      h2: {
        fontFamily: 'Arial, sans-serif',
      },
      h3: {
        fontFamily: 'Arial, sans-serif',
      },
      h4: {
        fontFamily: 'Arial, sans-serif',
      },
      h5: {
        fontFamily: 'Arial, sans-serif',
      },
      h6: {
        fontFamily: 'Arial, sans-serif',
      },
    },
    overrides: {
      MuiAppBar: {
        colorDefault: {
          color: '#556275',
          boxShadow: 'none',
        },
      },
      MuiDrawer: {
        paper: {
          background: '#F5F5F5',
        },
      },
      MuiTab: {
        root: {
          textTransform: 'none',
          fontWeight: 'bold',
        },
      },
      MuiPaper: {
        elevation1: {
          boxShadow: '0px 3px 6px rgba(142, 142, 142, 0.2)',
        },
      },
    },
    props: {
      MuiTabs: {
        indicatorColor: 'primary',
      },
      MuiCheckbox: {
        color: 'primary',
      },
    },
  },
  ptBR,
);
