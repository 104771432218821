import React, { useRef, useCallback } from 'react';
import {
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Button,
  makeStyles,
} from '@material-ui/core';
import { Form } from '@unform/web';
import {
  extractValidationErrors,
  t,
  ValidationError,
  AcceptPolicy,
  AcceptPolicyParams,
} from '@psp/common';
import { useHistory } from 'react-router-dom';
import createDomPurify from 'dompurify';
import CheckboxInput from '../CheckboxInput';
import { useLoading } from '../../contexts/loading.context';
import { LOGOUT_ROUTE } from '../../constants';
import { useAuth } from '../../contexts/auth.context';

type PendingPolicies = {
  id: string;
  description: string;
  url: string;
};

export type PendingUsagePoliciesProps = {
  acceptPolicy: AcceptPolicy;
};

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const domPurify = createDomPurify(window);

export default function PendingUsagePolicies({
  acceptPolicy,
}: PendingUsagePoliciesProps): JSX.Element {
  const classes = useStyles();
  const { hasPendingPolicy, pendingUsagePolicies, user } = useAuth();
  const { isLoading, showLoading, hideLoading, showBackdrop, hideBackdrop } = useLoading();
  const policiesFormRef = useRef({} as any);
  const policies = pendingUsagePolicies as PendingPolicies[];
  const history = useHistory();

  const handleLogoutClick = useCallback(() => {
    window.location.href = LOGOUT_ROUTE;
  }, []);

  const handlePoliciesSubmit = useCallback(
    (data) => {
      showLoading();
      (async () => {
        try {
          policiesFormRef.current.setErrors({});

          if (Object.values(data).includes(false)) {
            const validationErrors = ({} as any);

            Object.keys(data)
              .filter((key) => !data[key])
              .forEach((key) => {
                validationErrors[key] = t('validation.requiredTerms');
              });

            policiesFormRef.current.setErrors(validationErrors);
          } else {
            const req = {
              userId: user.userId,
              programUsagePoliciesIds: policies.map((policy) => (policy.id)),
            } as AcceptPolicyParams;
            await acceptPolicy.execute(req);

            history.go(0);
          }
        } catch (err) {
          if (err instanceof ValidationError) {
            policiesFormRef.current.setErrors(extractValidationErrors(err));
          }
        } finally {
          hideLoading();
        }
      })();
    },
    [],
  );

  return (
    <Dialog open>
      <DialogTitle id="simple-dialog-title">
        {t('pages.pendingUsagePolicies.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText align="justify">
          {t('pages.pendingUsagePolicies.explanation')}
        </DialogContentText>
        <Form ref={policiesFormRef} onSubmit={handlePoliciesSubmit}>
          <Grid container xs={12} sm="auto" spacing={1}>
            {policies.map((policy) => (
              <Grid key={policy.id} item xs={12} sm="auto">
                {policy.url && (
                  <Typography variant="body1">
                    <a
                      href={policy.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('pages.pendingUsagePolicies.readTerms', { termsDescription: policy.description })}
                    </a>
                  </Typography>
                )}
                <CheckboxInput
                  name={policy.id}
                  label={
                    policy.url
                      ? t('pages.pendingUsagePolicies.acceptTerms', {
                        termsDescription: policy.description,
                      })
                      : (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: domPurify.sanitize(
                              policy.description,
                              { ADD_ATTR: ['target'] },
                            ),
                          }}
                        />
                      )
                  }
                />
              </Grid>
            ))}
          </Grid>
          <Grid container item xs={12} sm="auto" spacing={2} justify="flex-end">
            <Grid item>
              <Button
                variant="text"
                color="default"
                size="large"
                onClick={handleLogoutClick}
                disabled={isLoading}
              >
                {t('logout')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={isLoading}
              >
                {t('confirm')}
              </Button>
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
