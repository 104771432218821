import React from 'react';
import { requestOptout } from '@psp/common';

import RemoveAccountDialog, {
  RemoveAccountDialogProps,
} from '../../components/RemoveAccountDialog';

type RemoveAccountDialogComposerProps = Omit<RemoveAccountDialogProps, 'requestOptout'>;

const dispatchers: Pick<RemoveAccountDialogProps, 'requestOptout'> = {
  requestOptout,
};

export default function RemoveAccountDialogComposer(
  props: RemoveAccountDialogComposerProps,
): JSX.Element {
  return <RemoveAccountDialog {...props} {...dispatchers} />;
}
