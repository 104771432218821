import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  default: {
    background: theme.palette.grey['400'],
  },
  primary: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  secondary: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  warning: {
    background: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  info: {
    background: theme.palette.info.main,
    color: theme.palette.info.contrastText,
  },
  success: {
    background: theme.palette.success.main,
    color: theme.palette.common.white,
  },
  error: {
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));
