import { useLocation } from 'react-router-dom';

export function useQuery<Params extends { [K in keyof Params]?: string }>(): Params {
  const params = new URLSearchParams(useLocation().search);
  let result: Params = {} as Params;
  params.forEach((v, k) => {
    result = { ...result, [k]: v };
  });
  return result;
}
