import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { TrashCan } from 'mdi-material-ui';
import clsx from 'clsx';
import { emptyFunction } from '@psp/common';

import { useStyles } from './styles';

export type FileCardProps = {
  title: string;
  description: string;
  icon?: React.ComponentType<any>;
  color?: 'default' | 'primary' | 'error';
  onClick?: () => void;
  showDelete: boolean;
  onDelete?: () => void;
};

export default function FileCard({
  title,
  description,
  icon: Icon,
  color = 'default',
  onClick = emptyFunction,
  showDelete = false,
  onDelete = emptyFunction,
}: FileCardProps): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Paper onClick={onClick} className={clsx(classes.innerContainer, classes[color])}>
        <Grid container direction="row">
          <Grid item className={classes.left}>
            <Grid container spacing={0} direction="column">
              <Grid item>
                <Grid container spacing={1} direction="column">
                  <Grid item>
                    <Typography variant="body1" color="inherit">
                      {title}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" color="inherit" className={classes.description}>
                      {description}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {Icon && <Icon className={classes.icon} />}
          </Grid>
        </Grid>
      </Paper>
      {showDelete && (
        <Grid item className={classes.deleteContainer}>
          <TrashCan onClick={onDelete} className={classes.deleteIcon} />
        </Grid>
      )}
    </div>
  );
}
