import React, { useRef, useMemo, useState, useEffect } from 'react';
import { TextField, Typography, Grid, Box, makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { LocationOn } from '@material-ui/icons';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import clsx from 'clsx';
import { GetProcedureAccredited, t } from '@psp/common';

import { useLoading } from '../../contexts/loading.context';

function loadScript(src: string, position: HTMLElement | null, id: string, onloaded: () => void) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.addEventListener('load', onloaded);
  script.src = src;
  position.appendChild(script);
}

let infoWindow: any = {};
function initMap(): any {
  infoWindow = new (window as any).google.maps.InfoWindow({ content: '' });
  return new (window as any).google.maps.Map(document.getElementById('map') as HTMLElement, {
    zoom: 4,
    center: new (window as any).google.maps.LatLng(-14.235004, -51.92528),
    mapId: '35d3e076b87bd0c1',
    mapTypeControl: false,
  });
}

let markers: any[] = [];
function clearMarkers() {
  for (let i = 0; i < markers.length; i++) {
    markers[i].setMap(null);
  }
  markers = [];
}

const autocompleteService = { current: null };
const placesService = { current: null };

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    height: '100%',
  },
  fullWidth: {
    width: '100vw',
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  padding: {
    padding: theme.spacing(2),
  },
  mapLabel: {
    WebkitTextStroke: '1px white',
  },
}));

interface PlaceType {
  description: string;
  /* eslint-disable-next-line */
  place_id: string;
  /* eslint-disable-next-line */
  structured_formatting: {
    /* eslint-disable-next-line */
    main_text: string;
    /* eslint-disable-next-line */
    secondary_text: string;
    /* eslint-disable-next-line */
    main_text_matched_substrings: [
      {
        offset: number;
        length: number;
      },
    ];
  };
}

export type PharmaciesMapProps = {
  getProcedureAccredited: GetProcedureAccredited;
};

export default function PharmaciesMap({ getProcedureAccredited }: PharmaciesMapProps): JSX.Element {
  const classes = useStyles();
  const [value, setValue] = useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<PlaceType[]>([]);
  const { code } = useParams<{ code: string }>();
  const loaded = useRef(false);
  const map = useRef<any>(undefined);
  const { showLoading, hideLoading, showBackdrop, hideBackdrop } = useLoading();

  const onLoaded = () => {
    loaded.current = true;
    map.current = initMap();
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && !loaded.current) {
      if (!document.querySelector('#google-maps')) {
        loadScript(
          'https://maps.googleapis.com/maps/api/js?key=AIzaSyC78DjSCcfhgresebV7LTozrx4FRlAq3so&libraries=places&language=pt_BR&region=BR&map_ids=35d3e076b87bd0c1&v=beta',
          document.querySelector('head'),
          'google-maps',
          onLoaded,
        );
      }
    }
  }, []);

  const fetch = useMemo(
    () => throttle((request: { input: string }, callback: (results?: PlaceType[]) => void) => {
      (autocompleteService.current as any).getPlacePredictions(
        {
          ...request,
          componentRestrictions: { country: 'br' },
        },
        callback,
      );
    }, 2000),
    [],
  );

  const fetchPlace = useMemo(
    () => throttle((request: { placeId: string }, callback: (result?: any) => void) => {
      (placesService.current as any).getDetails(request, callback);
    }, 200),
    [],
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: PlaceType[]) => {
      if (active) {
        let newOptions = [] as PlaceType[];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  useEffect(() => {
    let active = true;

    if (!placesService.current && (window as any).google) {
      placesService.current = new (window as any).google.maps.places.PlacesService(map.current);
    }
    if (!placesService.current) {
      return undefined;
    }
    if (!value || !value.place_id) {
      return undefined;
    }

    fetchPlace({ placeId: value.place_id }, (result?: any) => {
      if (active) {
        showLoading();
        (async () => {
          try {
            const request: any = {};
            result.address_components.forEach((a: any) => {
              if (a.types.includes('administrative_area_level_1')) {
                request.uf = a.short_name;
              }
              if (a.types.includes('postal_code')) {
                request.postalCode = a.long_name;
              }
            });

            if (!request.postalCode) {
              toast.info(t('msg.selectPlaceWithPostalCode'), {
                onOpen: showBackdrop,
                onClose: hideBackdrop,
              });
              hideLoading();
              return;
            }

            const pos = new (window as any).google.maps.LatLng(
              result.geometry.location.lat(),
              result.geometry.location.lng(),
            );
            map.current.setCenter(pos);
            map.current.setZoom(15);
            clearMarkers();

            const res = await getProcedureAccredited.execute({
              code,
              ...request,
            });

            if (!res.accredited || res.accredited.length === 0) {
              toast.info(t('err.accreditedNotFound'), {
                onOpen: showBackdrop,
                onClose: hideBackdrop,
              });
              hideLoading();
              return;
            }

            markers = res.accredited.map((r) => {
              const marker = new (window as any).google.maps.Marker({
                map: map.current,
                position: new (window as any).google.maps.LatLng(r.lat, r.lng),
                clickable: true,
                title: r.name,
                collisionBehavior: (window as any).google.maps.CollisionBehavior
                  .REQUIRED_AND_HIDES_OPTIONAL,
                label: {
                  text: r.name,
                  fontFamily: 'Arial, sans-serif',
                  fontWeight: 'bold',
                  fontSize: '24px',
                  color: '#0a0a96',
                  className: classes.mapLabel,
                },
                icon: {
                  url:
                    'https://www.google.com.au/maps/vt/icon/name=assets/icons/spotlight/spotlight_pin_v2_shadow-1-small.png,assets/icons/spotlight/spotlight_pin_v2-1-small.png,assets/icons/spotlight/spotlight_pin_v2_dot-1-small.png,assets/icons/spotlight/spotlight_pin_v2_accent-1-small.png&highlight=ff000000,407bff,0a0a96,ffffff&color=0000ff00',
                  labelOrigin: new (window as any).google.maps.Point(12.5, -12),
                },
              });
              marker.addListener('click', () => {
                const query = `${r.address},${r.city},${r.uf},${r.postalCode}`;
                infoWindow.setContent(`
                  <div class="poi-info-window gm-style" style="text-transform: capitalize">
                    <div class="title full-width">
                      ${r.name.toLowerCase()}
                    </div>
                    <div class="address">
                      <div class="address-line full-width">
                        ${r.address.toLowerCase()}
                      </div>
                      <div class="address-line full-width">
                        ${r.city.toLowerCase()} - ${r.uf}
                      </div>
                      <div class="address-line full-width">
                        ${r.postalCode.replace(/(\d{5})(\d{3})/, '$1-$2')}
                      </div>
                      <div class="address-line full-width">
                        ${r.phone}
                      </div>
                    </div>
                    <div class="view-link">
                      <a
                        target="_blank"
                        href="https://maps.google.com/maps?q=${query}"
                      >
                        Visualize no Google Maps
                      </a>
                    </div>
                  </div>
                `);
                infoWindow.open(map.current, marker);
              });
              return marker;
            });
          } catch (err) {
            console.log(err);
            toast.info(t('err.failedToLoadPharmacies'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } finally {
            hideLoading();
          }
        })();
      }
    });

    return () => {
      active = false;
    };
  }, [value]);

  return (
    <Grid container direction="column" className={classes.fullHeight}>
      <Grid item container justify="center" className={classes.padding}>
        <Autocomplete
          style={{ width: 300 }}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={value}
          onChange={(_event: any, newValue: PlaceType | null) => {
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue);
          }}
          onInputChange={(_event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label="CEP ou endereço" variant="outlined" fullWidth />
          )}
          renderOption={(option) => {
            const matches = option.structured_formatting.main_text_matched_substrings;
            if (!matches) {
              return <></>;
            }

            const parts = parse(
              option.structured_formatting.main_text,
              matches.map((match: any) => [match.offset, match.offset + match.length]),
            );

            return (
              <Grid container alignItems="center">
                <Grid item>
                  <LocationOn className={classes.icon} />
                </Grid>
                <Grid item xs>
                  {parts.map((part: any, index: number) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}
                  <Typography variant="body2" color="textSecondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            );
          }}
        />
      </Grid>
      <Grid item xs>
        <div id="map" className={clsx(classes.fullHeight, classes.fullWidth)} />
      </Grid>
    </Grid>
  );
}
