import React from 'react';
import { t, Product } from '@psp/common';

import { PRODUCT_REGISTER_ROUTE, PRODUCT_EDIT_ROUTE } from '../../constants';
import Table, { TableProps } from '../Table';

const columns = [
  {
    key: 'name',
    label: t('name'),
    compareFunction:
            (a: {
                id: string;
                [key: string]: unknown;
            }, b: {
                id: string;
                [key: string]: unknown;
            }) => a.id.localeCompare(b.id as string),
  },
];

export type ProductTableProps = {
  data: Product[];
} & Pick<
  TableProps,
  'canSearch'
  | 'count'
  | 'onChangePage'
  | 'onChangeRows'
  | 'onSearch'
  | 'page'
  | 'rowsPerPage'
  | 'onRemove'
>;

export default function ProductTable({
  data,
  onRemove,
  ...props
}: ProductTableProps): JSX.Element {
  return (
    <Table
      columns={columns}
      data={data}
      title={t('products')}
      size="small"
      canAdd
      addRoute={PRODUCT_REGISTER_ROUTE}
      canEdit
      editRoute={PRODUCT_EDIT_ROUTE}
      canRemove
      onRemove={onRemove}
      {...props}
    />
  );
}
