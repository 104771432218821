import React from 'react';
import {
  getProgramProductsPaged,
  deleteProduct,
} from '@psp/common';

import ProductList, { ProductListProps } from '../../components/Product/List';

const dispatchers: Pick<ProductListProps,
  'getProgramProductsPaged'
  | 'deleteProduct'
> = {
  getProgramProductsPaged,
  deleteProduct,
};

export default function ProductListComposer(): JSX.Element {
  return <ProductList {...dispatchers} />;
}
