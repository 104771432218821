import React from 'react';
import {
  dt,
  t,
  Procedure,
  Aid,
} from '@psp/common';

import Table, { TableProps } from '../Table';
import ProcedureStatusChip from '../ProcedureStatusChip';

type ColumnsProps = {
  key: string;
  label: string;
  width?: number;
  maxWidth?: number;
  render?: any;
  compareFunction: TableDataComparable;
};

export type TableDataComparable = ((
  a: {
    id: string;
    [key: string]: unknown;
  },
  b: {
    id: string;
    [key: string]: unknown;
  }) => number);

const CompareByEquality = (column: keyof {
    id: string;
    [key: string]: unknown;
  }) => (
  a: {
    id: string;
    [key: string]: unknown;
  },
  b: {
    id: string;
    [key: string]: unknown;
  },
) => {
  if (a[column] === b[column]) {
    return 0;
  }
  if ((a[column] as any).toString() > (b[column] as any).toString()) {
    return -1;
  }
  return 1;
};

function renderColumns(procedure: Procedure) {
  const columns: ColumnsProps[] = [];
  columns.push(
    {
      key: 'aid',
      label: t('aid'),
      compareFunction: CompareByEquality('aid'),
      render: (value: Aid) => value?.name,
    },
    {
      key: 'status',
      label: t('status'),
      compareFunction: CompareByEquality('status'),
      render: function ProcedureStatusRender(value: any) {
        return <ProcedureStatusChip status={value} />;
      },
    },
    {
      key: 'createdAt',
      label: t('requestDate'),
      compareFunction: CompareByEquality('createdAt'),
      render: function DateRender(value: Date) {
        return value != null ? dt(value) : '-';
      },
    },
    {
      key: 'concludedAt',
      label: t('concludedDate'),
      compareFunction: CompareByEquality('concludedAt'),
      render: function DateRender(value: Date) {
        return value != null ? dt(value) : '-';
      },
    },
  );

  return columns;
}

export type ProcedureTableProps = Pick<TableProps, 'canSelect' | 'onSelect'> & {
  data: Procedure[];
};

export default function ProcedureAidTable({ data, ...props }: ProcedureTableProps): JSX.Element {
  return <Table {...props} columns={renderColumns(data[0])} data={data} size="small" />;
}
