import React from 'react';
import { MenuItem } from '@material-ui/core';
import clsx from 'clsx';
import { t, procedureAgreementTypes } from '@psp/common';

import { useAuth } from '../../contexts/auth.context';
import Select, { SelectProps } from '../Select';
import { useStyles } from './styles';

type ProcedureAgreementTypeSelectProps = Omit<SelectProps, 'children'> & {
  off?: string[];
};

export default function ProcedureAgreementTypeSelect({
  className,
  off = [],
  ...props
}: ProcedureAgreementTypeSelectProps): JSX.Element {
  const classes = useStyles();
  const { role } = useAuth();

  return (
    <Select
      label={t('procedureAgreement')}
      className={clsx(className, classes.formControl)}
      {...props}
      classes={{ select: classes.selectContainer }}
    >
      {[
        <MenuItem value="" disabled key="disabled_item" />,
        ...procedureAgreementTypes
          .filter((pat) => !off.includes(pat))
          .map((pat: string) => (
            <MenuItem value={pat} key={pat}>
              {t(`procedureAgreementType.${pat}`)}
            </MenuItem>
          )),
      ]}
    </Select>
  );
}
