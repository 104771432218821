import React, { useCallback, useEffect, useState, useRef } from 'react';

import { Box, Button, Grid, Paper, Tooltip } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { SendPatientInvitation, t } from '@psp/common';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import Input from '../../Input';
import MaskedPhoneInput from '../../MaskedPhoneInput';
import { useStyles } from './styles';
import { useProgram } from '../../../contexts/program.context';
import { useLoading } from '../../../contexts/loading.context';
import PaperContent from '../../PaperContent';
import PaperTitle from '../../PaperTitle';
import DocumentInput from '../../DocumentInput';

export type PatientInvitationProps = {
  sendPatientInvitation: SendPatientInvitation;
};

export default function PatientInvitation({
  sendPatientInvitation,
}: PatientInvitationProps): JSX.Element {
  const classes = useStyles();
  const formRef = useRef({} as any);
  const { id } = useParams<{ id: string }>();
  const { isLoading, showLoading, hideLoading, showBackdrop, hideBackdrop } = useLoading();

  const validatePatientFormData = (formData: any): string | null => {
    if (!formData.patient.name) {
      return 'O nome do paciente é obrigatório.';
    }
    if (!formData.patient.cpf) {
      return 'O CPF do paciente é obrigatório.';
    }
    if (!formData.patient.cellphone) {
      return 'O celular do paciente é obrigatório.';
    }
    return null;
  };

  const handleSubmit = useCallback(
    (data: any): void => {
      showLoading();
      (async () => {
        try {
          const formData = formRef.current.getData();

          const validationError = validatePatientFormData(formData);
          if (validationError) {
            hideLoading();
            toast.info(validationError);
            return;
          }

          await sendPatientInvitation.execute({
            destination: formData.patient.cellphone,
            patientName: formData.patient.name,
            patientCpf: formData.patient.cpf,
          });

          toast.success(t('msg.successToInvitePatient'), {
            onOpen: showBackdrop,
            onClose: () => {
              hideBackdrop();
              window.location.reload();
            },
          });
        } catch (err) {
          console.log(err);
          toast.error('Falha ao convidar paciente', {
            onOpen: showBackdrop,
            onClose: () => {
              hideBackdrop();
              window.location.reload();
            },
          });
        } finally {
          hideLoading();
        }
      })();
    },
    [],
  );

  return (
    <Box className={classes.container}>
      <Paper className={classes.paper}>
        <PaperTitle title={t('inviteAPatient')} />
        <PaperContent>
          <Form
            onSubmit={handleSubmit}
            ref={formRef}
            initialData={{ }}
          >
            <Grid item>
              <Grid container spacing={2} direction="row" className={classes.nowrap}>
                <Grid item xs={12} sm={3} lg={2}>
                  <Input
                    name="patient.name"
                    variant="outlined"
                    label={t('name')}
                    className={classes.input}
                    disabled={isLoading}
                    autoComplete="nope"
                  />
                  <Input
                    name="patient.cpf"
                    variant="outlined"
                    label={t('cpf')}
                    className={classes.input}
                    disabled={isLoading}
                    autoComplete="nope"
                    InputProps={{
                      inputComponent: DocumentInput as any,
                    }}
                  />
                  <Input
                    name="patient.cellphone"
                    variant="outlined"
                    label={t('cellphone')}
                    className={classes.input}
                    disabled={isLoading}
                    autoComplete="nope"
                    InputProps={{
                      inputComponent: MaskedPhoneInput as any,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4} lg={3} className={classes.buttonSpacing}>
                <Button
                  onClick={handleSubmit}
                  disabled={isLoading}
                  variant="contained"
                  color="primary"
                >
                  Convidar
                </Button>
              </Grid>
            </Grid>
          </Form>
        </PaperContent>
      </Paper>
    </Box>
  );
}
