import React from 'react';
import { Grid } from '@material-ui/core';
import { Stethoscope, Key } from 'mdi-material-ui';
import { Client, t } from '@psp/common';

import { CLIENT_EDIT_ROUTE, CLIENT_REGISTER_ROUTE } from '../../constants';
import Table, { TableProps } from '../Table';

const columns = [
  {
    key: 'name',
    label: t('name'),
    maxWidth: 500,
    compareFunction:
        (a: {
            id: string;
            [key: string]: unknown;
        }, b: {
            id: string;
            [key: string]: unknown;
            }) => a.id.localeCompare(b.id as string),
  },
];

export type ClientTableProps = {
  data: Client[];
} & Pick<
  TableProps,
  'canSearch' | 'count' | 'onChangePage' | 'onChangeRows' | 'onSearch' | 'page' | 'rowsPerPage' | 'canRemove' | 'onRemove'
>;

export default function ClientTable({ data, ...props }: ClientTableProps): JSX.Element {
  return (
    <Table
      columns={columns}
      data={data}
      title={t('clients')}
      size="small"
      canAdd
      addRoute={CLIENT_REGISTER_ROUTE}
      canEdit
      editRoute={CLIENT_EDIT_ROUTE}
      canRemove
      {...props}
    />
  );
}
