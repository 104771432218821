import React from 'react';
import { ProcedureType, t } from '@psp/common';

import Breadcrumbs from '../../../../components/Breadcrumbs';
import ProcedureListComposer from '../../../../composers/ProcedureListComposer';
import { PROCEDURES_EXAMS_DETAIL_ROUTE, PROCEDURES_EXAMS_ROUTE } from '../../../../constants';

export default function ClinicalExaminationProcedureListPage(): JSX.Element {
  return (
    <>
      <Breadcrumbs title={t(`navigation.${PROCEDURES_EXAMS_ROUTE}`)} />
      <ProcedureListComposer
        type={ProcedureType.CLINICAL_EXAMINATION}
        viewRoute={PROCEDURES_EXAMS_DETAIL_ROUTE}
      />
    </>
  );
}
