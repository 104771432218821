import React from 'react';
import {
  dt,
  t,
  Procedure,
  ProcedureStatus,
  User,
  ProcedureType,
  Exam,
  Movement,
} from '@psp/common';

import Table, { TableProps } from '../Table';
import ProcedureStatusChip from '../ProcedureStatusChip';
import Chip from '../Chip';
import ProcedureSubStatusChip from '../ProcedureSubStatusChip';

type ColumnsProps = {
  key: string;
  label: string;
  width?: number;
  maxWidth?: number;
  render?: any;
  compareFunction: TableDataComparable;
};

export type TableDataComparable = ((a: {
    id: string;
    [key: string]: unknown;
}, b: {
    id: string;
    [key: string]: unknown;
    }) => number);

const CompareByEquality = (column: keyof {
    id: string;
    [key: string]: unknown;
}) => (a: {
    id: string;
    [key: string]: unknown;
}, b: {
    id: string;
    [key: string]: unknown;
}) => {
  if (a[column] === b[column]) {
    return 0;
  }
  return 1;
};

function renderColumns(procedure: Procedure) {
  const columns: ColumnsProps[] = [];
  columns.push(
    {
      key: 'exam',
      label: t('exam'),
      compareFunction: CompareByEquality('exam'),
      render: function ExamTypeRender(value: Exam) {
        return value !== null ? value.examType.name : '';
      },
    },
    {
      key: 'code',
      label: t('code'),
      compareFunction: CompareByEquality('code'),
    },
    {
      key: 'status',
      label: t('status'),
      compareFunction: CompareByEquality('status'),
      render: function ProcedureStatusRender(value: any) {
        return <ProcedureStatusChip status={value} />;
      },
    },
    {
      key: 'subStatus',
      label: 'SubStatus',
      compareFunction: CompareByEquality('subStatus'),
      render: function ProcedureStatusRender(value: any) {
        return <ProcedureSubStatusChip subStatus={value} />;
      },
    },
  );
  if (procedure.examGroup?.showInventory) {
    columns.push(
      {
        key: 'movements',
        label: t('inventory'),
        compareFunction: CompareByEquality('movements'),
        render: function ProcedureInventoryRender(value: Movement[], item: Procedure) {
          if (value.length === 0) {
            return <Chip color="error" label={t('requesterWithoutInventory')} size="small" />;
          }
          if (value[0].fromUser.id !== item.createdBy.id) {
            return (
              <Chip
                color="success"
                label={`${t(`userType.${value[0].fromUser.type}`)}: ${value[0].fromUser.name}`}
                size="small"
              />
            );
          }
          if (value[0].amount === 0) {
            return <Chip color="warning" label={t('inventoryReturned')} size="small" />;
          }
          return <Chip color="success" label={t('usedDoctorInventory')} size="small" />;
        },
      },
    );
  }
  if (procedure.examGroup?.showScheduledAt) {
    columns.push(
      {
        key: 'scheduledAt',
        label: t('scheduledDate'),
        compareFunction: CompareByEquality('scheduledAt'),
        render: function DateRender(value: Date) {
          return value != null ? dt(value) : '-';
        },
      },
    );
  }
  if (procedure.examGroup?.showCollectedAt) {
    columns.push(
      {
        key: 'collectedAt',
        label: t('collectDate'),
        compareFunction: CompareByEquality('collectedAt'),
        render: function DateRender(value: Date) {
          return value != null ? dt(value) : '-';
        },
      },
    );
  }
  if (procedure.examGroup?.showReceivedAt) {
    columns.push(
      {
        key: 'receivedAt',
        label: t('receivementDate'),
        compareFunction: CompareByEquality('receivedAt'),
        render: function DateRender(value: Date) {
          return value != null ? dt(value) : '-';
        },
      },
    );
  }
  if (procedure.examGroup?.showConcludedAt) {
    columns.push(
      {
        key: 'concludedAt',
        label: t('concludedDate'),
        compareFunction: CompareByEquality('concludedAt'),
        render: function DateRender(value: Date) {
          return value != null ? dt(value) : '-';
        },
      },
    );
  }
  if (procedure.examGroup?.showCanceledAt) {
    columns.push(
      {
        key: 'canceledAt',
        label: t('canceledDate'),
        compareFunction: CompareByEquality('canceledAt'),
        render: function DateRender(value: Date) {
          return value != null ? dt(value) : '-';
        },
      },
    );
    columns.push(
      {
        key: 'cancelationReason',
        label: t('cancelationReason'),
        compareFunction: CompareByEquality('cancelationReason'),
        render: function DateRender(value: string) {
          return value != null ? value : '-';
        },
      },
    );
  }

  if (procedure.examGroup?.showAuthorizerConsultInfo) {
    columns.push(
      {
        key: 'authorizerInfo.prazoEstimado',
        label: t('estimatedDeadline'),
        compareFunction: CompareByEquality('authorizerInfo.prazoEstimado'),
        render: function render(value: number | undefined, item: Procedure) {
          const _value = item.authorizerInfo?.prazoEstimado;
          return _value != null ? `${_value.toString()} dia(s)` : '-';
        },
      },
    );
    columns.push(
      {
        key: 'authorizerInfo.informacaoConsulta',
        label: t('consultInformation'),
        compareFunction: CompareByEquality('authorizerInfo.informacaoConsulta'),
        render: function render(value: string, item: Procedure) {
          return item.authorizerInfo?.informacaoConsulta ?? '-';
        },
      },
    );
  }

  return columns;
}

export type ProcedureTableProps = Pick<TableProps, 'canSelect' | 'onSelect'> & {
  data: Procedure[];
  selectedIds: string[];
};

export default function ProcedureExamTable({ data, ...props }: ProcedureTableProps): JSX.Element {
  return <Table {...props} columns={renderColumns(data[0])} data={data} size="small" />;
}
