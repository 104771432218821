import React from 'react';
import { getProgramProcedures } from '@psp/common';

import ProcedureList, { ProcedureListProps } from '../../components/Procedure/List';

type Dispatchers = Pick<ProcedureListProps, 'getProgramProcedures'>;

type ProcedureListComposerProps = Pick<ProcedureListProps, 'type' | 'viewRoute'>;

const dispatchers: Dispatchers = {
  getProgramProcedures,
};

export default function ProcedureListComposer(props: ProcedureListComposerProps): JSX.Element {
  return <ProcedureList {...dispatchers} {...props} />;
}
